import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import AdviceParagraph from 'src/shared/ui/paragraph/advice-paragraph/advice-paragraph';
import Button from 'src/shared/ui/button/button/button';
import ButtonClose from 'src/shared/ui/button/button-close/button-close';
import ButtonLink from 'src/shared/ui/button/button-link/button-link';
import Divisors from 'src/shared/ui/divisor/divisors/divisors';
import ArrowIcon from 'src/shared/assets/icons/arrow-icon/arrow-icon';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';
import DiscordIcon from 'src/shared/assets/icons/discord-icon/discord-icon';
import EyeIcon from 'src/shared/assets/icons/eye-icon/eye-icon';
import GlassIcon from 'src/shared/assets/icons/glass-icon/glass-icon';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import InputText from 'src/shared/ui/input/input-text/input-text';
import Select from 'src/shared/ui/select/select';
import Toogler from 'src/shared/ui/toogler/toogler';

import './components.scss';

const pageTitle = 'Компоненты';

const ComponentsPage = () => {
  useChangePageTitle(pageTitle);

  return (
    <div className="components-page">
      <div className="buttons">
        <Button background="green">Нажать</Button>
        <Button background="gray">Нажать</Button>
        <Button background="orange">Нажать</Button>
        <Button background="black">Нажать</Button>
        <Button background="white">Нажать</Button>
      </div>

      <ButtonLink>Ссылочная кнопка</ButtonLink>
      <Divisors values={[25, 50, 75, 100]} />

      <div className="icons">
        <ArrowIcon />
        <CopyIcon />
        <DiscordIcon />
        <EyeIcon />
        <GlassIcon />

        <ButtonClose onClick={() => {}} />
      </div>

      <InputForm
        title="Форма с заголовком"
        comment="Комментарий формы"
      >
        <InputText
          value=""
          placeholder="Поле для ввода"
          onChange={() => {}}
        />

      </InputForm>

      <div className="inputs-text">
        <InputText
          value=""
          placeholder="Поле для ввода активное"
          isDisabled={false}
          onChange={() => {}}
        />

        <InputText
          value=""
          placeholder="Поле для ввода неактивное"
          isDisabled
          onChange={() => {}}
        />

        <InputText
          value=""
          placeholder="Поле для ввода с иконкой"
          isDisabled
          onChange={() => {}}
        ><DiscordIcon />
        </InputText>
      </div>

      <div className="selects">
        <Select isActiveSearch={false}>
          { ['Выборка без поиска', '2', '3', '4', '5'].map((e) => <span>{e}</span>) }
        </Select>

        <Select isActiveSearch>
          { ['Выборка с поиском', '2', '3', '4', '5'].map((e) => <span>{e}</span>) }
        </Select>
      </div>

      <div className="Tooglers">
        <Toogler isActive={false} labelContent="Активная" />
        <Toogler isActive isBlocked labelContent="Неактивная" />
        <Toogler isActive labelContent="С комментарием" comment="Комментарий" />
      </div>

      <AdviceParagraph>Для выполнения действия подключите двухфакторную аутентификацию</AdviceParagraph>
    </div>
  );
};

export default ComponentsPage;
