import className from 'classnames';
import { PinInput } from '@mantine/core';
import styles from './input-code.module.scss';

  interface ICodeInputProps {
    onChange: (value: string) => void;
    error?: boolean;
    focus?: boolean;
  }

const InputCode = ({
  error, onChange, focus,
}: ICodeInputProps) => (
  <div className={className(styles.inputsWrapper, { [styles.error]: error })}>
    <PinInput autoFocus={focus} className={className(styles.gaInput, { [styles.error]: error })} size="md" error={error} inputMode="text" onChange={onChange} length={6} placeholder="-" type="number" />
  </div>
);

export default InputCode;
