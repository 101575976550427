import qs from 'qs';
import { useSelector, batch } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Tooltip, Divider } from '@mantine/core';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { Balance } from 'src/app/store/slices/balance/types';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';

import { ReactComponent as LogInIconSvg } from 'src/shared/assets/images/account/log-in.svg';
import { ReactComponent as LogOutIconSvg } from 'src/shared/assets/images/account/log-out.svg';
import { ReactComponent as RepeatIconSvg } from 'src/shared/assets/images/account/repeat.svg';
import { ReactComponent as FavoriteIconSvg } from 'src/shared/assets/images/favorite.svg';

import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { BalanceType } from 'src/entities/balance-type';
import { ETradingType, Nullable } from 'src/shared/types/global-types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import { setCurrentBalanceFrom, setCurrentBalanceTo } from 'src/widgets/transfers/model/slice';
import { toFixedDecimalPrecision, plusValues, scrollToTop } from 'src/shared/libs/helpers/helper.lib';

import useMobile from 'src/shared/libs/hooks/useMobile';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import DefaultExchangeImage from 'src/shared/assets/images/lot.png';

import styles from './subaccount.module.scss';

interface ISubAccountProps {
    subAccount: SubAccountType
    balance: Nullable<Balance>
    link: string
}
const SubAccount = ({ subAccount, balance, link }:ISubAccountProps) => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { theme } = useTheme();
  const { getExchangeImageById } = useExchange();

  const balanceVisible = useSelector(selectHiddenBalance);

  const totalBalance = balance ? plusValues(balance.futures, balance.spot) : '0.0';
  const navigateReplenishment = `/transfers?replenishment_sub_account_id=${subAccount.id}`;
  const navigateWithdrawal = `/transfers?withdrawal_sub_account_id=${subAccount.id}`;
  const navigateTransfer = `/sub-accounts?sub_account_id=${subAccount.id}&exchange_id=${subAccount.exchange_id}`;

  const handleTransfer = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();

    const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const subAccountIdQuery = Number(parsedParams?.sub_account_id);
    // bug fixs
    if (subAccountIdQuery === subAccount.id) return;

    batch(() => {
      dispatch(setCurrentBalanceFrom(null));
      dispatch(setCurrentBalanceTo(null));
    });
  };

  return (
    <div
      className={styles.subAccountCard}
      tabIndex={0}
      role="button"
      onClick={() => navigate(link)}
      onKeyDown={() => {}}
    >
      <div className={styles.title}>
        <img src={getExchangeImageById(subAccount.exchange_id) || DefaultExchangeImage} alt="exchange" />
        {subAccount.exchange_id === 2 && <BalanceType type={3} />}
        <h2>{subAccount.user_name}</h2>
        {subAccount.is_favorite && <FavoriteIconSvg />}
      </div>

      <div className={styles.subAccountBody}>
        <div className={styles.info}>
          <p className={styles.totalQuantity}>{!balanceVisible ? toFixedDecimalPrecision(String(totalBalance), USDT_PRECISION).replace(/^0$/, '0.0') : HIDDEN_BALANCES} USDT</p>

          <Divider style={{ width: '100%' }} color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#343434'} />

          <div className={styles.accounts}>

            <div className={styles.quantity}>
              <span>{balance ? !balanceVisible ? toFixedDecimalPrecision(String(balance.futures), USDT_PRECISION).replace(/^0$/, '0.0') : HIDDEN_BALANCES : 0} USDT</span>
              <BalanceType type={ETradingType.futures} />
            </div>

            <div className={styles.quantity}>
              <span>{balance ? !balanceVisible ? toFixedDecimalPrecision(String(balance.spot), USDT_PRECISION).replace(/^0$/, '0.0') : HIDDEN_BALANCES : 0} USDT</span>
              <BalanceType type={ETradingType.spot} />
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <Link
            to={navigateReplenishment}
            onClick={(e) => {
              e.stopPropagation();
              scrollToTop();
            }}
          >
            <Tooltip withArrow label="Ввод" position="left" offset={5}>
              <div className={styles.action}>
                <LogInIconSvg />
              </div>
            </Tooltip>
          </Link>

          <Link
            to={navigateWithdrawal}
            onClick={(e) => {
              e.stopPropagation();
              scrollToTop(document.body.scrollHeight);
            }}
          >
            <Tooltip withArrow label="Вывод" position="left" offset={5}>
              <div className={styles.action}>
                <LogOutIconSvg />
              </div>
            </Tooltip>
          </Link>

          {!isMobile && (
            <Link
              to={navigateTransfer}
              onClick={(e) => handleTransfer(e)}
            >
              <Tooltip withArrow label="Перевод" position="left" offset={5}>
                <div className={styles.action}>
                  <RepeatIconSvg />
                </div>
              </Tooltip>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubAccount;
