import informSlide1 from 'src/pages/auth/login/constants';
import informSlide2 from 'src/pages/auth/registration/constants';
import informSlide3 from 'src/pages/auth/verification/constants';
import informSlide4 from 'src/pages/auth/personal-data/constants';

export const informSlides = [informSlide1, informSlide2, informSlide3, informSlide4];
export const infromTitleSilde = [
  'Как получить повышенный кешбэк?',
  'Пару советов, как создать безопасный пароль:',
  'Пару советов, как обезопасить себя:',
  'Для чего нужно заполнять личные данные?',
];
