export const typeTranslate: Record<string, string> = {
  IMPORTANT: 'Важноe',
  WARNING: 'Внимание',
  NEWS: 'Новости',
};

export const fromRole: Record<string, string> = {
  SYSTEM: 'От cистемы',
  NOTIFICATION: 'Уведомление',
  ADMINISTRATOR: 'Администрация',
};
