import { batch } from 'react-redux';
import { useEffect } from 'react';
import { useAppDispatch } from 'src/app/store/store';
import { TransfersBlock } from 'src/widgets/transfers';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import {
  fetchOrders, fetchPositions, unsubscribeOrders, unsubscribePositions,
} from './model/thunks';

import Orders from './components/orders/orders';
import OpenPositions from './components/open-positions/open-positions';

import './trading.scss';

const pageTitle = 'Торговля';

const TradingPage = () => {
  useChangePageTitle(pageTitle);

  const dispatch = useAppDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(fetchPositions(true));
      dispatch(fetchOrders());
    });

    return () => {
      unsubscribePositions();
      unsubscribeOrders();
    };
  }, [dispatch]);

  return (
    <div className="trading-page-wrapper">
      <div className="trading-tables">
        <OpenPositions />
        <Orders />
      </div>
      <TransfersBlock />
    </div>
  );
};

export default TradingPage;
