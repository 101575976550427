import { useSelector } from 'react-redux';

import LanguageSelection from 'src/entities/language-selection/language-selection';
import AbstractIconsSvg from 'src/shared/assets/images/auth/abstract.svg';

import { AuthLayout } from 'src/layouts';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import { useAppDispatch } from 'src/app/store/store';
import { useLayoutEffect } from 'react';
import { informSlides, infromTitleSilde } from '../lib/helpers/constants';
import { selectRegistration } from '../registration/model/selectors';

import AuthBody from '../components/auth-body/auth-body';
import AuthTitle from '../components/auth-title/auth-title';
import AuthFooter from '../components/auth-footer/auth-footer';
import useCheckStep from '../lib/hooks/use-check-step';
import PersonalDataForm from './components/personal-data-form/personal-data-form';

import styles from './personal-data.module.scss';
import { selectSlidesPage } from '../login/model/selectors';
import { setSlidesPage } from '../login/model/slice';

const pageTitle = 'Заполните личные данные';

const PersonalDataPage = () => {
  const { email } = useSelector(selectRegistration);

  useChangePageTitle(pageTitle);
  useCheckStep(email, '/auth/registration');

  const dispatch = useAppDispatch();
  const slidePage = useSelector(selectSlidesPage);

  useLayoutEffect(() => {
    dispatch(setSlidesPage(3));
  }, []);

  return (
    <AuthLayout page="">
      <div className={styles.container}>
        <section className={styles.bodyWrapper}>
          <AuthBody
            activeSlide="fourth"
            titleUp="Личные данные"
            titleDown="под защитой"
            description="Мы берем на себя полную ответственность за сохранность ваших персональных данных"
            informList={informSlides[slidePage]}
            informTitle={infromTitleSilde[slidePage]}
          />
        </section>

        <section className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <LanguageSelection bgColor="#131313" />
            <AuthTitle visible step="third">Заполните <br /> личные данные</AuthTitle>
            <PersonalDataForm />
          </div>

          <AuthFooter />
        </section>

        <img className={styles.abstract} src={AbstractIconsSvg} alt="abstract" />
      </div>
    </AuthLayout>
  );
};

export default PersonalDataPage;
