import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTokensFromLocalStorage } from 'src/shared/libs/helpers/save-tokens';
import { getFromLocalStorage, removeItemFromLocalStorage, saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { Nullable } from 'src/shared/types/global-types';
import { IAuthSlice, User } from './types';

const tokens = getTokensFromLocalStorage();
const sessionId = getFromLocalStorage('sessionId', null);

const initialState: IAuthSlice = {
  user: null,
  isAuth: !!tokens?.access_token,
  isWelcomePage: false,
  session_id: sessionId,
  slidesPage: 0,
  sendCodeTypeVisible: false,

};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setIsWelcomePage: (state, action: PayloadAction<boolean>) => {
      state.isWelcomePage = action.payload;
    },
    setSessionId: (state, action: PayloadAction<Nullable<string>>) => {
      state.session_id = action.payload;

      if (action.payload) {
        saveToLocalStorage('sessionId', action.payload);
      } else {
        removeItemFromLocalStorage('sessionId');
      }
    },
    setSlidesPage: (state, action: PayloadAction<number>) => {
      state.slidesPage = action.payload;
    },
    setSendCodeTypeVisible: (state, action: PayloadAction<boolean>) => {
      state.sendCodeTypeVisible = action.payload;
    },
  },
});

export const {
  setUser, removeUser, setAuth, setIsWelcomePage, setSessionId, setSlidesPage, setSendCodeTypeVisible,
} = authSlice.actions;

export const authReducer = authSlice.reducer;
