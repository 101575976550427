import { LoadingOverlay } from '@mantine/core';
import './button-link.scss';

interface IButtonLinkProps {
  children: React.ReactNode;
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  loading?: boolean
  isActionDisabled?: boolean
}

const ButtonLink = ({
  children, onClick, disabled, type = 'button', loading, isActionDisabled,
}: IButtonLinkProps) => (!disabled ? (
  <div className="btn-link">
    {loading ? (
      <div className="loading-center loading-width">
        <LoadingOverlay
          className="loader"
          visible
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
          loaderProps={{ color: '#00C37C', type: 'oval' }}
        />
      </div>
    ) : (
      <button className={isActionDisabled ? 'isActionDisabled' : ''} disabled={isActionDisabled} type={type} onClick={onClick}>
        <span>{children}</span>
      </button>
    )}
  </div>
) : null);

export default ButtonLink;
