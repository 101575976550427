import { memo } from 'react';
import { Slider } from '@mantine/core';
import styles from './reduce-slider.module.scss';

const marks = [
  { value: 1, label: '1%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' },
];

interface IReduceSlider {
  value: number,
  onChange: (count: number) => void
}

const ReduceSlider = memo(({ value, onChange } : IReduceSlider) => (
  <Slider
    onChange={onChange}
    classNames={styles}
    min={0}
    max={100}
    value={value}
    thumbSize={12}
    defaultValue={50}
    step={1}
    styles={{ markLabel: { fontSize: '8px', marginTop: '5px' } }}
    marks={marks}
    size="xs"
  />
));
export default ReduceSlider;
