import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import {
  CalclulatorLeverageQueue, ILeveragesSlice, InstrumentUpdate, LeveregesCalculatorDetail, UpdateMarginModeInstrument,
} from './types';
import {
  fetchCalculate, fetchInstruments, fetchMarginMode, fetchSetInstrumentParams,
  fetchSetLeverageInstrument,
} from './thunks';

const initialState: ILeveragesSlice = {
  instruments: null,
  updateInstrument: null,
  leveregesCalculatorDetail: null,
  leveragesCalculationQueue: [],
  marginModeQueue: [],
  status: EStatus.loading,
  statusParams: EStatus.success,
  statusCalculate: EStatus.success,
  statusMarginMode: EStatus.success,
  statusSetLeverage: EStatus.success,
};

export const leveragesSlice = createSlice({
  name: 'leverages',
  initialState,
  reducers: {
    clearInstruments: (state) => {
      state.status = EStatus.loading;
      state.instruments = null;
    },
    updateInstrument: (state, action: PayloadAction<InstrumentUpdate>) => {
      const instrument = action.payload;

      if (state.instruments) {
        const updatedItems = state.instruments.items.map((item) => {
          if (item.instrument.id === instrument.instrument_id) {
            return {
              ...item,
              choosen_bracket: instrument.choosen_bracket,
            };
          }
          return item;
        });
        state.instruments.items = updatedItems;
      }
    },
    updateMarginMode: (state, action: PayloadAction<UpdateMarginModeInstrument>) => {
      state.updateInstrument = action.payload;

      if (state.instruments) {
        const itemUpdate = action.payload;
        const indexInstrument = state.instruments.items.findIndex((instrument) => instrument.instrument.id === itemUpdate.instrument_id);

        if (indexInstrument !== -1) {
          state.instruments.items[indexInstrument] = {
            ...state.instruments.items[indexInstrument],
            margin_mode: itemUpdate.margin_mode,

          };
        }
      }
    },
    addCalculationToQueue: (state, action: PayloadAction<CalclulatorLeverageQueue>) => {
      const task = action.payload;
      state.leveragesCalculationQueue.push(task);
    },
    removeCalculationFromQueue: (state, action: PayloadAction<number>) => {
      const taskId = action.payload;
      state.leveragesCalculationQueue = state.leveragesCalculationQueue.filter((task) => task.sub_account_id !== taskId);
    },
    addMarginModeToQueue: (state, action: PayloadAction<number>) => {
      const taskId = action.payload;
      state.marginModeQueue.push(taskId);
    },
    removeMarginModeQueue: (state, action: PayloadAction<number>) => {
      const taskId = action.payload;
      state.marginModeQueue = state.marginModeQueue.filter((id) => id !== taskId);
    },
    setCalculatorDetailMessage: (state, action: PayloadAction<LeveregesCalculatorDetail>) => {
      state.leveregesCalculatorDetail = action.payload;
    },
    clearCalculatorDetailMessage: (state) => {
      state.leveregesCalculatorDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstruments.pending, (state) => {
        state.status = EStatus.loading;
      })
      .addCase(fetchInstruments.fulfilled, (state, action) => {
        state.status = EStatus.success;

        if (!action.payload.items.length) {
          state.instruments = null;
        } else {
          const existingInstruments = state.instruments?.items || [];
          const newInstruments = action.payload.items;

          // New array of unique elements by the field instrument.id
          const uniqueItems = [
            ...existingInstruments,
            ...newInstruments.filter((newItem) => !existingInstruments.some((existingItem) => existingItem.instrument.id === newItem.instrument.id)),
          ];

          state.instruments = {
            ...action.payload,
            items: uniqueItems,
          };
        }
      })
      .addCase(fetchInstruments.rejected, (state) => {
        state.status = EStatus.rejected;
        state.instruments = null;
      })

      .addCase(fetchSetInstrumentParams.pending, (state) => {
        state.statusParams = EStatus.loading;
      })
      .addCase(fetchSetInstrumentParams.fulfilled, (state) => {
        state.statusParams = EStatus.success;
      })
      .addCase(fetchSetInstrumentParams.rejected, (state) => {
        state.statusParams = EStatus.rejected;
      })

      .addCase(fetchCalculate.pending, (state) => {
        state.statusCalculate = EStatus.loading;
      })
      .addCase(fetchCalculate.fulfilled, (state) => {
        state.statusCalculate = EStatus.success;
      })
      .addCase(fetchCalculate.rejected, (state) => {
        state.statusCalculate = EStatus.rejected;
      })

      .addCase(fetchMarginMode.pending, (state) => {
        state.statusMarginMode = EStatus.loading;
      })
      .addCase(fetchMarginMode.fulfilled, (state) => {
        state.statusMarginMode = EStatus.success;
      })
      .addCase(fetchMarginMode.rejected, (state) => {
        state.statusMarginMode = EStatus.rejected;
      })

      .addCase(fetchSetLeverageInstrument.pending, (state) => {
        state.statusSetLeverage = EStatus.loading;
      })
      .addCase(fetchSetLeverageInstrument.fulfilled, (state) => {
        state.statusSetLeverage = EStatus.success;
      })
      .addCase(fetchSetLeverageInstrument.rejected, (state) => {
        state.statusSetLeverage = EStatus.rejected;
      });
  },
});

export const {
  updateInstrument,
  updateMarginMode,
  clearInstruments,
  addCalculationToQueue,
  setCalculatorDetailMessage,
  removeCalculationFromQueue,
  removeMarginModeQueue,
  clearCalculatorDetailMessage,
  addMarginModeToQueue,
} = leveragesSlice.actions;
export const leveragesReducer = leveragesSlice.reducer;
