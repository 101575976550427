import moment from 'moment';
import className from 'classnames';
import {
  memo, useEffect, useRef, useState, useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { LoadingOverlay } from '@mantine/core';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { useAppDispatch } from 'src/app/store/store';
import { Exchange } from 'src/app/store/slices/exchanges/types';
import { selectRebate } from 'src/app/store/slices/bonus/selectors';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { clearRebateHistory } from 'src/app/store/slices/bonus/slice';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { RebateHistory, SortFieldBonus } from 'src/app/store/slices/bonus/types';
import { RebateHistoryParams, fetchRebateHistory } from 'src/app/store/slices/bonus/thunks';

import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as ThreePointsIconSvg } from 'src/shared/assets/images/trading/three-points.svg';

import Empty from 'src/entities/empty/empty';
import Button from 'src/shared/ui/button/button/button';
import Select from 'src/shared/ui/select/select';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';

import { EStatus, ETradingType, Nullable } from 'src/shared/types/global-types';
import { BalanceType } from 'src/entities/balance-type';
import { ColumnOrderItem } from 'src/pages/trading/model/types';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import {
  getDate, getFromLocalStorage, saveToLocalStorage, sortSubAccountsByFavorite,
} from 'src/shared/libs/helpers/helper.lib';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import {
  allExchanges, allSubAccounts, bonusHistoryColumn, bonusHistoryColumns,
} from './constants';

import styles from './rebate-history-table.module.scss';

const RebateHistoryTable = memo(() => {
  const dispatch = useAppDispatch();
  const { getSubAccountNameById } = useSubAccount();
  const { availableExchanges: exchanges } = useExchange();
  const { theme } = useTheme();

  const balanceVisible = useSelector(selectHiddenBalance);

  const subAccounts = useSelector(selectSubAccounts);
  const { rebateHistory: bonusHistory, statusRebateHistory: statusBonusHistory } = useSelector(selectRebate);

  const [currentExchange, setCurrentExchange] = useState<Nullable<Exchange>>(null);
  const [currentSubAccount, setCurrentSubAccount] = useState<Nullable<SubAccountType>>(null);

  const [fetchPage, setFetchPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortField, setSortField] = useState<SortFieldBonus | null>(null);
  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [showOptionsTable, setShowOptionsTable] = useState(false);
  const [tableColumns, setTableColumns] = useState(bonusHistoryColumns);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(bonusHistoryColumn);
  const [sortedBonusHistory, setSortedBonusHistory] = useState<RebateHistory[] | []>(bonusHistory?.items || []);
  const [subAccountsFiltered, setSubAccountsFiltered] = useState<Nullable<SubAccountType[]>>(null);

  const optionsRef = useRef<HTMLDivElement>(null);
  const optionsButtonRef = useRef<HTMLButtonElement>(null);

  useIntersectionObserver(fetchPage, bonusHistory?.meta.total_pages || 1, sortedBonusHistory, 'history-item', setFetchPage, 9);

  const { currentDateAsString, sevenDaysAgoAsString } = getDate();
  const [dateRange, setDateRange] = useState<any>([sevenDaysAgoAsString, currentDateAsString]);
  const [startDate, endDate] = dateRange;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrder = [...columnOrder];
    const [movedColumn] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedColumn);

    setColumnOrder(newOrder);

    saveToLocalStorage('BonusHistoryColumnOrder', newOrder);
  };

  const handleSort = (field: SortFieldBonus) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleClearBonusHistory = () => {
    dispatch(clearRebateHistory());
    setFetchPage(1);
  };

  const handleResetFilter = () => {
    const currentDate = moment();
    const startDateCurrent = currentDate.clone().subtract(7, 'days');

    const start = startDateCurrent.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end = currentDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const checkStartDate = moment(startDate).format('YYYY-MM-DD');
    const checkEndDate = moment(endDate).format('YYYY-MM-DD');

    if (checkStartDate === moment(start).format('YYYY-MM-DD') && checkEndDate === moment(end).format('YYYY-MM-DD') && currentExchange?.name === 'Все' && currentSubAccount?.user_name === 'Все') return;

    setSortedBonusHistory([]);
    handleClearBonusHistory();

    setCurrentExchange(allExchanges);
    setCurrentSubAccount(allSubAccounts);
    setFetchPage(1);
    setDateRange([start, end]);
  };

  const handleOption = (key: string) => {
    setTableColumns((prevTableItems) => {
      const updatedTableColumns = {
        ...prevTableItems,
        [key as keyof typeof tableColumns]: {
          ...prevTableItems[key as keyof typeof tableColumns],
          show: !prevTableItems[key as keyof typeof tableColumns].show,
        },
      };

      saveToLocalStorage('BonusHistoryTableColumns', updatedTableColumns);
      return updatedTableColumns;
    });
  };

  const handleSetCurrentSubAccount = (subAccount: SubAccountType) => {
    if (subAccount.id === currentSubAccount?.id) return;

    handleClearBonusHistory();
    setCurrentSubAccount(subAccount);
  };

  const handleSetCurrentExchange = (exchange: Exchange) => {
    handleClearBonusHistory();
    setCurrentExchange(exchange);
    handleSetCurrentSubAccount(allSubAccounts);
  };

  useEffect(() => {
    if (!subAccounts || !currentExchange) return;

    if (currentExchange?.name === 'Все') {
      setSubAccountsFiltered([allSubAccounts, ...subAccounts]);
    } else {
      const subAccountsByBybit = subAccounts.filter((subAccount) => subAccount.exchange_id === currentExchange.id);
      setSubAccountsFiltered([allSubAccounts, ...subAccountsByBybit]);
    }
  }, [subAccounts, currentExchange]);

  useEffect(() => {
    if (bonusHistory) {
      setSortedBonusHistory(bonusHistory.items);
    } else {
      setSortedBonusHistory([]);
    }
  }, [bonusHistory]);

  useEffect(() => {
    const sorted: RebateHistory[] = bonusHistory ? [...bonusHistory.items].sort((a, b) => {
      if (sortField === 'asset') {
        const assetA = a.asset.symbol;
        const assetB = b.asset.symbol;

        if (assetA < assetB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (assetA > assetB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'exchange') {
        const exchangeA = a.exchange.name;
        const exchangeB = b.exchange.name;

        if (exchangeA < exchangeB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (exchangeA > exchangeB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'quantity') {
        const exchangeA = parseFloat(a.quantity);
        const exchangeB = parseFloat(b.quantity);

        if (exchangeA < exchangeB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (exchangeA > exchangeB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'commission') {
        const exchangeA = parseFloat(a.commission);
        const exchangeB = parseFloat(b.commission);

        if (exchangeA < exchangeB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (exchangeA > exchangeB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'sub_account_id') {
        const subAccountA = getSubAccountNameById(a.sub_account_id);
        const subAccountB = getSubAccountNameById(b.sub_account_id);

        if (subAccountA < subAccountB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (subAccountA > subAccountB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField) {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (aValue !== undefined && bValue !== undefined && aValue !== null && bValue !== null) {
          if (aValue < bValue) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortOrder === 'asc' ? 1 : -1;
          }
        }
      }
      return 0;
    }) : [];

    setSortedBonusHistory(sorted);
  }, [sortField, sortOrder]);

  useEffect(() => {
    if (!currentExchange || !currentSubAccount) return;

    const fetchParams: RebateHistoryParams = {
      from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      page: fetchPage,
      limit: 10,
    };

    if (currentSubAccount.user_name !== 'Все') {
      fetchParams.sub_account_id = currentSubAccount.id;
    }

    if (currentExchange.name !== 'Все') {
      fetchParams.exchange_id = currentExchange.id;
    }

    if (startDate && endDate) {
      dispatch(fetchRebateHistory(fetchParams));
    }
  }, [startDate, endDate, fetchPage, currentSubAccount, currentExchange]);

  useEffect(() => () => {
    handleClearBonusHistory();
  }, []);

  useEffect(() => {
    const showOptionsHandler = (e: MouseEvent) => {
      e.stopPropagation();

      if (!optionsRef.current?.contains(e.target as Node) && !optionsButtonRef.current?.contains(e.target as Node)) {
        setShowOptionsTable(false);
      }
    };

    window.addEventListener('click', showOptionsHandler);

    return () => {
      window.removeEventListener('click', showOptionsHandler);
    };
  }, [showOptionsTable]);

  useLayoutEffect(() => {
    const bonusHistoryTableColumnsLocalStorage = getFromLocalStorage('BonusHistoryTableColumns', null);
    const bonusHistoryColumnOrderLocalStorage = getFromLocalStorage('BonusHistoryColumnOrder', null);

    if (bonusHistoryTableColumnsLocalStorage) {
      setTableColumns(bonusHistoryTableColumnsLocalStorage);
    }
    if (bonusHistoryColumnOrderLocalStorage) {
      setColumnOrder(bonusHistoryColumnOrderLocalStorage);
    }
  }, []);

  useLayoutEffect(() => {
    if (exchanges && subAccounts) {
      const [firstExchange] = exchanges;
      const [firstSubAccount] = subAccounts;

      if (firstExchange) {
        setCurrentExchange(allExchanges);
      }
      if (firstSubAccount) {
        setCurrentSubAccount(allSubAccounts);
      }
    }
  }, [exchanges, subAccounts]);

  return (
    <section className={styles.bonusHistory}>
      <div className={styles.title}>
        <h2>История начисления бонусов</h2>
        <div className={styles.actionsOptions}>
          <button ref={optionsButtonRef} onClick={() => setShowOptionsTable((prev) => !prev)}>
            <ThreePointsIconSvg />
          </button>
        </div>

        { showOptionsTable && (
          <div ref={optionsRef} className={styles.options}>
            <p className={styles.optionsTitle}>Настройка позиций</p>
            <ul>
              {Object.entries(tableColumns).map(([key, value]) => (
                <li
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleOption(key)}
                  onClick={() => handleOption(key)}
                  key={key}
                >
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={value.show}
                  />
                  <span>{value.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <header className={styles.header}>
        <DataPickerRange whiteMode={theme === EThemeMode.LIGHT} removeData={handleClearBonusHistory} setPage={setFetchPage} setDateRange={setDateRange} dateRange={dateRange} />
        <div className={styles.filtersWrapper}>
          <InputForm minWidth="130px" title="Биржа">
            {exchanges && currentExchange ? (
              <Select
                isActiveSearch={false}
                isSelectValue={(
                  <div className={styles.selectItem}>
                    {currentExchange.image && <img src={currentExchange.image} alt={currentExchange.image} />}
                    <span className="short-name">{currentExchange.name}</span>
                  </div>
                )}
              >
                {
                  [allExchanges, ...exchanges].map((exchange) => (
                    <div
                      className={styles.selectItem}
                      key={exchange.name}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSetCurrentExchange(exchange)}
                      onKeyDown={() => handleSetCurrentExchange(exchange)}
                    >
                      {exchange.image && <img src={exchange.image} alt={exchange.image} />}
                      <span className="short-name">{exchange.name}</span>
                    </div>
                  ))
                }
              </Select>
            ) : <SelectSkeleton /> }
          </InputForm>

          <InputForm minWidth="150px" title="Счет">
            {subAccountsFiltered && currentSubAccount ? (
              <Select
                isActiveSearch={false}
                isSelectValue={(
                  <div className={styles.selectItem}>
                    <span className="short-name">{currentSubAccount.user_name}</span>
                  </div>
                )}
              >
                { subAccountsFiltered.map((account) => (
                  <div
                    key={account.id}
                    className={styles.selectItem}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleSetCurrentSubAccount(account)}
                    onKeyDown={() => handleSetCurrentSubAccount(account)}
                  >
                    <span className="short-name">{account.user_name}</span>
                  </div>
                ))}
              </Select>
            ) : <SelectSkeleton /> }
          </InputForm>
        </div>

        <div className={styles.buttons}>
          <Button onClick={handleResetFilter} maxWidth="" background={theme === EThemeMode.LIGHT ? 'black' : 'white'}>Сбросить фильтры</Button>
        </div>
      </header>

      <div className={styles.historyTableWrapper}>

        <DragDropContext onDragEnd={onDragEnd}>
          <table className={styles.tablePositions}>
            <thead>
              <Droppable droppableId="columns" direction="horizontal">
                {(provided, snapshot) => (
                  <tr
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      background: snapshot.isDraggingOver ? (theme === 'light' ? '#131313' : '#474747') : (theme === 'light' ? '#131313' : '#474747'),
                    }}
                  >
                    {columnOrder.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index}>
                        {(provided, snapshot) => (
                          <th
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? 'transparent' : (theme === 'light' ? '#131313' : '#474747'),
                              color: snapshot.isDragging ? 'var(--color-main)' : '#BCBCBC',
                              display: snapshot.isDragging ? 'flex' : '',
                              width: column.key === 'checkbox' ? '50px' : 'auto',
                            }}
                          >
                            {tableColumns.created_at.show && column.key === 'created_at' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldBonus)}
                                onClick={() => handleSort(column.key as SortFieldBonus)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.exchange.show && column.key === 'exchange' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldBonus)}
                                onClick={() => handleSort(column.key as SortFieldBonus)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.sub_account_id.show && column.key === 'sub_account_id' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldBonus)}
                                onClick={() => handleSort(column.key as SortFieldBonus)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.quantity.show && column.key === 'quantity' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldBonus)}
                                onClick={() => handleSort(column.key as SortFieldBonus)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.commission.show && column.key === 'commission' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldBonus)}
                                onClick={() => handleSort(column.key as SortFieldBonus)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.asset.show && column.key === 'asset' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldBonus)}
                                onClick={() => handleSort(column.key as SortFieldBonus)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                          </th>
                        )}
                      </Draggable>
                    ))}
                    { provided.placeholder}
                  </tr>
                )}
              </Droppable>
            </thead>

            <tbody>
              {sortedBonusHistory.map((history) => (
                <tr key={history.id} className="history-item">
                  {columnOrder.map((column) => (
                    <td key={column.key}>
                      {tableColumns.created_at.show && column.key === 'created_at' && (
                        <div className={styles.tdWrapper}>
                          {moment(history.created_at).format('DD.MM.YYYY HH:mm')}
                        </div>
                      )}
                      {tableColumns.exchange.show && column.key === 'exchange' && (
                        <div className={styles.tdWrapper}>
                          <img src={history.exchange.image} alt="exchange" />
                        </div>
                      )}
                      {tableColumns.sub_account_id.show && column.key === 'sub_account_id' && (
                        <div className={styles.tdWrapper}>
                          {getSubAccountNameById(history.sub_account_id)}
                          <BalanceType type={history.exchange.name === 'Bybit' ? ETradingType.unified : history.trading_type} />
                        </div>
                      )}
                      {tableColumns.quantity.show && column.key === 'quantity' && (
                        <div className={styles.tdWrapper}>
                          {!balanceVisible ? history.quantity : HIDDEN_BALANCES}
                        </div>
                      )}
                      {tableColumns.commission.show && column.key === 'commission' && (
                        <div className={styles.tdWrapper}>
                          {!balanceVisible ? history.commission : HIDDEN_BALANCES}
                        </div>
                      )}
                      {tableColumns.asset.show && column.key === 'asset' && (
                        <div className={styles.tdWrapper}>
                          {history.asset.symbol}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>

          </table>
        </DragDropContext>

        {statusBonusHistory === EStatus.loading && sortedBonusHistory && sortedBonusHistory.length === 0 && (
          <div className={styles.loadingWrapper}>
            <LoadingOverlay
              className="loader"
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
        {statusBonusHistory === EStatus.success && sortedBonusHistory && sortedBonusHistory.length === 0 && (
          <Empty>
            Нет истории <br /> за выбранный период
          </Empty>
        )}
        {statusBonusHistory === EStatus.rejected && (
          <Empty>
            Произошла внутренняя ошибка <br />при получении данных
          </Empty>
        )}
        {statusBonusHistory === EStatus.loading && sortedBonusHistory && sortedBonusHistory.length > 0 && (
          <div className={styles.loadingItemWrapper}>
            <LoadingOverlay
              className={`loader ${styles.loader}`}
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
      </div>
      <div />
    </section>
  );
});

export default RebateHistoryTable;
