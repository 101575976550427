import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { ITransferHistorySlice, TransferHistoryType } from './types';
import { fetchTransfersHistory, fetchWithdrawalDetail } from './thunks';

const initialState: ITransferHistorySlice = {
  transfersHistory: null,
  status: EStatus.loading,
  withdrawalDetail: null,
  withdrawalDetailStatus: EStatus.success,
};

export const transfersHistorySlice = createSlice({
  name: 'transfersHistory',
  initialState,
  reducers: {
    addTransferHistory: (state, action: PayloadAction<TransferHistoryType>) => {
      if (state.transfersHistory) {
        state.transfersHistory.items.unshift(action.payload);
      } else {
        state.transfersHistory = {
          items: [action.payload],
          meta: {
            total_items: 1,
            total_pages: 1,
          },
        };
      }
    },
    removeTransferHistory: (state) => {
      state.transfersHistory = null;
    },
    removeWithdrawaDetail: (state) => {
      state.withdrawalDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransfersHistory.pending, (state) => {
        state.status = EStatus.loading;
      })
      .addCase(fetchTransfersHistory.fulfilled, (state, action) => {
        state.status = EStatus.success;

        if (!state.transfersHistory) {
          state.transfersHistory = action.payload;
          return;
        }
        if (state.transfersHistory) {
          const items = [...state.transfersHistory.items, ...action.payload.items];
          const uniqueItems = items.filter((item, index, self) => index === self.findIndex((obj) => obj.id === item.id));

          state.transfersHistory.items = uniqueItems;
        }
      })
      .addCase(fetchTransfersHistory.rejected, (state) => {
        state.transfersHistory = null;
        state.status = EStatus.rejected;
      })

      .addCase(fetchWithdrawalDetail.pending, (state) => {
        state.withdrawalDetailStatus = EStatus.loading;
      })
      .addCase(fetchWithdrawalDetail.fulfilled, (state, action) => {
        state.withdrawalDetailStatus = EStatus.success;
        state.withdrawalDetail = action.payload;
      })
      .addCase(fetchWithdrawalDetail.rejected, (state) => {
        state.withdrawalDetailStatus = EStatus.rejected;
        state.withdrawalDetail = null;
      });
  },
});

export const { removeTransferHistory, addTransferHistory, removeWithdrawaDetail } = transfersHistorySlice.actions;
export const transfersHistoryReducer = transfersHistorySlice.reducer;
