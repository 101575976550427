import { Asset } from 'src/app/store/slices/assets/types';
import { ColumnOrderItem } from 'src/pages/trading/model/types';
import { getFromLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { TransferHistorySortColumn } from '../model/types';

export const transferHistoryColumnLocalStorage = getFromLocalStorage('transferHistoryColumnOrder', null);

export const TRANSFER_HISTORY_COLUMNS: ColumnOrderItem[] = [
  { key: 'created_at', label: 'Дата и время' },
  { key: 'transfer_type', label: 'Тип' },
  { key: 'from_sub_account_id', label: 'Со счета' },
  { key: 'to_sub_account_id', label: 'На счет' },
  { key: 'quantity', label: 'Сумма' },
  { key: 'asset_id', label: 'Актив' },
];

export const ALL_ASSET: Asset = {
  id: 0,
  exchange_id: 0,
  symbol: 'Все',
  name: 'Все монеты',
  image: '',
  asset_type: 0,
  price_precision: 0,
  collateral_switch: true,
  is_active_to_withdrawal: true,
};

export const TRANSFER_HISTORY_SORT_COLUMNS: TransferHistorySortColumn[] = [
  {
    column: 'quantity',
    title: 'По Сумме (от большего)',
    orderBy: 'desc',
  },
  {
    column: 'quantity',
    title: 'По Сумме (от меньшего)',
    orderBy: 'asc',
  },
  {
    column: 'created_at',
    title: 'По Дате (от большего)',
    orderBy: 'desc',
  },
  {
    column: 'created_at',
    title: 'По Дате (от меньшего)',
    orderBy: 'asc',
  },
];
