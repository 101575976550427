import { manipulateString } from 'src/pages/profile/libs/helpers/manipulate-string';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';
import Button from 'src/shared/ui/button/button/button';
import { Wallet } from 'src/app/store/slices/wallets/types';
import styles from './wallet.module.scss';

interface IWalletCardProps {
    wallet: Wallet
    deleteWallet: (walletId: number) => void;
}

const WalletCard = (props: IWalletCardProps) => {
  const { wallet, deleteWallet } = props;
  const {
    address, memo, network, user_name: userName, asset, wallet_id: walletId,
  } = wallet;

  const x = 1;
  return (
    <div className={styles.wallet}>
      <h3 className={styles.walletName}>{userName}</h3>

      <div className={styles.box}>
        <div>
          <span>Cеть:</span>
          <p>{network.name}</p>
        </div>

        <div>
          <span>Валюта:</span>
          <p>{asset?.symbol || 'Универсальный'}</p>
        </div>
      </div>

      <div className={styles.box}>
        <div>
          <span>MEMO:</span>
          <p>  {memo || '-'}</p>
        </div>
      </div>

      <div className={styles.box}>
        <div className={styles.walletAddressContainer}>
          {manipulateString(address)}

          <CopyIcon value={address} />
        </div>

      </div>

      <Button onClick={() => deleteWallet(walletId)} background="black">Удалить</Button>
    </div>
  );
};

export default WalletCard;
