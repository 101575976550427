import { createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { fetchRebateHistory, fetchRebateStatistics } from './thunks';
import { IRebateSlice } from './types';

const initialState: IRebateSlice = {
  rebateHistory: null,
  rebateStatistics: null,
  statusRebateHistory: EStatus.success,
  statusRebateStatistics: EStatus.success,
  statusRebateConvertation: EStatus.success,
};

export const rebateSlice = createSlice({
  name: 'rebate',
  initialState,
  reducers: {
    clearRebateSlice: (state) => {
      state.rebateHistory = null;
      state.rebateStatistics = null;
    },
    clearRebateHistory: (state) => {
      state.rebateHistory = null;
    },
    clearRebateStatistics: (state) => {
      state.rebateStatistics = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRebateHistory.pending, (state) => {
        state.statusRebateHistory = EStatus.loading;
      })
      .addCase(fetchRebateHistory.fulfilled, (state, action) => {
        state.statusRebateHistory = EStatus.success;

        if (!action.payload.items.length) {
          state.rebateHistory = action.payload;
        } else if (!state.rebateHistory) {
          state.rebateHistory = action.payload;
        } else {
          state.rebateHistory.items = [...state.rebateHistory.items, ...action.payload.items];
        }
      })
      .addCase(fetchRebateHistory.rejected, (state) => {
        state.statusRebateHistory = EStatus.rejected;
        state.rebateHistory = null;
      })

      .addCase(fetchRebateStatistics.pending, (state) => {
        state.statusRebateStatistics = EStatus.loading;
      })
      .addCase(fetchRebateStatistics.fulfilled, (state, action) => {
        state.statusRebateStatistics = EStatus.success;
        state.rebateStatistics = action.payload;
      })
      .addCase(fetchRebateStatistics.rejected, (state) => {
        state.statusRebateStatistics = EStatus.rejected;
        state.rebateHistory = null;
      });
  },
});

export const { clearRebateSlice, clearRebateHistory, clearRebateStatistics } = rebateSlice.actions;

export const rebateReducer = rebateSlice.reducer;
