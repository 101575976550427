import { useEffect } from 'react';

export const useLoadScript = (pathToScript: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = pathToScript;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
