import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { Exchange } from './types';

export const fetchExchanges = createAsyncThunk(
  'exchanges/fetchExchanges',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<Exchange[]>(ROUTE_PATH.exchanges);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data);
      return rejectWithValue(error);
    }
  },
);
