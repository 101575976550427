import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { IWalletsSlice } from './types';
import { fetchCreateWallet, fetchDeleteWallet, fetchWallets } from './thunks';

const initialState: IWalletsSlice = {
  wallets: [],
  exists: false,
  status: EStatus.success,
  statusCreate: EStatus.success,
  statusDelete: EStatus.success,
};

export const walletsSlice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {
    removeWallet: (state, action: PayloadAction<number>) => {
      state.wallets = state.wallets.filter((wallet) => wallet.wallet_id !== action.payload);
    },
    clearWallets: (state) => {
      state.wallets = [];
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWallets.pending, (state) => {
        state.status = EStatus.loading;
        state.wallets = [];
      })
      .addCase(fetchWallets.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.wallets = action.payload.items;
        state.exists = action.payload.exists;
      })
      .addCase(fetchWallets.rejected, (state) => {
        state.status = EStatus.rejected;
        state.wallets = [];
      })

      .addCase(fetchCreateWallet.pending, (state) => {
        state.statusCreate = EStatus.loading;
      })
      .addCase(fetchCreateWallet.fulfilled, (state, action) => {
        state.statusCreate = EStatus.success;
        state.wallets.push(action.payload);
      })
      .addCase(fetchCreateWallet.rejected, (state) => {
        state.statusCreate = EStatus.rejected;
      })

      .addCase(fetchDeleteWallet.pending, (state) => {
        state.statusDelete = EStatus.loading;
      })
      .addCase(fetchDeleteWallet.fulfilled, (state) => {
        state.statusDelete = EStatus.success;
      })
      .addCase(fetchDeleteWallet.rejected, (state) => {
        state.statusDelete = EStatus.rejected;
      });
  },
});

export const { removeWallet, clearWallets } = walletsSlice.actions;

export const walletsReducer = walletsSlice.reducer;
