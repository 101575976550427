import CardSteps from '../card-steps/card-steps';
import styles from './auth-title.module.scss';

interface IAuthTitleProps {
  children: React.ReactNode;
  step: 'none' | 'first' | 'second' | 'third' | string;
  visible?: boolean
}
const AuthTitle = ({ children, step, visible }: IAuthTitleProps) => (
  <div className={styles.authTitle}>
    <h3>{children}</h3>

    <CardSteps visible={visible} step={step} />
  </div>
);

export default AuthTitle;
