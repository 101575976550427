import { useDispatch, useSelector } from 'react-redux';
import { selectAlert } from 'src/app/store/slices/alert/selectors';
import { AlertType, MessageType } from 'src/app/store/slices/alert/types';
import {
  addMessage, open, removeMessage,
  removeMessageAutoClose,
  setSystemAlert,
} from 'src/app/store/slices/alert/slice';

const useAlert = () => {
  const { isOpen, messages } = useSelector(selectAlert);
  const dispatch = useDispatch();

  const openAlert = () => dispatch(open(true));

  const closeAlert = (alertIndex: number) => dispatch(removeMessage(alertIndex));

  const closeAlertAutoClose = (alertIndex: number) => {
    dispatch(removeMessageAutoClose(alertIndex));
    dispatch(setSystemAlert(false));
  };

  const setAlertMessage = (messageValue: string | object, typeValue: AlertType, autoClose?: boolean) => {
    const newMessage: MessageType = {
      message: typeof messageValue !== 'string' ? JSON.stringify(messageValue) : messageValue,
      type: typeValue,
      autoClose: Boolean(autoClose),
    };

    dispatch(addMessage(newMessage));

    if (!isOpen) openAlert();
  };

  return {
    isOpen, messages, closeAlert, setAlertMessage, closeAlertAutoClose,
  };
};

export default useAlert;
