import { memo } from 'react';
import QRCode from 'react-qr-code';
import QRCodeSkeleton from 'src/shared/ui/skeleton/qr-code-skeleton/qr-code-skeleton';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { Nullable } from 'src/shared/types/global-types';
import styles from './qr-code.module.scss';

interface IQRCodeGenerator {
    qrText: Nullable<string>;
}

const QRCodeGenerator = memo(({ qrText }: IQRCodeGenerator) => {
  const { theme } = useTheme();

  return (
    <div className={styles.qrCodeWrapper}>
      { qrText ? <QRCode level="M" size={128} fgColor={theme === EThemeMode.DARK ? 'white' : '#0E0E0E'} bgColor={theme === EThemeMode.DARK ? '#343434' : '#FFF'} value={qrText} /> : <QRCodeSkeleton />}
    </div>
  );
});

export default QRCodeGenerator;
