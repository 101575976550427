import qs from 'qs';
import className from 'classnames';
import { LoadingOverlay, Tooltip } from '@mantine/core';
import {
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { BalanceType } from 'src/entities/balance-type';
import { setTransfersAsset } from 'src/widgets/transfers/model/slice';
import {
  CollateralStatus,
  fetchSetCollateralStatus,
  fetchSubAccountsAssets, subscribeInstrument, unsubscribeInstrument,
} from 'src/app/store/slices/sub-accounts/thunks';
import {
  decimalizeQuantity, decimalizeQuantityLocked, minusValues, multiplyValues, toFixed,
} from 'src/shared/libs/helpers/helper.lib';
import {
  EStatus, ETradingType, Nullable,
  ColumnOrderItem,
} from 'src/shared/types/global-types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { removeSubAccountsAssets, setCollateralStatus } from 'src/app/store/slices/sub-accounts/slice';
import { SubAccountAsset, SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { selectStatusSubAccountsAssets, selectSubAccountsAssets } from 'src/app/store/slices/sub-accounts/selectors';

import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as TransferIconSvg } from 'src/shared/assets/images/account/transfer-color.svg';
import { ReactComponent as LogOutIconSvg } from 'src/shared/assets/images/account/log-out-color.svg';
import { ReactComponent as TipIconSvg } from 'src/shared/assets/images/tip-icon.svg';
import { ReactComponent as SearchIconSvg } from 'src/shared/assets/images/glass.svg';
import { HIDDEN_BALANCES, KEYBOARD_LAYOUT, USDT_PRECISION } from 'src/shared/constants/constants';

import Empty from 'src/entities/empty/empty';
import Toogler from 'src/shared/ui/toogler/toogler';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputText from 'src/shared/ui/input/input-text/input-text';
import useExchange from 'src/shared/libs/hooks/use-exchange';

import { SortFieldAssets } from '../../../../model/types';
import { assetsTableColumnByBit } from '../../../../constants';
import styles from './assets-table-bybit.module.scss';

interface IAssetsTableBybitProps {
  subAccount: Nullable<SubAccountType>
}

const AssetsTableBybit = ({ subAccount } : IAssetsTableBybitProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { getExchangeNameById } = useExchange();
  const { setAlertMessage } = useAlert();

  const balanceVisible = useSelector(selectHiddenBalance);
  const subAccountAssets = useSelector(selectSubAccountsAssets);
  const statusSubAccountsAssets = useSelector(selectStatusSubAccountsAssets);

  const [searchAsset, setSearchAsset] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [sortField, setSortField] = useState <Nullable<SortFieldAssets>>(null);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(assetsTableColumnByBit);
  const [prevSubAccountAssetsLength, setPrevSubAccountAssetsLength] = useState(0);
  const [filterAccountType, setFilterAccountType] = useState<Nullable<boolean>>(null);

  const subAccountId = Number(window.location.pathname.slice('/sub-accounts/'.length));

  const [collateralcurrentAsset, setCollateralcurrentAsset] = useState<Nullable<number>>(null);

  const filteredData = useMemo(() => subAccountAssets
    ?.filter((subAccountAsset) => {
      if (filterAccountType === null) {
        return subAccountAsset.sub_account_id === subAccountId;
      }
      return subAccountAsset.sub_account_id === subAccountId && subAccountAsset.collateralizable === filterAccountType;
    })
    ?.filter((item) => {
      if (searchAsset.length > 0) {
        const symbolMatch = item.asset.symbol.toLowerCase().includes(searchAsset.toLowerCase());
        return symbolMatch;
      }
      return true;
    })
    ?.sort((a, b) => {
      if (sortField === 'output') return 0;
      if (sortField === 'instrument') return 0;

      if (sortField === 'available') {
        const availableA = minusValues(a.quantity, a.locked);
        const availableB = minusValues(b.quantity, b.locked);

        if (availableA < availableB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (availableA > availableB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'quantity') {
        const availableA = a.instrument ? multiplyValues(a.instrument.price.bid, a.quantity) : a.quantity;
        const availableB = b.instrument ? multiplyValues(b.instrument.price.bid, b.quantity) : b.quantity;

        if (availableA < availableB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (availableA > availableB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'asset') {
        const assetA = a.asset.symbol;
        const assetB = b.asset.symbol;

        if (assetA < assetB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (assetA > assetB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField) {
        if (a[sortField] < b[sortField]) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (a[sortField] > b[sortField]) {
          return sortOrder === 'asc' ? 1 : -1;
        }
      }
      return 0;
    }), [subAccountAssets, filterAccountType, subAccountId, searchAsset, sortField, sortOrder]);

  const handleSort = (field: SortFieldAssets) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const renderAssetsAction = (asset: SubAccountAsset) => (
    <>
      {asset.asset_type === ETradingType.spot && (

        <button
          onClick={() => navigate(`/transfers?sub_account_id=${asset.sub_account_id}&asset_symbol=${asset.asset.symbol}&asset_id=${asset.asset.id}`, { replace: true })}
          className={styles.assetAction}
        >
          <LogOutIconSvg />
          Вывести
        </button>

      )}
      {asset.asset_type === ETradingType.futures && (
        <button onClick={() => dispatch((setTransfersAsset(asset)))} className={styles.assetAction}><TransferIconSvg /> Перевести</button>
      )}
    </>
  );

  const renderPnl = (pnl: string) => (
    <>
      {Number(pnl) === 0 && (
        <span className={styles.zero}>{pnl} %</span>
      )}
      {Number(pnl) > 0 && (
        <span className={styles.plus}>+{Number(toFixed(pnl, 2)).toLocaleString()} %</span>
      )}
      {Number(pnl) < 0 && (
        <span className={styles.minus}>-{Number(toFixed(pnl, 2)).toLocaleString()} %</span>
      )}

    </>
  );

  const handleOnChangeTransliterate = (value: string) => {
    const latinText: string = value
      .split('')
      .map((char) => KEYBOARD_LAYOUT[char] || char)
      .join('');

    setSearchAsset(latinText);
  };

  const handleCollateralSwitch = async (asset: SubAccountAsset) => {
    const params: CollateralStatus = {
      asset_id: asset.asset_id,
      sub_account_id: subAccountId,
      collateralizable: !asset.collateralizable,
    };

    try {
      setCollateralcurrentAsset(asset.asset_id);
      const response = await dispatch(fetchSetCollateralStatus(params));
      const payload = unwrapResult(response);

      if (payload) {
        dispatch(setCollateralStatus(params));
      } else {
        setAlertMessage('Ответ отрицательный', 'error');
      }
    } catch (error) {
      console.log('Error fetchSetCollateralStatus', error);
    } finally {
      setCollateralcurrentAsset(null);
    }
  };

  useEffect(() => {
    if (subAccount) {
      dispatch(fetchSubAccountsAssets({ exchange_id: subAccount.exchange_id, sub_account_id: subAccount.id }));
    }
  }, [subAccount]);

  useEffect(() => {
    if (subAccountAssets) {
      // Extract instrument IDs from assets
      const instrumentIds: number[] = subAccountAssets
        .map((asset) => asset.instrument?.id)
        .filter((id) => id !== null && id !== undefined) as number[];

      // Check if there are instruments to subscribe to and if there have been changes in assets since the last call
      if (instrumentIds.length > 0 && subAccountAssets.length !== prevSubAccountAssetsLength) {
        // Subscribe to instrument changes
        subscribeInstrument(instrumentIds);

        // Update the previous length of assets
        setPrevSubAccountAssetsLength(subAccountAssets.length);
      }
    }
  }, [subAccountAssets, prevSubAccountAssetsLength]);

  useLayoutEffect(() => {
    const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const balanceType = parsedParams?.balanceType as unknown;

    if (balanceType && Number(balanceType) === ETradingType.futures) setFilterAccountType(true);
    if (balanceType && Number(balanceType) === ETradingType.spot) setFilterAccountType(false);
  }, [location]);

  useEffect(() => () => {
    dispatch(removeSubAccountsAssets());
    unsubscribeInstrument();
  }, []);

  return (
    <section className={styles.assetsTable}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.after} />
          <div className={styles.filters}>
            <button className={className(styles.allFilter, { [styles.activeFilter]: filterAccountType === null })} onClick={() => setFilterAccountType(null)}>Все <BalanceType type={ETradingType.unified} /></button>
            <button className={className({ [styles.activeFilter]: filterAccountType === true })} onClick={() => setFilterAccountType(true)}><p>Маржинальные активы</p> <span>F</span> </button>
            <button className={className({ [styles.activeFilter]: filterAccountType === false })} onClick={() => setFilterAccountType(false)}><p>Свободные активы</p><span>S</span> </button>
          </div>

          <InputText
            className={styles.search}
            value={searchAsset}
            placeholder="Поиск"
            onChange={handleOnChangeTransliterate}
          >
            <SearchIconSvg />
          </InputText>
        </div>

        <div className={styles.body}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columnOrder.map((column) => (
                  <th key={column.key}>
                    {column.key === 'asset' && (
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => handleSort(column.key as SortFieldAssets)}
                        className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                      >
                        {column.label}
                        <SortIconSvg />
                      </div>
                    )}
                    {column.key === 'quantity' && (
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => handleSort(column.key as SortFieldAssets)}
                        className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                      >
                        {column.label}
                        <SortIconSvg />
                      </div>
                    )}
                    {column.key === 'available' && (
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => handleSort(column.key as SortFieldAssets)}
                        className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                      >
                        {column.label}
                        <SortIconSvg />
                      </div>
                    )}
                    {column.key === 'pnl' && (
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => handleSort(column.key as SortFieldAssets)}
                        className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                      >
                        {column.label}
                        <SortIconSvg />
                      </div>
                    )}
                    {column.key === 'collateralizable' && subAccount && getExchangeNameById(subAccount.exchange_id) === 'Bybit' && (
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => handleSort(column.key as SortFieldAssets)}
                        className={className(styles.thWrapper, {
                          [styles.activeColumn]: currentSortColumn === column.key,
                        })}
                      >
                        {column.label}
                        <SortIconSvg />

                        <Tooltip maw={250} multiline withArrow label="Трейдеры могут выбирать, какие активы использовать в качестве обеспечения. Для спотовой маржинальной и деривативной торговли в качестве маржи позиции можно будет использовать только определённые активы обеспечения в пересчёте на USD" position="bottom" offset={9}>
                          <TipIconSvg className={styles.info} />
                        </Tooltip>
                      </div>
                    )}
                    {column.key === 'output' && (
                      <div className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}>
                        {column.label}
                        <Tooltip maw={250} multiline withArrow label="Cистема автоматически выберет из вашего списка тот кошелек, который обеспечит наименьшую комиссию" position="bottom" offset={9}>
                          <TipIconSvg className={styles.info} />
                        </Tooltip>
                      </div>
                    )}
                  </th>
                ))}
              </tr>

            </thead>

            { filteredData && filteredData.length > 0 && (
              <tbody>
                {filteredData.map((asset) => (
                  <tr key={asset.id}>
                    {columnOrder.map((column) => (
                      <td key={column.key}>
                        {column.key === 'asset' && (
                          <div className={styles.asset}>
                            <img src={asset.asset.image} alt="asset" />
                            <span className={styles.symbol}>{asset.asset.symbol}</span>
                            <span className={styles.name}>{asset.asset.name}</span>
                            <BalanceType type={asset.collateralizable ? ETradingType.futures : ETradingType.spot} />
                          </div>
                        )}
                        {column.key === 'quantity' && (
                          <div className={styles.quantity}>
                            <span className={styles.balance}>
                              {!balanceVisible ? decimalizeQuantity(asset.quantity, asset.asset.symbol) : HIDDEN_BALANCES}
                            </span>
                            {asset.asset.symbol !== 'USDT' && asset.asset.symbol !== 'BNFCR' && (
                              <span className={styles.available}>
                                {asset.instrument && (!balanceVisible ? `≈ ${multiplyValues(asset.instrument.price.bid, asset.quantity, USDT_PRECISION)}` : HIDDEN_BALANCES)} USDT
                              </span>
                            )}
                          </div>

                        )}
                        {column.key === 'available' && (
                          <div className={styles.quantity}>
                            <span className={styles.balance}>{!balanceVisible ? decimalizeQuantityLocked(asset) : HIDDEN_BALANCES} </span>
                          </div>
                        )}
                        {column.key === 'pnl' && (
                          <div className={styles.pnl}>{renderPnl('0')}</div>
                        )}
                        {column.key === 'collateralizable' && subAccount && getExchangeNameById(subAccount.exchange_id) === 'Bybit' && (
                          <Toogler
                            isBlocked={!asset.collateralizable && !asset.asset.collateral_switch}
                            isActiveDisabled={!asset.asset.collateral_switch && asset.collateralizable}
                            onChange={() => handleCollateralSwitch(asset)}
                            isActive={asset.collateralizable}
                            isLoading={collateralcurrentAsset === asset.asset_id}
                          />
                        )}
                        {column.key === 'output' && (
                          <div className={styles.output}>
                            {renderAssetsAction(asset)}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>

          {statusSubAccountsAssets === EStatus.success && filteredData?.length === 0 && (
            <div className={styles.emptyTable}>
              <Empty>
                Список активов пуст
              </Empty>
            </div>
          )}

          {statusSubAccountsAssets === EStatus.loading && (
            <div className={styles.loadingWrapper}>
              <LoadingOverlay
                className="loader"
                visible
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: '#00C37C', type: 'dots' }}
              />
            </div>
          )}

          {statusSubAccountsAssets === EStatus.rejected && (
            <div className={styles.loadingWrapper}>
              <Empty>
                Список активов пуст
              </Empty>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AssetsTableBybit;
