import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IForgotPasswordSlice } from './types';

const initialState: IForgotPasswordSlice = {
  email: '',
  password: '',
  phoneNumber: '',
  smsType: 'whatsapp',
  step: 0,
  methodSms: [],
};

export const ForgotPasswordSlice = createSlice({
  name: 'forgot-password',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setForgotSmsType: (state, action: PayloadAction<string>) => {
      state.smsType = action.payload;
    },
    setForgotPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setForgotEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setMethodSms: (state, action: PayloadAction<string[]>) => {
      state.methodSms = action.payload;
    },
    clearForgotSlice: (state) => {
      state.email = '';
      state.password = '';
      state.phoneNumber = '';
      state.smsType = 'whatsapp';
      state.step = 0;
      state.methodSms = [];
    },
  },
});

export const {
  setStep, setForgotSmsType, setForgotPhoneNumber, setForgotEmail, clearForgotSlice, setMethodSms,
} = ForgotPasswordSlice.actions;

export const forgotPasswordReducer = ForgotPasswordSlice.reducer;
