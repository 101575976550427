import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import TelegramoLogService, { TelegramLogData } from 'src/services/telegram-log.service';
import { getTokensFromLocalStorage, saveTokensLocalStorage } from 'src/shared/libs/helpers/save-tokens';
import { BASE_URL } from 'src/shared/constants/constants';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { Tokens } from '../types/global-types';
import { getUserEmail, removeTokensFromLocalStorage } from '../libs/helpers/helper.lib';

interface RequestItem {
  config: AxiosRequestConfig;
  resolve: (value?: AxiosResponse) => void;
  reject: (error?: AxiosError) => void;
}

let refreshingToken = false; // Flag to track the current token update
let requestsQueue: RequestItem[] = []; // Queue to store failed requests

const headersToRestore = ['sub_account_id', 'verification-id'];

export const refreshTokens = async () => {
  const tokens = getTokensFromLocalStorage();

  if (tokens) {
    try {
      const response = await fetch(BASE_URL + ROUTE_PATH.refreshToken, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh_token: tokens.refresh_token,
        }),
      });

      if (response.ok) {
        const returnData: Tokens = await response.json();
        return returnData;
      }

      removeTokensFromLocalStorage();
      window.location.href = '/auth/login';

      return null;
    } catch (error) {
      removeTokensFromLocalStorage();
      window.location.href = '/auth/login';

      return null;
    }
  }

  return null;
};

export const loggingTelegram = (error: AxiosError) => {
  if (error.response?.config.url === '/api/volume-screener/download-release') return;
  if (!error.response || !error.response.data) return;

  if (error.response.config.url) {
    const ignorePath = ['login', 'registration', 'forgot-password', 'step1', 'step2', 'logs'];
    const containsIgnoredPath = ignorePath.some((path) => error.response?.config?.url?.includes(path));
    if (containsIgnoredPath) return;
  }

  const errorData = typeof error.response.config.data === 'object' ? JSON.parse(error.response.config.data) : error.response.config.data;

  const logData: TelegramLogData = {
    url: error.response.config.url || '',
    // @ts-ignore
    message: error.response.data?.detail || error.response.data,
    status: error.response.status,
    data: errorData,
    user: getUserEmail(),
  };

  TelegramoLogService.log(logData);
};

export const $api = axios.create({
  baseURL: BASE_URL,
});

$api.interceptors.request.use(
  async (config) => {
    const tokens = getTokensFromLocalStorage();

    if (tokens && tokens?.access_token) {
      config.headers.Authorization = `Bearer ${tokens?.access_token}`;
    }
    // Save sub_account_id header if present in the request
    headersToRestore.forEach((header) => {
      if (config.headers[header]) {
        config.headers[`saved_${header}`] = config.headers[header];
      }
    });

    return config;
  },
  (error) => Promise.reject(error),
);

$api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config; // The original request that resulted in an error
    const tokens = getTokensFromLocalStorage();

    const errorStatus = error.response?.status;

    if (errorStatus === 401) {
      originalRequest.retry = true; // Set the retry flag to true for the original request

      if (tokens && tokens.refresh_token) {
        if (!refreshingToken) { // If token refresh is not currently in progress
          refreshingToken = true; // Set the refreshing token flag to true

          try {
            const data = await refreshTokens();

            if (data) {
              saveTokensLocalStorage(data); // Save the new tokens to local storage

              // Save sub_account_id header if present in the response
              headersToRestore.forEach((header) => {
                if (originalRequest.headers[`saved_${header}`]) {
                  originalRequest.headers[header] = originalRequest.headers[`saved_${header}`];
                }
              });

              originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
              refreshingToken = false; // Reset the flag after a successful token update

              // Retry all the failed requests
              requestsQueue.forEach((request: any) => {
                originalRequest.headers.Authorization = `Bearer ${data.access_token}`;

                $api(request)
                  .then((response) => request.resolve(response))
                  .catch((error) => request.reject(error));
              });

              requestsQueue = []; // Clear the queue after retrying requests
              return $api(originalRequest); // Return the original request after retrying
            }
          } catch (refreshError) {
            refreshingToken = false; // Reset the flag in case of a token update error
            removeTokensFromLocalStorage();
            window.location.href = '/auth/login';
          }
        } else {
          // Add the failed request to the queue
          return new Promise((resolve, reject) => {
            requestsQueue.push({ ...originalRequest, resolve, reject });
          });
        }
      }
    }

    if (errorStatus !== 429 && (errorStatus === 400 || error.response?.status > 401)) {
      loggingTelegram(error);
    }

    return error;
  },
);
