const informSlide4 = [
  'Восстановлени доступа к личному кабинету чтобы подтвердить вашу личность',
  'Для того, чтобы техническая поддержка могла определить Вас',
];

export const ERROR_MSG_LIST: Record<string, string> = {
  INVALID_VERIFICATION_CODE: 'Неверный код верификации',
  PHONE_EXISTS: 'Этот телефон уже используется',
  INVALID_IP: 'Некорректный IP-адрес. Обратитесь в службу поддержки. Или попробуйте пройти регистрацию с первого шага',
  STEP_EXPIRED: 'Пожалуйста, начните регистрацию с первого шага',
  INVALID_STEP: 'Пожалуйста, начните регистрацию с первого шага',
  TOO_MANY_REQUESTS: 'Слишком много попыток. Повторите через 2 минуты',
};

export default informSlide4;
