import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EStatus, UpdateInstrument } from 'src/shared/types/global-types';
import { formatPositionSummaryProcess } from 'src/shared/processors';
import {
  fetchDustTransformsAssets, fetchPositionsSummury, fetchDustTransformsCountdown, fetchDustTransformsHistory, fetchDustTransformsConversion,
} from './thunks';
import {
  DustTransformsHistory, IAccountSlice, PositionsSummary, PositionsSummaryUpdate,
} from './types';

const initialState: IAccountSlice = {
  positionsSummury: null,
  dustTransformsAssets: null,
  dustTransformsHistory: null,
  dustTransformsCountdown: 0,
  status: EStatus.success,
  statusDustTransformsAssets: EStatus.success,
  statusDustTransformsHistory: EStatus.success,
  statusDustTransformsCountdown: EStatus.success,
  statusDustTransformsConversion: EStatus.success,
};

export const accountSlice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    clearPositionsSummury: (state) => {
      state.positionsSummury = null;
    },
    addPositionsSummury: (state, action: PayloadAction<PositionsSummary>) => {
      if (state.positionsSummury) {
        state.positionsSummury.items.unshift(action.payload);
      } else {
        state.positionsSummury = {
          items: [action.payload],
          meta: {
            total_items: 1,
            total_pages: 1,
          },
        };
      }
    },
    addDustTransformHistory: (state, action: PayloadAction<DustTransformsHistory>) => {
      if (state.dustTransformsHistory) {
        state.dustTransformsHistory.items.push(action.payload);
      } else {
        state.dustTransformsHistory = {
          items: [action.payload],
          meta: {
            total_items: 1,
            total_pages: 1,
          },
        };
      }
    },
    updatePositionsSummury: (state, action: PayloadAction<PositionsSummaryUpdate>) => {
      if (state.positionsSummury) {
        const findIndex = state.positionsSummury.items.findIndex((item) => item.id === action.payload.id);

        if (findIndex !== -1) {
          state.positionsSummury.items[findIndex] = {
            ...state.positionsSummury.items[findIndex],
            ...action.payload,
          };
        }
      }
    },
    updatePositionsSummuryInstrument: (state, action: PayloadAction<UpdateInstrument>) => {
      if (state.positionsSummury) {
        state.positionsSummury.items = state.positionsSummury.items.map((item) => {
          if (item.instrument.id === action.payload.id) {
            return {
              ...item,
              instrument: {
                ...item.instrument,
                ...action.payload,
              },
            };
          }
          return item;
        });
      }
    },
    removePositionsSummury: (state, action: PayloadAction<string>) => {
      if (state.positionsSummury) {
        state.positionsSummury.items = state.positionsSummury.items.filter((item) => item.id !== action.payload);
      }
    },
    clearDustTransforms: (state) => {
      state.dustTransformsAssets = null;
      state.dustTransformsHistory = null;
      state.dustTransformsCountdown = 0;
    },
    clearDustTransformsHistory: (state) => {
      state.dustTransformsHistory = null;
    },
    removeDustTransformsAssetByIds: (state, action: PayloadAction<number[]>) => {
      if (state.dustTransformsAssets) {
        state.dustTransformsAssets = state.dustTransformsAssets.filter((dust) => !action.payload.includes(dust.asset_id));
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPositionsSummury.pending, (state) => {
        state.status = EStatus.loading;
      })
      .addCase(fetchPositionsSummury.fulfilled, (state, action) => {
        state.status = EStatus.success;

        const positionSummary = action.payload.items.map((position) => formatPositionSummaryProcess(position));
        const positionSummaryMeta = action.payload.meta;

        const positionSummaryData = {
          items: positionSummary,
          meta: positionSummaryMeta,
        };

        if (!state.positionsSummury) {
          state.positionsSummury = positionSummaryData;
        } else {
          state.positionsSummury.items = [...state.positionsSummury.items, ...positionSummaryData.items];
        }
      })
      .addCase(fetchPositionsSummury.rejected, (state) => {
        state.status = EStatus.rejected;
        state.positionsSummury = null;
      })

      .addCase(fetchDustTransformsAssets.pending, (state) => {
        state.statusDustTransformsAssets = EStatus.loading;
        state.dustTransformsAssets = null;
      })
      .addCase(fetchDustTransformsAssets.fulfilled, (state, action) => {
        state.statusDustTransformsAssets = EStatus.success;
        state.dustTransformsAssets = action.payload;
      })
      .addCase(fetchDustTransformsAssets.rejected, (state) => {
        state.statusDustTransformsAssets = EStatus.rejected;
        state.dustTransformsAssets = null;
      })

      .addCase(fetchDustTransformsCountdown.pending, (state) => {
        state.statusDustTransformsCountdown = EStatus.loading;
      })
      .addCase(fetchDustTransformsCountdown.fulfilled, (state, action) => {
        state.statusDustTransformsCountdown = EStatus.success;
        state.dustTransformsCountdown = action.payload;
      })
      .addCase(fetchDustTransformsCountdown.rejected, (state) => {
        state.statusDustTransformsCountdown = EStatus.rejected;
      })

      .addCase(fetchDustTransformsHistory.pending, (state) => {
        state.statusDustTransformsHistory = EStatus.loading;
      })
      .addCase(fetchDustTransformsHistory.fulfilled, (state, action) => {
        state.statusDustTransformsHistory = EStatus.success;

        if (!state.dustTransformsHistory) {
          state.dustTransformsHistory = action.payload;
        } else {
          state.dustTransformsHistory.items = [...state.dustTransformsHistory.items, ...action.payload.items];
        }
      })
      .addCase(fetchDustTransformsHistory.rejected, (state) => {
        state.statusDustTransformsHistory = EStatus.rejected;
        state.dustTransformsHistory = null;
      })

      .addCase(fetchDustTransformsConversion.pending, (state) => {
        state.statusDustTransformsConversion = EStatus.loading;
      })
      .addCase(fetchDustTransformsConversion.fulfilled, (state) => {
        state.statusDustTransformsConversion = EStatus.success;
      })
      .addCase(fetchDustTransformsConversion.rejected, (state) => {
        state.statusDustTransformsConversion = EStatus.rejected;
      });
  },
});

export const {
  clearPositionsSummury,
  addPositionsSummury,
  updatePositionsSummury,
  clearDustTransforms,
  clearDustTransformsHistory,
  removeDustTransformsAssetByIds,
  addDustTransformHistory,
  removePositionsSummury,
  updatePositionsSummuryInstrument,
} = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
