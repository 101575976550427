import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Balance } from 'src/widgets/balance';
import { Nullable } from 'src/shared/types/global-types';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { TransfersBlock } from 'src/widgets/transfers';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import useMobile from 'src/shared/libs/hooks/useMobile';
import LoadingPageState from 'src/entities/loading-page-state/loading-page-state';
import DustContainer from './components/dust-container/dust-container';
import AccountContainer from './components/account-container/account-container';
import AnalyticsContainer from './components/analytics-container/analytics-container';
import SubaccountContainer from './components/account-container/components/subaccount-container/subaccount-container';

import { ActiveLink } from './model/types';
import styles from './account.module.scss';
import AccountPageAdaptive from './adaptive/account-adaptive';

const pageTitle = 'Счет';

const AccountPage = () => {
  useChangePageTitle(pageTitle);

  const isMobile = useMobile();
  const { pathname } = useLocation();

  const subAccounts = useSelector(selectSubAccounts);
  const { exchanges } = useSelector(selectExchanges);

  const [subAccount, setSubAccount] = useState<Nullable<SubAccountType>>(null);
  const [activeLink, setActiveLink] = useState<ActiveLink>('sub_account');

  useEffect(() => {
    const [page, subAccountId, selectTab] = pathname.substring(1).split('/');

    if (subAccounts) {
      const currentSubAccount = subAccounts.find((subAccount) => subAccount.id === Number(subAccountId));

      if (currentSubAccount) setSubAccount(currentSubAccount);
    }

    if (selectTab) {
      const queryLink = selectTab as ActiveLink;
      setActiveLink(queryLink);
    } else {
      setActiveLink('sub_account');
    }
  }, [pathname, subAccounts]);

  if (!subAccounts || !exchanges) {
    return (
      <LoadingPageState />
    );
  }

  return isMobile ? <AccountPageAdaptive /> : (
    <div className={styles.subAccountsWrapper}>
      <div className={styles.content}>
        <SubaccountContainer />
        {activeLink === 'sub_account' && <AccountContainer subAccount={subAccount} />}
        {activeLink === 'analytics' && <AnalyticsContainer subAccountId={subAccount?.id} />}
        {activeLink === 'dust-transforms' && <DustContainer subAccountId={subAccount?.id} />}
      </div>
      <div className={styles.aside}>
        <Balance subAccountId={subAccount?.id} />
        <TransfersBlock />
      </div>
    </div>
  );
};

export default AccountPage;
