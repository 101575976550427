import './divisors.scss';

export interface IDivisorsProps {
  values: string[] | number[];
}

const Divisors = ({ values }: IDivisorsProps) => (
  <div className="divisors">
    { values.map((item) => (
      <div key={item} className="divisors-item">
        <span>{item}%</span>
      </div>
    ))}
  </div>
);

export default Divisors;
