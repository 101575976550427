const informSlide2 = [
  'Минимум 8 символов в длину',
  'Буквы нижнего регистра (a–z)',
  'Буквы верхнего регистра (A–Z)',
  'Цифры (0-9)',
  'Специальные символы (например, !@#$%^&*)',
];

export const ERROR_MSG_LIST: Record<string, string> = {
  EMAIL_EXISTS: 'Эта почта уже занята',
  RECAPTCHA_TOKEN_REQUIRED: 'Заполните капчу',
  TOO_MANY_REQUESTS: 'Слишком много попыток. Повторите через 10 секунд',
  INVALID_CAPTCHA: 'Капча устарела',
  INVALID_INVITE_CODE: 'Некорректный код приглашения',
  INVITE_CODE_USED: 'Код приглашения устарел',
  DEFAULT: 'Произошла внутренняя ошибка',

};
export default informSlide2;
