import { ReactComponent as BookIconSvg } from 'src/shared/assets/images/profile/book.svg';
import { ReactComponent as SmileIconSvg } from 'src/shared/assets/images/profile/smile.svg';
import { ReactComponent as CheckMarkIconSvg } from 'src/shared/assets/images/check-mark.svg';
import DropdownMenuItem from './dropdown-menu-item/dropdown-menu-item';

import './dropdown-menu.scss';

const DropdownMenu = () => {
  const content = (
    <>
      <div className="list list_possibilities">
        <div className="list-item">
          <CheckMarkIconSvg />
          <p>Выводы без ограничений</p>
        </div>

        <div className="list-item">
          <CheckMarkIconSvg />
          <p>Смена почты</p>
        </div>

        <div className="list-item">
          <CheckMarkIconSvg />
          <p>Подключение Yubikey</p>
        </div>

        <div className="list-item">
          <CheckMarkIconSvg />
          <p>Идентификация при восстановлении доступа</p>
        </div>
      </div>

      <span className="requirements">Что потребуется:</span>

      <div className="list">
        <div className="list-item">
          <BookIconSvg />
          <p>Удостоверение личности</p>
        </div>

        <div className="list-item">
          <SmileIconSvg />
          <p>Фото лица</p>
        </div>
      </div>
    </>
  );

  return (
    <div className="dropdown-menu">
      <DropdownMenuItem verificationLevel="Базовая верификация">{content}</DropdownMenuItem>
    </div>
  );
};

export default DropdownMenu;
