import {
  useState, useEffect, useRef,
} from 'react';

import className from 'classnames';
import i18n from 'src/app/locales/i18n';

import { LANGUEAGES } from 'src/shared/constants/constants';
import { saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';

import { Language } from 'src/shared/types/global-types';
import { formatLanguageProcess } from 'src/shared/processors';
import styles from './language-selection.module.scss';

interface ILanguageSelectionProps {
  position?: 'static' | 'relative'
  bgColor?: string
}

const LanguageSelection = ({ position, bgColor }: ILanguageSelectionProps) => {
  const fallbackLanguageLS = formatLanguageProcess();
  const [selectLang, setSelectLang] = useState(fallbackLanguageLS);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const languageContainerRef = useRef<HTMLDivElement>(null);

  const handleSelect = (selectLanguage: Language) => {
    saveToLocalStorage('lang', selectLanguage);
    setSelectLang(selectLanguage);
    setShowSelectLanguage((prev) => !prev);
    i18n.changeLanguage(selectLanguage.type);
  };

  useEffect(() => {
    const setOpenListHandler = (e: MouseEvent) => {
      if (!languageContainerRef.current?.contains(e.target as Node)) {
        setShowSelectLanguage(false);
      }
    };
    window.addEventListener('click', setOpenListHandler);

    return () => {
      window.removeEventListener('click', setOpenListHandler);
    };
  }, [showSelectLanguage]);

  return (
    <div ref={languageContainerRef} className={className(styles.languageContainer, { [styles.position]: position })}>
      <div className={styles.btnContainer}>
        <button
          className={styles.language}
          style={bgColor ? { background: bgColor, color: 'white' } : {}}
          onClick={() => setShowSelectLanguage((prev) => !prev)}
        >
          {selectLang.type.toLocaleUpperCase()}
        </button>
      </div>

      {showSelectLanguage
       && (
         <ul className={styles.list}>
           {
             LANGUEAGES.map((lang) => (
               <li
                 role="button"
                 tabIndex={0}
                 onKeyDown={() => handleSelect(lang)}
                 onClick={() => handleSelect(lang)}
                 key={lang.type}
               >{lang.name}
               </li>
             ))
           }
         </ul>
       )}
    </div>
  );
};

export default LanguageSelection;
