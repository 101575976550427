import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';

export type SendCodeData = {
    phone_number: string,
    email: string
  }

export type ForgotDataStepOne = {
    email: string,
    phone_number: string,
    recaptcha_token: string
    method_sms: string
}

export type ForgotDataStepTwo = {
    email: string
    phone_number: string
    email_verification_code: number
    phone_verification_code?: number
    whatsapp_verification_code?: number
    ga_verification_code?: string
}

export type ForgotDataStepThree = {
    email: string,
    new_password: string
}

class ForgotPasswordService {
  static async stepOne(data: ForgotDataStepOne) {
    const response = await $api.post(ROUTE_PATH.forgotPassword.stepOne, data);
    return response;
  }

  static async stepTwo(data: ForgotDataStepTwo) {
    const response = await $api.post(ROUTE_PATH.forgotPassword.stepTwo, data);
    return response;
  }

  static async stepThree(data: ForgotDataStepThree) {
    const response = await $api.post(ROUTE_PATH.forgotPassword.stepThree, data);
    return response;
  }

  static async sendCodeEmail(email: string) {
    const response = await $api.post(ROUTE_PATH.forgotPassword.sendCodeEmail, { email });
    return response;
  }

  static async sendCodePhone(data: SendCodeData) {
    const response = await $api.post(ROUTE_PATH.forgotPassword.sendCodePhone, data);
    return response;
  }

  static async sendCodeWhatsApp(data: SendCodeData) {
    const response = await $api.post(ROUTE_PATH.forgotPassword.sendCodeWhatsApp, data);
    return response;
  }
}

export default ForgotPasswordService;
