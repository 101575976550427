import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { AxiosError, AxiosResponse } from 'axios';

export type TelegramlResponse = {
  url: string
}

class SupportService {
  static async telegram() {
    try {
      const response: AxiosResponse<TelegramlResponse> = await $api.get(ROUTE_PATH.support.telegram);
      const { data } = response;

      if (data === undefined) throw response;
      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) throw error.response.data.detail;
      throw error;
    }
  }
}

export default SupportService;
