import Button from 'src/shared/ui/button/button/button';
import Hint from 'src/shared/ui/help/hint';
import styles from './close-positions-content.module.scss';

interface IClosePositionsContentProps {
    onClose: () => void
    onSubmit: () => void
}

const ClosePositionsContent = ({ onClose, onSubmit }: IClosePositionsContentProps) => {
  const x = 1;

  return (
    <div className={styles.content}>
      <div className={styles.body}>
        <p>Вы уверены что хотите закрыть все позиции и снять лимитные и стоп-заявки?</p>
        <Hint text="Все позиции будут закрыты по рыночной цене" />
      </div>
      <div className={styles.actions}>
        <Button onClick={onSubmit} background="green">Да, закрыть все</Button>
        <Button onClick={onClose} background="gray">Отмена</Button>
      </div>
    </div>
  );
};

export default ClosePositionsContent;
