import { Link } from 'react-router-dom';
import { ReactComponent as InfoSvg } from 'src/shared/assets/images/auth/info.svg';

import styles from './restore-password.module.scss';

const RestorePassword = () => (
  <Link to="/auth/forgot-password">
    <button type="button" className={styles.restoreButton}>
      <InfoSvg />
      <span>Забыли пароль?</span>
    </button>
  </Link>
);

export default RestorePassword;
