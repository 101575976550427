import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlertSlice, MessageType } from './types';

const initialState: IAlertSlice = {
  messages: [],
  isOpen: false,
  systemAlert: false,
  disconnectedWebSocket: false,
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    close: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
      state.messages = []; // Clear the queue when closing notifications
    },
    addMessage: (state, action: PayloadAction<MessageType>) => {
      state.messages.push(action.payload);
    },
    removeMessage: (state, action: PayloadAction<number>) => {
      let index = action.payload;

      // Check if the index is within the valid range
      if (index >= 0 && index < state.messages.length) {
        // While the index is within the array range
        while (index < state.messages.length) {
          // If the autoClose property of the current element is true, move to the next element
          if (state.messages[index].autoClose) {
            index += 1;
          } else {
            // If the autoClose property of the current element is false, remove the element
            state.messages.splice(index, 1);
            // No need to increment the index after removal, as the next element has shifted to the current position
            break;
          }
        }
      }
    },
    setSystemAlert: (state, action: PayloadAction<boolean>) => {
      state.systemAlert = action.payload;
    },
    removeMessageAutoClose: (state, action: PayloadAction<number>) => {
      const alertIndex = action.payload;

      state.messages.splice(alertIndex, 1);
    },
    setHandleConnectedWebSocket: (state, action: PayloadAction<boolean>) => {
      state.disconnectedWebSocket = action.payload;
    },
  },
});

export const {
  open, close, addMessage, removeMessage, removeMessageAutoClose, setSystemAlert, setHandleConnectedWebSocket,
} = alertSlice.actions;

export const alertReducer = alertSlice.reducer;
