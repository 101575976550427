import { ReactComponent as GlassIconSvg } from 'src/shared/assets/images/glass.svg';

import './glass-icon.scss';

const GlassIcon = () => (
  <div className="icon glass-icon">
    <button><GlassIconSvg /></button>
  </div>
);

export default GlassIcon;
