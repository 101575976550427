import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EStatus, UpdateInstrument } from 'src/shared/types/global-types';
import { formatOrderProcess, formatPositionProcess } from 'src/shared/processors';
import {
  fetchHiddenPositions,
  fetchOrderCancel,
  fetchOrderCancelAll,
  fetchOrders,
  fetchPositionReduce, fetchPositions, fetchPositionsClose, fetchPositionsCloseAll, fetchPositionsPnl,
} from './thunks';
import {
  ITradingSlice,
  UpdateOrder,
  UpdatePosition,
} from './types';

const initialState: ITradingSlice = {
  positions: [],
  orders: [],
  pnl: null,
  statusPnl: EStatus.success,
  status: EStatus.success,
  statusOrders: EStatus.success,
  statusReduce: EStatus.success,
  statusHidden: EStatus.success,
  statusClose: EStatus.success,
  statusAll: EStatus.success,
  statusOrderCancel: EStatus.success,
  statusOrderCancelAll: EStatus.success,
};

export const tradingSlice = createSlice({
  name: 'trading',
  initialState,
  reducers: {
    setPositions: (state, action) => {
      state.positions.unshift(action.payload);
    },
    setOrders: (state, action) => {
      state.orders.unshift(action.payload);
    },
    setPositionsPnl: (state, action: PayloadAction<string>) => {
      state.pnl = action.payload;
    },
    updatePosition: (state, action: PayloadAction<UpdatePosition>) => {
      const updatedPosition = action.payload;
      const index = state.positions.findIndex((pos) => pos.id === updatedPosition.id);

      if (index !== -1) {
        state.positions[index] = {
          ...state.positions[index],
          ...updatedPosition,
          id: updatedPosition.id,
          instrument: {
            ...state.positions[index].instrument,
            ...updatedPosition?.instrument,
          },
          exchange: {
            ...state.positions[index].exchange,
            ...updatedPosition?.exchange,
          },
        };
      }
    },
    updatePositionsInstrument: (state, action: PayloadAction<UpdateInstrument>) => {
      const updatedInstrument = action.payload;

      state.positions.forEach((position, index) => {
        if (position.instrument.id === updatedInstrument.id) {
          state.positions[index] = {
            ...position,
            instrument: {
              ...position.instrument,
              price: updatedInstrument.price,
            },
          };
        }
      });
    },
    updateOrdersInstrument: (state, action: PayloadAction<UpdateInstrument>) => {
      const updatedOrder = action.payload;

      state.orders.forEach((order, index) => {
        if (order.instrument.id === updatedOrder.id) {
          state.orders[index] = {
            ...order,
            instrument: {
              ...order.instrument,
              price: updatedOrder.price,
            },
          };
        }
      });
    },
    updateOrder: (state, action: PayloadAction<UpdateOrder>) => {
      const updatedOrder = action.payload;
      const index = state.orders.findIndex((order) => order.id === updatedOrder.id);

      if (index !== -1) {
        state.orders[index] = {
          ...state.orders[index],
          ...updatedOrder,
          instrument: { ...state.orders[index].instrument, ...updatedOrder?.instrument },
          exchange: { ...state.orders[index].exchange, ...updatedOrder?.exchange },
        };
      }
    },
    removePositionById: (state, action: PayloadAction<string>) => {
      state.positions = state.positions.filter((position) => position.id !== action.payload);
    },
    removePositionByIds: (state, action: PayloadAction<string[]>) => {
      state.positions = state.positions.filter((position) => !action.payload.includes(position.id));
    },
    removeOrderById: (state, action: PayloadAction<string>) => {
      state.orders = state.orders.filter((order) => order.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPositions.pending, (state) => {
        state.status = EStatus.loading;
        state.positions = [];
      })
      .addCase(fetchPositions.fulfilled, (state, action) => {
        state.status = EStatus.success;

        const positions = action.payload;

        if (positions.length === 0) {
          state.positions = [];
        } else {
          state.positions = positions.map((position) => formatPositionProcess(position));
        }
      })
      .addCase(fetchPositions.rejected, (state) => {
        state.status = EStatus.rejected;
        state.positions = [];
      })

      .addCase(fetchPositionReduce.pending, (state) => {
        state.statusReduce = EStatus.loading;
      })
      .addCase(fetchPositionReduce.fulfilled, (state) => {
        state.statusReduce = EStatus.success;
      })
      .addCase(fetchPositionReduce.rejected, (state) => {
        state.statusReduce = EStatus.rejected;
      })

      .addCase(fetchPositionsClose.pending, (state) => {
        state.statusClose = EStatus.loading;
      })
      .addCase(fetchPositionsClose.fulfilled, (state) => {
        state.statusClose = EStatus.success;
      })
      .addCase(fetchPositionsClose.rejected, (state) => {
        state.statusClose = EStatus.rejected;
      })

      .addCase(fetchPositionsCloseAll.pending, (state) => {
        state.statusAll = EStatus.loading;
      })
      .addCase(fetchPositionsCloseAll.fulfilled, (state) => {
        state.statusAll = EStatus.success;
      })
      .addCase(fetchPositionsCloseAll.rejected, (state) => {
        state.statusAll = EStatus.rejected;
      })

      .addCase(fetchOrders.pending, (state) => {
        state.statusOrders = EStatus.loading;
        state.orders = [];
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.statusOrders = EStatus.success;

        const orders = action.payload;

        if (orders.length === 0) {
          state.orders = [];
        } else {
          state.orders = orders.map((order) => formatOrderProcess(order));
        }
      })
      .addCase(fetchOrders.rejected, (state) => {
        state.statusOrders = EStatus.rejected;
        state.orders = [];
      })

      .addCase(fetchOrderCancel.pending, (state) => {
        state.statusOrderCancel = EStatus.loading;
      })
      .addCase(fetchOrderCancel.fulfilled, (state) => {
        state.statusOrderCancel = EStatus.success;
      })
      .addCase(fetchOrderCancel.rejected, (state) => {
        state.statusOrderCancel = EStatus.rejected;
      })

      .addCase(fetchOrderCancelAll.pending, (state) => {
        state.statusOrderCancelAll = EStatus.loading;
      })
      .addCase(fetchOrderCancelAll.fulfilled, (state) => {
        state.statusOrderCancelAll = EStatus.success;
      })
      .addCase(fetchOrderCancelAll.rejected, (state) => {
        state.statusOrderCancelAll = EStatus.rejected;
      })

      .addCase(fetchPositionsPnl.pending, (state) => {
        state.statusPnl = EStatus.loading;
        state.pnl = null;
      })
      .addCase(fetchPositionsPnl.fulfilled, (state, action) => {
        state.statusPnl = EStatus.success;
        state.pnl = action.payload;
      })
      .addCase(fetchPositionsPnl.rejected, (state) => {
        state.statusPnl = EStatus.rejected;
        state.pnl = null;
      })

      .addCase(fetchHiddenPositions.pending, (state) => {
        state.statusHidden = EStatus.loading;
      })
      .addCase(fetchHiddenPositions.fulfilled, (state, action) => {
        state.statusHidden = EStatus.success;
      })
      .addCase(fetchHiddenPositions.rejected, (state) => {
        state.statusHidden = EStatus.rejected;
      });
  },
});

export const {
  setPositions,
  setOrders,
  removePositionById,
  removePositionByIds,
  removeOrderById,
  updatePosition,
  updateOrder,
  updatePositionsInstrument,
  updateOrdersInstrument,
  setPositionsPnl,
} = tradingSlice.actions;
export const tradingReducer = tradingSlice.reducer;
