import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { IVerificationsSlice } from './types';
import { fetchVerifications, fetchVerificationsConfirm, fetchVerificationsConfirmGA } from './thunks';

const initialState: IVerificationsSlice = {
  verification: null,
  status: EStatus.success,
  statusConfirm: EStatus.success,
  statusConfirmGA: EStatus.success,
};

export const verificationsSlice = createSlice({
  name: 'verifications',
  initialState,
  reducers: {
    setVerificationId: (state, action: PayloadAction<number>) => {
      state.verification = {
        methods: [],
        verification_id: action.payload,
      };
    },
    removeVerifications: (state) => {
      state.verification = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVerifications.pending, (state) => {
        state.status = EStatus.loading;
        state.verification = null;
      })
      .addCase(fetchVerifications.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.verification = action.payload;
      })
      .addCase(fetchVerifications.rejected, (state) => {
        state.status = EStatus.rejected;
        state.verification = null;
      })

      .addCase(fetchVerificationsConfirm.pending, (state) => {
        state.statusConfirm = EStatus.loading;
      })
      .addCase(fetchVerificationsConfirm.fulfilled, (state) => {
        state.statusConfirm = EStatus.success;
      })
      .addCase(fetchVerificationsConfirm.rejected, (state) => {
        state.statusConfirm = EStatus.rejected;
      })

      .addCase(fetchVerificationsConfirmGA.pending, (state) => {
        state.statusConfirmGA = EStatus.loading;
      })
      .addCase(fetchVerificationsConfirmGA.fulfilled, (state, action) => {
        state.statusConfirmGA = EStatus.success;
      })
      .addCase(fetchVerificationsConfirmGA.rejected, (state) => {
        state.statusConfirmGA = EStatus.rejected;
      });
  },
});

export const { removeVerifications, setVerificationId } = verificationsSlice.actions;

export const verificationsReducer = verificationsSlice.reducer;
