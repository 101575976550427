import classNames from 'classnames';
import styles from './card-steps.module.scss';

interface ICardStepsProps {
    visible?: boolean
    step: 'none' | 'first' | 'second' | 'third' | string
  }
const CardSteps = ({ step, visible }: ICardStepsProps) => (

  <div className={classNames(styles.cardContainer, { [styles.visible]: !visible })}>
    <div className={styles[step]}>Шаг №1</div>
    <div className={styles[step]}>Шаг №2</div>
    <div className={styles[step]}>Шаг №3</div>
  </div>

);

export default CardSteps;
