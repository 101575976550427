import { useRef, useEffect } from 'react';
import { Nullable } from 'src/shared/types/global-types';

export function useIntersectionObserver(
  page: number,
  totalPages: number,
  data: unknown,
  className: string,
  setPage: Function,
  maxItems?: number,
) {
  const observerRef = useRef<Nullable<IntersectionObserver>>(null);
  const count = maxItems || 19;

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prevPage: number) => prevPage + 1);
      }
    });

    return () => {
      observerRef.current?.disconnect();
    };
  }, [page, totalPages, setPage]);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    const postNodes = document.querySelectorAll(`.${className}`);
    if (postNodes.length > count) {
      // eslint-disable-next-line no-unused-expressions
      observerRef.current && observerRef.current.observe(postNodes[postNodes.length - 1]);
    }
  }, [data, className, count, totalPages]);

  return observerRef;
}
