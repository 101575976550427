import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRegistrationSlice } from './types';

const initialState: IRegistrationSlice = {
  email: '',
  password: '',
  regionCode: 0,
  emailVerificationCode: 0,
};

export const RegistrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setRegionCode: (state, action: PayloadAction<number>) => {
      state.regionCode = action.payload;
    },
    setCodeEmail: (state, action: PayloadAction<number>) => {
      state.emailVerificationCode = action.payload;
    },
    resetRegistrationData: (state) => {
      state.email = '';
      state.password = '';
      state.regionCode = 0;
      state.emailVerificationCode = 0;
    },
  },
});

export const {
  setEmail, setPassword, setRegionCode, setCodeEmail, resetRegistrationData,
} = RegistrationSlice.actions;

// Экспортируйте редюсер, а не срез
export const registrationReducer = RegistrationSlice.reducer;
