import { ReactComponent as DiscordIconSvg } from 'src/shared/assets/images/socials/discord.svg';

import './discord-icon.scss';

const CopyIcon = () => (
  <div className="icon discord-icon">
    <button><DiscordIconSvg /></button>
  </div>
);

export default CopyIcon;
