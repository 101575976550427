import { Divider } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'src/shared/ui/button/button/button';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';

import { ReactComponent as ReplenishmentIconSvg } from 'src/shared/assets/images/account/replenishment.svg';
import { ReactComponent as WithdrawalIconSvg } from 'src/shared/assets/images/account/withdrawal.svg';
import { ReactComponent as TransfersIconSvg } from 'src/shared/assets/images/account/transfers.svg';

import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { BalanceType } from 'src/entities/balance-type';
import { useAppDispatch } from 'src/app/store/store';
import { setTransfersAsset } from 'src/widgets/transfers/model/slice';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { ETradingType, Nullable } from 'src/shared/types/global-types';
import { SubAccountAsset, SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import {
  decimalizeQuantity, decimalizeQuantityLocked, multiplyValues, scrollToTop, toFixed,
} from 'src/shared/libs/helpers/helper.lib';

import styles from './asset-table-item-adaptive.module.scss';

interface IAssetTableItemAdaptiveProps {
    asset: SubAccountAsset
    subAccount: Nullable<SubAccountType>
}

const AssetTableItemAdaptive = ({
  asset, subAccount,
}: IAssetTableItemAdaptiveProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { theme } = useTheme();
  const { getExchangeImageById } = useExchange();
  const { getSubAccountNameById } = useSubAccount();

  const balanceVisible = useSelector(selectHiddenBalance);

  const renderPnl = (pnl: string) => (
    <>
      {Number(pnl) === 0 && (
        <span className={styles.zero}>{pnl} %</span>
      )}
      {Number(pnl) > 0 && (
        <span className={styles.plus}>+{Number(toFixed(pnl, 2)).toLocaleString()} %</span>
      )}
      {Number(pnl) < 0 && (
        <span className={styles.minus}>-{Number(toFixed(pnl, 2)).toLocaleString()} %</span>
      )}

    </>
  );

  const handleWithdrawalAsset = () => {
    const route = '/transfers';
    const subAccountId = `?sub_account_id=${asset.sub_account_id}`;
    const assetSymbol = `&asset_symbol=${asset.asset.symbol}`;
    const assetId = `&asset_id=${asset.asset.id}`;
    const tabIndex = '&tab_index=1';

    const navigateWithdrawal = route + subAccountId + assetSymbol + assetId + tabIndex;
    navigate(navigateWithdrawal, { replace: true });
  };

  const handleReplenishmentAsset = () => {
    const route = '/transfers';
    const exchangeId = `?exchange_id=${subAccount?.exchange_id}`;
    const subAccountId = `&replenishment_sub_account_id=${asset.sub_account_id}`;
    const assetId = `&asset_id=${asset.asset.id}`;
    const assetType = `&asset_type=${asset.asset_type === ETradingType.spot ? 'SPOT' : 'FUTURES'}`;
    const tabIndex = '&tab_index=0';
    const navigateReplenishment = route + exchangeId + subAccountId + assetId + assetType + tabIndex;

    navigate(navigateReplenishment, { replace: true });
    scrollToTop();
  };

  const handleTransfersAsset = (asset: SubAccountAsset) => {
    const route = '/transfers';
    const subAccountId = `?transfer_sub_account_id=${subAccount?.id}`;
    const exchangeId = `&exchange_id=${subAccount?.exchange_id}`;
    const tabIndex = '&tab_index=2';
    const navigateTransfers = route + subAccountId + exchangeId + tabIndex;

    navigate(navigateTransfers);

    dispatch(setTransfersAsset(asset));
  };

  return (
    <div className={styles.assetContainer} key={asset.id}>
      <div className={styles.header}>
        <h2>{asset.asset.symbol} <BalanceType type={asset.asset_type} /></h2>
        <div className={styles.subAccount}>
          <span className={styles.account}>Счет:</span>
          <img src={getExchangeImageById(subAccount?.exchange_id)} alt="exchange" />
          <span>{getSubAccountNameById(asset.sub_account_id)}</span>
        </div>
      </div>

      <Divider className={styles.divider} color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />

      <div className={styles.box}>
        <span>Сумма</span>
        <span>{!balanceVisible ? decimalizeQuantity(asset.quantity, asset.asset.symbol) : HIDDEN_BALANCES}</span>
      </div>

      <div className={styles.box}>
        <span>Доступно</span>
        <span>{!balanceVisible ? decimalizeQuantityLocked(asset) : HIDDEN_BALANCES}</span>
      </div>

      <div className={styles.box}>
        <span>PNL за день %</span>
        <span>{renderPnl('0')}</span>
      </div>

      <div className={styles.box}>
        <span>В USDT</span>
        <span className={styles.available}>
          {asset.instrument
            ? (!balanceVisible ? `≈ ${multiplyValues(asset.instrument.price.bid, asset.quantity, USDT_PRECISION)} USDT` : HIDDEN_BALANCES)
            : '-'}
        </span>
      </div>

      { asset.asset_type === ETradingType.spot && (
        <div className={styles.box}>
          <Button onClick={handleWithdrawalAsset} background="black"><WithdrawalIconSvg className={styles.withdrawalIcon} /> Вывести</Button>
          <Button onClick={handleReplenishmentAsset} background="black"><ReplenishmentIconSvg className={styles.replenishmentIcon} /> Пополнить</Button>
        </div>
      )}

      { asset.asset_type === ETradingType.futures && (
        <div className={styles.box}>
          <Button onClick={() => handleTransfersAsset(asset)} background="black"><TransfersIconSvg className={styles.transferIcon} /> Перевести</Button>
        </div>
      )}
    </div>
  );
};

export default AssetTableItemAdaptive;
