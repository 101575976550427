import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISubscribeEvent } from 'src/shared/types/interfaces';
import { ISubscriptionsSlice } from './types';

const initialState: ISubscriptionsSlice = {
  subscriptions: null,
};

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    addSubsciptionQueue: (state, action: PayloadAction<ISubscribeEvent>) => {
      if (state.subscriptions) {
        const findIndex = state.subscriptions.findIndex((subscription) => subscription.metadata.event_type === action.payload.metadata.event_type);

        if (findIndex !== -1) {
          const oldData = state.subscriptions[findIndex].metadata?.data;
          const newData = action.payload.metadata?.data;

          if (oldData && newData) {
            const updateData = [...new Set([...oldData, ...newData])];
            state.subscriptions[findIndex].metadata.data = updateData;
          } else {
            state.subscriptions[findIndex] = action.payload;
          }
        } else {
          state.subscriptions.push(action.payload);
        }
      } else {
        state.subscriptions = [action.payload];
      }
    },
    removeSubsciptionQueue: (state, action: PayloadAction<string>) => {
      if (state.subscriptions) {
        state.subscriptions = state.subscriptions.filter((subscription) => subscription.metadata.event_type !== action.payload);
      }
    },
    clearSubsciptionQueue: (state) => {
      state.subscriptions = null;
    },
  },
});

export const { addSubsciptionQueue, removeSubsciptionQueue, clearSubsciptionQueue } = subscriptionsSlice.actions;
export const subscriptionsReducer = subscriptionsSlice.reducer;
