import { AxiosError, AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { DetailError } from 'src/shared/types/global-types';
import { InstrumentParams, InstrumentData } from './types';

export type InstrumentsParams = {
  limit: number;
  page: number;
  symbol?: string;
  subAccountId: number;
}

export type SetInstrumentParams = {
  params: InstrumentParams,
  subAccountId: number
}

export type CalculateParams = {
  deposit: number
  type: string,
}

export type SetCalculateParams = {
  params: CalculateParams,
  subAccountId: number
}

export type ChangeMarginMode = {
  subAccountId: number,
  marginMode: number
}

export type SetLeverageParams = {
  sub_account_id?: number
  instrument_ids?: number[]
  excluded_instrument_ids?: number[]
  choosen_bracket: number
}

export const fetchInstruments = createAsyncThunk(
  'leverages/fetchInstruments',
  async ({
    limit, page, symbol, subAccountId,
  }: InstrumentsParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<InstrumentData>(ROUTE_PATH.leverages.leverages, {
        params: {
          limit,
          page,
          symbol,
        },
        headers: { 'sub-account-id': subAccountId },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchSetLeverageInstrument = createAsyncThunk(
  'leverages/fetchSetLeverageInstrument',
  async (fetchParams: SetLeverageParams, { rejectWithValue }) => {
    try {
      const params: SetLeverageParams = {
        instrument_ids: fetchParams.instrument_ids,
        choosen_bracket: fetchParams.choosen_bracket,
      };

      if (fetchParams.excluded_instrument_ids) params.excluded_instrument_ids = fetchParams.excluded_instrument_ids;

      const response: AxiosResponse<boolean | DetailError> = await $api.post(
        ROUTE_PATH.leverages.leverages,
        params,
        { headers: { 'sub-account-id': fetchParams.sub_account_id } },
      );

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchSetInstrumentParams = createAsyncThunk(
  'leverages/setInstrumentParams',
  async ({ params, subAccountId }: SetInstrumentParams, { rejectWithValue }) => {
    try {
      const response = await $api.put<InstrumentData>(ROUTE_PATH.leverages.leverages, params, {
        headers: { 'sub-account-id': subAccountId },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchCalculate = createAsyncThunk(
  'leverages/fetchCalculate',
  async ({ params, subAccountId }: SetCalculateParams, { rejectWithValue }) => {
    try {
      const response = await $api.post<DetailError | boolean>(ROUTE_PATH.leverages.calculate, params, {
        headers: { 'sub-account-id': subAccountId },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchAutoCalculate = createAsyncThunk(
  'leverages/fetchAutoCalculate',
  async (subAccountId: number, { rejectWithValue }) => {
    try {
      const response = await $api.put(ROUTE_PATH.leverages.autoCalulate, {}, {
        headers: { 'sub-account-id': subAccountId },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchMarginMode = createAsyncThunk(
  'leverages/fetchMarginMode',
  async (params: ChangeMarginMode, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.leverages.marginMode, { margin_mode: params.marginMode }, {
        headers: { 'sub-account-id': params.subAccountId },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);
