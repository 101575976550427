export const REBATE_EXCHANGES_INFO = [
  {
    exchangeName: 'Binance',
    hintLabel: 'Ваш процент кэшбека',
    tradingType: [
      {
        type: 'FUTURES',
        name: 'Фьючерс',
      },
      {
        type: 'SPOT',
        name: 'Спот',
      },
    ],
  },
  {
    exchangeName: 'Bybit',
    hintLabel: 'Подробнее об условиях',
    tradingType: [
      {
        type: 'FUTURES',
        name: 'Фьючерс',
      },
      {
        type: 'SPOT',
        name: 'Спот',
      },
    ],
  },
];
