import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useEffect, useState } from 'react';
import { Carousel } from '@mantine/carousel';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import TransferHistoryAdaptive from 'src/widgets/transfer-history/adaptive/transfer-history-adaptive';

import { Exchange } from 'src/app/store/slices/exchanges/types';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import { useAppDispatch } from 'src/app/store/store';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { CreateSubAccountParams, fetchCreateSubAccount } from 'src/app/store/slices/sub-accounts/thunks';
import { selectCreateSubAccountStatus, selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';

import { Balance } from 'src/widgets/balance';
import { Nullable } from 'src/shared/types/global-types';
import { getFromLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { ModalAddSubAccount } from 'src/shared/ui/modal/modal-add-subaccount';

import { DEFAULT_ERROR_STATE, ERROR_MSG_LIST } from '../constants';
import SubAccount from '../components/subaccount/subaccount';
import styles from './accounts-adaptive.module.scss';
import '@mantine/carousel/styles.css';

const skeletonArray = [1, 2, 3];

const AccountsPageAdaptive = () => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();

  const [opened, { open, close }] = useDisclosure(false);

  const { balances } = useSelector(selectBalances);
  const { exchanges } = useSelector(selectExchanges);
  const subAccounts = useSelector(selectSubAccounts);
  const createSubAccountStatus = useSelector(selectCreateSubAccountStatus);

  const [subAccountName, setSubAccountName] = useState('');
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_STATE);
  const [currentExchange, setCurrentExchange] = useState<Nullable<Exchange>>(exchanges ? exchanges[0] : null);
  const [orderedSubAccounts, setOrderedSubAccounts] = useState<Nullable<SubAccountType[]>>(null);

  const noExchange = exchanges === null;

  const checkSubAccountNameExist = useCallback((name: string) => {
    if (currentExchange && subAccounts) {
      const subAccountsName = subAccounts.filter((subAccount) => subAccount.exchange_id === currentExchange.id).map((subAccount) => subAccount.user_name);

      return subAccountsName.includes(name.trim());
    }
    return false;
  }, [currentExchange, subAccounts]);

  const handleOpenModalAddSubAccount = () => {
    if (exchanges) {
      setCurrentExchange(exchanges[0]);
      setSubAccountName('');
    }
    open();
  };

  const handleCloseModal = () => {
    setErrorMessage(DEFAULT_ERROR_STATE);
    setSubAccountName('');
    close();
  };

  const handleCreateSubAccount = async () => {
    if (!currentExchange || subAccountName === '') return;

    if (checkSubAccountNameExist(subAccountName)) {
      setErrorMessage({ state: true, message: 'Это имя уже занято' });
      return;
    }
    if (!checkSubAccountNameExist(subAccountName)) {
      setErrorMessage(DEFAULT_ERROR_STATE);
    }
    if (subAccountName.length > 16) {
      setErrorMessage({ state: true, message: 'Максимальная длина 16 символов' });
      return;
    }

    const newSubAccount: CreateSubAccountParams = {
      exchange_id: currentExchange.id,
      user_name: subAccountName,
    };

    const { payload } = await dispatch(fetchCreateSubAccount(newSubAccount));

    if (typeof payload === 'object') {
      close();
    } else if (typeof payload === 'string') {
      const message = ERROR_MSG_LIST[payload] || payload;
      setAlertMessage(message, 'error');
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const handleSelectExchange = (exchange: Exchange) => {
    setCurrentExchange(exchange);
    setErrorMessage(DEFAULT_ERROR_STATE);
  };

  useEffect(() => {
    const storedOrder: number[] = getFromLocalStorage('subAccountsColumnOrder', []);

    if (storedOrder.length && subAccounts) {
      const orderedSubAccounts = storedOrder.map((id: number) => subAccounts.find((subAccount) => subAccount.id === id)).filter(Boolean) as SubAccountType[];

      if (orderedSubAccounts) setOrderedSubAccounts(orderedSubAccounts);
    } else {
      setOrderedSubAccounts(subAccounts);
    }
  }, [subAccounts]);

  return (
    <div className={styles.subAccountAdaptiveWrapper}>
      <div className={styles.body}>
        <Balance />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Счета</h1>
          <Button disabled={noExchange || !subAccounts} onClick={handleOpenModalAddSubAccount} maxWidth="190px" background={noExchange ? 'gray' : 'black'}>Открыть новый счет</Button>
        </div>

        <div className={styles.subAccountsWrapper}>
          {subAccounts ? (
            <Carousel slideSize="100%" height={230} slideGap="xs" align="start" controlsOffset="md" controlSize={14} withControls={false} withIndicators>
              {
                subAccounts?.map((subAccount) => (
                  <Carousel.Slide key={subAccount.id}>
                    <SubAccount
                      link={`/sub-accounts/${subAccount?.id}`}
                      subAccount={subAccount}
                      balance={balances?.sub_accounts.find((balance) => balance.sub_account_id === subAccount.id)}
                    />
                  </Carousel.Slide>

                ))
              }
            </Carousel>
          ) : (
            <Carousel slideSize="100%" height={260} slideGap="xs" align="start" controlsOffset="md" controlSize={14} withControls={false} withIndicators>
              {
                skeletonArray?.map((item) => (
                  <Carousel.Slide key={item}>
                    <SelectSkeleton key={item} radius="16px" height={171} />
                  </Carousel.Slide>

                ))
              }
            </Carousel>
          )}
        </div>

        <TransferHistoryAdaptive />
      </div>

      <ModalAddSubAccount
        opened={opened}
        closeModal={handleCloseModal}
        exchanges={exchanges}
        addSubAccount={handleCreateSubAccount}
        selectExchange={handleSelectExchange}
        currentExchange={currentExchange}
        inputValue={subAccountName}
        inputOnChange={setSubAccountName}
        createStatus={createSubAccountStatus}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default AccountsPageAdaptive;
