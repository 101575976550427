import { ColumnOrderItem } from '../model/types';

export const positionsColumn: ColumnOrderItem[] = [
  { key: 'checkbox', label: 'Чекбокс' },
  { key: 'instrument_symbol', label: 'Инструмент' },
  { key: 'exchange_name', label: 'Биржа' },
  { key: 'leverage', label: 'Плечо' },
  { key: 'sub_account_id', label: 'Счет' },
  { key: 'side', label: 'Напр.' },
  { key: 'quantity', label: 'Объём' },
  { key: 'quantity_dollars', label: 'Объём ($)' },
  { key: 'average_price', label: 'Цена входа' },
  { key: 'price', label: 'Посл. цена' },
  { key: 'pnlInPercents', label: 'PNL (%)' },
  { key: 'pnlInCurrency', label: 'PNL ($)' },
  { key: 'reduce', label: 'Сократить' },
  { key: 'close', label: 'Закрыть' },
];

export const ordersColumn: ColumnOrderItem[] = [
  { key: 'checkbox', label: 'Чекбокс' },
  { key: 'instrument_symbol', label: 'Инструмент' },
  { key: 'exchange_name', label: 'Биржа' },
  { key: 'sub_account_id', label: 'Счет' },
  { key: 'quantity', label: 'Объём' },
  { key: 'quantity_dollars', label: 'Объём ($)' },
  { key: 'price', label: 'Цена' },
  { key: 'last_price', label: 'Посл. цена' },
  { key: 'action', label: 'Направление' },
  { key: 'type', label: 'Тип' },
  { key: 'close', label: 'Отменить' },
];

export const orderTableColumns = {
  checkbox: {
    show: true,
    name: 'Выбор',
  },
  instrument: {
    show: true,
    name: 'Инструменты',
  },
  exchange: {
    show: true,
    name: 'Биржа',
  },
  subAccountId: {
    show: true,
    name: 'Счет',
  },
  quantity: {
    show: true,
    name: 'Объём',
  },
  quantity_dollars: {
    show: true,
    name: 'Объём ($)',
  },
  price: {
    show: true,
    name: 'Цена',
  },
  lastPrice: {
    show: true,
    name: 'Посл. цена',
  },
  action: {
    show: true,
    name: 'Направление',
  },
  type: {
    show: true,
    name: 'Тип',
  },
  close: {
    show: true,
    name: 'Отменить',
  },
};

type TableColumn = {
  show: boolean;
  name: string;
};

export const positionsTableColumns: Record<string, TableColumn> = {
  checkbox: {
    show: true,
    name: 'Выбор',
  },
  instrument: {
    show: true,
    name: 'Инструменты',
  },
  exchange: {
    show: true,
    name: 'Биржа',
  },
  leverage: {
    show: true,
    name: 'Плечо',
  },
  subAccountId: {
    show: true,
    name: 'Счет',
  },
  side: {
    show: true,
    name: 'Напр.',
  },
  quantity: {
    show: true,
    name: 'Объём',
  },
  quantity_dollars: {
    show: true,
    name: 'Объём ($)',
  },
  averagePrice: {
    show: true,
    name: 'Цена входа',
  },
  price: {
    show: true,
    name: 'Посл. цена',
  },
  pnlInPercents: {
    show: true,
    name: 'PNL (%)',
  },
  pnlInCurrency: {
    show: true,
    name: 'PNL ($)',
  },
  reduce: {
    show: true,
    name: 'Сократить',
  },
  close: {
    show: true,
    name: 'Закрыть',
  },
};
