import { useParams } from 'react-router-dom';
import styles from './trade.module.scss';

const Trade = () => {
  const { id } = useParams();

  return (
    <div className={styles.tradeContainer}>{id}</div>
  );
};

export default Trade;
