import { useNavigate } from 'react-router-dom';
import { saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as GooglePlayIconSvg } from 'src/shared/assets/images/socials/google-play.svg';
import { ReactComponent as AppStoreIconSvg } from 'src/shared/assets/images/socials/app-store.svg';

import Button from 'src/shared/ui/button/button/button';
import { GA_LINK_APP_STORE, GA_LINK_GOOGLE_PLAY } from 'src/shared/constants/app-links';
import styles from './connection-ga-step-zero.module.scss';

interface IConnectionGaStepZero {
  closeModal: () => void
  nextStep?: () => void
}
export const ConnectionGaStepZero = ({ closeModal, nextStep }:IConnectionGaStepZero) => {
  const navigate = useNavigate();

  const startConnectedGA = async () => {
    if (nextStep) {
      nextStep();
    }
    navigate('/profile?tab=1');
  };

  const skip = () => {
    closeModal();
    saveToLocalStorage('showGA', false);
  };

  return (
    <div className={styles.gaModalWrapper}>
      <p className={styles.info}>Загрузите и установите приложение <br /> Google Authenticator</p>

      <div className={styles.downloadLinksWrapper}>
        <a className={styles.downloadLink} href={GA_LINK_GOOGLE_PLAY}>
          <GooglePlayIconSvg />
          <span>Скачать <br /> из Google Play</span>
        </a>
        <a className={styles.downloadLink} href={GA_LINK_APP_STORE}>
          <AppStoreIconSvg />
          <span>Скачать <br /> из App Store</span>
        </a>
      </div>

      <div className={styles.gaModalActions}>
        <Button onClick={skip} background="black">Больше не уведомлять</Button>
        <Button onClick={startConnectedGA} background="green">Далее</Button>
      </div>
    </div>
  );
};
