import { AxiosError } from 'axios';
import { useState, useRef } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ReCaptcha, { ReCAPTCHA } from 'react-google-recaptcha';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import AuthHelper from 'src/pages/auth/components/auth-helper/auth-helper';
import AuthService, { RegistrationStepOne } from 'src/services/auth.service';

import { ERROR_MSG_LIST } from '../../constants';
import { setEmail, setPassword } from '../../model/slice';

import InputText from '../../../components/inputs-auth/input-auth';
import InputPassword from '../../../components/inputs-auth/input-password';
import UserAgreement from '../user-agreement/user-agreement';
import styles from './registration-form.module.scss';

const schema = yup.object().shape({
  email: yup.string().required('Электронная почта обязательна').email('Введите корректный адрес электронной почты'),
  password: yup.string().required('Пароль обязателен')
    .test('password', 'Пароль должен содержать минимум 8 символов', (value) => value.length >= 8)
    .test('lowercase', 'Пароль должен содержать буквы нижнего регистра (a–z)', (value) => /[a-z]/.test(value))
    .test('uppercase', 'Пароль должен содержать буквы верхнего регистра (A–Z)', (value) => /[A-Z]/.test(value))
    .test('digits', 'Пароль должен содержать цифры (0-9)', (value) => /\d/.test(value))
    .test('specialChars', 'Пароль должен содержать специальные символы (например, !@#$%^&*)', (value) => /[!@#$%^&*]/.test(value)),
  reenterPassword: yup.string()
    .required('Пароль обязателен')
    .oneOf([yup.ref('password')], 'Пароли не совпадают'),
});

type RegisterType = {
  email: string,
  password: string,
  reenterPassword: string,
}

const RegistrationProd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setAlertMessage } = useAlert();

  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [checkedPolicy, setCheckedPolicy] = useState(false);
  const [errorMessageCheckedPolicy, setErrorMessageCheckedPolicy] = useState('');

  const captchaRef = useRef<ReCaptcha>(null);
  const captchaContainerRef = useRef<HTMLInputElement>(null);

  const form = useForm<RegisterType>({
    defaultValues: {
      email: '',
      password: '',
      reenterPassword: '',
    },
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, formState } = form;
  const { errors } = formState;

  const handleResetCaptcha = () => {
    if (captchaRef.current) {
      captchaRef.current.reset();
    }
  };

  const handleSendCodeEmail = async (email: string) => {
    const result = await AuthService.sendCodeEmail(email);

    if (result.data) {
      navigate('/auth/verification');
    } else if (result instanceof AxiosError && result.response) {
      if (result.response.data.detail === 'TOO_MANY_REQUESTS') {
        setLoading(false);
        setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
        handleResetCaptcha();
      } else if (result.response.data.detail === 'INVALID_CAPTCHA') {
        setLoading(false);
        setAlertMessage('Капча устарела', 'error');
        handleResetCaptcha();
      } else {
        setLoading(false);
        setAlertMessage('Произошла внутренняя ошибка при отправке кода на почту', 'error');
        handleResetCaptcha();
      }
    } else {
      setLoading(false);
      setAlertMessage('Произошла внутренняя ошибка', 'error');
      handleResetCaptcha();
    }
  };

  const handleRegistrationStepOne = async (userData: RegisterType) => {
    setLoading(true);

    const stepOne: RegistrationStepOne = {
      email: userData.email,
      recaptcha_token: captcha || '',
    };

    const result = await AuthService.registrationStepOne(stepOne);

    if (result.data) {
      handleSendCodeEmail(userData.email);
    } else if (result instanceof AxiosError && result.response) {
      const { detail } = result.response.data;

      if (typeof detail === 'string') {
        const message = detail ? ERROR_MSG_LIST[detail] : ERROR_MSG_LIST.DEFAULT;
        setAlertMessage(message, 'error');
        setLoading(false);
        handleResetCaptcha();
      }
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
      setLoading(false);
    }
  };

  const saveData = (registrationData: RegisterType) => {
    batch(() => {
      dispatch(setEmail(registrationData.email));
      dispatch(setPassword(registrationData.password));
    });
  };

  const onSubmit = (data: RegisterType) => {
    if (!captcha) {
      setAlertMessage('Заполните капчу', 'error');
      return;
    }

    if (!checkedPolicy) {
      setErrorMessageCheckedPolicy('Пожалуйста, подтвердите, что вы согласны с условиями использования, чтобы продолжить.');
      return;
    }
    setErrorMessageCheckedPolicy('');

    saveData(data);
    handleRegistrationStepOne(data);
  };

  return (
    <form className={styles.registrationForm} onSubmit={handleSubmit(onSubmit)}>

      <Controller
        name="email"
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <InputText
            ref={ref}
            error={!!errors.email}
            placeholder="Электронная почта"
            label="E-mail"
            {...fieldProps}
          />
        )}
      />
      {errors.email && errors.email.message && <Hint error text={errors.email.message} />}

      <Controller
        name="password"
        control={control}
        render={({
          field: {
            ref, value, onChange, ...fieldProps
          },
        }) => (
          <InputPassword
            value={value || ''}
            onChange={onChange}
            ref={ref}
            error={!!errors.password}
            placeholder="Введите пароль"
            label="Пароль"
            {...fieldProps}
          />
        )}
      />
      {errors.password && errors.password.message && <Hint error text={errors.password.message} />}

      <Controller
        name="reenterPassword"
        control={control}
        render={({
          field: {
            ref, value, onChange, ...fieldProps
          },
        }) => (
          <InputPassword
            value={value || ''}
            onChange={onChange}
            ref={ref}
            error={!!errors.reenterPassword}
            placeholder="Введите пароль"
            label="Повторите пароль"
            {...fieldProps}
          />
        )}
      />
      {errors.reenterPassword && errors.reenterPassword.message && <Hint error text={errors.reenterPassword.message} />}

      <div
        ref={captchaContainerRef}
        className="recaptcha"
      >
        <ReCaptcha
          ref={captchaRef}
          sitekey={window.appConfig.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={() => setCaptcha(captchaRef.current?.getValue() || null)}
          asyncScriptOnLoad={() => {
            captchaContainerRef.current!.className = `${captchaContainerRef.current!.className} recaptcha_loaded`;
          }}
        />
      </div>

      <UserAgreement setChecked={setCheckedPolicy} checked={checkedPolicy} />
      {errorMessageCheckedPolicy.length > 0 && <Hint error text={errorMessageCheckedPolicy} />}
      <Button type="submit" loading={loading} background="green">Продолжить</Button>

      <AuthHelper title="Уже есть аккаунт?" path="/auth/login" linkTitle="Войти" />
    </form>
  );
};

export default RegistrationProd;
