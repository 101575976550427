import { ReactComponent as DashboardIconSvg } from 'src/shared/assets/images/diary/aside/dasboard.svg';
import { ReactComponent as TradeListIconSvg } from 'src/shared/assets/images/diary/aside/trade-list.svg';
import { ReactComponent as HelpIconSvg } from 'src/shared/assets/images/diary/aside/help.svg';

export const DIARY_MENU_ITEMS = [
  { page: 'dashboard', icon: DashboardIconSvg },
  { page: 'trade-list', icon: TradeListIconSvg },
  { page: 'help', icon: HelpIconSvg },
];

export const DIARY_PAGES: Record<string, string> = {
  dashboard: 'dashboard',
  'trade-list': 'trade-list',
  help: 'help',
};
