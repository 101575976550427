import { EStatus, Nullable } from 'src/shared/types/global-types';

export type Verification = {
  verification_id: number
  methods: string[]
}

export interface IVerificationsSlice {
  verification: Nullable<Verification>
  status: EStatus
  statusConfirm: EStatus
  statusConfirmGA: EStatus
}

export enum EVerificationType {
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  CHANGE_DISCORD = 'CHANGE_DISCORD',
  CHANGE_TELEGRAM = 'CHANGE_TELEGRAM',
  CHANGE_PHONE_NUMBER = 'CHANGE_PHONE_NUMBER',
  CHANGE_API_KEY = 'CHANGE_API_KEY',
  CREATE_API_KEY = 'CREATE_API_KEY',
  DELETE_API_KEY = 'DELETE_API_KEY',
  CREATE_GA = 'CREATE_GA',
  DELETE_GA = 'DELETE_GA',
  MAKE_WITHDRAW = 'MAKE_WITHDRAW',
  CHANGE_LOGIN_VERIFICATIONS = 'CHANGE_LOGIN_VERIFICATIONS',
  CHANGE_ACCOUNT_ACTION_VERIFICATIONS = 'CHANGE_ACCOUNT_ACTION_VERIFICATIONS',
  CREATE_IP_ADDRESS = 'CREATE_IP_ADDRESS',
  DELETE_IP_ADDRESS = 'DELETE_IP_ADDRESS',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CREATE_WHITE_WALLET = 'CREATE_WHITE_WALLET',
  DELETE_WHITE_WALLET = 'DELETE_WHITE_WALLET'
}
