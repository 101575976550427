import { Link } from 'react-router-dom';
import ButtonLink from 'src/shared/ui/button/button-link/button-link';
import styles from './auth-helper.module.scss';

interface IAuthHelperProps {
  title: string,
  path: string,
  linkTitle: string
}

const AuthHelper = ({ title, path, linkTitle }: IAuthHelperProps) => (
  <div className={styles.noAccount}>
    <p>{title}</p>
    <Link to={path}>
      <ButtonLink>{linkTitle}</ButtonLink>
    </Link>
  </div>
);

export default AuthHelper;
