import { memo } from 'react';
import './divisor.scss';

export interface IDivisorProps {
  value: string | number;
  active: boolean
  onClick: () => void;
}

const Divisor = memo(({ value, onClick, active }: IDivisorProps) => (
  <div className="divisor">
    <button onClick={onClick} className={`divisor-item ${active && 'active-divisor'}`}>
      <span>{value}%</span>
    </button>
  </div>
));

export default Divisor;
