import { ReactComponent as ArrowIconSvg } from 'src/shared/assets/images/arrow.svg';

import './arrow-icon.scss';

const ArrowIcon = () => (
  <div className="icon arrow-icon">
    <button><ArrowIconSvg /></button>
  </div>
);

export default ArrowIcon;
