import { useSelector } from 'react-redux';
import { AuthLayout } from 'src/layouts';

import AbstractIconsSvg from 'src/shared/assets/images/auth/abstract.svg';
import LanguageSelection from 'src/entities/language-selection/language-selection';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import { useAppDispatch } from 'src/app/store/store';
import { useLayoutEffect } from 'react';
import AuthBody from '../components/auth-body/auth-body';
import AuthTitle from '../components/auth-title/auth-title';
import AuthFooter from '../components/auth-footer/auth-footer';
import useCheckStep from '../lib/hooks/use-check-step';
import VerificationForm from './verification-form/verification-form';

import { informSlides, infromTitleSilde } from '../lib/helpers/constants';
import { selectRegistration } from '../registration/model/selectors';

import styles from './verification.module.scss';
import { selectSlidesPage } from '../login/model/selectors';
import { setSlidesPage } from '../login/model/slice';

const pageTitle = 'Верификация почты';

const VerificationPage = () => {
  const { email } = useSelector(selectRegistration);

  useChangePageTitle(pageTitle);
  useCheckStep(email, '/auth/registration');

  const dispatch = useAppDispatch();
  const slidePage = useSelector(selectSlidesPage);

  useLayoutEffect(() => {
    dispatch(setSlidesPage(2));
  }, []);

  return (
    <AuthLayout page="">
      <div className={styles.container}>
        <section className={styles.bodyWrapper}>
          <AuthBody
            activeSlide="third"
            titleUp="Используйте"
            titleDown="защищенную почту"
            description="Рекомендуется использовать отдельную почту для создания торговых учетных записей"
            informList={informSlides[slidePage]}
            informTitle={infromTitleSilde[slidePage]}
          />
        </section>

        <section className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <LanguageSelection bgColor="#131313" />
            <AuthTitle visible step="second">Подтверждение<br />E-mail адреса</AuthTitle>
            <p className={styles.text}>Мы отправили письмо с пятизначным кодом подтверждения на вашу почту</p>

            <VerificationForm />
          </div>

          <AuthFooter />
        </section>

        <img className={styles.abstract} src={AbstractIconsSvg} alt="abstract" />
      </div>
    </AuthLayout>
  );
};

export default VerificationPage;
