import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { EStatus, ModalSteps } from 'src/shared/types/global-types';

import { activeGA, disabledPhoneVerification } from 'src/app/store/slices/user/slice';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { useAppDispatch } from 'src/app/store/store';
import { selectVerifications } from 'src/app/store/slices/verifications/selectors';
import { VerificationConfirmGA, fetchVerificationsConfirmGA } from 'src/app/store/slices/verifications/thunks';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputCode from 'src/shared/ui/input/input-code/input-code';
import InputForm from 'src/shared/ui/input/input-form/input-form';

import styles from './connection-ga-step-two.module.scss';

interface IConnectionGaStepTwo extends ModalSteps {}

export const ConnectionGaStepTwo = ({ step, setStep }:IConnectionGaStepTwo) => {
  const dispatch = useAppDispatch();

  const { setAlertMessage } = useAlert();
  const { verification, statusConfirmGA } = useSelector(selectVerifications);
  const { connectedGALink } = useSelector(selectUser);

  const [codeGA, setCodeGA] = useState('');
  const [errorCode, setErrorCode] = useState(false);

  const nextStepVerifyGA = async () => {
    if (verification && connectedGALink) {
      setErrorCode(false);

      const params: VerificationConfirmGA = {
        confirm: {
          ga_verification_code: codeGA,
          secret_key: connectedGALink.secret_key,
        },
        verification_id: verification.verification_id,
      };

      const { payload } = await dispatch(fetchVerificationsConfirmGA(params));

      if (payload === true) {
        dispatch(activeGA(true));
        dispatch(disabledPhoneVerification());
        setStep(3);
      } else if (payload === 'INVALID_CODE') {
        setErrorCode(true);
      } else {
        setAlertMessage('Произошла внутренняя ошибка!', 'error');
      }
    }
  };

  const backStepVerifyGA = () => {
    setStep(1);
    setErrorCode(false);
  };

  useEffect(() => {
    if (codeGA.length === 6) {
      nextStepVerifyGA();
    }
  }, [codeGA]);

  return step === 2 ? (
    <div className={styles.gaModalWrapper}>
      <p className={styles.info}>Для сохранения настроек, введите код из приложения Google Authenticator</p>

      <InputForm title="Код Google Authenticator">
        <InputCode error={errorCode} onChange={setCodeGA} />
      </InputForm>

      <div className={styles.gaModalActions}>
        <Button disabled={statusConfirmGA === EStatus.loading} onClick={backStepVerifyGA} background="black">Назад</Button>
        <Button disabled={statusConfirmGA === EStatus.loading} loading={statusConfirmGA === EStatus.loading} onClick={nextStepVerifyGA} background="green">Далее</Button>
      </div>
    </div>
  ) : null;
};
