import { $api } from 'src/shared/api/axios.instance';
import { AxiosError, AxiosResponse } from 'axios';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateWallet, Wallet } from './types';

export type CreateWalletParams = {
  wallet: CreateWallet,
  verification_id: number
}

export type DeleteWallet = {
  wallet_id: number,
  verification_id: number
}

export type ResponseWallet = {
  items: Wallet[]
  exists: boolean
}

export type WhiteWalletsParams = {
  asset_id?: number
}

export const fetchWallets = createAsyncThunk(
  'wallets/fetchWallets',
  async (params: WhiteWalletsParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ResponseWallet> = await $api.get(ROUTE_PATH.users.whiteWallets, { params: params || {} });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchCreateWallet = createAsyncThunk(
  'wallets/fetchCreateWallet',
  async (params: CreateWalletParams, { rejectWithValue }) => {
    try {
      const response = await $api.post<Wallet>(ROUTE_PATH.users.whiteWallets, params.wallet, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchDeleteWallet = createAsyncThunk(
  'wallets/fetchDeleteWallet',
  async (params: DeleteWallet, { rejectWithValue }) => {
    try {
      const response = await $api.delete(ROUTE_PATH.users.whiteWallets, {
        params: {
          wallet_id: params.wallet_id,
        },
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);
