import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { DiaryLayout, MainLayout } from 'src/layouts';
import { useAuth } from 'src/shared/libs/hooks/use-auth';

export const PrivateRouter = () => {
  const { isAuth, isWelcomePage } = useAuth();
  const { pathname } = useLocation();

  if (isAuth) {
    if (isWelcomePage) return <Navigate to="/welcome" />;

    const Layout = pathname.includes('diary') ? DiaryLayout : MainLayout;

    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
  return <Navigate to="auth/login" />;
};
