interface IBinanceHintContentProps {
  rebatePercent: string
}
const BinanceHintContent = ({ rebatePercent }: IBinanceHintContentProps) => (
  <div>
    На вашем аккаунте действует индивидуальное условие начисления бонусов:
    начисляется фиксированные {rebatePercent}% бонусов с торговых комиссий.

    <br />
    <br />

    Бонусы зачисляются на фьючерсный кошелёк в исходной валюте
    единоразово на следующий календарный день по итогам сделок
    торгового дня.
  </div>
);

export default BinanceHintContent;
