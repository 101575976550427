import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { fetchBalance } from './thunks';
import { IBalanceSlice, Balance, TotalBalance } from './types';

const initialState: IBalanceSlice = {
  balances: null,
  selectedBalance: null,
  status: EStatus.success,
};

export const balanceSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    updateBalance: (state, action: PayloadAction<Balance>) => {
      if (!state.balances) return;

      const updatedBalance = action.payload;
      const index = state.balances.sub_accounts.findIndex((balance) => balance.sub_account_id === updatedBalance.sub_account_id);

      if (index !== -1) {
        state.balances.sub_accounts[index] = {
          ...updatedBalance,
        };
      }
    },
    updateTotalBalance: (state, action: PayloadAction<TotalBalance>) => {
      if (!state.balances) return;

      state.balances.total_pnl_usdt = action.payload.total_pnl_usdt;
    },
    setBalance: (state, action: PayloadAction<Balance>) => {
      state.selectedBalance = action.payload;
    },
    clearBalancesSlice: (state) => {
      state.balances = null;
      state.selectedBalance = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalance.pending, (state) => {
        state.balances = null;
        state.status = EStatus.loading;
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.balances = action.payload;
        state.status = EStatus.success;
      })
      .addCase(fetchBalance.rejected, (state) => {
        state.balances = null;
        state.status = EStatus.rejected;
      });
  },
});

export const {
  updateBalance, updateTotalBalance, setBalance, clearBalancesSlice,
} = balanceSlice.actions;

export const balanceReducer = balanceSlice.reducer;
