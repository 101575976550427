import {
  useEffect, useState, useRef, useLayoutEffect,
  memo,
} from 'react';
import { LoadingOverlay, Tooltip } from '@mantine/core';
import { useSelector } from 'react-redux';
import className from 'classnames';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';

import { ReactComponent as ThreePointsIconSvg } from 'src/shared/assets/images/trading/three-points.svg';
import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as TipIconSvg } from 'src/shared/assets/images/tip-icon.svg';
import { ReactComponent as CloseIconSvg } from 'src/shared/assets/images/trading/close.svg';
import { ReactComponent as GridIconSvg } from 'src/shared/assets/images/trading/grid.svg';
import { ReactComponent as ListCionSvg } from 'src/shared/assets/images/trading/list.svg';
import { ReactComponent as CloseXIconSvg } from 'src/shared/assets/images/trading/closeX.svg';

import {
  getFromLocalStorage, multiplyValues, saveToLocalStorage, toFixedDecimal,
  toFixedDecimalPrecision,
} from 'src/shared/libs/helpers/helper.lib';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';

import { useAppDispatch } from 'src/app/store/store';
import { BalanceType } from 'src/entities/balance-type';
import { ETradingType, EStatus } from 'src/shared/types/global-types';
import { subscribeInstrument, unsubscribeInstrument } from 'src/app/store/slices/sub-accounts/thunks';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import useMobile from 'src/shared/libs/hooks/useMobile';
import {
  ColumnOrderItem, Order, OrderToClosed, SortFieldOrder,
  PositionCloseDetail,
} from '../../model/types';
import {
  selectOrders, selectStatusCloseOrders, selectStatusOrderCancel, selectStatusOrders,
} from '../../model/selectors';
import { fetchOrderCancel, fetchOrderCancelAll } from '../../model/thunks';
import { orderTableColumns, ordersColumn } from '../../constants';

import Empty from '../../../../entities/empty/empty';
import useSubAccount from '../../../../shared/libs/hooks/use-sub-account-name';
import styles from './orders.module.scss';

const Orders = memo(() => {
  const dispatch = useAppDispatch();
  const isMobile = useMobile();

  const { theme } = useTheme();
  const { setAlertMessage } = useAlert();
  const { getSubAccountNameById } = useSubAccount();

  const orders = useSelector(selectOrders);
  const statusOrders = useSelector(selectStatusOrders);
  const balanceVisible = useSelector(selectHiddenBalance);
  const statusCloseOrders = useSelector(selectStatusOrderCancel);
  const statusCloseAllOrders = useSelector(selectStatusCloseOrders);

  const [orderCardType, setOrderCardType] = useState<'card' | 'list'>(getFromLocalStorage('orderCardType', 'card'));
  const [tableColumns, setTableColumns] = useState(orderTableColumns);
  const [filterAccountType, setFilterAccountType] = useState<string | number>('');
  const [showOptionsPositions, setShowOptionsPositions] = useState(false);
  const [sortField, setSortField] = useState<SortFieldOrder>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(ordersColumn);
  const [prevOrdersLength, setPrevOrdersLength] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState<Order[]>([]);
  const [closeOrderButtonById, setCloseOrderButtonById] = useState<string[]>([]);

  const optionsRef = useRef<HTMLDivElement>(null);
  const optionsButtonRef = useRef<HTMLButtonElement>(null);

  const sortedData: Order[] = Array.isArray(orders) ? orders.slice().sort((a, b) => {
    if (sortField === 'price') {
      const priceA = parseFloat(a.price);
      const priceB = parseFloat(b.price);

      if (priceA < priceB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (priceA > priceB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'quantity') {
      const quantityA = parseFloat(a.quantity);
      const quantityB = parseFloat(b.quantity);

      if (quantityA < quantityB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (quantityA > quantityB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'quantity_dollars') {
      const quantityA = parseFloat(multiplyValues(a.price, a.quantity));
      const quantityB = parseFloat(multiplyValues(b.price, b.quantity));

      if (quantityA < quantityB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (quantityA > quantityB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'instrument_symbol') {
      const symbolA = a.instrument.symbol;
      const symbolB = b.instrument.symbol;

      if (symbolA < symbolB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (symbolA > symbolB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'exchange_name') {
      const exchangeNameA = a.exchange.name;
      const exchangeNameB = b.exchange.name;

      if (exchangeNameA < exchangeNameB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (exchangeNameA > exchangeNameB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'last_price') {
      const lastPriceA = parseFloat(a.instrument.price.ask);
      const lastPriceB = parseFloat(b.instrument.price.ask);

      if (lastPriceA < lastPriceB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (lastPriceA > lastPriceB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'type') {
      const typeA = a.type;
      const typeB = b.type;

      if (typeA < typeB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (typeA > typeB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }

    if (sortField) {
      if (a[sortField] < b[sortField]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
    }
    return 0;
  }) : [];

  const filteredData = sortedData.filter((data) => {
    if (filterAccountType === '') {
      return true;
    }
    return data.instrument.instrument_type === filterAccountType;
  });

  const handleSetOrderCardType = (type: 'list' | 'card') => {
    saveToLocalStorage('orderCardType', type);
    setOrderCardType(type);
  };

  const handleSort = (field: any) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleOption = (key: string) => {
    if (key === 'checkbox') return;

    setTableColumns((prevTableItems) => {
      const updatedTableColumns = {
        ...prevTableItems,
        [key as keyof typeof tableColumns]: {
          ...prevTableItems[key as keyof typeof tableColumns],
          show: !prevTableItems[key as keyof typeof tableColumns].show,
        },
      };

      saveToLocalStorage('ordersTableColumns', updatedTableColumns);
      return updatedTableColumns;
    });
  };

  const handleSelectOrder = (order: Order) => {
    if (selectedOrder.find((item) => item.id === order.id)) {
      setSelectedOrder((prev) => {
        prev = prev.filter((item) => item.id !== order.id);
        return prev;
      });
    } else {
      setSelectedOrder((prev) => {
        prev = [...prev, order];
        return prev;
      });
    }
  };

  const handleSelectAll = () => {
    if (selectedOrder.length < 1) {
      setSelectedOrder([...filteredData]);
    } else {
      setSelectedOrder([]);
    }
  };

  const closeOrderSelected = async () => {
    const formattedOrderToClose: OrderToClosed[] = selectedOrder.map((order) => ({
      order_id: order.id,
      sub_account_id: order.sub_account_id,
      trading_type: order.instrument.instrument_type,
      exchange_id: order.exchange.id,
    }));

    if (!formattedOrderToClose.length) return;

    const { payload } = await dispatch(fetchOrderCancel(formattedOrderToClose));

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('Заявки отменены', 'success');
      setSelectedOrder([]);
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const closeOrder = async (order: Order) => {
    const formattedOrderToClose: OrderToClosed = {
      order_id: order.id,
      sub_account_id: order.sub_account_id,
      trading_type: order.instrument.instrument_type,
      exchange_id: order.exchange.id,
    };

    setCloseOrderButtonById((prev) => prev.concat(order.id));

    const { payload } = await dispatch(fetchOrderCancel([formattedOrderToClose]));

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('Заявка отменена', 'success');
      setCloseOrderButtonById((prev) => prev.filter((item) => item !== order.id));
      setSelectedOrder((prev) => prev.filter((item) => item.id !== order.id));
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
      setCloseOrderButtonById((prev) => prev.filter((item) => item !== order.id));
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
      setCloseOrderButtonById((prev) => prev.filter((item) => item !== order.id));
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
      setCloseOrderButtonById((prev) => prev.filter((item) => item !== order.id));
    }
  };

  const closeOrderAll = async () => {
    if (!filteredData.length) return;

    const { payload } = await dispatch(fetchOrderCancelAll());

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('Заявки отменены', 'success');
      setSelectedOrder([]);
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrder = [...columnOrder];
    const [movedColumn] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedColumn);

    setColumnOrder(newOrder);

    saveToLocalStorage('ordersColumnOrder', newOrder);
  };

  useEffect(() => {
    if (orders && orders.length > 0) {
      const instrumentIds: number[] = orders.map((order) => order.instrument.id);
      const uniqueIds = [...new Set(instrumentIds)];

      if (instrumentIds.length > 0 && orders.length !== prevOrdersLength) {
        subscribeInstrument(uniqueIds);

        // Update the previous length of assets
        setPrevOrdersLength(orders.length);
      }
    }
  }, [orders]);

  useEffect(() => {
    const showOptionsHandler = (e: MouseEvent) => {
      e.stopPropagation();

      if (!optionsRef.current?.contains(e.target as Node) && !optionsButtonRef.current?.contains(e.target as Node)) {
        setShowOptionsPositions(false);
      }
    };

    window.addEventListener('click', showOptionsHandler);

    return () => {
      window.removeEventListener('click', showOptionsHandler);
    };
  }, [showOptionsPositions]);

  useLayoutEffect(() => {
    const ordersColumnLocalStorage = getFromLocalStorage('ordersColumnOrder', null);
    if (ordersColumnLocalStorage) {
      setColumnOrder(ordersColumnLocalStorage);
    }
  }, []);

  useLayoutEffect(() => {
    const ordersTableColumnsLocalStorage = getFromLocalStorage('ordersTableColumns', null);

    if (ordersTableColumnsLocalStorage) {
      setTableColumns(ordersTableColumnsLocalStorage);
    }
  }, []);

  useLayoutEffect(() => {
    if (isMobile) {
      setOrderCardType('card');
    }
  }, [isMobile]);

  useEffect(() => () => unsubscribeInstrument(), []);

  return (
    <section className={styles.orders}>
      <div className={styles.title}>
        <h2>Заявки</h2>
        <div className={styles.actionsOptions}>
          <button ref={optionsButtonRef} onClick={() => setShowOptionsPositions((prev) => !prev)}>
            <ThreePointsIconSvg />
          </button>
        </div>

        { showOptionsPositions && (
          <div ref={optionsRef} className={styles.options}>
            <p className={styles.optionsTitle}>Настройка позиций</p>
            <ul>
              {Object.entries(tableColumns).map(([key, value]) => (
                <li
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleOption(key)}
                  onClick={() => handleOption(key)}
                  key={key}
                >
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={value.show}
                    onChange={() => {}}
                  />
                  <span>{value.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.filters}>
            <button className={className({ [styles.activeFilter]: filterAccountType === '' })} onClick={() => setFilterAccountType('')}>Все</button>
            <button className={className({ [styles.activeFilter]: filterAccountType === ETradingType.futures })} onClick={() => setFilterAccountType(ETradingType.futures)}>Фьючерсы <span>F</span> </button>
            <button className={className({ [styles.activeFilter]: filterAccountType === ETradingType.spot })} onClick={() => setFilterAccountType(ETradingType.spot)}>Спот <span>S</span> </button>
          </div>
          <div className={styles.actions}>

            <div className={styles.windows}>
              <Tooltip withArrow label="Таблица" position="bottom" offset={5}>
                <button className={className({ [styles.active]: orderCardType === 'list' })} onClick={() => handleSetOrderCardType('list')}><ListCionSvg /></button>
              </Tooltip>

              <Tooltip withArrow label="Карточки" position="bottom" offset={5}>
                <button className={className({ [styles.active]: orderCardType === 'card' })} onClick={() => handleSetOrderCardType('card')}><GridIconSvg /></button>
              </Tooltip>
            </div>

            <div className={styles.tableActions}>
              {selectedOrder.length > 0 && <Button minWidth="175px" loading={statusCloseOrders === EStatus.loading} disabled={statusCloseOrders === EStatus.loading} onClick={closeOrderSelected} background="orange">Отменить выбранное ({selectedOrder.length})</Button>}
              {orders && orders.length > 0 && <Button minWidth="140px" loading={statusCloseAllOrders === EStatus.loading} disabled={statusCloseAllOrders === EStatus.loading} onClick={closeOrderAll} background="green">Отменить все</Button>}
            </div>

          </div>
        </div>

        {orderCardType === 'list' && (
          <div className={styles.bodyList}>
            <DragDropContext onDragEnd={onDragEnd}>
              <table className={styles.tableOrders}>
                <thead>
                  <Droppable droppableId="columns" direction="horizontal">
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          background: snapshot.isDraggingOver ? (theme === 'light' ? '#131313' : '#474747') : (theme === 'light' ? '#131313' : '#474747'),
                        }}
                      >
                        {columnOrder.map((column, index) => (
                          <Draggable key={column.key} draggableId={column.key} index={index}>
                            {(provided, snapshot) => (
                              <th
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  background: snapshot.isDragging ? 'transparent' : (theme === 'light' ? '#131313' : '#474747'),
                                  color: snapshot.isDragging ? 'var(--color-main)' : '#BCBCBC',
                                  display: snapshot.isDragging ? 'flex' : '',
                                  width: column.key === 'checkbox' ? '50px' : 'auto',
                                }}
                              >
                                {tableColumns.checkbox.show && column.key === 'checkbox' && (
                                  <input
                                    className={styles.checkbox}
                                    type="checkbox"
                                    checked={selectedOrder.length === filteredData.length && filteredData.length > 0}
                                    onChange={handleSelectAll}
                                  />
                                )}
                                {tableColumns.instrument.show && column.key === 'instrument_symbol' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {column.label}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.exchange.show && column.key === 'exchange_name' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {column.label}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.subAccountId.show && column.key === 'sub_account_id' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {column.label}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.quantity.show && column.key === 'quantity' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {column.label}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.quantity_dollars.show && column.key === 'quantity_dollars' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {column.label}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.price.show && column.key === 'price' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {column.label}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.lastPrice.show && column.key === 'last_price' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {column.label}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.type.show && column.key === 'type' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {column.label}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.action.show && column.key === 'action' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {column.label}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.close.show && column.key === 'close' && (
                                  <div className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}>
                                    {column.label}
                                    {/* <TipIconSvg /> */}
                                  </div>
                                )}
                              </th>
                            )}
                          </Draggable>
                        ))}
                        { provided.placeholder}
                      </tr>
                    )}
                  </Droppable>
                </thead>
                {filteredData && filteredData.length > 0 && (
                  <tbody>
                    {filteredData.map((order) => (
                      <tr key={order.id}>
                        {columnOrder.map((column) => (
                          <td key={column.key}>
                            {tableColumns.checkbox.show && column.key === 'checkbox' && tableColumns.checkbox && (
                              <input
                                className={styles.checkbox}
                                type="checkbox"
                                checked={Boolean(selectedOrder.find((item) => item.id === order.id))}
                                onChange={() => handleSelectOrder(order)}
                              />
                            )}
                            {tableColumns.instrument.show && column.key === 'instrument_symbol' && (
                              <div
                                className={className(styles.instrumentTd, { [styles.spot]: order.instrument.instrument_type === ETradingType.spot })}
                                role="button"
                                tabIndex={0}
                                onClick={() => handleSelectOrder(order)}
                                onKeyDown={() => handleSelectOrder(order)}
                              >
                                {order.instrument.symbol}
                                <BalanceType type={order.instrument.instrument_type} />
                              </div>
                            ) }
                            {tableColumns.exchange.show && column.key === 'exchange_name' && (
                              <div className={styles.exchangeTd}>
                                <img src={order.exchange.image} alt="биржа" />
                              </div>
                            ) }
                            {tableColumns.subAccountId.show && column.key === 'sub_account_id' && (
                              <div className={styles.accountTd}>{getSubAccountNameById(order.sub_account_id)}</div>
                            ) }
                            {tableColumns.quantity.show && column.key === 'quantity' && (
                              <div>{!balanceVisible ? toFixedDecimal(order.quantity) : HIDDEN_BALANCES}</div>
                            ) }
                            {tableColumns.quantity_dollars.show && column.key === 'quantity_dollars' && (
                              <div>{!balanceVisible ? multiplyValues(order.price, order.quantity, 2) : HIDDEN_BALANCES}</div>
                            ) }
                            {tableColumns.price.show && column.key === 'price' && (
                              <div className={styles.priceTd}>{toFixedDecimalPrecision(order.price)}</div>
                            ) }
                            {tableColumns.lastPrice.show && column.key === 'last_price' && (
                              <div className={styles.lastPriceTd}>{order.action === 'SELL' ? toFixedDecimalPrecision(order.instrument.price.bid) : toFixedDecimalPrecision(order.instrument.price.ask)}</div>
                            ) }
                            {tableColumns.type.show && column.key === 'type' && (
                              <div className={className(styles.typeTd, {
                                [styles.red]: order.type === 'SL',
                                [styles.green]: order.type === 'TP',
                              })}
                              >{order.type}
                              </div>
                            ) }
                            {tableColumns.action.show && column.key === 'action' && (
                              <div className={className(styles.actionTd, { [styles.sell]: order.action === 'SELL' })}>{order.action}</div>
                            ) }
                            {tableColumns.close.show && column.key === 'close' && (
                              <button disabled={closeOrderButtonById.includes(order.id)} onClick={() => closeOrder(order)} className={styles.canceledTd}>Отменить <CloseIconSvg /></button>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </DragDropContext>

            {statusOrders === EStatus.loading && (
              <div className={styles.emptyTable}>
                <LoadingOverlay
                  className="loader"
                  style={{ height: 'calc(100% - 100px)' }}
                  visible
                  zIndex={1000}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                  loaderProps={{ color: '#00C37C', type: 'dots' }}
                />
              </div>
            )}
            {statusOrders === EStatus.success && filteredData.length === 0 && (
              <div className={styles.emptyTable}>
                <Empty>
                  Здесь будут отображаться <br /> ваши заявки
                </Empty>

              </div>
            )}
            {statusOrders === EStatus.rejected && (
              <div className={styles.emptyTable}>
                <Empty horizontal error>
                  Произошла внутренняя ошибка <br /> при получении данных
                </Empty>

              </div>
            )}
          </div>
        )}

        {orderCardType === 'card' && (
          <div className={styles.bodyCard}>

            {filteredData && filteredData.length > 0 && (
              <div className={styles.positionCardContainer}>
                {filteredData.map((order) => (
                  <div className={className(styles.positionCardWrapper, { [styles.active]: Boolean(selectedOrder.find((item) => item.id === order.id)) })} key={order.id}>
                    <div
                      className={styles.positionCard}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSelectOrder(order)}
                      onKeyDown={() => handleSelectOrder(order)}
                    >
                      {(tableColumns.exchange.show || tableColumns.instrument.show || tableColumns.action.show) ? (
                        <div className={styles.box}>
                          {tableColumns.exchange.show && (
                            <div className={styles.exchange}> <img src={order.exchange.image} alt="биржа" /></div>
                          ) }
                          {tableColumns.instrument.show && (
                            <div className={styles.instrument}>
                              {order.instrument.symbol}
                              <BalanceType type={order.instrument.instrument_type} />
                            </div>
                          ) }
                          {tableColumns.action.show && (
                            <div className={className(styles.actionTd, { [styles.sell]: order.action === 'SELL' })}>{order.action}</div>
                          )}
                        </div>
                      ) : null}
                      {tableColumns.subAccountId.show ? (
                        <div className={className(styles.box, styles.margin)}>
                          <div className={styles.accountTd}>Счет: {getSubAccountNameById(order.sub_account_id)}</div>
                        </div>
                      ) : null }
                      {tableColumns.type.show ? (
                        <div className={styles.box}>
                          <div className={className(styles.typeTd, {
                            [styles.red]: order.type === 'SL',
                            [styles.green]: order.type === 'TP',
                          })}
                          >Тип заявки: {order.type}
                          </div>
                        </div>
                      ) : null }
                      {tableColumns.price.show || tableColumns.lastPrice.show ? (
                        <div className={styles.box}>
                          Цена:
                          {tableColumns.price.show && <div className={styles.priceTd}>{toFixedDecimalPrecision(order.price)}</div>}
                          { tableColumns.price.show && tableColumns.lastPrice.show && '|'}
                          {tableColumns.lastPrice.show && <div className={styles.lastPriceTd}>{order.action === 'SELL' ? toFixedDecimalPrecision(order.instrument.price.bid) : toFixedDecimalPrecision(order.instrument.price.ask)}</div>}
                        </div>
                      ) : null }
                      {tableColumns.quantity.show || tableColumns.quantity_dollars.show ? (
                        <div className={className(styles.box, styles.margin)}>
                          Объем:
                          { tableColumns.quantity.show && <div>{!balanceVisible ? toFixedDecimal(order.quantity) : HIDDEN_BALANCES}</div>}
                          { tableColumns.quantity.show && tableColumns.quantity_dollars.show && '|'}
                          { tableColumns.quantity_dollars.show && <div>{!balanceVisible ? `${multiplyValues(order.price, order.quantity, 2)}$` : HIDDEN_BALANCES}</div>}
                        </div>
                      ) : null }
                      {tableColumns.close.show ? (
                        <div className={styles.box}>
                          <Button
                            loading={closeOrderButtonById.includes(order.id)}
                            disabled={closeOrderButtonById.includes(order.id)}
                            background="black"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              closeOrder(order);
                            }}
                          >
                            <CloseXIconSvg /> Отменить
                          </Button>
                        </div>
                      ) : null }
                    </div>
                  </div>
                ))}
              </div>
            )}

            {statusOrders === EStatus.loading && (
              <div className={styles.emptyTable}>
                <LoadingOverlay
                  className="loader"
                  style={{ height: 'calc(100% - 100px)' }}
                  visible
                  zIndex={1000}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                  loaderProps={{ color: '#00C37C', type: 'dots' }}
                />
              </div>
            )}
            {statusOrders === EStatus.success && filteredData.length === 0 && (
              <div className={styles.emptyTable}>
                <Empty>
                  Здесь будут отображаться <br /> ваши заявки
                </Empty>

              </div>
            )}
            {statusOrders === EStatus.rejected && (
              <div className={styles.emptyTable}>
                <Empty horizontal error>
                  Произошла внутренняя ошибка <br /> при получении данных
                </Empty>

              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
});

export default Orders;
