import { store } from 'src/app/store/store';
import { PRICE_PRECISION_EXCHANGES } from 'src/shared/constants/constants';

export const pricePrecisionBack = (exchangeId: number) => {
  const exchange = store.getState().exchanges.exchanges?.find((exchange) => exchange.id === exchangeId);
  const exchangeName = exchange?.name.toLowerCase();

  if (exchangeName === 'bybit') {
    return PRICE_PRECISION_EXCHANGES.bybit;
  }
  return PRICE_PRECISION_EXCHANGES.binance;
};
