import qs from 'qs';
import {
  useState, useRef, FormEventHandler, useEffect, useCallback,
} from 'react';
import { useSelector, batch } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { LoadingOverlay, Modal, Tooltip } from '@mantine/core';

import { Wallet } from 'src/app/store/slices/wallets/types';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { clearWallets, removeWallet } from 'src/app/store/slices/wallets/slice';
import { selectWallets } from 'src/app/store/slices/wallets/selectors';
import { useAppDispatch } from 'src/app/store/store';
import { selectVerifications } from 'src/app/store/slices/verifications/selectors';
import { EVerificationType, Verification } from 'src/app/store/slices/verifications/types';
import {
  Asset, Network, SearchAssetType,
} from 'src/app/store/slices/assets/types';
import {
  removeAssets,
  clearAssetsSlice, removeWalletAddress, setCurrentAssetWallet, setCurrentNetworkAll,
} from 'src/app/store/slices/assets/slice';
import {
  Confirm,
  VerificationConfirm,
  VerificationsSendEmailCode,
  VerificationsSendPhoneCode, VerificationsSendWhatsAppCode, fetchVerifications, fetchVerificationsConfirm, fetchVerificationsSendEmailCode, fetchVerificationsSendPhoneCode, fetchVerificationsSendWhatsAppCode,
} from 'src/app/store/slices/verifications/thunks';
import { removeVerifications } from 'src/app/store/slices/verifications/slice';
import {
  selectAssetsSlice,
} from 'src/app/store/slices/assets/selectors';
import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as TrashIconSvg } from 'src/shared/assets/images/trash-table.svg';

import Hint from 'src/shared/ui/help/hint';
import Empty from 'src/entities/empty/empty';
import Button from 'src/shared/ui/button/button/button';
import Select from 'src/shared/ui/select/select';
import Toogler from 'src/shared/ui/toogler/toogler';
import useAlert from 'src/shared/libs/hooks/use-alert';
import GlassIcon from 'src/shared/assets/icons/glass-icon/glass-icon';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import InputText from 'src/shared/ui/input/input-text/input-text';
import InputCode from 'src/shared/ui/input/input-code/input-code';
import InputAction from 'src/pages/auth/components/inputs-auth/input-action';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import SelectCurrency from 'src/pages/transfers/components/select-currency/select-currency';

import {
  hideEmail, scrollToTop, handleSupportTelegram, debounce,
} from 'src/shared/libs/helpers/helper.lib';
import {
  AllNetworksParams,
  NewtrorksAssetsParams, fetchAllNetworks, fetchAllNetworksSearch, fetchNetworksAssets,
} from 'src/app/store/slices/assets/thunks';
import {
  CreateWalletParams, DeleteWallet, fetchCreateWallet, fetchDeleteWallet, fetchWallets,
} from 'src/app/store/slices/wallets/thunks';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import { BalanceType } from 'src/entities/balance-type';
import validateApiKey from '../../libs/helpers/validate-api-key';
import styles from './wallet-table.module.scss';

const WalletTable = () => {
  const dispatch = useAppDispatch();
  const [opened, { open, close }] = useDisclosure(false);
  const [openedVerify, { open: openVerify, close: closeVerify }] = useDisclosure(false);

  const { setAlertMessage } = useAlert();

  const { user, settings } = useSelector(selectUser);
  const { wallets, status, statusCreate } = useSelector(selectWallets);
  const { verification, statusConfirm } = useSelector(selectVerifications);
  const {
    currentAsset, currentNetworkAll, networksAll, networksAssets,
  } = useSelector(selectAssetsSlice);

  const verifyFormRef = useRef<HTMLFormElement>(null);

  const [verifyInputError, setVerifyInputError] = useState({
    emailError: false,
    phoneError: false,
    gaError: false,
  });

  const [editInput, setEditInput] = useState<'addWallet' | 'removeWallet' | ''>('');
  const [validateApiKeyError, setValidateApiKeyError] = useState(false);
  const [verifyWalletStatus, setVerifyWalletStatus] = useState(false);
  // verify modal from states
  const [codeEmail, setCodeEmail] = useState('');
  const [codePhone, setCodePhone] = useState('');
  const [codeGA, setCodeGA] = useState('');

  // modal form states
  const [walletName, setWalletName] = useState('');
  const [walletAddressValue, setWalletAddressValue] = useState('');
  const [walletMemo, setWalletMemo] = useState('');
  const [walletType, setWalletType] = useState(false);
  const [currentWalletId, setCurrentWalletId] = useState(0);

  const [walletAssetsList, setWalletAssetsList] = useState<string[]>(['Все валюты', 'Универсальные']);
  const [walletNetworksList, setWalletNetworksList] = useState<string[]>(['Все сети']);

  const [filterNetwork, setFilterNetwork] = useState('');
  const [filterAsset, setFilterAsset] = useState<Nullable<string>>('');
  const [walletAddressError, setWalletAddressError] = useState(false);
  const [walletError, setWalletError] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchAssetSymbol, setSearchAssetSymbol] = useState('');
  const [searchNetworks, setSearchNetworks] = useState('');

  const [sortField, setSortField] = useState<Nullable<keyof Wallet>>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const [networksPage, setNetworksPage] = useState(1);

  useIntersectionObserver(networksPage, networksAll?.meta.total_pages || 1, networksAll?.items, 'observer-network', setNetworksPage, 9);

  const sortedData: Wallet[] = wallets.slice().sort((a, b) => {
    if (sortField === 'network') {
      const networkA = a.network.name;
      const networkB = b.network.name;

      if (networkA < networkB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (networkA > networkB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }

    if (sortField === 'asset') {
      const assetA = a.asset?.symbol;
      const assetB = b.asset?.symbol;

      if (assetA === undefined || assetB === undefined) {
        if (assetA === undefined && assetB === undefined) return 0;
        return assetA === undefined ? 1 : -1;
      }

      if (assetA < assetB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (assetA > assetB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }

    if (sortField) {
      const aValue = a[sortField];
      const bValue = b[sortField];

      if (aValue !== null && bValue !== null) {
        if (aValue !== undefined && bValue !== undefined) {
          if (aValue < bValue) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortOrder === 'asc' ? 1 : -1;
          }
        }
      }
    }
    return 0;
  });

  const filteredData = sortedData.filter((wallet) => (
    wallet.user_name.toLowerCase().includes(searchValue.toLowerCase())
    || wallet.network.name.toLowerCase().includes(searchValue.toLowerCase())
    || wallet.asset?.name.toLowerCase().includes(searchValue.toLowerCase())
    || wallet.asset?.symbol.toLowerCase().includes(searchValue.toLowerCase())
  ))
    .filter((item) => {
    // Проверка совпадения по asset:
    // Если filterAsset === null, возвращаем только те элементы, у которых asset === null.
    // Если filterAsset имеет значение, проверяем совпадение символа (item.asset.symbol) с filterAsset.
      const assetMatches = filterAsset === null
        ? item.asset === null
        : !filterAsset || item?.asset?.symbol === filterAsset;

      // Проверка совпадения по network:
      // Если filterNetwork имеет значение, проверяем совпадение имени сети (item.network.name) с filterNetwork.
      // Если filterNetwork не задан, возвращаем все элементы.
      const networkMatches = !filterNetwork || item.network?.name === filterNetwork;

      // Возвращаем элемент только если он соответствует обоим условиям (по asset и по network).
      return assetMatches && networkMatches;
    });

  const copyWalletAddress = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      setAlertMessage('Скопировано', 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const handleSort = (field: keyof Wallet) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleSetFilterAsset = (value: string) => {
    if (value === 'Все валюты') {
      setFilterAsset('');
    } else if (value === 'Универсальные') {
      setFilterAsset(null);
    } else {
      setFilterAsset(value);
    }
  };

  const clearVerifyInputs = () => {
    setCodeGA('');
    setCodeEmail('');
    setCodePhone('');

    setVerifyInputError({
      emailError: false,
      phoneError: false,
      gaError: false,
    });
  };

  const clearCreateWalletForm = () => {
    setWalletName('');
    setWalletAddressValue('');
    setWalletMemo('');
    setWalletType(false);
  };

  const handleCloseVerify = () => {
    if (statusConfirm === EStatus.loading) return;

    dispatch(removeVerifications());
    clearVerifyInputs();
    closeVerify();
  };

  const handleCloseModal = () => {
    setWalletName('');
    setWalletAddressValue('');

    setWalletError(false);
    setWalletAddressError(false);
    setValidateApiKeyError(false);
    dispatch(clearAssetsSlice());
    setNetworksPage(1);
    close();
  };

  const handleSetCurrentNetwork = (network: Network) => {
    setSearchAssetSymbol('');
    dispatch(removeAssets());
    dispatch(setCurrentNetworkAll(network));
  };

  const debouncedFetch = useCallback(
    debounce((queryParams: string) => {
      dispatch(fetchAllNetworksSearch(queryParams.toUpperCase()));
    }, 500) as any,
    [dispatch],
  );

  const handleSetCurrentAsset = useCallback((asset: Asset | SearchAssetType) => {
    batch(() => {
      dispatch(removeWalletAddress());
      dispatch(setCurrentAssetWallet(asset));
    });
  }, [dispatch]);

  const handleCreateWalletOpenModal = () => {
    open();
  };

  const handleFetchVerification = async (verificationType: EVerificationType) => {
    let result: string | boolean = false;

    const { payload } = await dispatch(fetchVerifications(verificationType));
    const responseVerification = payload as Verification;

    if (payload === 'TOO_MANY_REQUESTS') {
      result = payload;
      return result;
    }

    if (responseVerification && user) {
      const sendPhoneCodeParams: VerificationsSendPhoneCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: responseVerification.verification_id,
      };

      if (responseVerification.methods.includes('phone')) {
        const { payload } = await dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));

        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('whatsapp')) {
        const { payload } = await dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));

        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('email')) {
        dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('ga')) {
        if (payload) {
          result = true;
        }
      }
    } else {
      setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
    }

    return result;
  };

  const handleDeleteWallet = async (walletId: number) => {
    setCurrentWalletId(walletId);

    if (settings) {
      setEditInput('removeWallet');

      const result = await handleFetchVerification(EVerificationType.DELETE_WHITE_WALLET);

      if (result === true) {
        openVerify();
      } else if (result === 'TOO_MANY_REQUESTS') {
        setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
      } else {
        setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
      }
    }
  };

  const deleteWallet = async () => {
    if (!verification) return;

    const deleteWallet: DeleteWallet = {
      wallet_id: currentWalletId,
      verification_id: verification.verification_id,
    };

    const { payload } = await dispatch(fetchDeleteWallet(deleteWallet));

    if (payload === true) {
      dispatch(removeWallet(currentWalletId));
      setAlertMessage('Кошелек удален', 'success');
      clearVerifyInputs();
    } else {
      setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'error');
    }
  };

  const handleCreateWalletVerification = async () => {
    if (settings) {
      close();
      setVerifyWalletStatus(true);
      setEditInput('addWallet');

      const result = await handleFetchVerification(EVerificationType.CREATE_WHITE_WALLET);

      if (result === true) {
        openVerify();
        setVerifyWalletStatus(false);
      } else if (result === 'TOO_MANY_REQUESTS') {
        setVerifyWalletStatus(false);
        setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
      } else {
        setAlertMessage('Произошла внутренняя ошибка', 'warning');
        setVerifyWalletStatus(false);
      }
    }
  };

  const createWallet = async () => {
    if (!currentAsset || !currentNetworkAll || !verification) return;

    const createWallet: CreateWalletParams = {
      wallet: {
        user_name: walletName,
        network_id: currentNetworkAll.id,
        address: walletAddressValue,
        memo: walletMemo,
      },
      verification_id: verification.verification_id,
    };

    if (!walletType) {
      createWallet.wallet.asset_id = currentAsset.id;
    }

    const { payload } = await dispatch(fetchCreateWallet(createWallet));

    if (typeof payload === 'object') {
      clearCreateWalletForm();
      setAlertMessage('Кошелек успешно создан', 'success');
    } else if (payload === 'WALLET_ALREADY_EXISTS') {
      setAlertMessage('Кошелек уже существует', 'error');
      clearCreateWalletForm();
      clearVerifyInputs();
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
      closeVerify();
      clearVerifyInputs();
      clearCreateWalletForm();
    }
  };

  const onSubmitCreateWallet = async (event: any) => {
    event.preventDefault();

    const apiKeyError = validateApiKey(walletAddressValue, 'USDT');

    if (!currentNetworkAll || !currentAsset) return;

    if (!apiKeyError) {
      setValidateApiKeyError(true);
      return;
    }

    if (!walletName) {
      setWalletError(true);
      return;
    }

    setWalletError(false);

    if (!walletAddressValue) {
      setWalletAddressError(true);
      return;
    }

    setWalletAddressError(false);

    handleCreateWalletVerification();
  };

  const handleSubmitVerification: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (responseConfirm.payload === true) {
          closeVerify();
          clearVerifyInputs();

          if (editInput === 'addWallet') createWallet();
          if (editInput === 'removeWallet') deleteWallet();
        } else if (responseConfirm.payload === 'VERIFICATION_NOT_FOUND') {
          setAlertMessage('Время действия запроса истекло. Пожалуйста, повторите попытку.', 'error');
          closeVerify();
          clearVerifyInputs();
        } else if (verification) {
          if (verification.methods.includes('email')) {
            setVerifyInputError((prev) => (
              { ...prev, emailError: true }
            ));
          }
          if (verification.methods.includes('phone')) {
            setVerifyInputError((prev) => (
              { ...prev, phoneError: true }
            ));
          }
          if (verification.methods.includes('whatsapp')) {
            setVerifyInputError((prev) => (
              { ...prev, phoneError: true }
            ));
          }
          if (verification.methods.includes('ga')) {
            setVerifyInputError((prev) => (
              { ...prev, gaError: true }
            ));
          }
        }
      }
    }
  };

  const handleFetchCodeSms = () => {
    if (user && verification && settings) {
      if (settings.type_sms_code === 'phone') {
        const sendPhoneCodeParams: VerificationsSendPhoneCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };
        dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));
      } else {
        const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };
        dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));
      }
    }
  };

  const handleFetchCodeEmail = () => {
    if (user && verification) {
      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: verification.verification_id,
      };

      dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
    }
  };

  const handleAutoSubmit = async () => {
    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (!responseConfirm.payload === true) {
          if (verification) {
            if (verification.methods.includes('email')) {
              setVerifyInputError((prev) => (
                { ...prev, emailError: true }
              ));
            }
            if (verification.methods.includes('phone')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('whatsapp')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('ga')) {
              setVerifyInputError((prev) => (
                { ...prev, gaError: true }
              ));
            }
          }
        } else if (responseConfirm.payload === 'VERIFICATION_NOT_FOUND') {
          setAlertMessage('Время действия запроса истекло. Пожалуйста, повторите попытку.', 'error');
          closeVerify();
          clearVerifyInputs();
        } else {
          closeVerify();
          clearVerifyInputs();

          if (editInput === 'addWallet') createWallet();
          if (editInput === 'removeWallet') deleteWallet();
        }
      }
    }
  };

  const renderVerificationForm = (methods: string[]) => {
    const formElements: React.ReactNode[] = [];

    if (methods.includes('phone')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label="Код из СМС"
            placeholder="Код"
            actionTitle="Отправить код"
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('whatsapp')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label="Код из WhatsApp"
            placeholder="Код"
            actionTitle="Отправить код"
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('email')) {
      formElements.push(
        <div key="email-input" className="verification-input">
          <InputAction
            label="Код из Письма"
            placeholder="Код"
            type="number"
            actionTitle="Отправить код"
            onClick={handleFetchCodeEmail}
            actionActive
            error={verifyInputError.emailError}
            {...{
              value: codeEmail,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodeEmail(e.target.value),
            }}
          />
          <span className="verification-input-text">Код подтверждения будет отправлен на {hideEmail(user?.email)}</span>
        </div>,
      );
    }

    if (methods.includes('ga')) {
      formElements.push(
        <div key="ga-input" className="verification-input">
          <InputForm title="Код Google Authenticator">
            <InputCode onChange={setCodeGA} error={verifyInputError.gaError} />
          </InputForm>
        </div>,
      );
    }

    formElements.push(
      <div key="modal-hint" className="verification-input">
        <Hint
          hintOpen
          position="bottom-start"
          text="Возникли проблемы с верификацией?"
          hintText={(
            <div>
              Код с почты не подходит:
              <br />
              - Возможно, срок действия кода истек, запросите новый код по кнопке - Отправить код
              <br />
              - Убедитесь в корректности почты
              <br />
              <br />
              Код Google Autenticator не подходит:
              <br />
              - Синхронизируйте время между устройствами. Если вы используете GA на Андроид, воспользуйтесь функцией - Синхронизация времени
              <br />
              - Убедитесь что вы вводите код для аккаунта league.broker
              <br />
              <br />
              Если какой то из кодов по прежнему не подходит, обратитесь в тех. поддержку <button onClick={handleSupportTelegram}>Телеграмм</button>
            </div>
          )}
        />
      </div>,
    );

    formElements.push(
      <div key="verify-modal-action" className="verify-modal-action">
        <Button
          disabled={statusConfirm === EStatus.loading}
          loading={statusConfirm === EStatus.loading}
          type="submit"
          onClick={() => {}}
          background="green"
        >Подтвердить
        </Button>
        <Button disabled={statusConfirm === EStatus.loading} onClick={handleCloseVerify} background="gray">Отмена</Button>
      </div>,
    );

    return <form ref={verifyFormRef} className="verification-form-wrapper" onSubmit={handleSubmitVerification}>{formElements}</form>;
  };

  useEffect(() => {
    if (openedVerify) {
      setTimeout(() => {
        if (verifyFormRef.current) {
          const inputElement = verifyFormRef.current[0] as HTMLInputElement;
          inputElement.focus();
        }
      }, 100);
    }
  }, [verifyFormRef.current, openedVerify]);

  useEffect(() => {
    if (wallets.length) {
      const assetsList = wallets.map((wallet) => wallet.asset?.symbol).filter((asset) => asset !== undefined) as string[];
      const updateAssetsList = walletAssetsList.concat(assetsList);
      const uniqeAssets = [...new Set(updateAssetsList)];

      const networkList = wallets.map((wallet) => wallet.network.name);
      const updateNetworksList = walletNetworksList.concat(networkList);
      const uniqeNetworks = [...new Set(updateNetworksList)];

      setWalletAssetsList(uniqeAssets);
      setWalletNetworksList(uniqeNetworks);
    }
  }, [wallets]);

  useEffect(() => {
    if (!currentNetworkAll) return;

    const params: NewtrorksAssetsParams = {
      network_id: currentNetworkAll.id,
    };

    dispatch(fetchNetworksAssets(params));
  }, [currentNetworkAll]);

  useEffect(() => {
    if (!opened) return;

    const params: AllNetworksParams = {
      page: networksPage,
      limit: 20,
    };
    dispatch(fetchAllNetworks(params));
  }, [networksPage, opened]);

  useEffect(() => {
    if (searchNetworks.length) {
      debouncedFetch.execute(searchNetworks.toUpperCase());
    } else {
      debouncedFetch.stop();

      const params: AllNetworksParams = {
        page: 1,
        limit: 20,
      };

      dispatch(fetchAllNetworks(params));
    }
  }, [searchNetworks, debouncedFetch]);

  useEffect(() => {
    dispatch(fetchWallets({}));

    return () => {
      dispatch(clearAssetsSlice());
      dispatch(clearWallets());
    };
  }, []);

  useEffect(() => {
    if (networksAssets && !currentAsset) {
      dispatch(setCurrentAssetWallet(networksAssets[0].asset));
    }
  }, [networksAssets, currentAsset]);

  useEffect(() => {
    const parsedParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const queryAddWallet = parsedParams?.addWallet;

    if (queryAddWallet) {
      scrollToTop(document.body.scrollHeight);
    }
  }, []);

  useEffect(() => {
    if (verification) {
      const allPhone = verification.methods.includes('ga') && verification.methods.includes('email') && verification.methods.includes('phone');
      const allWhatsapp = verification.methods.includes('ga') && verification.methods.includes('email') && verification.methods.includes('whatsapp');
      const onlyGa = verification.methods.every((method) => method === 'ga') && verification.methods.includes('ga');
      const hasGaAndEmail = verification.methods.includes('ga') && verification.methods.includes('email');
      const hasGaAndPhone = verification.methods.includes('ga') && verification.methods.includes('phone');
      const hasGaAndWhatsapp = verification.methods.includes('ga') && verification.methods.includes('whatsapp');
      const hasEmailAndWhatsapp = verification.methods.includes('email') && verification.methods.includes('whatsapp');
      const hasEmailAndPhone = verification.methods.includes('email') && verification.methods.includes('phone');

      if (allWhatsapp) {
        if (codePhone.length === 4 && codeGA.length === 6 && codeEmail.length === 5) {
          handleAutoSubmit();
          return;
        }
      }

      if (allPhone) {
        if (codePhone.length === 5 && codeGA.length === 6 && codeEmail.length === 5) {
          handleAutoSubmit();
          return;
        }
      }

      if (onlyGa) {
        if (codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndEmail) {
        if (codeEmail.length === 5 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndPhone) {
        if (codePhone.length === 5 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndWhatsapp) {
        if (codePhone.length === 4 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasEmailAndWhatsapp) {
        if (codeEmail.length === 5 && codePhone.length === 4) {
          handleAutoSubmit();
        }
      }

      if (hasEmailAndPhone) {
        if (codeEmail.length === 5 && codePhone.length === 5) {
          handleAutoSubmit();
        }
      }
    }
  }, [codeGA, codeEmail, codePhone, verification]);

  return (
    <div>
      <div className={styles.filterBox}>
        <div className={styles.selectContainer}>

          <div className={styles.selectGroup}>
            <InputForm title="Валюта">
              <div className={styles.selectItem}>
                <Select isActiveSearch={false}>
                  { walletAssetsList.map((value) => (
                    <span
                      key={value}
                      className={styles.filterItem}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSetFilterAsset(value)}
                      onKeyDown={() => {}}
                    >
                      {value || 'Все валюты'}
                    </span>
                  )) }
                </Select>
              </div>
            </InputForm>
          </div>

          <div className={styles.selectGroup}>
            <InputForm title="Сеть">
              <div className={styles.selectItem}>
                <Select isActiveSearch={false}>
                  { walletNetworksList.map((value) => (
                    <span
                      className={styles.filterItem}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setFilterNetwork(value === 'Все сети' ? '' : value)}
                      onClick={() => setFilterNetwork(value === 'Все сети' ? '' : value)}
                      key={value}
                    >
                      {value || 'Все сети'}
                    </span>
                  )) }
                </Select>
              </div>
            </InputForm>
          </div>

        </div>
        <div className={styles.searchInput}>
          <span>Поиск</span>
          <InputText
            value={searchValue}
            placeholder="Искать название и адрес"
            onChange={setSearchValue}
          ><GlassIcon />
          </InputText>
        </div>
      </div>

      <table className={styles.walletTable}>
        <thead>
          <tr>
            <th onClick={() => handleSort('user_name')}><div className={styles.thWrapper}>Название <SortIconSvg /></div></th>
            <th onClick={() => handleSort('network')}><div className={styles.thWrapper}>Сеть <SortIconSvg /></div></th>
            <th onClick={() => handleSort('asset')}><div className={styles.thWrapper}>Валюта <SortIconSvg /></div></th>
            <th>Адрес кошелька</th>
            <th>МЕМО</th>
            <th>Действие</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((wallet) => (
            <tr key={wallet.wallet_id}>
              <td>{wallet.user_name}</td>
              <td>{wallet.network.name}</td>
              <td>
                <div>
                  {wallet.asset && <img src={wallet.asset?.image} alt="asset" />}
                  {wallet?.asset?.name || <BalanceType type={3} />}
                </div>
              </td>
              <Tooltip withArrow onClick={() => copyWalletAddress(wallet.address)} position="bottom" offset={5} color="#00AB6D" label={wallet.address}>
                <td className={styles.walletAddress}>
                  {wallet.address}
                </td>
              </Tooltip>
              <td>{wallet.memo || '-'}</td>
              <td className={styles.actions}>
                <button onClick={() => handleDeleteWallet(wallet.wallet_id)}>
                  <TrashIconSvg />
                </button>
              </td>
            </tr>
          ))}
        </tbody>

      </table>

      <div style={{ display: filteredData.length ? 'none' : 'flex' }} className={styles.walletTableInfo}>
        {status === EStatus.success && filteredData.length === 0 && (<Empty>Список пуст</Empty>)}
        {status === EStatus.loading && (
          <LoadingOverlay
            className="loader"
            style={{ height: 'calc(100% - 100px)' }}
            visible
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: '#00C37C', type: 'dots' }}
          />
        )}
        {status === EStatus.rejected && (<span>Произошла внутренняя ошибка при получении данных</span>)}
      </div>

      <div className={styles.walletsAction}>
        {wallets.length === 0 && <p>Вы еще не добавили ваш кошелек в белый список...</p>}
        <Button loading={verifyWalletStatus} disabled={verifyWalletStatus} maxWidth="200px" onClick={handleCreateWalletOpenModal} background="green">Добавить кошелек</Button>
      </div>

      <Modal
        title="Подтвердите действие"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        style={{ overflow: 'unset' }}
        radius={16}
        size="sm"
        opened={openedVerify}
        onClose={handleCloseVerify}
        className="modal-custom-overflow"
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <div>
          {verification && renderVerificationForm(verification.methods)}
        </div>
      </Modal>

      <Modal
        title="Добавить кошелек"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="md"
        opened={opened}
        onClose={handleCloseModal}
        className="modal-custom-overflow"
      >
        <form className="form-add-wallet" onSubmit={onSubmitCreateWallet}>

          <div className="input-group">
            <span>Название кошелька</span>
            <InputText
              value={walletName}
              placeholder="USDT Основной"
              onChange={setWalletName}
            />
          </div>
          {walletError && <Hint error text="Обязательное поле" />}

          <div className="input-group">
            <span>Адрес кошелька</span>
            <InputText
              value={walletAddressValue}
              placeholder="Введите адрес"
              onChange={setWalletAddressValue}
            />
          </div>
          {walletAddressError && <Hint error text="Обязательное поле" />}
          {validateApiKeyError && <Hint error text="Некорректный Api ключ" />}

          <div className="input-group">
            <InputForm title="МЕМО">
              <InputText
                value={walletMemo}
                placeholder="Опционально"
                onChange={setWalletMemo}
              />
            </InputForm>
          </div>

          <InputForm title="Сеть">
            {networksAll && currentNetworkAll ? (
              <Select
                isActiveSearch
                searchValue={searchNetworks}
                isSearch={setSearchNetworks}
                isSelectValue={(
                  <div className="network">
                    <span className="short-name">{currentNetworkAll?.symbol || ''}</span>
                    <span className="full-name">{currentNetworkAll?.name || ''}</span>
                  </div>
                )}
              >
                {
                  networksAll.items?.map((network) => (
                    <div
                      key={network.id}
                      className="observer-network network"
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => handleSetCurrentNetwork(network)}
                      onClick={() => handleSetCurrentNetwork(network)}
                    >
                      <span className="short-name">{network.symbol}</span>
                      <span className="full-name">{network.name}</span>
                    </div>
                  ))
                }

              </Select>
            ) : <SelectSkeleton /> }
          </InputForm>

          <Toogler
            isActive={walletType}
            onChange={setWalletType}
            labelContent="Универсальные, без привязки к валюте"
          />

          {!walletType && (
            <InputForm title="Валюта">
              { networksAssets ? (
                <Select
                  isActiveSearch
                  searchValue={searchAssetSymbol}
                  isSearch={setSearchAssetSymbol}
                >
                  {networksAssets.filter((assetData) => {
                    const searchQuery = searchAssetSymbol.toLowerCase();
                    return assetData.asset.symbol.toLowerCase().includes(searchQuery) || assetData.asset.name.toLowerCase().includes(searchQuery);
                  }).map((assetData) => (
                    <SelectCurrency className="instrument-observer" key={assetData.asset.id} asset={assetData.asset} handleSetAsset={handleSetCurrentAsset} />
                  ))}

                </Select>
              ) : <SelectSkeleton /> }
            </InputForm>
          )}

          <div className="actions-group">
            <Button loading={statusCreate === EStatus.loading} disabled={statusCreate === EStatus.loading} type="submit" background="green">Создать</Button>
            <Button disabled={statusCreate === EStatus.loading} onClick={handleCloseModal} background="black">Отмена</Button>
          </div>

        </form>
      </Modal>
    </div>
  );
};

export default WalletTable;
