import { useDispatch } from 'react-redux';
import {
  useState, useEffect, useRef, forwardRef, Ref, BaseSyntheticEvent,
} from 'react';

// todo: move to server
import countries from 'src/data/phones.json';
import { join, hasNumber } from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as ArrowSvg } from 'src/shared/assets/images/arrow.svg';
import { getValidationClass } from './helpers/get-validation-class';
import { setRegionCode } from '../../registration/model/slice';

import './input.scss';

interface IInputPhoneProps {
  error?: boolean;
  label: string
  placeholder: string;
  isValid?: boolean;
  onChange: (...event: any[]) => void
  value: string
  isDisabled?: boolean
}

const InputPhone = forwardRef(({
  error,
  label,
  placeholder,
  isValid,
  onChange,
  value,
  isDisabled,
  ...fieldProps
}: IInputPhoneProps, ref: Ref<HTMLInputElement>) => {
  const dispatch = useDispatch();

  const [searchFormValue, setSearchFormValue] = useState('');
  const [shownCountries, setShownCountries] = useState(countries);
  const [isActiveCountrySelection, setIsActiveCountrySelection] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countries.find((c) => c.id === 176));

  const inputContainerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const regionCode = selectedCountry && Number(selectedCountry.code.replace('+', ''));

    if (regionCode) {
      dispatch(setRegionCode(regionCode));
    }

    const checkClickOutsideHandler = (e: MouseEvent) => {
      e.stopPropagation();
      if (!inputContainerRef.current?.contains(e.target as Node)
        && isActiveCountrySelection) {
        setIsActiveCountrySelection(false);
      }
    };

    window.addEventListener('click', checkClickOutsideHandler);

    return () => {
      window.removeEventListener('click', checkClickOutsideHandler);
    };
  }, [isActiveCountrySelection]);

  const setSelectedCountryLocal = (country: typeof countries[0]) => {
    const regionCode = Number(country.code.replace('+', ''));
    dispatch(setRegionCode(regionCode));
    setIsActiveCountrySelection(false);
    setSelectedCountry(country);
  };

  const setSearchFormValueLocal = (value: string) => {
    setSearchFormValue(value);

    if (!value) {
      return setShownCountries(countries);
    }

    if (hasNumber(value)) {
      return setShownCountries(countries.filter((c) => c.code.includes(value)));
    }

    const loweredValue = value.toLowerCase();
    return setShownCountries(countries.filter(
      (c) => c.country.toLowerCase().includes(loweredValue),
    ));
  };

  const handleOnChange = (...events: BaseSyntheticEvent[]) => {
    events.forEach((event) => {
      onChange(event);
      localStorage.setItem('tell', event.target.value);
    });
  };

  return (
    <div
      ref={inputContainerRef}
      className={join('auth-input-container', 'auth-input-container_phone', getValidationClass(isValid), isDisabled && 'isDisabled')}
    >
      <label>{label}</label>
      <div className="input-container">

        <div
          className={join('country-list-container', isActiveCountrySelection && 'country-list-container_active', error && 'error')}
          role="button"
          tabIndex={0}
          onKeyDown={() => setIsActiveCountrySelection(!isActiveCountrySelection)}
          onClick={() => setIsActiveCountrySelection(!isActiveCountrySelection)}
        >
          <div className="country-flag" style={{ backgroundPosition: selectedCountry?.position }} />
          <span className="phone-format">{selectedCountry?.code}</span>
          <ArrowSvg />
        </div>

        <input
          ref={ref}
          className={`auth-input ${error && 'error'}`}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          {...fieldProps}
        />
        {/* <button type="button" onClick={onClick} className="input-action">{actionTitle}</button> */}

      </div>

      { isActiveCountrySelection
        && (
          <div className="select-container">
            <div className="search-form">
              <input
                type="text"
                placeholder="Страна или код"
                value={searchFormValue}
                onChange={(e) => setSearchFormValueLocal(e.target.value)}
              />
            </div>

            <div className="list-country">
              { shownCountries.map((country) => (
                <div
                  key={country.id}
                  className="select-country"
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => setSelectedCountryLocal(country)}
                  onClick={() => setSelectedCountryLocal(country)}
                >
                  <div
                    className="country-flag"
                    style={{ backgroundPosition: country.position }}
                  />
                  <p>{country.country}</p>
                </div>
              )) }
            </div>
          </div>
        )}
    </div>
  );
});

export default InputPhone;
