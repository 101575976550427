import { WithdrawalStatus } from 'src/widgets/transfer-history/model/types';

const statusTranslations: Record<WithdrawalStatus, string> = {
  PROCESSED: 'Исполнен',
  TRANSFERRED: 'Создан',
  CREATED: 'Создан',
  REJECTED: 'Отклонен',
};

export const translateStatus = (status: WithdrawalStatus): string => {
  const unknownStatus = 'Неизвестно';

  if (typeof status !== 'string') return unknownStatus;

  return statusTranslations[status] || unknownStatus;
};
