import { $api } from 'src/shared/api/axios.instance';
import { AxiosError, AxiosResponse } from 'axios';

export type TelegramLogData = {
    user: string,
    url: string,
    message: string,
    status: number,
    data: any,
}

class TelegramoLogService {
  static async log(errorData: TelegramLogData) {
    try {
      const response: AxiosResponse<boolean> = await $api.post('/api/telegram/logs', { message: JSON.stringify(errorData) });
      const { data } = response;

      if (data === undefined) throw response;
      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) throw error.response.data.detail;
      throw error;
    }
  }
}

export default TelegramoLogService;
