import moment from 'moment';
import className from 'classnames';
import {
  memo, useEffect, useRef, useState, useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, LoadingOverlay } from '@mantine/core';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { useAppDispatch } from 'src/app/store/store';

import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as ExcelBtnIconSvg } from 'src/shared/assets/images/excel-btn.svg';
import { ReactComponent as ThreePointsIconSvg } from 'src/shared/assets/images/trading/three-points.svg';

import Empty from 'src/entities/empty/empty';
import Button from 'src/shared/ui/button/button/button';
import Select from 'src/shared/ui/select/select';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';

import { EStatus, Nullable } from 'src/shared/types/global-types';
import { BalanceType } from 'src/entities/balance-type';
import { ColumnOrderItem } from 'src/pages/trading/model/types';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import {
  getDate,
  getFromLocalStorage, multiplyValues, saveToLocalStorage, toFixedDecimal,
  toFixedDecimalPrecision,
} from 'src/shared/libs/helpers/helper.lib';

import { subscribeInstrument, unsubscribeInstrument } from 'src/app/store/slices/sub-accounts/thunks';
import { calculatePnl, calculatePnlInPercents, sortPositionsSummury } from 'src/pages/account/libs/helpers';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import { transactionHistoryColumn, transactionHistoryColumns } from './constants';
import { selectAccount } from '../../../../model/selectors';
import { clearPositionsSummury } from '../../../../model/slice';
import { SortFieldPositionsSummary, PositionsSummary } from '../../../../model/types';
import {
  fetchPositionsSummury, PositionsSummuryParams, subscribePositionsSummury, unsubscribePositionsSummury,
} from '../../../../model/thunks';
import styles from './positions-summury-table.module.scss';

  interface ITransactionHistoryProps {
    subAccountId?: Nullable<number>
  }

const PositionsSummuryTable = memo(({ subAccountId }: ITransactionHistoryProps) => {
  const dispatch = useAppDispatch();
  const optionsRef = useRef<HTMLDivElement>(null);
  const optionsButtonRef = useRef<HTMLButtonElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { theme } = useTheme();
  const balanceVisible = useSelector(selectHiddenBalance);

  const { positionsSummury, status } = useSelector(selectAccount);
  const [fetchPage, setFetchPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortField, setSortField] = useState<Nullable<SortFieldPositionsSummary>>(null);
  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(transactionHistoryColumn);
  const [showOptionsTransactions, setShowOptionsTransactions] = useState(false);
  const [tableColumns, setTableColumns] = useState(transactionHistoryColumns);
  const [prevSubAccountAssetsLength, setPrevSubAccountAssetsLength] = useState(0);
  const [sortedPositionsSummury, setSortedPositionSummury] = useState<PositionsSummary[] | []>(positionsSummury?.items || []);

  const { currentDateAsString, sevenDaysAgoAsString } = getDate();
  const [dateRange, setDateRange] = useState<any>([sevenDaysAgoAsString, currentDateAsString]);
  const [startDate, endDate] = dateRange;

  useIntersectionObserver(fetchPage, positionsSummury?.meta.total_pages || 1, sortedPositionsSummury, 'history-item', setFetchPage, 9);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrder = [...columnOrder];
    const [movedColumn] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedColumn);

    setColumnOrder(newOrder);

    saveToLocalStorage('transactionHistoryColumnOrder', newOrder);
  };

  const handleSort = (field: SortFieldPositionsSummary) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleClearPositionsSummury = () => {
    dispatch(clearPositionsSummury());
    unsubscribePositionsSummury();
    unsubscribeInstrument();
    setPrevSubAccountAssetsLength(0);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo(0, 0);
    }
  };

  const handleResetFilter = () => {
    if (!subAccountId) return;

    const currentDate = moment();
    const startDateCurrent = currentDate.clone().subtract(7, 'days');

    const start = startDateCurrent.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end = currentDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const checkStartDate = moment(startDate).format('YYYY-MM-DD');
    const checkEndDate = moment(endDate).format('YYYY-MM-DD');

    if (checkStartDate === moment(start).format('YYYY-MM-DD') && checkEndDate === moment(end).format('YYYY-MM-DD')) return;

    setSortedPositionSummury([]);
    handleClearPositionsSummury();

    setFetchPage(1);
    setDateRange([start, end]);
  };

  const handleOption = (key: string) => {
    setTableColumns((prevTableItems) => {
      const updatedTableColumns = {
        ...prevTableItems,
        [key as keyof typeof tableColumns]: {
          ...prevTableItems[key as keyof typeof tableColumns],
          show: !prevTableItems[key as keyof typeof tableColumns].show,
        },
      };

      saveToLocalStorage('transactionHistoryTableColumns', updatedTableColumns);
      return updatedTableColumns;
    });
  };

  useEffect(() => {
    if (positionsSummury) {
      setSortedPositionSummury(positionsSummury.items.filter((item) => item.sub_account_id === subAccountId));
    } else {
      setSortedPositionSummury([]);
    }
  }, [positionsSummury, subAccountId]);

  useEffect(() => {
    if (!sortField) return;

    const sorted: PositionsSummary[] = positionsSummury ? [...positionsSummury.items].sort((a, b) => sortPositionsSummury(a, b, sortField, sortOrder)) : [];

    setSortedPositionSummury(sorted);
  }, [sortField, sortOrder]);

  useEffect(() => {
    if (currentSortColumn) {
      if (!sortField) return;

      const sorted: PositionsSummary[] = positionsSummury ? [...positionsSummury.items].sort((a, b) => sortPositionsSummury(a, b, sortField, sortOrder)) : [];

      setSortedPositionSummury(sorted);
    }
  }, [currentSortColumn, positionsSummury]);

  useEffect(() => {
    if (!subAccountId) return;

    const fetchParams: PositionsSummuryParams = {
      from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      page: fetchPage,
      limit: 10,
      sub_account_id: subAccountId,
    };

    if (startDate && endDate) {
      unsubscribeInstrument();
      setPrevSubAccountAssetsLength(0);
      dispatch(fetchPositionsSummury(fetchParams));
    }
  }, [startDate, endDate, fetchPage, subAccountId]);

  useEffect(() => {
    subscribePositionsSummury();

    return () => {
      handleClearPositionsSummury();
    };
  }, []);

  useEffect(() => {
    if (positionsSummury) {
      const itemsWithoutExitPrice = positionsSummury.items.filter((item) => !item.exit_price).map((item) => item.instrument.id);

      // Check if there are instruments to subscribe to and if there have been changes in assets since the last call
      if (itemsWithoutExitPrice.length > 0 && positionsSummury.items.length !== prevSubAccountAssetsLength) {
        // Subscribe to instrument changes
        subscribeInstrument(itemsWithoutExitPrice);

        // Update the previous length of assets
        setPrevSubAccountAssetsLength(positionsSummury.items.length);
      }
    }
  }, [positionsSummury]);

  useEffect(() => {
    const showOptionsHandler = (e: MouseEvent) => {
      e.stopPropagation();

      if (!optionsRef.current?.contains(e.target as Node) && !optionsButtonRef.current?.contains(e.target as Node)) {
        setShowOptionsTransactions(false);
      }
    };

    window.addEventListener('click', showOptionsHandler);

    return () => {
      window.removeEventListener('click', showOptionsHandler);
    };
  }, [showOptionsTransactions]);

  useLayoutEffect(() => {
    const transactionHistoryTableColumnsLocalStorage = getFromLocalStorage('transactionHistoryTableColumns', null);
    const transactionHistoryColumnLocalStorage = getFromLocalStorage('transactionHistoryColumnOrder', null);

    if (transactionHistoryTableColumnsLocalStorage) {
      setTableColumns(transactionHistoryTableColumnsLocalStorage);
    }
    if (transactionHistoryColumnLocalStorage) {
      setColumnOrder(transactionHistoryColumnLocalStorage);
    }
  }, []);

  return (
    <section className={styles.transactionHistory}>
      <div className={styles.title}>
        <h2>История сделок</h2>
        <div className={styles.actionsOptions}>
          <button ref={optionsButtonRef} onClick={() => setShowOptionsTransactions((prev) => !prev)}>
            <ThreePointsIconSvg />
          </button>
        </div>

        { showOptionsTransactions && (
          <div ref={optionsRef} className={styles.options}>
            <p className={styles.optionsTitle}>Настройка позиций</p>
            <ul>
              {Object.entries(tableColumns).map(([key, value]) => (
                <li
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleOption(key)}
                  onClick={() => handleOption(key)}
                  key={key}
                >
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={value.show}
                    onChange={() => {}}
                  />
                  <span>{value.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <header className={styles.header}>
        <DataPickerRange whiteMode={theme === EThemeMode.LIGHT} removeData={handleClearPositionsSummury} setPage={setFetchPage} setDateRange={setDateRange} dateRange={dateRange} />

        <div className={styles.buttons}>
          <Tooltip withArrow label="Скачать историю" position="left" offset={9}>
            <ExcelBtnIconSvg />
          </Tooltip>
          <Button onClick={handleResetFilter} maxWidth="" background={theme === EThemeMode.DARK ? 'white' : 'black'}>Сбросить фильтры</Button>
        </div>
      </header>

      <div ref={tableContainerRef} className={styles.historyTableWrapper}>
        <DragDropContext onDragEnd={onDragEnd}>
          <table className={styles.table}>
            <thead>
              <Droppable droppableId="columns" direction="horizontal">
                {(provided, snapshot) => (
                  <tr
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      background: snapshot.isDraggingOver ? (theme === 'light' ? '#131313' : '#474747') : (theme === 'light' ? '#131313' : '#474747'),
                    }}
                    className={snapshot.isDraggingOver ? styles.dragging : styles.notDragging}

                  >
                    {columnOrder.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index}>
                        {(provided, snapshot) => (
                          <th
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={snapshot.isDragging ? styles.dragging : styles.notDragging}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? 'transparent' : (theme === 'light' ? '#131313' : '#474747'),
                              color: snapshot.isDragging ? 'var(--color-main)' : '#BCBCBC',
                              display: snapshot.isDragging ? 'flex' : '',
                              width: column.key === 'checkbox' ? '50px' : 'auto',
                            }}
                          >
                            {tableColumns.exchange_created_at.show && column.key === 'exchange_created_at' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.instrument.show && column.key === 'instrument' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.action.show && column.key === 'action' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.pnl.show && column.key === 'pnl' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.pnlPercent.show && column.key === 'pnl_percent' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.quantity.show && column.key === 'quantity' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.quantity_dollars.show && column.key === 'quantity_dollars' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.entry_price.show && column.key === 'entry_price' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.exit_price.show && column.key === 'exit_price' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.profit.show && column.key === 'profit' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.cumulative_quantity.show && column.key === 'cumulative_quantity' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.commission.show && column.key === 'commission' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {column.label}
                                <SortIconSvg />
                              </div>
                            )}
                          </th>
                        )}
                      </Draggable>
                    ))}
                    { provided.placeholder}
                  </tr>
                )}
              </Droppable>
            </thead>
            {sortedPositionsSummury && sortedPositionsSummury.length > 0 && (
              <tbody>
                {sortedPositionsSummury.map((transaction) => (
                  <tr key={transaction.id} className="history-item">
                    {columnOrder.map((column) => (
                      <td key={column.key}>
                        {tableColumns.exchange_created_at.show && column.key === 'exchange_created_at' && (
                          <div className={styles.tdWrapper}>
                            {moment(transaction.exchange_created_at).format('DD.MM.YYYY HH:mm')}
                          </div>
                        )}
                        {tableColumns.instrument.show && column.key === 'instrument' && (
                          <div className={styles.instrumentTd}>
                            {transaction.instrument.symbol}
                            <BalanceType type={transaction.trading_type} />
                          </div>
                        )}
                        {tableColumns.action.show && column.key === 'action' && (
                          <div className={className(styles.actionTd, { [styles.sell]: transaction.action === 'SELL' })}>
                            {transaction.action}
                          </div>
                        )}
                        {tableColumns.pnl.show && column.key === 'pnl' && (
                          <div className={className(
                            styles.tdWrapper,
                            { [styles.minus]: parseFloat(calculatePnl(transaction)) < 0 },
                            { [styles.plus]: parseFloat(calculatePnl(transaction)) > 0 },
                          )}
                          >
                            {!balanceVisible ? calculatePnl(transaction) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.pnlPercent.show && column.key === 'pnl_percent' && (
                          <div className={className(
                            styles.tdWrapper,
                            { [styles.minus]: parseFloat(calculatePnlInPercents(transaction)) < 0 },
                            { [styles.plus]: parseFloat(calculatePnlInPercents(transaction)) > 0 },
                          )}
                          >
                            {`${calculatePnlInPercents(transaction)} %`}
                          </div>
                        )}
                        {tableColumns.quantity.show && column.key === 'quantity' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible ? toFixedDecimal(transaction.quantity) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.quantity_dollars.show && column.key === 'quantity_dollars' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible ? toFixedDecimalPrecision(multiplyValues(transaction.quantity, transaction.entry_price), USDT_PRECISION) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.entry_price.show && column.key === 'entry_price' && (
                          <div className={styles.tdWrapper}>
                            {toFixedDecimal(transaction.entry_price)}
                          </div>
                        )}
                        {tableColumns.exit_price.show && column.key === 'exit_price' && (
                          <div className={styles.tdWrapper}>
                            {transaction.exit_price ? toFixedDecimal(transaction.exit_price) : '-'}
                          </div>
                        )}
                        {tableColumns.profit.show && column.key === 'profit' && (
                          <div className={className(
                            styles.tdWrapper,
                            { [styles.minus]: parseFloat(transaction.profit) < 0 },
                            { [styles.plus]: parseFloat(transaction.profit) > 0 },
                          )}
                          >
                            {!balanceVisible ? toFixedDecimal(transaction.profit) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.cumulative_quantity.show && column.key === 'cumulative_quantity' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible ? toFixedDecimalPrecision(transaction.cumulative_quantity) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.commission.show && column.key === 'commission' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible ? toFixedDecimal(transaction.commission) : HIDDEN_BALANCES}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </DragDropContext>

        {status === EStatus.loading && sortedPositionsSummury && sortedPositionsSummury.length === 0 && (
          <div className={styles.loadingWrapper}>
            <LoadingOverlay
              className="loader"
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
        {status === EStatus.success && sortedPositionsSummury && sortedPositionsSummury.length === 0 && (
          <Empty>
            Нет истории <br /> за выбранный период
          </Empty>
        )}
        {status === EStatus.rejected && (
          <Empty horizontal error>
            Произошла внутренняя ошибка <br />при получении данных
          </Empty>
        )}
        {status === EStatus.loading && sortedPositionsSummury && sortedPositionsSummury.length > 0 && (
          <div className={styles.loadingItemWrapper}>
            <LoadingOverlay
              className={`loader ${styles.loader}`}
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
      </div>
      <div />
    </section>
  );
});

export default PositionsSummuryTable;
