import { RootState } from 'src/app/store/store';

export const selectApiKeys = (state: RootState) => state.subAccounts.apiKeys;
export const selectApiKeysGetStatus = (state: RootState) => state.subAccounts.apiKeysGetStatus;
export const selectApiKeysCreateStatus = (state: RootState) => state.subAccounts.apiKeysCreateStatus;
export const selectStatusSubAccountsAssets = (state: RootState) => state.subAccounts.statusSubAccountsAssets;
export const selectStatusSubAccountsAssetsTransfers = (state: RootState) => state.subAccounts.statusSubAccountsTransfers;
export const selectApiKeysEditStatus = (state: RootState) => state.subAccounts.apiKeysEditStatus;
export const selectFavoriteSubAccount = (state: RootState) => state.subAccounts.favoriteSubAccount;
export const selectApiKeysDeleteStatus = (state: RootState) => state.subAccounts.apiKeysDeleteStatus;
export const selectSubAccounts = (state: RootState) => state.subAccounts.subAccounts;
export const selectSubAccountsAssets = (state: RootState) => state.subAccounts.subAccountsAssets;
export const selectSubAccountsAssetsTransfers = (state: RootState) => state.subAccounts.subAccountsAssetsTransfers;
export const selectSelectedSubAccounts = (state: RootState) => state.subAccounts.selectedSubAccount;
export const selectEditSubAccountStatus = (state: RootState) => state.subAccounts.editSubAccountStatus;
export const selectCreateSubAccountStatus = (state: RootState) => state.subAccounts.createSubAccountStatus;
export const selectSubAccountSettingsStatus = (state: RootState) => state.subAccounts.subAccountSettingsStatus;
export const selectSubAccountSettings = (state: RootState) => state.subAccounts.subAccountSettings;
