import React from 'react';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { LoadingOverlay } from '@mantine/core';
import { join } from 'src/shared/libs/helpers/helper.lib';
import Empty from 'src/entities/empty/empty';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import { useSelector } from 'react-redux';
import { Instrument } from '../../model/types';
import { selectLeverages } from '../../model/selectors';
import './table-leverages.scss';

interface TableLeveragesProps {
  instruments: Nullable<Instrument[]>;
  selectedInstrumentIds: number[];
  searchValue: string;
  currentSubAccount: Nullable<SubAccountType>;
  selectedAllInstrumentIds: () => void;
  openCalculator: (instrument: Instrument) => void;
}

const TableLeverages: React.FC<TableLeveragesProps> = ({
  instruments,
  selectedInstrumentIds,
  searchValue,
  currentSubAccount,
  selectedAllInstrumentIds,
  openCalculator,
}) => {
  const { getExchangeImageById } = useExchange();
  const { status: statusInstruments } = useSelector(selectLeverages);
  return (
    <div className="instrument-list-size-limiter">
      {statusInstruments === EStatus.loading && !instruments && (
        <div className="loading-position">
          <LoadingOverlay
            className="loader"
            visible
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: '#00C37C', type: 'dots' }}
          />
        </div>
      )}

      {instruments ? (
        <table className="instrument-list">
          <thead>
            <tr>
              <th>
                <input
                  className={join('checkbox', Boolean(searchValue.length) && 'checkbox-disabled')}
                  type="checkbox"
                  checked={selectedInstrumentIds.length === instruments.length}
                  onChange={selectedAllInstrumentIds}
                />
              </th>
              <th><span>Инструмент</span></th>
              <th><span>Счет</span></th>
              <th><span>Плечо</span></th>
              <th><span>Макс. плечо</span></th>
              <th><span>Макс. позиция</span></th>
            </tr>
          </thead>
          <tbody>
            {instruments.map((instrument) => (
              <tr
                key={instrument.instrument.id}
                onClick={() => openCalculator(instrument)}
                className={join('post', selectedInstrumentIds.includes(instrument.instrument.id) && 'active')}
              >
                <td>
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={selectedInstrumentIds.includes(instrument.instrument.id)}
                    onChange={() => {}}
                  />
                </td>
                <td>{instrument.instrument.symbol}</td>
                <td>
                  <div>
                    {currentSubAccount && <img src={getExchangeImageById(currentSubAccount.exchange_id)} alt="exchange" />}
                    {currentSubAccount?.user_name}
                  </div>
                </td>
                <td>{instrument.choosen_bracket[0].toString().replace('.', ',')}</td>
                <td>{instrument.brackets[0][0].toString().replace('.', ',')}</td>
                <td>{instrument.choosen_bracket[1].toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        statusInstruments === EStatus.success && <Empty className="empty-position">Нет доступных инструментов</Empty>
      )}

      {statusInstruments === EStatus.rejected && (
        <div className="instruments-error-message">
          <Empty className="empty-position" error>Произошла внутренняя ошибка <br /> при получении данных</Empty>
        </div>
      )}

      {statusInstruments === EStatus.loading && instruments && (
        <LoadingOverlay
          className="loader"
          visible
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
          loaderProps={{ color: '#00C37C', type: 'dots' }}
        />
      )}
    </div>
  );
};

export default TableLeverages;
