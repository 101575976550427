import { Link } from 'react-router-dom';
import { PRIVACY_POLICY, USER_AGREEMENT } from 'src/shared/constants/app-links';
import { Checkbox } from '@mantine/core';
import styles from './user-agreement.module.scss';

interface IUserAgreementprops {
  checked: boolean;
  setChecked: (value: boolean) => void;
}

const UserAgreement = ({ checked, setChecked } : IUserAgreementprops) => (
  <div className={styles.userAgreement}>
    <Checkbox
      checked={checked}
      onChange={(event) => setChecked(event.currentTarget.checked)}
      color="#00C37C"
      size="md"
      className={styles.checkbox}
    />

    <div>
      Создавая аккаунт, я принимаю <Link target="_black" to={USER_AGREEMENT}>пользовательское соглашение </Link> и <Link target="_black" to={PRIVACY_POLICY}>политику конфиденциальности</Link>
    </div>
  </div>
);

export default UserAgreement;
