import InputForm from 'src/shared/ui/input/input-form/input-form';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { useSelector } from 'react-redux';
import './margin-mode-setting.scss';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import { useAppDispatch } from 'src/app/store/store';
import useAlert from 'src/shared/libs/hooks/use-alert';
import { selectSubAccountSettings } from 'src/app/store/slices/sub-accounts/selectors';
import { useEffect, useState } from 'react';
import { ChangeMarginMode, fetchMarginMode } from '../../model/thunks';
import { MARGIN_MODE } from '../../constants';
import { selectLeverages } from '../../model/selectors';
import { addMarginModeToQueue } from '../../model/slice';

interface IMarginModeSetting {
  subAccountId: Nullable<number>
}

const MarginModeSetting = ({ subAccountId }: IMarginModeSetting) => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();

  const [marginModeAll, setMarginModeAll] = useState(1);

  const { marginModeQueue, statusMarginMode } = useSelector(selectLeverages);
  const subAccountSettings = useSelector(selectSubAccountSettings);

  const checkSubAccountMarginModeStatus = () => marginModeQueue.some((id) => id === subAccountId);

  const handleOnChangeMarginMode = async () => {
    if (!subAccountId || !subAccountSettings) return;

    const params: ChangeMarginMode = {
      subAccountId,
      marginMode: marginModeAll,
    };

    const { payload } = await dispatch(fetchMarginMode(params));

    if (payload === true) {
      setAlertMessage('В процессе', 'warning');
      dispatch(addMarginModeToQueue(subAccountId));
    } else if (payload === 'TASK_IN_PROGRESS') {
      setAlertMessage('В процессе', 'warning');
      dispatch(addMarginModeToQueue(subAccountId));
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  useEffect(() => {
    if (subAccountSettings) {
      setMarginModeAll(subAccountSettings.margin_mode);
    }
  }, [subAccountSettings]);

  return (
    <div className="common-settings">
      <InputForm title="Режим маржи">
        <Select
          isActiveSearch={false}
          isSelectValue={<span className="marge-item">{MARGIN_MODE.find((item) => item.value === marginModeAll)?.name}</span>}
        >
          {MARGIN_MODE.map((item) => (
            <span
              className="marge-item"
              key={item.value}
              tabIndex={0}
              role="button"
              onClick={() => setMarginModeAll(item.value)}
              onKeyDown={() => setMarginModeAll(item.value)}
            >{item.name}
            </span>
          )) }
        </Select>
      </InputForm>

      <Button
        disabled={statusMarginMode === EStatus.loading || checkSubAccountMarginModeStatus()}
        loading={statusMarginMode === EStatus.loading}
        onClick={handleOnChangeMarginMode}
        background={checkSubAccountMarginModeStatus() ? 'orange' : 'green'}
      >
        {checkSubAccountMarginModeStatus() ? 'В процессе...' : 'Применить ко всем инструментам'}
      </Button>
    </div>
  );
};

export default MarginModeSetting;
