import { InputHTMLAttributes, Ref, forwardRef } from 'react';
import classNames from 'classnames';
import styles from './input-auth.module.scss';

interface IInputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  label: string;
  placeholder: string
  icon?: string;
}

const InputText = forwardRef(({
  label, placeholder, error, icon, ...fieldProps
}: IInputTextProps, ref: Ref<HTMLInputElement>) => (
  <div className={classNames(styles.formGroup, { [styles.error]: error })}>
    <label>{label}</label>
    <input
      ref={ref}
      placeholder={placeholder}
      {...fieldProps}
    />
    { icon && <img src={icon} alt="icon" />}
  </div>
));

export default InputText;
