import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import Empty from 'src/entities/empty/empty';

import './alerts.scss';

const pageTitle = 'Алерты';

const AlertsPage = () => {
  useChangePageTitle(pageTitle);

  return (
    <div className="alerts-page">
      <Empty>Эта страница в процессе разработки ...</Empty>
    </div>
  );
};

export default AlertsPage;
