import { useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputPassword from 'src/pages/auth/components/inputs-auth/input-password';
import ForgotPasswordService, { ForgotDataStepThree } from 'src/services/forgot-password.service';
import { useSelector } from 'react-redux';
import { selectForgotPassword } from '../../model/selectors';
import styles from './forgot-step-three.module.scss';

const schema = yup.object().shape({
  password: yup.string().required('Пароль обязателен')
    .test('password', 'Пароль должен содержать минимум 8 символов', (value) => value.length >= 8)
    .test('lowercase', 'Пароль должен содержать буквы нижнего регистра (a–z)', (value) => /[a-z]/.test(value))
    .test('uppercase', 'Пароль должен содержать буквы верхнего регистра (A–Z)', (value) => /[A-Z]/.test(value))
    .test('digits', 'Пароль должен содержать цифры (0-9)', (value) => /\d/.test(value))
    .test('specialChars', 'Пароль должен содержать специальные символы (например, !@#$%^&*)', (value) => /[!@#$%^&*]/.test(value)),
  reenterPassword: yup.string()
    .required('Пароль обязателен')
    .oneOf([yup.ref('password')], 'Пароли не совпадают'),
});

type ForgotPassword = {
  password: string,
  reenterPassword: string
}

const ForgotStepThree = () => {
  const { setAlertMessage } = useAlert();

  const navigate = useNavigate();
  const { email } = useSelector(selectForgotPassword);

  const [loading, setLoading] = useState(false);

  const form = useForm<ForgotPassword>({
    defaultValues: {
      password: '',
      reenterPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, formState } = form;
  const { errors } = formState;

  const forgotPasswordStepThree = async (data: ForgotPassword) => {
    setLoading(true);

    const forgotData: ForgotDataStepThree = {
      email,
      new_password: data.password,
    };

    const result = await ForgotPasswordService.stepThree(forgotData);

    if (result.data) {
      setAlertMessage('Пароль успешно изменен', 'success');
      navigate('/auth/login');
    } else if (result instanceof AxiosError && result.response) {
      setLoading(false);
      const { detail } = result.response.data;

      if (detail === 'STEP_EXPIRED') {
        setAlertMessage('Начните с первого шага', 'error');
      }
    } else {
      setLoading(false);
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const onSubmit = async (userData: ForgotPassword) => {
    forgotPasswordStepThree(userData);
  };

  return (
    <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="password"
        control={control}
        render={({
          field: {
            ref, value, onChange, ...fieldProps
          },
        }) => (
          <InputPassword
            ref={ref}
            value={value || ''}
            onChange={onChange}
            error={!!errors.password}
            placeholder="Введите пароль"
            label="Новый пароль"
            {...fieldProps}
          />
        )}
      />
      {errors.password && errors.password.message && <Hint error text={errors.password.message} />}
      <Controller
        name="reenterPassword"
        control={control}
        render={({
          field: {
            ref, value, onChange, ...fieldProps
          },
        }) => (
          <InputPassword
            ref={ref}
            value={value || ''}
            onChange={onChange}
            error={!!errors.reenterPassword}
            placeholder="Введите пароль"
            label="Повторите пароль"
            {...fieldProps}
          />
        )}
      />
      {errors.reenterPassword && errors.reenterPassword.message && <Hint error text={errors.reenterPassword.message} />}

      <Button type="submit" loading={loading} background="green">Продолжить</Button>
    </form>
  );
};

export default ForgotStepThree;
