import className from 'classnames';
import { Loader } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import { fetchVerifyKycUrl } from 'src/app/store/slices/kyc/thunks';
import { selectKyc } from 'src/app/store/slices/kyc/selectors';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { EKycStatus } from 'src/app/store/slices/user/types';
import { ReactComponent as HamsterPassportIconSvg } from 'src/shared/assets/images/hamster-passport.svg';
import { EStatus } from 'src/shared/types/global-types';
import useAlert from 'src/shared/libs/hooks/use-alert';

import styles from './passport.module.scss';

interface IPassportProps {
  visible: boolean
  horizontal?: boolean
}

const Passport = ({ visible, horizontal }: IPassportProps) => {
  const dispatch = useAppDispatch();

  const { status } = useSelector(selectKyc);
  const { kycStatus } = useSelector(selectUser);
  const { setAlertMessage } = useAlert();

  const statusClassName = kycStatus === EKycStatus.IN_PROCESS ? styles.process
    : kycStatus === EKycStatus.VERIFIED ? styles.verified
      : kycStatus === EKycStatus.NOT_VERIFIED ? styles.noVerified
        : kycStatus === EKycStatus.CREATED ? styles.created
          : kycStatus === EKycStatus.IN_REVIEW ? styles.process
            : '';

  const verifyKyc = async () => {
    try {
      const { payload } = await dispatch(fetchVerifyKycUrl());

      if (typeof payload === 'string' && payload.startsWith('http')) {
        window.open(payload, '_blank');
      } else if (typeof payload === 'string' && payload === 'Network Error') {
        setAlertMessage('Ошибка соединения', 'error');
      } else if (payload && typeof payload === 'object' && 'detail' in payload) {
        setAlertMessage('Аккаунт верифицирован', 'success');
      } else {
        setAlertMessage('Получен недопустимый URL-адрес', 'error');
      }
    } catch (error) {
      console.error('Ошибка при проверке KYC:', error);
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  return !visible ? (
    <div className={className(styles.passport, { [styles.horizontal]: horizontal })}>
      <HamsterPassportIconSvg />
      <div className={styles.passportBody}>
        <div className={styles.title}>
          {kycStatus === EKycStatus.CREATED && (<span>Верификация личности <br /> не пройдена</span>)}
          {kycStatus === EKycStatus.VERIFIED && (<span>Верификация личности <br /> пройдена</span>)}
          {kycStatus === EKycStatus.NOT_VERIFIED && (<span>Верификация личности <br /> отклонена</span>)}
          {kycStatus === EKycStatus.IN_PROCESS && (<span>Верификация личности <br /> в процессе</span>)}
          {kycStatus === EKycStatus.IN_REVIEW && (<span>Верификация личности <br /> на рассмотрении</span>)}
        </div>
        <p className={styles.info}>Чтобы использовать данную функцию, пройдите <br />  базовую верификацию аккаунта</p>

        <div className={className(styles.verificationStatus, statusClassName)}>
          {kycStatus === EKycStatus.VERIFIED && (<span className="status-text">Верификация пройдена</span>)}
          {kycStatus === EKycStatus.CREATED && (
            <button onClick={verifyKyc}>
              {status === EStatus.loading ? <Loader size={16} color="white" /> : 'Пройти проверку'}
            </button>
          )}
          {kycStatus === EKycStatus.IN_PROCESS && (
            <button onClick={verifyKyc}> {status === EStatus.loading ? <Loader size={16} color="white" /> : 'В процессе...'}.</button>
          )}
          {kycStatus === EKycStatus.IN_REVIEW && (<span className="status-text">На рассмотрении...</span>)}
          {kycStatus === EKycStatus.NOT_VERIFIED && (
            <button onClick={verifyKyc}>
              {status === EStatus.loading ? <Loader size={16} color="white" /> : 'Пройти заново'}
            </button>
          )}
          {!EKycStatus[kycStatus] && (<span className="status-text">Неопределнный статус</span>)}
        </div>
      </div>
    </div>
  ) : null;
};

export default Passport;
