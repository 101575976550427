import { Nullable } from 'src/shared/types/global-types';
import { Instrument } from 'src/pages/leverages/model/types';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import styles from './instrument-item.module.scss';

interface ILeverageItemProps {
  instrument: Instrument
  instrumentExchangeId: Nullable<number>
  openCalculator: (instrument: Instrument) => void
}

const InstrumentItem = ({ instrument, instrumentExchangeId, openCalculator }: ILeverageItemProps) => {
  const { getExchangeImageById } = useExchange();

  return (
    <div
      className={styles.instrumentWrapper}
      role="button"
      tabIndex={0}
      onClick={() => openCalculator(instrument)}
      onKeyDown={() => {}}
    >
      <div className={styles.instrument}>
        <img src={getExchangeImageById(instrumentExchangeId)} alt="exchange" />
        <h2>{instrument.instrument.symbol}</h2>
      </div>

      <div className={styles.brackets}>
        <span className={styles.maxLeverage}>Макс. {instrument.brackets[0][0]}</span>
        <span className={styles.choosenBracket}>{instrument.choosen_bracket[0]}</span>
      </div>
    </div>
  );
};

export default InstrumentItem;
