import { batch } from 'react-redux';
import { useEffect } from 'react';

import { MantineProvider } from '@mantine/core';
import { I18nextProvider } from 'react-i18next';

import { fetchBalance } from './app/store/slices/balance/thunks';
import { useAppDispatch } from './app/store/store';
import { fetchExchanges } from './app/store/slices/exchanges/thunks';
import { fetchSubAccounts } from './app/store/slices/sub-accounts/thunks';
import { fetchTradeSettings } from './pages/settings/model/thunks';
import { fetchUser, fetchUserKycStatus, fetchUserSettings } from './app/store/slices/user/thunks';

import { setAuth } from './pages/auth/login/model/slice';
import { useAuth } from './shared/libs/hooks/use-auth';
import { fetchNotifications } from './widgets/notifications/model/thunks';
import { fetchPositionsPnl } from './pages/trading/model/thunks';
import { connectWebSocket, socket } from './shared/api/websocket.instance';
import { setHandleConnectedWebSocket } from './app/store/slices/alert/slice';

import i18n from './app/locales/i18n';
import Routers from './app/routers';

import 'src/app/styles/app.scss';

const App = () => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();

  const fetchBalancesAndConnectWebSocket = async () => {
    const { payload } = await dispatch(fetchBalance());

    if (payload) dispatch(connectWebSocket());
  };

  useEffect(() => {
    const logoutEvent = (event: StorageEvent) => {
      if (event.key === 'accessToken') {
        dispatch(setAuth(false));
        dispatch(setHandleConnectedWebSocket(false));

        try {
          socket?.close();
        } catch (error) {
          console.error('Ошибка при закрытии WebSocket:', error);
        }
      }
    };

    window.addEventListener('storage', logoutEvent);

    return () => {
      window.removeEventListener('storage', logoutEvent);
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      batch(() => {
        dispatch(fetchUser());
        dispatch(fetchUserKycStatus());
        dispatch(fetchTradeSettings());
        dispatch(fetchUserSettings());
        dispatch(fetchSubAccounts());
        dispatch(fetchExchanges());
        dispatch(fetchPositionsPnl());
        dispatch(fetchNotifications({ limit: 20, page: 1 }));
        fetchBalancesAndConnectWebSocket();
      });
    }
  }, [isAuth]);

  return (
    <I18nextProvider i18n={i18n}>
      <MantineProvider>
        <Routers />
      </MantineProvider>
    </I18nextProvider>
  );
};

export default App;
