import { Link } from 'react-router-dom';
import { ReactComponent as FiteIconSvg } from 'src/shared/assets/images/screener/file-text.svg';
import {
  COOKIE_INFO, PRIVACY_POLICY, RISK_WARNINGS, USER_AGREEMENT,
} from 'src/shared/constants/app-links';

import './documents-slide.scss';

const DocumentsSlide = () => (
  <div className="slide documents-slide">
    <h4>Документы</h4>

    <div className="documents-slide_content">
      <Link target="_blank" to={USER_AGREEMENT}><FiteIconSvg /> Условия использования</Link>
      <Link target="_blank" to={PRIVACY_POLICY}><FiteIconSvg /> Политика конфиденциальности</Link>
      <Link target="_blank" to={RISK_WARNINGS}><FiteIconSvg /> Предупреждение о рисках</Link>
      <Link target="_blank" to={COOKIE_INFO}><FiteIconSvg /> Политика использования файлов cookie</Link>
    </div>
  </div>
);

export default DocumentsSlide;
