import { Nullable } from 'src/shared/types/global-types';
import DustTransformsAssetsTable from './components/dust-transforms-assets-table/dust-transforms-assets-table';
import DustTransformsHistoryTable from './components/dust-transforms-history-table/dust-transforms-history-table';

interface IDustContainerProps {
  subAccountId: Nullable<number>
}

const DustContainer = ({ subAccountId }: IDustContainerProps) => (
  <>
    <DustTransformsAssetsTable subAccountId={subAccountId} />
    <DustTransformsHistoryTable subAccountId={subAccountId} />
  </>
);

export default DustContainer;
