import { useSelector } from 'react-redux';
import ForgotStepOne from '../forgot-step-one/forgot-step-one';
import ForgotStepThree from '../forgot-step-three/forgot-step-three';
import ForgotStepTwo from '../forgot-step-two/forgot-step-two';
import { selectForgotPassword } from '../../model/selectors';

const LIST_STEPS = [ForgotStepOne, ForgotStepTwo, ForgotStepThree];

const ForgotPasswordForm = () => {
  const { step } = useSelector(selectForgotPassword);
  const RenderStepComponent = LIST_STEPS[step];

  return (
    <RenderStepComponent />
  );
};

export default ForgotPasswordForm;
