import './button-close.scss';

interface IButtonCloseProps {
  onClick: () => void,
  darkTheme?: boolean,
  className?: string
}

const ButtonClose = ({ onClick, darkTheme, className }: IButtonCloseProps) => (
  <button
    onClick={onClick}
    className={`btn-close ${darkTheme ? 'close-theme' : ''} ${className ?? ''}`}
  />
);

export default ButtonClose;
