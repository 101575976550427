import { Divider, Modal } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'src/app/store/store';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { selectKyc } from 'src/app/store/slices/kyc/selectors';
import { fetchVerifyKycUrl } from 'src/app/store/slices/kyc/thunks';

import { join } from 'src/shared/libs/helpers/helper.lib';
import { EStatus } from 'src/shared/types/global-types';
import { useTheme } from 'src/shared/libs/hooks/use-theme';

import useAlert from 'src/shared/libs/hooks/use-alert';
import useMobile from 'src/shared/libs/hooks/useMobile';
import Verification from 'src/entities/verification/verification';
import KycPolicyModalContent from 'src/entities/kyc-policy-modal-content/kyc-policy-modal-content';

import './dropdown-menu-item.scss';

interface IDropdownMenuItemProps {
  children: React.ReactNode;
  verificationLevel: string;
}

const isItemOpened = true;

const DropdownMenuItem = ({
  children,
  verificationLevel,
}: IDropdownMenuItemProps) => {
  const dispatch = useAppDispatch();

  const isMobile = useMobile();
  const { theme } = useTheme();
  const { setAlertMessage } = useAlert();

  const { status } = useSelector(selectKyc);

  const [openedModal, { open: openModal, close: closeModal }] = useDisclosure(false);

  const verificationKYC = async () => {
    try {
      const { payload } = await dispatch(fetchVerifyKycUrl());

      if (typeof payload === 'string' && payload.startsWith('http')) {
        window.open(payload, '_blank');
      } else if (typeof payload === 'string' && !payload.startsWith('http')) {
        setAlertMessage(payload, 'error');
      } else {
        setAlertMessage('Произошла внутрення оишбка', 'error');
      }
    } catch (error) {
      console.error('Ошибка при проверке KYC:', error);
      setAlertMessage('Произошла внутрення оишбка', 'error');
    }
  };

  return (
    <>
      <div className={join('dropdown-menu-item', isItemOpened && 'dropdown-menu-item_opened')}>
        <div className="item-title">
          <p className="verification-level">{verificationLevel}</p>
          {!isMobile && <Verification openModal={openModal} />}
        </div>

        <Divider color={theme === EThemeMode.LIGHT ? '#D9D9D9' : '#3A3A3A'} />

        <div className="item-content">
          {children}
          <div className="item-content_verification"> {isMobile && <Verification openModal={openModal} />}</div>
        </div>
      </div>

      <Modal
        title={<span>Правила пользования <br /> Продуктами и Услугами Компании</span>}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="md"
        opened={openedModal}
        onClose={closeModal}
        className="modal-custom-overflow"
      >
        <KycPolicyModalContent btnStatus={status === EStatus.loading} onClose={closeModal} verify={verificationKYC} />
      </Modal>
    </>
  );
};

export default DropdownMenuItem;
