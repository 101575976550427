import { ReactComponent as DiscordSvg } from 'src/shared/assets/images/socials/discord.svg';
import { ReactComponent as TelegramSvg } from 'src/shared/assets/images/socials/telegram.svg';

import { handleSupportTelegram } from 'src/shared/libs/helpers/helper.lib';
import BodyFooter from '../body-footer/body-footer';
import styles from './auth-footer.module.scss';

const AuthFooter = () => (
  <footer className={styles.authFooter}>
    <BodyFooter />
    <p className={styles.info}>Узнавайте первыми <br /> об обновлениях проекта:</p>
    <div className={styles.social}>
      <ul>
        <li className={styles.telegram}><TelegramSvg onClick={handleSupportTelegram} /></li>
        <li className={styles.discord}><a href="https://discord.gg/TUP9BzTvfX" target="_blank"><DiscordSvg /></a></li>
      </ul>
    </div>
  </footer>
);

export default AuthFooter;
