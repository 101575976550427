import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';

import { fetchCreatePositionCategory, fetchTradeList } from './thunk';
import {
  ITradeListSlice, TradeListFilterKey, TradeListFilterValue,
} from './types';

const initialState: ITradeListSlice = {
  tradeList: null,
  tradefilters: {
    symbol: null,
    timePeriod: null,
    subAccountId: null,
    exchangeId: null,
    pnl: null,
    duration: null,
    category: null,
    side: null,
    leverage: null,
    profit: null,
    activeQuantityUsdt: null,
    activeQuantity: null,
    tradingType: null,
    page: 1,
  },
  status: EStatus.loading,
  tradeListColumns: null,
  pnlValue: '',
  durationValue: '',
  leverageValue: '',
  profitValue: '',
  quantityUsdtValue: '',
  resetFiltersToggle: false,
};

export const tradeListSlice = createSlice({
  name: 'tradeList',
  initialState,
  reducers: {
    setTableColumns: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.tradeListColumns = action.payload;
      }

      if (typeof action.payload === 'object') {
        if (!state.tradeListColumns) return;

        state.tradeListColumns = state.tradeListColumns.map((column) => (column.key === action.payload.key
          ? { ...column, visible: action.payload.visible }
          : column));
      }
    },
    setTradeFilter: <T extends TradeListFilterKey>(
      state: ITradeListSlice,
      action: PayloadAction<{ key: T; value: TradeListFilterValue<T> }>,
    ) => {
      state.tradefilters[action.payload.key] = action.payload.value;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.tradefilters.page = action.payload;
    },
    resetFilters: (state) => {
      state.tradefilters = {
        symbol: null,
        timePeriod: null,
        subAccountId: null,
        exchangeId: null,
        pnl: null,
        duration: null,
        category: null,
        side: null,
        leverage: null,
        profit: null,
        activeQuantity: null,
        activeQuantityUsdt: null,
        tradingType: null,
        page: 1,
      };
      state.pnlValue = '';
      state.durationValue = '';
      state.leverageValue = '';
      state.profitValue = '';
      state.quantityUsdtValue = '';
      state.resetFiltersToggle = true;
    },
    setPnlValue: (state, action: PayloadAction<string>) => {
      state.pnlValue = action.payload;
    },
    setDurationValue: (state, action: PayloadAction<string>) => {
      state.durationValue = action.payload;
    },
    setLeverageValue: (state, action: PayloadAction<string>) => {
      state.leverageValue = action.payload;
    },
    setProfitValue: (state, action: PayloadAction<string>) => {
      state.profitValue = action.payload;
    },
    setQuantityUsdtValue: (state, action: PayloadAction<string>) => {
      state.quantityUsdtValue = action.payload;
    },
    setResetFilters: (state, action: PayloadAction<boolean>) => {
      state.resetFiltersToggle = action.payload;
    },
    clearTradeListSlice: (state) => {
      state.tradeList = null;
      state.tradeListColumns = null;
      state.tradefilters = {
        symbol: null,
        timePeriod: null,
        subAccountId: null,
        exchangeId: null,
        pnl: null,
        duration: null,
        category: null,
        side: null,
        leverage: null,
        profit: null,
        activeQuantity: null,
        activeQuantityUsdt: null,
        tradingType: null,
        page: 1,
      };
      state.pnlValue = '';
      state.durationValue = '';
      state.leverageValue = '';
      state.profitValue = '';
      state.quantityUsdtValue = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradeList.pending, (state) => {
        if (state.tradeList) {
          state.tradeList.items = [];
        }
        state.status = EStatus.loading;
      })
      .addCase(fetchTradeList.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.tradeList = action.payload;
      })
      .addCase(fetchTradeList.rejected, (state) => {
        state.tradeList = null;
        state.status = EStatus.rejected;
      })
      .addCase(fetchCreatePositionCategory.fulfilled, (state, action) => {
        if (state.tradeList) {
          action.payload.forEach((newItem) => {
            if (!state.tradeList) return;

            const index = state.tradeList.items.findIndex((item) => item.id === newItem.id);

            if (index !== -1) {
              // Заменяем свойство categories для найденного элемента
              state.tradeList.items[index].categories = newItem.categories;
            }
          });
        }
      });
  },
});

export const {
  setTableColumns, setTradeFilter, resetFilters, setPage,
  setPnlValue,
  setDurationValue,
  setLeverageValue,
  setProfitValue,
  setQuantityUsdtValue,
  clearTradeListSlice,
  setResetFilters,
} = tradeListSlice.actions;
export const tradeListReducer = tradeListSlice.reducer;
