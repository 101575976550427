import { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Nullable } from 'src/shared/types/global-types';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { TransfersHistory } from 'src/widgets/transfer-history';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import AccountBalances from './components/account-balances/account-balances';
import AssetsTableBybit from './components/assets-table-bybit/assets-table-bybit';
import AssetsTable from './components/assets-table/assets-table';

interface IAccountPageProps {
    subAccount: Nullable<SubAccountType>
}

const AccountContainer = ({ subAccount } : IAccountPageProps) => {
  const navigate = useNavigate();

  const subAccounts = useSelector(selectSubAccounts);
  const [exchangeName, setExchangeName] = useState('');
  const { getExchangeNameById } = useExchange();

  useLayoutEffect(() => {
    const { pathname } = window.location;
    const [_, id] = pathname.split('/sub-accounts/');

    if (subAccounts) {
      const findSubAccount = subAccounts.find((item) => item.id === Number(id));
      const exchangeNameBySubAccount = getExchangeNameById(findSubAccount?.exchange_id);
      setExchangeName(exchangeNameBySubAccount);

      if (!findSubAccount) navigate('/sub-accounts', { replace: false });
    }
  }, [subAccounts]);

  return (
    <>
      <AccountBalances subAccounts={subAccounts} />
      {exchangeName === 'Bybit'
        ? <AssetsTableBybit subAccount={subAccount} />
        : <AssetsTable subAccount={subAccount} />}
      <TransfersHistory />
    </>
  );
};

export default AccountContainer;
