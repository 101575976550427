import className from 'classnames';
import { Skeleton } from '@mantine/core';
import { useTheme } from 'src/shared/libs/hooks/use-theme';

import { EThemeMode } from 'src/app/store/slices/user/types';
import styles from './select-skeleton.module.scss';

interface ISelectSkeletonProps {
  height?: number
  radius?: string
  darkStyle?: boolean
}

const SelectSkeleton = ({ height, radius, darkStyle }: ISelectSkeletonProps) => {
  const { theme } = useTheme();
  return (
    <div className={styles.skeleton}>
      <Skeleton className={className((darkStyle || theme === EThemeMode.DARK) && styles.darkStyle)} style={{ margin: '0' }} height={height || 41.99} mt={6} width="100%" radius={radius || '8px'} />
    </div>
  );
};

export default SelectSkeleton;
