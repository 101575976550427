import { Skeleton } from '@mantine/core';
import styles from './qr-code-skeleton.module.scss';

const QRCodeSkeleton = () => (
  <div className={styles.skeleton}>
    <Skeleton height={174} width={178} mt={6} radius="20px" />
  </div>
);

export default QRCodeSkeleton;
