import { createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { IKycSlice } from './types';
import { fetchVerifyKycUrl } from './thunks';

const initialState: IKycSlice = {
  url: null,
  status: EStatus.success,
};

export const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    removeUrlKyc: (state) => {
      state.url = null;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVerifyKycUrl.pending, (state) => {
        state.status = EStatus.loading;
        state.url = null;
      })
      .addCase(fetchVerifyKycUrl.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.url = action.payload;
      })
      .addCase(fetchVerifyKycUrl.rejected, (state) => {
        state.status = EStatus.rejected;
        state.url = null;
      });
  },
});

export const { removeUrlKyc } = kycSlice.actions;

export const kycReducer = kycSlice.reducer;
