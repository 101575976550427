import { CalclulationLeverage } from '../model/types';

interface PayloadError {
  [key: string]: {
    msg: string;
    type: 'success' | 'warning' | 'error';
  };
}

export const MARGIN_MODE = [{
  name: 'Кросс-маржа',
  value: 1,
},
  // {
  //   name: 'Изолированный',
  //   value: 2,
  // },
] as const;

export const CALCULATION_LEVERAGE: CalclulationLeverage[] = [
  {
    name: 'Депозита',
    type: 'FROM_DEPOSIT',
  },
  {
    name: 'Рабочего объёма',
    type: 'FROM_WORKING_VOLUME',
  },
];

export const CALCULATION_LEVERAGE_ERROR_MSG: PayloadError = {
  true: {
    msg: 'Расчет выполняется...',
    type: 'success',
  },
  false: {
    msg: 'Произошла внутренняя ошибка',
    type: 'error',
  },
  TOO_MANY_REQUESTS: {
    msg: 'Слишком много попыток. Повторите через 10 секунд',
    type: 'warning',
  },
  TASK_IN_PROGRESS: {
    msg: 'Расчет плечей в процессе, пожалуйста, подождите.',
    type: 'warning',
  },
  MAX_VALUE_FOR_CHOOSEN_INSTRUMENTS_EXCEEDED: {
    msg: 'Достигнут предел максимального плеча для выбранных инструментов',
    type: 'error',
  },
  SUB_ACCOUNT_NOT_FOUND: {
    msg: 'Произошла ошибка! Нет суб-аккаунта',
    type: 'error',
  },
  default: {
    msg: 'Произошла внутренняя ошибка',
    type: 'error',
  },
};

export const LEVERAGE = [2, 5, 10, 20, 25, 50];
