import { useSelector } from 'react-redux';
import { selectAuth } from 'src/pages/auth/login/model/selectors';

export const useAuth = () => {
  const { isAuth, isWelcomePage } = useSelector(selectAuth);

  return {
    isAuth,
    isWelcomePage,
  };
};
