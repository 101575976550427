import { ColumnOrderItem } from 'src/shared/types/global-types';

export const assetsTableColumnByBit: ColumnOrderItem[] = [
  { key: 'asset', label: 'Инструмент' },
  { key: 'quantity', label: 'Количество' },
  { key: 'available', label: 'Доступно' },
  { key: 'pnl', label: 'PnL за день (%)' },
  { key: 'collateralizable', label: 'Обеспечение' },
  { key: 'output', label: 'Вывод' },
];

export const assetsTableColumnBinance: ColumnOrderItem[] = [
  { key: 'asset', label: 'Инструмент' },
  { key: 'quantity', label: 'Количество' },
  { key: 'available', label: 'Доступно' },
  { key: 'pnl', label: 'PnL за день (%)' },
  { key: 'output', label: 'Вывод' },
];

export const dustTransformsAssetsColumn: ColumnOrderItem[] = [
  { key: 'checkbox', label: 'Чекбокс' },
  { key: 'asset', label: 'Актив' },
  { key: 'quantity', label: 'Количество' },
  { key: 'priceInUSDT', label: 'Стоимость USDT' },
  { key: 'priceInBNB', label: 'Стоимость BNB' },
];

export const dustTransfromsHistoryColumn: ColumnOrderItem[] = [
  { key: 'created_at', label: 'Дата и время' },
  { key: 'commission', label: 'Комиссия' },
  { key: 'income_quantity', label: 'Количество' },
  { key: 'to_asset', label: 'Актив' },
];

export const CONVERSION_ASSETS = ['BNB', 'USDT'] as const;

export const ASSETS_TYPES: Record<string, any> = [
  {
    column: 0,
    title: 'Все',
  },
  {
    column: 2,
    title: 'Фьючерсы',
  },
  {
    column: 1,
    title: 'Спот',
  },
];

export const ASSETS_TYPES_BYBIT: Record<string, any> = [
  {
    column: null,
    title: 'Все',
  },
  {
    column: true,
    title: 'Маржинальные активы',
  },
  {
    column: false,
    title: 'Свободные активы',
  },
];

export const ASSETS_SORT_COLUMNS: Record<string, any> = [
  {
    column: 'symbol',
    title: 'По названию',
  },
  {
    column: 'quantity',
    title: 'По сумме (USDT)',
  },
  // {
  //   column: 'pnl',
  //   title: 'По PNL за день ',
  // },
];
