import {
  Ref, forwardRef, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import ButtonLink from 'src/shared/ui/button/button-link/button-link';
import { TIMER_SEND_SMS } from 'src/shared/constants/constants';
import { useAppDispatch } from 'src/app/store/store';
import { setSendCodeTypeVisible } from '../../login/model/slice';
import styles from './input-auth.module.scss';

interface IInputActionProps {
  placeholder: string;
  error?: boolean
  label: string;
  onClick?: () => void;
  actionTitle?: string;
  actionActive?: boolean;
  type?: string;
}

const InputAction = forwardRef(({
  placeholder, label, error, onClick, actionTitle, actionActive, type, ...fieldProps
}: IInputActionProps, ref: Ref<HTMLInputElement>) => {
  const [remainingTime, setRemainingTime] = useState(TIMER_SEND_SMS);
  const [timerVisible, setTimerVisible] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSendCodeTypeVisible(timerVisible));
  }, [timerVisible]);

  const handleOnClick = () => {
    setRemainingTime(TIMER_SEND_SMS);
    setTimerVisible(true);

    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setTimerVisible(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  const minutes = String(Math.floor(remainingTime / 60)).padStart(2, '0');
  const seconds = String(remainingTime % 60).padStart(2, '0');

  return (
    <div className={styles.inputContainer}>
      <div className={classNames(
        styles.formGroup,
        { [styles.error]: error },
      )}
      >
        <label>{label}</label>
        <div className={styles.inputWrapper}>
          <input
            ref={ref}
            type={type || 'text'}
            placeholder={placeholder}
            {...fieldProps}
          />

          {actionActive && (
            <ButtonLink onClick={handleOnClick} disabled={timerVisible}>
              {actionTitle}
            </ButtonLink>
          )}

          {timerVisible && <time>{`${minutes}:${seconds}`}</time>}
        </div>

      </div>
    </div>
  );
});

export default InputAction;
