import moment from 'moment';
import className from 'classnames';
import {
  memo, useEffect, useState, useLayoutEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, LoadingOverlay } from '@mantine/core';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { useAppDispatch } from 'src/app/store/store';

import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as ExcelBtnIconSvg } from 'src/shared/assets/images/excel-btn.svg';

import Empty from 'src/entities/empty/empty';
import Button from 'src/shared/ui/button/button/button';
import Select from 'src/shared/ui/select/select';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';

import { EStatus, Nullable } from 'src/shared/types/global-types';
import { ColumnOrderItem } from 'src/pages/trading/model/types';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import {
  toFixedDecimalPrecision, getFromLocalStorage, saveToLocalStorage,
  getDate,
} from 'src/shared/libs/helpers/helper.lib';
import { CONVERSION_ASSETS, dustTransfromsHistoryColumn } from 'src/pages/account/constants';

import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { selectAccount } from '../../../../model/selectors';
import { clearDustTransformsHistory } from '../../../../model/slice';
import {
  SortFieldDustTransformsHistory, DustTransformsHistory,
} from '../../../../model/types';
import {
  DustTransfromsHistoryParams,
  fetchDustTransformsHistory,
} from '../../../../model/thunks';
import styles from './dust-transforms-history-table.module.scss';

  interface IDustTransformsHistoryTableProps {
    subAccountId?: Nullable<number>
  }

const DustTransformsHistoryTable = memo(({ subAccountId }: IDustTransformsHistoryTableProps) => {
  const dispatch = useAppDispatch();
  const { theme } = useTheme();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const balanceVisible = useSelector(selectHiddenBalance);
  const { dustTransformsHistory, statusDustTransformsHistory } = useSelector(selectAccount);

  const [fetchPage, setFetchPage] = useState(1);
  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentTransferSymbol, setCurrentTransferSymbol] = useState('Все');
  const [sortField, setSortField] = useState<Nullable<SortFieldDustTransformsHistory>>(null);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(dustTransfromsHistoryColumn);
  const [sortedDustTransformsHistory, setSortedDustTransformsHistory] = useState<DustTransformsHistory[] | []>(dustTransformsHistory?.items || []);

  useIntersectionObserver(fetchPage, dustTransformsHistory?.meta.total_pages || 1, sortedDustTransformsHistory, 'history-item', setFetchPage, 9);

  const { currentDateAsString, sevenDaysAgoAsString } = getDate();
  const [dateRange, setDateRange] = useState<any>([sevenDaysAgoAsString, currentDateAsString]);
  const [startDate, endDate] = dateRange;

  const handleClearDustTransformsHistory = () => {
    dispatch(clearDustTransformsHistory());

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo(0, 0);
    }
  };

  const handleSetCurrentTransferSymbol = (symbol: string) => {
    if (symbol === currentTransferSymbol) return;

    handleClearDustTransformsHistory();
    setFetchPage(1);
    setCurrentTransferSymbol(symbol);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrder = [...columnOrder];
    const [movedColumn] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedColumn);

    setColumnOrder(newOrder);

    saveToLocalStorage('dustTransformsHistoryColumnOrder', newOrder);
  };

  const handleSort = (field: SortFieldDustTransformsHistory) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleResetFilter = () => {
    if (!subAccountId) return;

    const currentDate = moment();
    const startDateCurrent = currentDate.clone().subtract(7, 'days');

    const start = startDateCurrent.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end = currentDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const checkStartDate = moment(startDate).format('YYYY-MM-DD');
    const checkEndDate = moment(endDate).format('YYYY-MM-DD');

    if (checkStartDate === moment(start).format('YYYY-MM-DD') && checkEndDate === moment(end).format('YYYY-MM-DD') && currentTransferSymbol === 'Все') return;

    setSortedDustTransformsHistory([]);
    handleClearDustTransformsHistory();
    setCurrentTransferSymbol('Все');

    setFetchPage(1);
    setDateRange([start, end]);
  };

  useEffect(() => {
    if (dustTransformsHistory) {
      setSortedDustTransformsHistory(dustTransformsHistory.items);
    } else {
      setSortedDustTransformsHistory([]);
    }
  }, [dustTransformsHistory]);

  useEffect(() => {
    const sorted: DustTransformsHistory[] = dustTransformsHistory ? [...dustTransformsHistory.items].sort((a, b) => {
      if (sortField === 'commission') {
        const commissionA = parseFloat(a.commission);
        const commissionB = parseFloat(b.commission);

        if (commissionA < commissionB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (commissionA > commissionB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'income_quantity') {
        const quantityA = parseFloat(a.income_quantity);
        const quantityB = parseFloat(b.income_quantity);

        if (quantityA < quantityB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (quantityA > quantityB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'to_asset') {
        const symbolA = a.to_asset.symbol;
        const symbolB = b.to_asset.symbol;

        if (symbolA < symbolB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (symbolA > symbolB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField) {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (aValue !== undefined && bValue !== undefined && aValue !== null && bValue !== null) {
          if (aValue < bValue) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortOrder === 'asc' ? 1 : -1;
          }
        }
      }
      return 0;
    }) : [];

    setSortedDustTransformsHistory(sorted);
  }, [sortField, sortOrder]);

  useEffect(() => {
    if (!subAccountId) return;

    const fetchParams: DustTransfromsHistoryParams = {
      from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      page: fetchPage,
      limit: 10,
      sub_account_id: subAccountId,
    };

    if (currentTransferSymbol !== 'Все') {
      fetchParams.symbol = currentTransferSymbol;
    } else {
      delete fetchParams.symbol;
    }

    if (startDate && endDate) {
      dispatch(fetchDustTransformsHistory(fetchParams));
    }
  }, [startDate, endDate, fetchPage, subAccountId, currentTransferSymbol]);

  useEffect(() => () => {
    handleClearDustTransformsHistory();
  }, []);

  useLayoutEffect(() => {
    const dustTransformsHistoryColumnOrderLocalStorage = getFromLocalStorage('dustTransformsHistoryColumnOrder', null);

    if (dustTransformsHistoryColumnOrderLocalStorage) {
      setColumnOrder(dustTransformsHistoryColumnOrderLocalStorage);
    }
  }, []);

  return (
    <section className={styles.dustTransformsHistory}>
      <div className={styles.title}>
        <h2>История конвертаций</h2>
      </div>

      <header className={styles.header}>
        <DataPickerRange whiteMode={theme === EThemeMode.LIGHT} removeData={handleClearDustTransformsHistory} setPage={setFetchPage} setDateRange={setDateRange} dateRange={dateRange} />

        <InputForm minWidth="150px" maxWidth="150px" titleColor="white" title="Актив">
          { CONVERSION_ASSETS ? (
            <Select
              isActiveSearch={false}
              isSelectValue={(
                <div className={styles.selectItem}>
                  <span className="short-name">{currentTransferSymbol}</span>
                </div>
              )}
            >
              {
                ['Все', ...CONVERSION_ASSETS].map((asset) => (
                  <div
                    key={asset}
                    className={styles.selectItem}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleSetCurrentTransferSymbol(String(asset))}
                    onKeyDown={() => handleSetCurrentTransferSymbol(String(asset))}
                  >
                    <span className="short-name">{asset}</span>
                  </div>
                ))
              }
            </Select>
          ) : <SelectSkeleton darkStyle />}
        </InputForm>

        <div className={styles.buttons}>
          <Tooltip withArrow label="Скачать историю" position="left" offset={9}>
            <ExcelBtnIconSvg />
          </Tooltip>
          <Button onClick={handleResetFilter} maxWidth="100px" background={theme === EThemeMode.DARK ? 'white' : 'black'}> параметры</Button>
        </div>
      </header>

      <div ref={tableContainerRef} className={styles.historyTableWrapper}>
        <DragDropContext onDragEnd={onDragEnd}>
          <table className={styles.table}>
            <thead>
              <Droppable droppableId="columns" direction="horizontal">
                {(provided, snapshot) => (
                  <tr
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      background: snapshot.isDraggingOver ? (theme === 'light' ? '#131313' : '#474747') : (theme === 'light' ? '#131313' : '#474747'),
                    }}
                    className={snapshot.isDraggingOver ? styles.dragging : styles.notDragging}
                  >
                    {columnOrder.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index}>
                        {(provided, snapshot) => (
                          <th
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? 'transparent' : (theme === 'light' ? '#131313' : '#474747'),
                              color: snapshot.isDragging ? 'var(--color-main)' : '#BCBCBC',
                              display: snapshot.isDragging ? 'flex' : '',
                              width: column.key === 'checkbox' ? '50px' : 'auto',
                            }}
                            className={snapshot.isDragging ? styles.dragging : styles.notDragging}
                          >
                            <div
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => handleSort(column.key as SortFieldDustTransformsHistory)}
                              onClick={() => handleSort(column.key as SortFieldDustTransformsHistory)}
                              className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                            >
                              {column.label}
                              <SortIconSvg />
                            </div>
                          </th>
                        )}
                      </Draggable>
                    ))}
                    { provided.placeholder}
                  </tr>
                )}
              </Droppable>
            </thead>
            {sortedDustTransformsHistory && sortedDustTransformsHistory.length > 0 && (
              <tbody>
                {sortedDustTransformsHistory.map((history) => (
                  <tr key={history.id} className="history-item">
                    {columnOrder.map((column) => (
                      <td key={column.key}>
                        {column.key === 'created_at' && (
                          <div className={styles.tdWrapper}>
                            {moment(history.created_at).format('DD.MM.YYYY HH:mm')}
                          </div>
                        )}
                        {column.key === 'commission' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible && history.to_asset.symbol === 'USDT' && '≈ '}
                            {!balanceVisible ? toFixedDecimalPrecision(history.commission) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {column.key === 'income_quantity' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible && history.to_asset.symbol === 'USDT' && '≈ '}
                            {!balanceVisible ? toFixedDecimalPrecision(history.income_quantity) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {column.key === 'to_asset' && (
                          <div className={styles.tdWrapper}>
                            {history.to_asset.symbol}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </DragDropContext>

        {statusDustTransformsHistory === EStatus.loading && sortedDustTransformsHistory && sortedDustTransformsHistory.length === 0 && (
          <div className={styles.loadingWrapper}>
            <LoadingOverlay
              className="loader"
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
        {statusDustTransformsHistory === EStatus.success && sortedDustTransformsHistory && sortedDustTransformsHistory.length === 0 && (
          <div className={styles.emptyWrapper}>
            <Empty>
              Нет истории <br /> за выбранный период
            </Empty>
          </div>
        )}
        {statusDustTransformsHistory === EStatus.rejected && (
          <Empty horizontal error>
            Произошла внутренняя ошибка <br />при получении данных
          </Empty>
        )}
        {statusDustTransformsHistory === EStatus.loading && sortedDustTransformsHistory && sortedDustTransformsHistory.length > 0 && (
          <div className={styles.loadingItemWrapper}>
            <LoadingOverlay
              className={`loader ${styles.loader}`}
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
      </div>
      <div />
    </section>
  );
});

export default DustTransformsHistoryTable;
