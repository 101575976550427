export const getPasswordStrength = (password: string) => {
  let strength = 0;

  if (password.match(/[a-z]+/)) {
    strength += 0.5;
  }

  if (password.match(/[A-Z]+/)) {
    strength += 0.5;
  }

  if (password.match(/[0-9]+/)) {
    strength += 0.5;
  }

  if (password.match(/[$@#&!]+/)) {
    strength += 0.5;
  }

  if (password.length >= 8) {
    strength += 0.5;
  }

  return Math.ceil(strength);
};

export const getQualityByStrength = (strenght: number) => {
  let passwordQuality = '';

  if (strenght <= 1) {
    passwordQuality = 'Слабый';
  } else if (strenght === 2) {
    passwordQuality = 'Средний';
  } else {
    passwordQuality = 'Надежный';
  }

  return passwordQuality;
};

export const getSlideText = (slideNumber: number) => {
  switch (slideNumber) {
  case 0:
    return 'first';
  case 1:
    return 'second';
  case 2:
    return 'third';
  case 3:
    return 'fourth';
  default:
    throw new Error('Invalid slide number');
  }
};
