import {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import { toFixedDecimalPrecision } from 'src/shared/libs/helpers/helper.lib';
import { Instrument } from '../../model/types';
import './calculator.scss';

  interface ICalculatorProps {
    instrument: Instrument,
    setChoosenBracket: (value: number[]) => void,
  }

const Calculator = ({ instrument, setChoosenBracket }:ICalculatorProps) => {
  const { brackets } = instrument;
  const { symbol } = instrument.instrument;
  const maxLeverage = brackets.reduce((max, current) => Math.max(max, current[0]), 0);
  const sliderContainerRef = useRef<HTMLDivElement>(null);
  const [choosenLeverage, setChoosenLeverage] = useState(instrument.choosen_bracket);

  const [leverageText, setLeverageText] = useState(String(choosenLeverage[0]));

  const [filledPartProgress, setFilledPartProgress] = useState(0);
  const [sliderPosition, setSliderPosition] = useState({ x: 0, y: 0 });

  const getStopLines = (data: typeof brackets) => {
    const distance = 100 / (data.length - 1);

    return (
      <div className="stops">
        { data.slice().reverse().map((e, index) => <div key={index} style={{ left: `${distance * index}%` }}><span>x{e[0]}</span></div>) }
      </div>
    );
  };

  const getBracketByXPosition = (x: number) => {
    const sliderWidth = sliderContainerRef.current ? sliderContainerRef.current.offsetWidth : 0;
    const distanceBetweenBrackets = sliderWidth / (brackets.length - 1);

    const index = brackets.findIndex((e, index) => distanceBetweenBrackets * index >= x);
    const prevBracket = brackets[brackets.length - index];
    const bracket = brackets[brackets.length - 1 - index];

    if (!prevBracket) {
      return bracket;
    }

    const coefficient = (x / distanceBetweenBrackets) * (bracket.length - 1);
    const fraction = coefficient % 1;

    return fraction < 0.5 ? prevBracket : bracket;
  };

  const getDistanceToBracket = (bracket: typeof brackets[0]) => {
    const sliderWidth = sliderContainerRef.current ? sliderContainerRef.current.offsetWidth : 0;
    const distanceBetweenBrackets = sliderWidth / (brackets.length - 1);
    const bracketIndex = brackets.findIndex((e) => bracket[0] >= e[0]);

    return distanceBetweenBrackets * (brackets.length - bracketIndex - 1);
  };

  const calculateSliderPosition = (newLeverage: number) => {
    try {
      const sliderWidth = sliderContainerRef.current ? sliderContainerRef.current!.offsetWidth : 0;

      const currentRangeIndex = brackets.findIndex((e) => newLeverage >= e[0]);
      const currentRangeLeverage = brackets[currentRangeIndex][0];
      const nextRangeBracket = brackets[currentRangeIndex - 1];

      if (!nextRangeBracket) {
        setFilledPartProgress(sliderWidth);

        return {
          x: sliderWidth,
          y: 0,
        };
      }

      const distanceBetweenBrackets = sliderWidth / (brackets.length - 1);
      const differenceBetweenLeverages = distanceBetweenBrackets / (nextRangeBracket[0] - currentRangeLeverage);

      let distanceForSlider = (((brackets.length - 1) - currentRangeIndex) * distanceBetweenBrackets);
      const distanceForFilledPart = distanceForSlider + (differenceBetweenLeverages * (newLeverage - currentRangeLeverage));

      if (newLeverage !== currentRangeLeverage) {
        distanceForSlider += distanceBetweenBrackets;
      }

      setFilledPartProgress(distanceForFilledPart);

      return {
        x: distanceForSlider,
        y: 0,
      };
    } catch (error) {
      console.error('Error calculating slider position:', error);
      return {
        x: 0,
        y: 0,
      }; // Возвращаем значения по умолчанию в случае ошибки
    }
  };

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    const bracket = getBracketByXPosition(data.x);
    const distance = getDistanceToBracket(bracket);

    setFilledPartProgress(distance);
    setChoosenLeverage(bracket);
  };

  const handleStop = (e: DraggableEvent, data: DraggableData) => {
    const bracket = getBracketByXPosition(data.x);
    const distance = getDistanceToBracket(bracket);

    setChoosenLeverage(bracket);
    setLeverageText(String(bracket[0]));
    setSliderPosition({ x: distance, y: 0 });
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;

    if (target.classList.contains('slider')) {
      return;
    }

    const bracket = getBracketByXPosition(e.nativeEvent.offsetX);
    const distance = getDistanceToBracket(bracket);

    setFilledPartProgress(distance);
    setSliderPosition({ x: distance, y: 0 });

    setChoosenLeverage(bracket);
    setLeverageText(String(bracket[0]));
  };

  const calculateAndSetSliderPosition = (leverage: number) => setSliderPosition(calculateSliderPosition(leverage));

  const localSetChoosenValue = (value: string) => {
    const newValue = parseFloat(value);
    const maxBracket = brackets[0];
    const minBracket = brackets.at(-1)!;

    if (newValue > brackets[0][0]) {
      setChoosenLeverage(maxBracket);
      setLeverageText(String(newValue));
      calculateAndSetSliderPosition(brackets[0][0]);
      return;
    }

    if (newValue < minBracket[0]) {
      setChoosenLeverage(minBracket);
      setLeverageText(String(newValue));
      calculateAndSetSliderPosition(minBracket[0]);
      return;
    }

    let indexNext = -1;

    for (let i = 0; i < brackets.length; i += 1) {
      const [leverage] = brackets[i];

      if (newValue <= leverage) {
        indexNext = i;
      }
    }

    setLeverageText(value);
    setChoosenLeverage([newValue, brackets[indexNext][1]]);
    calculateAndSetSliderPosition(newValue);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    const pattern = /^\s*([0-9]+([.,][0-9]{0,2})?)?\s*$/;

    if (!pattern.test(value)) return;

    if (!instrument.step) {
      // Remove non-digit characters
      value = value.replace(/\D/g, '');
    }

    if (value === '') value = '1';
    if (value.length > 5) return;

    setLeverageText(value);
    localSetChoosenValue(value);
  };

  const initHandler = () => {
    calculateAndSetSliderPosition(instrument.choosen_bracket[0]);
  };

  const nextLeverage = (targetLeverage: number, bracketss: number[][]) => {
    // Сортируем массив по возрастанию плеча для более эффективного поиска
    const brackets = bracketss.slice().sort((a, b) => a[0] - b[0]);

    for (let i = 0; i < brackets.length; i += 1) {
      const [lever, sum] = brackets[i];
      if (lever >= targetLeverage) {
        return [lever, sum];
      }
    }

    // Если не найдено подходящего плеча, возвращаем первый элемент массива
    return brackets[0];
  };

  const prevLeverage = (targetLeverage: number, bracketss: number[][]) => {
    // Сортируем массив по убыванию плеча для более эффективного поиска
    const brackets = bracketss.slice().sort((a, b) => b[0] - a[0]);

    for (let i = 0; i < brackets.length; i += 1) {
      const [lever, sum] = brackets[i];
      if (lever <= targetLeverage) {
        return [lever, sum];
      }
    }

    // Если не найдено подходящего плеча, возвращаем последний элемент массива
    return brackets[brackets.length - 1];
  };

  const handleLocalSetChoosenValue = (value: string, type: 'plus' | 'minus') => {
    if (type === 'minus') {
      const leverage = prevLeverage(Number(value), brackets);

      if (leverage) {
        localSetChoosenValue(String(leverage[0]));
      }
    }

    if (type === 'plus') {
      const leverage = nextLeverage(Number(value), brackets);

      if (Number(value) <= instrument.brackets[0][0] && leverage) {
        localSetChoosenValue(String(leverage[0]));
      }
    }
  };

  useEffect(() => {
    const [first, second] = choosenLeverage;
    const fixedFirst = parseFloat(toFixedDecimalPrecision(String(first), 2));
    const choosenLeverageFixed = [fixedFirst, second];

    setChoosenBracket(choosenLeverageFixed);
  }, [choosenLeverage]);

  useEffect(() => {
    window.addEventListener('load', initHandler);
    window.addEventListener('resize', initHandler);

    return () => {
      window.removeEventListener('load', initHandler);
      window.removeEventListener('resize', initHandler);
    };
  }, []);

  useEffect(() => {
    setChoosenLeverage(instrument.choosen_bracket);
    calculateAndSetSliderPosition(instrument.choosen_bracket[0]);
    setLeverageText(String(instrument.choosen_bracket[0]));
  }, [brackets]);

  return (
    <div className="calculator">
      <div className="calculator-container">
        <div className="instrument-name">
          <h4 className="instrument-name-title">{symbol.toUpperCase()}</h4>
          <p className="max-leverage">Макс. плечо: <span>{String(maxLeverage).replace('.', ',')}</span></p>
        </div>
        <div className="input-container">
          <button onClick={() => handleLocalSetChoosenValue(String(choosenLeverage[0] - 1), 'minus')}>-</button>
          <input type={instrument.step ? 'number' : 'text'} onChange={handleOnChange} value={leverageText} />
          <button onClick={() => handleLocalSetChoosenValue(String(choosenLeverage[0] + 1), 'plus')}>+
          </button>
        </div>

        <div ref={sliderContainerRef} className="slider-container">
          { getStopLines(brackets) }
          <div
            onClick={handleClick}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            className="slider-line-wrapper"
          >
            <div
              className="slider-line"
              onClick={handleClick}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              <div style={{ width: filledPartProgress }} className="filled-part" />

              <Draggable
                axis="x"
                bounds="parent"
                onDrag={handleDrag}
                onStop={handleStop}
                position={sliderPosition}
              >
                <div className="slider" />
              </Draggable>
            </div>
          </div>
        </div>

        <div className="max-trade-sum">
          <p>Максимальная позиция при текущем <br />
            кредитном плече:
          </p>
          <h1>{choosenLeverage[1].toLocaleString()} USDT</h1>
        </div>

      </div>
    </div>
  );
};

export default Calculator;
