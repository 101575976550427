import Button from 'src/shared/ui/button/button/button';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';
import QRCodeGenerator from 'src/entities/qr-code/qr-code';

import { ModalSteps } from 'src/shared/types/global-types';
import styles from './connection-ga-step-one.module.scss';

interface IConnectionGaStepOne extends ModalSteps {
    uri: string | undefined;
    secretKey: string | undefined;
    disconectedGA: () => void;
    nextStepAction: () => void;
  }

export const ConnectionGaStepOne = ({
  uri, secretKey, disconectedGA, nextStepAction, step, setStep,
}: IConnectionGaStepOne) => (step === 1 ? (
  <div className={styles.gaModalWrapper}>
    <p className={styles.gaQrInfo}>
      Отсканируйте <strong>QR-код</strong> с этой страницы через установленное мобильное приложение. Если не получается отсканировать QR-код, введите код вручную в приложении:
    </p>
    <QRCodeGenerator qrText={uri || ''} />
    <div className={styles.gaKeyWrapper}>
      <p className={styles.gaKey}>{secretKey}</p>
      <CopyIcon value={secretKey} />
    </div>

    <div className={styles.gaModalActions}>
      <Button onClick={disconectedGA} background="black">Отменить</Button>
      <Button onClick={nextStepAction} background="green">Далее</Button>
    </div>
  </div>
) : null);
