export const ERROR_MSG_LIST: Record<string, string> = {
  NO_ASSET_WITH_THIS_SYMBOL: 'Нет активов с этой монетой',
  BALANCE_QUANTITY_LESS_THAN_PASSED_QUANTITY: 'Недостаточно средств',
  USER_DOESNOT_HAVE_PERMISSION_FOR_THIS_ACTION: 'Введённые средства проходят AML-проверку, переводы временно недоступны. Для подробностей обратитесь в тех. поддержку.',
  TRANSFER_BETWEEN_EQUAL_ACCOUNTS: 'Перевод между равными счетами невозможен',
  NO_SUB_ACCOUNT_ASSET_LOCKED_QUANTITY: 'Нет доступных средств для первода',
  INSUFFICIENT_BALANCE_FOR_TRANSFER: 'Недостаточно средств для перевода. Закройте фьючерсные позиции или уменьшите сумму перевода.',
  NO_SUB_ACCOUNT_ASSET: 'Недостаточно средств',
  DEFAULT: 'Произошла внутренняя ошибка',
};

export const EXCHANGE_TRANSFERS_LIMIT: Record<string, string> = {
  Binance: '0.00000001',
  Bybit: '0.0001',
};
