import { useNavigate } from 'react-router-dom';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import { ReactComponent as HamsterIconSvg } from 'src/shared/assets/images/hamster-404.svg';

import Button from 'src/shared/ui/button/button/button';
import './error-page.scss';

const pageTitle = 'Страница не найдена (404)';

const Code404Page = () => {
  const navigate = useNavigate();
  useChangePageTitle(pageTitle);

  return (
    <div className="error-page">
      <div className="error-page-wrapper">
        <HamsterIconSvg />
        <div className="wrapper-info">
          <h1>Страница не найдена</h1>
          <Button onClick={() => navigate('/trading')} background="orange">На главную</Button>
        </div>
      </div>
    </div>

  );
};

export default Code404Page;
