import {
  useLayoutEffect, useRef, useState, useEffect, FormEventHandler,
} from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import qs from 'qs';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { EKycStatus } from 'src/app/store/slices/user/types';
import { selectKycVerify, selectUser } from 'src/app/store/slices/user/selectors';
import { store, useAppDispatch } from 'src/app/store/store';
import { removeVerifications } from 'src/app/store/slices/verifications/slice';
import { EVerificationType, Verification } from 'src/app/store/slices/verifications/types';
import {
  Confirm, VerificationConfirm, VerificationsSendEmailCode, VerificationsSendPhoneCode, VerificationsSendWhatsAppCode, fetchVerifications, fetchVerificationsConfirm, fetchVerificationsSendEmailCode, fetchVerificationsSendPhoneCode, fetchVerificationsSendWhatsAppCode,
} from 'src/app/store/slices/verifications/thunks';
import { selectVerifications } from 'src/app/store/slices/verifications/selectors';
import {
  ChangeConnectedGAParams,
  ChangePassword,
  ChangePhoneNumberParams, LoginVerificationsParams, fetchChangeConnectedGA, fetchChangePassword, fetchChangeSmsType, fetchChangeUserPhoneNumber, fetchLoginVerifications, fetchSaveUserOldPhoneNumber,
} from 'src/app/store/slices/user/thunks';
import {
  activeGA, addLoginVerificationType, removeConnectedGALink, setTypeSmsCode,
} from 'src/app/store/slices/user/slice';

import Hint from 'src/shared/ui/help/hint';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import Toogler from 'src/shared/ui/toogler/toogler';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputCode from 'src/shared/ui/input/input-code/input-code';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import Delimiter from 'src/shared/ui/delimiter/delimiter/delimiter';
import InputPassword from 'src/pages/auth/components/inputs-auth/input-password';

import { handleSupportTelegram, hideEmail } from 'src/shared/libs/helpers/helper.lib';
import { SMS_TYPES, VERIFICATION_TYPES } from 'src/shared/constants/constants';
import {
  EStatus, LoginVerificationType, Nullable, SendTypeCode,
} from 'src/shared/types/global-types';
import { ReactComponent as PhoneIconSvg } from 'src/shared/assets/images/profile/phone.svg';
import { ReactComponent as EnvelopeIconSvg } from 'src/shared/assets/images/profile/envelope.svg';
import { ReactComponent as PasswordIconSvg } from 'src/shared/assets/images/lock.svg';
import { ReactComponent as AuthenticatorIconSvg } from 'src/shared/assets/images/profile/authenticator.svg';

import Passport from 'src/entities/passport/password';
import useMobile from 'src/shared/libs/hooks/useMobile';
import InputAction from 'src/pages/auth/components/inputs-auth/input-action';
import KycModaLView from 'src/entities/kyc-modal-viev/kyc-modal-view';

import WalletTable from '../../../wallet-table/wallet-table';
import InputTextWithIcon from '../../../../../../shared/ui/input/input-text-with-icon/input-text-with-icon';
import WalletTableAdaptive from '../../../wallet-table/adaptive/wallet-table-adaptive';

import { ConnectionGaStepOne } from '../../../connection-ga-modal-steps/step-one/connection-ga-step-one';
import { ConnectionGaStepTwo } from '../../../connection-ga-modal-steps/step-two/connection-ga-step-two';
import { ConnectionGaStepThree } from '../../../connection-ga-modal-steps/step-three/connection-ga-step-three';
import { ConnectionGaStepFour } from '../../../connection-ga-modal-steps/step-four/connection-ga-step-four';
import './security-slide.scss';

const schema = yup.object().shape({
  oldPassword: yup.string().required('Обязательное поле'),
  newPassword: yup.string().required('Обязательное поле')
    .test('password', 'Пароль должен содержать минимум 8 символов', (value) => value.length >= 8)
    .test('lowercase', 'Пароль должен содержать буквы нижнего регистра (a–z)', (value) => /[a-z]/.test(value))
    .test('uppercase', 'Пароль должен содержать буквы верхнего регистра (A–Z)', (value) => /[A-Z]/.test(value))
    .test('digits', 'Пароль должен содержать цифры (0-9)', (value) => /\d/.test(value))
    .test('specialChars', 'Пароль должен содержать специальные символы (например, !@#$%^&*)', (value) => /[!@#$%^&*]/.test(value)),
  reenterPassword: yup.string()
    .required('Обязательное поле')
    .oneOf([yup.ref('newPassword')], 'Пароли не совпадают'),
});

type ChangePasswordForm = {
  oldPassword: string,
  newPassword: string,
  reenterPassword: string,
}
const SecuritySlide = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMobile();

  const { setAlertMessage } = useAlert();
  const {
    user,
    settings,
    statusGA,
    kycStatus,
    statusPhone,
    connectedGALink,
    statusChangePassword,
  } = useSelector(selectUser);
  const verifyKYC = useSelector(selectKycVerify);
  const { verification, statusConfirm, status: statusVerify } = useSelector(selectVerifications);
  const verifyFormRef = useRef<HTMLFormElement>(null);
  const codeSmsFormRef = useRef<HTMLFormElement>(null);

  const [openedVerify, { open: openVerify, close: closeVerify }] = useDisclosure(false);
  const [openedGAVerify, { open: openVerifyGA, close: closeVerifyGA }] = useDisclosure(false);
  const [openedSmsVerify, { open: openSmsVerify, close: closeSmsVerify }] = useDisclosure(false);
  const [openedKYCVerify, { open: openVerifyKYC, close: closeVerifyKYC }] = useDisclosure(false);
  const [openedChangePassword, { open: openChangePassword, close: closeChangePassword }] = useDisclosure(false);

  const [editInput, setEditInput] = useState<'phone' | 'savePhone' |'discrod' | 'telegram' | '2FaLogin' | '2FaToggle' | 'GA' | 'changePassword' | ''>('');

  const form = useForm<ChangePasswordForm>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      reenterPassword: '',
    },
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, formState } = form;
  const { errors } = formState;

  // verify modal from states
  const [codeEmail, setCodeEmail] = useState('');
  const [codePhone, setCodePhone] = useState('');
  const [codeGA, setCodeGA] = useState('');

  const [verifyInputError, setVerifyInputError] = useState({
    emailError: false,
    phoneError: false,
    gaError: false,
  });

  const [emailValue, setEmailValue] = useState('');
  const [password, setPassword] = useState('**************');
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneChange, setPhoneChange] = useState(true);
  const [phoneChangeStatus, setPhoneChangeStatus] = useState(false);
  const [phoneChangeVerifyStatus, setPhoneChangeVerifyStatus] = useState(false);
  const [changePasswordStatus, setChangePasswordStatus] = useState(false);
  const [passwordChange, setPasswordChange] = useState(true);
  const [phoneValueTimer, setPhoneValueTimer] = useState(false);
  const [changePasswordTimer, setChangePasswordTimer] = useState(false);

  const [connectedGAStatus, setConnectedGAStatus] = useState(false);
  const [gaTimer, setGaTimer] = useState(false);
  const [currentSmsType, setCurrentSmsType] = useState<Nullable<SendTypeCode>>(null);
  const [smsTypeTimer, setSmsTypeTimer] = useState(false);

  // 2FA на вход в Личный кабинет
  const [selectedLoginType, setSelectedLoginType] = useState('');
  const [disabledToggler, setDisabledToggler] = useState(false);
  const [gaModalStep, setGaModalStep] = useState(1);

  const handleFetchVerification = async (verificationType: EVerificationType) => {
    let result: string | boolean = false;

    const { payload } = await dispatch(fetchVerifications(verificationType));
    const responseVerification = payload as Verification;

    if (payload === 'TOO_MANY_REQUESTS') {
      result = payload;
      return result;
    }

    if (responseVerification && user) {
      const sendPhoneCodeParams: VerificationsSendPhoneCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: responseVerification.verification_id,
      };

      if (responseVerification.methods.includes('phone')) {
        const { payload } = await dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));

        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('whatsapp')) {
        const { payload } = await dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));

        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('email')) {
        dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('ga')) {
        if (payload) {
          result = true;
        }
      }
    } else {
      setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
    }

    return result;
  };

  const clearVerifyInputs = () => {
    setCodeEmail('');
    setCodePhone('');
    setCodeGA('');
  };

  const handleChangeSmsType = async (codeType: SendTypeCode) => {
    if (settings?.type_sms_code === codeType.type) return;

    setCurrentSmsType(codeType);

    const { payload } = await dispatch(fetchChangeSmsType(codeType.type));

    if (payload === true) {
      dispatch(setTypeSmsCode(codeType.type));
      setAlertMessage('Тип уведомления изменен', 'success');
    } else if (payload === 'TOO_MANY_REQUESTS') {
      setAlertMessage('Слишком много попыток. Повторите через 15 секунд', 'warning');
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const handleCloseVerify = () => {
    if (statusConfirm === EStatus.loading) return;

    dispatch(removeVerifications());
    clearVerifyInputs();
    setVerifyInputError({
      emailError: false,
      phoneError: false,
      gaError: false,
    });
    closeVerify();
  };

  const handleOpenKYC = () => {
    if (!user) return;

    if ((kycStatus === EKycStatus.CREATED || kycStatus === EKycStatus.NOT_VERIFIED) && user && !user.is_verified) {
      openVerifyKYC();
    }
  };

  const handleCloseChangePassword = () => {
    form.reset({
      oldPassword: '',
      newPassword: '',
      reenterPassword: '',
    });
    closeChangePassword();
    dispatch(removeVerifications());
  };

  const handleCloseSmsVerify = () => {
    setCodePhone('');
    setPhoneChange(true);
    setVerifyInputError({
      emailError: false,
      phoneError: false,
      gaError: false,
    });
    closeSmsVerify();
  };

  const handleCloseVerifyGA = () => {
    closeVerifyGA();
    dispatch(removeConnectedGALink());
    handleOpenKYC();
  };

  const handleNextStepVerifyGa = () => {
    setGaModalStep(2);
  };

  const handleFetchCodeSms = () => {
    if (user && verification && settings) {
      if (settings.type_sms_code === 'phone') {
        const sendPhoneCodeParams: VerificationsSendPhoneCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };
        dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));
      } else {
        const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };
        dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));
      }
    }
  };

  const handleSendCodeNewNumber = () => {
    if (user && verification && settings) {
      const sendPhoneCodeParams: VerificationsSendPhoneCode = {
        phoneNumber: phoneValue,
        verification_id: verification.verification_id,
      };
      dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));
    }
  };

  const handleFetchCodeEmail = () => {
    if (user && verification) {
      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: verification.verification_id,
      };

      dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
    }
  };

  const onSubmitSaveNewPhone: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (verification && settings) {
      setPhoneChangeStatus(true);

      const verificationParams:VerificationConfirm = {
        confirm: {
          sms_verification_code: Number(codePhone),
        },
        verification_id: verification.verification_id,
      };

      const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

      if (responseConfirm.payload) {
        const changePhoneNumberParams: ChangePhoneNumberParams = {
          phone_number: phoneValue,
          verification_id: verification.verification_id,
        };

        setVerifyInputError((prev) => (
          { ...prev, phoneError: false }
        ));

        const responseChangePhone = await dispatch(fetchChangeUserPhoneNumber(changePhoneNumberParams));

        if (responseChangePhone.payload) {
          setPhoneChange(true);
          setPhoneChangeStatus(false);
          setCodePhone('');

          setPhoneValueTimer(false);
          localStorage.removeItem('changePhoneValueTimer');

          closeSmsVerify();
          setAlertMessage('Данные успешно изменены', 'success');
          setVerifyInputError((prev) => (
            { ...prev, phoneError: false }
          ));
          dispatch(removeVerifications());
        } else {
          setPhoneValueTimer(false);
          localStorage.removeItem('changePhoneValueTimer');

          dispatch(removeVerifications());
          setPhoneChangeStatus(false);
          setAlertMessage('Произошла внутренняя ошибка', 'error');
        }
      } else {
        setPhoneChangeStatus(false);
        setVerifyInputError((prev) => (
          { ...prev, phoneError: true }
        ));
      }
    }
  };

  const onSavePhone = async () => {
    const phoneNumberRegex = /^\d+$/;

    const formattedPhoneValue = phoneValue.replace(/ /g, '').replace(/\+/g, '');

    if (verification) {
      if (!phoneNumberRegex.test(formattedPhoneValue)) {
        setAlertMessage('Удалите символы и пробелы', 'error');
        return;
      }

      const changePhoneNumberParams: ChangePhoneNumberParams = {
        phone_number: formattedPhoneValue,
        verification_id: verification.verification_id,
      };

      const responseSaveOldNumber = await dispatch(fetchSaveUserOldPhoneNumber(changePhoneNumberParams));

      if (responseSaveOldNumber.payload) {
        setEditInput('savePhone');
        handleSendCodeNewNumber();
        openSmsVerify();
      } else {
        setAlertMessage('Произошла внутренняя ошибка', 'error');
      }
    }
  };

  const handleChangeSetPhone = async () => {
    setPhoneChangeVerifyStatus(true);
    setEditInput('phone');

    const result = await handleFetchVerification(EVerificationType.CHANGE_PHONE_NUMBER);

    if (result === true) {
      setPhoneValueTimer(true);
      setPhoneChangeVerifyStatus(false);
      openVerify();
    } else if (result === 'TOO_MANY_REQUESTS') {
      setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
    } else {
      setPhoneValueTimer(false);
      setPhoneChangeVerifyStatus(false);
    }
  };

  const changePassword = async () => {
    if (!verification) return;

    const changePassword: ChangePassword = {
      updatePassword: {
        old_password: form.getValues().oldPassword,
        new_password: form.getValues().newPassword,
      },
      verification_id: verification.verification_id,
    };

    const { payload } = await dispatch(fetchChangePassword(changePassword));

    if (payload === true) {
      closeChangePassword();
      form.reset();
      setAlertMessage('Пароль успешно изменен', 'success');
    } else if (payload === 'INVALID_PASSWORD') {
      form.setError('oldPassword', {
        type: 'manual',
        message: 'Некорректный старый пароль',
      });
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
    setChangePasswordTimer(false);
    localStorage.removeItem('changePasswordTimer');
  };

  const onSubmitChangePasswordVerification = async (data: ChangePasswordForm) => {
    closeChangePassword();
    setChangePasswordStatus(true);
    setEditInput('changePassword');

    const result = await handleFetchVerification(EVerificationType.CHANGE_PASSWORD);

    if (result === true) {
      setChangePasswordStatus(false);
      setChangePasswordTimer(true);
      openVerify();
    } else if (result === 'TOO_MANY_REQUESTS') {
      setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
    } else {
      setChangePasswordStatus(false);
    }
  };

  const handgeChangeLoginVarifications = async (loginVerificationType: LoginVerificationType) => {
    setEditInput('2FaLogin');
    setDisabledToggler(true);

    if (loginVerificationType.type === 'phone' && settings) {
      setSelectedLoginType(settings.type_sms_code);
    } else {
      setSelectedLoginType(loginVerificationType.type);
    }

    const { verification } = store.getState().verifications;

    if (!verification?.verification_id) {
      const result = await handleFetchVerification(EVerificationType.CHANGE_LOGIN_VERIFICATIONS);

      if (result === true) {
        openVerify();
        setDisabledToggler(false);
      } else if (result === 'TOO_MANY_REQUESTS') {
        setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
        setDisabledToggler(false);
      } else if (typeof result === 'string') {
        setAlertMessage(result, 'error');
      } else {
        setDisabledToggler(false);
      }
    }
  };

  const handleSaveLoginVerifications = async () => {
    if (verification && settings) {
      let params: LoginVerificationsParams;

      if (settings.login_verifications.includes(selectedLoginType) && selectedLoginType) {
        const filteredLoginVerification = settings.login_verifications.filter((item) => item !== selectedLoginType);

        params = {
          methods: filteredLoginVerification,
          verification_id: verification.verification_id,
        };
      } else {
        params = {
          methods: settings.login_verifications.concat(selectedLoginType).filter((method) => {
            if (selectedLoginType === 'phone') {
              return method !== 'whatsapp';
            } if (selectedLoginType === 'whatsapp') {
              return method !== 'phone';
            }
            return true;
          }),
          verification_id: verification.verification_id,
        };
      }

      const { payload } = await dispatch(fetchLoginVerifications(params));

      if (payload === true) {
        setEditInput('');
        dispatch(addLoginVerificationType(selectedLoginType));
        setDisabledToggler(false);
      } else if (payload === 'VERIFICATION_NOT_FOUND') {
        // The verification code lifetime has expired. Request a new code
        dispatch(removeVerifications());
        handgeChangeLoginVarifications(VERIFICATION_TYPES[0]);
      } else if (payload === 'TOO_MANY_REQUESTS') {
        setEditInput('');
        setDisabledToggler(false);
        setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
      }
    }
  };

  const connectedGA = async () => {
    if (settings && verification) {
      const connectedGAParams: ChangeConnectedGAParams = {
        is_active_ga: !settings.is_active_ga,
        verification_id: verification.verification_id,
      };

      const response: any = await dispatch(fetchChangeConnectedGA(connectedGAParams));

      if (response.payload === undefined) return;

      if (response.payload.type === 'enabled') {
        openVerifyGA();
        return;
      }
      if (response.payload.type === 'disabled') {
        dispatch(activeGA(false));
        dispatch(removeVerifications());
      } else {
        setAlertMessage('Произошла внутренняя ошибка', 'error');
      }

      setGaTimer(false);
      localStorage.removeItem('changeGAConnect');
    }
  };

  const disconectedGA = async () => {
    if (settings && verification) {
      const connectedGAParams: ChangeConnectedGAParams = {
        is_active_ga: false,
        verification_id: verification.verification_id,
      };

      const response: any = await dispatch(fetchChangeConnectedGA(connectedGAParams));

      if (response.payload === undefined) return;

      if (response.payload.type === 'enabled') {
        openVerifyGA();
        return;
      }
      if (response.payload.type === 'disabled') {
        handleCloseVerifyGA();
      } else {
        setAlertMessage('Произошла внутренняя ошибка', 'error');
      }
    }
  };

  const checkedConnectGA = (loginType: string) => {
    if (loginType === 'ga' && !settings?.is_active_ga) return 'Не подключено';
    return '';
  };

  const handleOnChangeConnectGA = async () => {
    if (settings) {
      setConnectedGAStatus(true);
      setEditInput('GA');

      const changeConnectedGA = settings.is_active_ga ? EVerificationType.DELETE_GA : EVerificationType.CREATE_GA;
      const result = await handleFetchVerification(changeConnectedGA);

      if (result === true) {
        openVerify();
        setConnectedGAStatus(false);
        setGaTimer(true);
      } else if (result === 'TOO_MANY_REQUESTS') {
        setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
      } else {
        setAlertMessage('Произошла внутренняя ошибка!', 'error');
        setConnectedGAStatus(false);
        setGaTimer(false);
      }
    }
  };

  const handleAutoSubmit = async () => {
    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (!responseConfirm.payload === true) {
          if (verification) {
            if (verification.methods.includes('email')) {
              setVerifyInputError((prev) => (
                { ...prev, emailError: true }
              ));
            }
            if (verification.methods.includes('phone')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('whatsapp')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('ga')) {
              setVerifyInputError((prev) => (
                { ...prev, gaError: true }
              ));
            }
          }
        } else if (responseConfirm.payload === 'VERIFICATION_NOT_FOUND') {
          setAlertMessage('Время действия запроса истекло. Пожалуйста, повторите попытку.', 'error');
          closeVerify();
          clearVerifyInputs();
        } else {
          closeVerify();
          clearVerifyInputs();

          if (editInput === 'phone') onSavePhone();
          if (editInput === 'changePassword') changePassword();
          if (editInput === '2FaLogin') handleSaveLoginVerifications();
          if (editInput === 'GA') connectedGA();
        }
      }
    }
  };

  const handleSubmitVerification: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (responseConfirm.payload === true) {
          closeVerify();
          clearVerifyInputs();

          if (editInput === 'phone') onSavePhone();
          if (editInput === 'changePassword') changePassword();
          if (editInput === '2FaLogin') handleSaveLoginVerifications();
          if (editInput === 'GA') connectedGA();
        } else if (responseConfirm.payload === 'VERIFICATION_NOT_FOUND') {
          setAlertMessage('Время действия запроса истекло. Пожалуйста, повторите попытку.', 'error');
          closeVerify();
          clearVerifyInputs();
        } else if (verification) {
          if (verification.methods.includes('email')) {
            setVerifyInputError((prev) => (
              { ...prev, emailError: true }
            ));
          }
          if (verification.methods.includes('phone')) {
            setVerifyInputError((prev) => (
              { ...prev, phoneError: true }
            ));
          }
          if (verification.methods.includes('whatsapp')) {
            setVerifyInputError((prev) => (
              { ...prev, phoneError: true }
            ));
          }
          if (verification.methods.includes('ga')) {
            setVerifyInputError((prev) => (
              { ...prev, gaError: true }
            ));
          }
        }
      }
    }
  };

  const renderVerificationForm = (methods: string[]) => {
    const formElements: React.ReactNode[] = [];

    if (methods.includes('phone')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label="Код из СМС"
            placeholder="Код"
            actionTitle="Отправить код"
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('whatsapp')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label="Код из WhatsApp"
            placeholder="Код"
            actionTitle="Отправить код"
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('email')) {
      formElements.push(
        <div key="email-input" className="verification-input">
          <InputAction
            label="Код из Письма"
            placeholder="Код"
            type="number"
            actionTitle="Отправить код"
            onClick={handleFetchCodeEmail}
            actionActive
            error={verifyInputError.emailError}
            {...{
              value: codeEmail,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodeEmail(e.target.value),
            }}
          />
          <span className="verification-input-text">Код подтверждения будет отправлен на {hideEmail(user?.email)}</span>
        </div>,
      );
    }

    if (methods.includes('ga')) {
      formElements.push(
        <div key="ga-input" className="verification-input">
          <InputForm title="Код Google Authenticator">
            <InputCode onChange={setCodeGA} error={verifyInputError.gaError} />
          </InputForm>
        </div>,
      );
    }

    formElements.push(
      <div key="modal-hint" className="verification-input">
        <Hint
          hintOpen
          position="bottom-start"
          text="Возникли проблемы с верификацией?"
          hintText={(
            <div>
              Код с почты не подходит:
              <br />
              - Возможно, срок действия кода истек, запросите новый код по кнопке - Отправить код
              <br />
              - Убедитесь в корректности почты
              <br />
              <br />
              Код Google Autenticator не подходит:
              <br />
              - Синхронизируйте время между устройствами. Если вы используете GA на Андроид, воспользуйтесь функцией - Синхронизация времени
              <br />
              - Убедитесь что вы вводите код для аккаунта league.broker
              <br />
              <br />
              Если какой то из кодов по прежнему не подходит, обратитесь в тех. поддержку <button onClick={handleSupportTelegram}>Телеграмм</button>
            </div>
          )}
        />
      </div>,
    );

    formElements.push(
      <div key="verify-modal-action" className="verify-modal-action">
        <Button
          disabled={statusConfirm === EStatus.loading}
          loading={statusConfirm === EStatus.loading}
          type="submit"
          onClick={() => {}}
          background="green"
        >Подтвердить
        </Button>
        <Button disabled={statusConfirm === EStatus.loading} onClick={handleCloseVerify} background="gray">Отмена</Button>
      </div>,
    );

    return <form ref={verifyFormRef} className="verification-form-wrapper" onSubmit={handleSubmitVerification}>{formElements}</form>;
  };

  const renderChangePhoneForm = () => (
    <form ref={codeSmsFormRef} className="verification-form-wrapper" onSubmit={onSubmitSaveNewPhone}>
      <InputAction
        label="Код из СМС"
        placeholder="Код"
        actionTitle="Отправить код"
        actionActive
        onClick={handleSendCodeNewNumber}
        type="number"
        error={verifyInputError.phoneError}
        {...{
          value: codePhone,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
        }}
      />

      <div key="verify-modal-action" className="verify-modal-action actions-margin">
        <Button
          maxWidth="175px"
          loading={statusPhone === EStatus.loading || phoneChangeStatus}
          disabled={statusPhone === EStatus.loading || phoneChangeStatus}
          type="submit"
          background="green"
        >Сохранить
        </Button>

        <Button disabled={statusPhone === EStatus.loading || phoneChangeStatus} onClick={handleCloseSmsVerify} background="gray">Отмена</Button>
      </div>
    </form>
  );

  const isVerificationTypeActive = (type: string) => {
    if (!settings) return false;

    let isActive = false;

    if (settings.login_verifications.includes(type)) {
      isActive = true;
    }
    if (type === 'phone' && settings.login_verifications.includes('whatsapp')) {
      isActive = true;
    }

    return isActive;
  };

  useLayoutEffect(() => {
    if (user) {
      setPhoneValue(user.phone_number);
      setEmailValue(user.email);
    }

    if (settings) {
      const foundType = SMS_TYPES.find((item) => item.type === settings.type_sms_code);
      if (foundType) {
        setCurrentSmsType(foundType);
      }
    }
  }, [user, settings]);

  useEffect(() => {
    if (!settings) return;

    const parsedParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const queryTabIndex = parsedParams?.tab;

    if (queryTabIndex === '1' && !settings.is_active_ga && !verification) {
      handleOnChangeConnectGA();
    }

    if (queryTabIndex === '1' && !settings.is_active_ga && verification) {
      connectedGA();
    }
  }, [settings]);

  useEffect(() => {
    if (verification?.verification_id && editInput === '2FaLogin') {
      handleSaveLoginVerifications();
    }
  }, [editInput]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('reload', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (openedVerify) {
      setTimeout(() => {
        if (verifyFormRef.current) {
          const inputElement = verifyFormRef.current[0] as HTMLInputElement;
          inputElement.focus();
        }
      }, 100);
    }
  }, [verifyFormRef.current, openedVerify]);

  useEffect(() => {
    if (verification) {
      const onlyGa = verification.methods.every((method) => method === 'ga') && verification.methods.includes('ga');
      const hasGaAndEmail = verification.methods.includes('ga') && verification.methods.includes('email');
      const hasGaAndPhone = verification.methods.includes('ga') && verification.methods.includes('phone');
      const hasGaAndWhatsapp = verification.methods.includes('ga') && verification.methods.includes('whatsapp');
      const hasEmailAndWhatsapp = verification.methods.includes('email') && verification.methods.includes('whatsapp');
      const hasEmailAndPhone = verification.methods.includes('email') && verification.methods.includes('phone');

      if (onlyGa) {
        if (codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndEmail) {
        if (codeEmail.length === 5 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndPhone) {
        if (codePhone.length === 5 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndWhatsapp) {
        if (codePhone.length === 4 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasEmailAndWhatsapp) {
        if (codeEmail.length === 5 && codePhone.length === 4) {
          handleAutoSubmit();
        }
      }

      if (hasEmailAndPhone) {
        if (codeEmail.length === 5 && codePhone.length === 5) {
          handleAutoSubmit();
        }
      }
    }
  }, [codeGA, codeEmail, codePhone, verification]);

  return (
    <div className="slide security-slide">
      <div className="security-settings">
        <h4 className="title">Настройки безопасности и 2FA</h4>
        <p className="description">
          Настройка методов верификации и двухфакторной аутентификации (2FA) на вход в Личный кабинет,
          <br />
          операции в Личном кабинете и восстановление пароля
        </p>

        <div className="communication-services">
          <InputTextWithIcon
            label="Номер телефона"
            IconContent={PhoneIconSvg}
            value={phoneValue}
            onChange={setPhoneValue}
            placeholder="Номер телефона"
            buttonText="Изменить"
            onEdit={() => setPhoneChange(false)}
            onSave={handleChangeSetPhone}
            isDisabled={phoneChange}
            loading={phoneChangeVerifyStatus}
            disabled={phoneChangeVerifyStatus}
            inputType="string"
            focus
            startTimer={phoneValueTimer}
            stopTimer={setPhoneValueTimer}
            timerId="changePhoneValueTimer"
          />

          <InputForm title="Отправка уведомлений">
            <Select
              disabledSelect={statusVerify === EStatus.loading || smsTypeTimer}
              isActiveSearch={false}
              startTimer={smsTypeTimer}
              stopTimer={setSmsTypeTimer}
              timerId="changeSmsTypeTimer"
              isSelectValue={<span className="sms-type-current">{currentSmsType?.name || 'Не выбрано' }</span>}
            >
              { SMS_TYPES.map((element) => (
                <span
                  key={element.type}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleChangeSmsType(element)}
                  onKeyDown={() => handleChangeSmsType(element)}
                >{element.name}
                </span>
              )) }
            </Select>
          </InputForm>
        </div>

        <InputTextWithIcon
          label="Почта"
          IconContent={EnvelopeIconSvg}
          value={emailValue}
          onChange={setEmailValue}
          placeholder="Почта"
          buttonText="Изменить"
          disabled
          focus
          lockedIcon
        />

        <InputTextWithIcon
          label="Пароль"
          buttonText="Изменить пароль"
          IconContent={PasswordIconSvg}
          value={password}
          onChange={setPassword}
          onEdit={() => openChangePassword()}
          onSave={() => {}}
          loading={changePasswordStatus}
          disabled={changePasswordStatus}
          isDisabled={passwordChange}
          placeholder="Пароль"
          inputType="password"
          startTimer={changePasswordTimer}
          stopTimer={setChangePasswordTimer}
          timerId="changePasswordTimer"
          focus
        />

        <Hint className="profile-hint" warning mediumText text="При изменении пароля, выводы будут ограничены на сутки" />

        <div className="additional-protection">
          <InputTextWithIcon
            IconContent={AuthenticatorIconSvg}
            label="Google Authenticator"
            value=""
            placeholder="Мобильное приложение для 2FA"
            buttonText={settings?.is_active_ga ? 'Отвязать' : 'Привязать'}
            onEdit={handleOnChangeConnectGA}
            onChange={() => {}}
            loading={connectedGAStatus || statusGA === EStatus.loading}
            lockedIcon={settings?.login_verifications.includes('ga')}
            startTimer={gaTimer}
            stopTimer={setGaTimer}
            timerId="changeGAConnect"
          />
          <Hint className="profile-hint" warning mediumText text="Чтобы отвязать Google Authenticator, сперва отключите его в разделе “2FA на вход в Личный кабинет”" />
          {/* <InputTextWithIcon
            IconContent={KeyIconSvg}
            label="Yubikey"
            value=""
            placeholder="Физический ключ безопасности"
            buttonText="Подключить"
            onChange={() => {}}
          /> */}

          <div className="twofa-settings">
            <div className="twofa-settings-item">
              <InputForm title="2FA на вход в Личный кабинет">
                {settings && (
                  <div className="toggler-group">
                    {
                      VERIFICATION_TYPES.map((item) => (

                        <Toogler
                          key={item.type}
                          isActive={isVerificationTypeActive(item.type)}
                          onChange={() => handgeChangeLoginVarifications(item)}
                          labelContent={item.name}
                          isBlocked={(item.type === 'ga' && !settings.is_active_ga)}
                          comment={checkedConnectGA(item.type)}
                          isLoading={disabledToggler}
                        />
                      ))
                    }
                  </div>
                )}
              </InputForm>

            </div>
          </div>
        </div>
      </div>

      <Delimiter />

      <div className="white-wallets">
        <h4 className="title">Кошельки</h4>
        <p className="description">Белый список кошельков нужен для более безопасного и быстрого вывода средств. Когда вывод средств<br />запрашивается на адрес из белого списка, то подтверждение администратора не требуется.</p>
        {!!verifyKYC && !isMobile && <WalletTable />}
        {isMobile && <WalletTableAdaptive />}

        <div className="white-wallets-passport">
          <Passport horizontal visible={!!verifyKYC} />
        </div>
      </div>

      <Modal
        title="Подтвердите действие"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="sm"
        opened={openedVerify}
        onClose={handleCloseVerify}
        closeOnClickOutside={false}
        closeOnEscape={false}
        className="modal-custom-overflow"
      >
        <div>
          {verification && renderVerificationForm(verification.methods)}
        </div>
      </Modal>

      <Modal
        title="Код на новый номер"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="sm"
        opened={openedSmsVerify}
        onClose={handleCloseSmsVerify}
        closeOnClickOutside={false}
        closeOnEscape={false}
        className="modal-custom-overflow"
      >
        <div>
          {renderChangePhoneForm()}
        </div>
      </Modal>

      <Modal
        title="Подключение Google Authenticator"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="md"
        opened={openedGAVerify}
        onClose={handleCloseVerifyGA}
        closeOnClickOutside={false}
        closeOnEscape={false}
        className="modal-custom-overflow"
      >
        <ConnectionGaStepOne
          uri={connectedGALink?.uri}
          secretKey={connectedGALink?.secret_key}
          disconectedGA={disconectedGA}
          nextStepAction={handleNextStepVerifyGa}
          step={gaModalStep}
          setStep={setGaModalStep}
        />
        <ConnectionGaStepTwo step={gaModalStep} setStep={setGaModalStep} />
        <ConnectionGaStepThree step={gaModalStep} setStep={setGaModalStep} stopTimer={setGaTimer} />
        <ConnectionGaStepFour step={gaModalStep} setStep={setGaModalStep} close={handleCloseVerifyGA} />
      </Modal>

      <Modal
        title={<span>Базовая верификация <br /> не пройдена</span>}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="md"
        opened={openedKYCVerify}
        onClose={closeVerifyKYC}
        className="modal-custom-overflow"
      >
        <KycModaLView closeModal={closeVerifyKYC} />
      </Modal>

      <Modal
        title="Изменить пароль"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="md"
        opened={openedChangePassword}
        onClose={handleCloseChangePassword}
        className="modal-custom-overflow"
      >
        <form className="form-change-password" onSubmit={handleSubmit(onSubmitChangePasswordVerification)}>

          <Controller
            name="oldPassword"
            control={control}
            render={({
              field: {
                ref, value, onChange, ...fieldProps
              },
            }) => (
              <InputPassword
                value={value || ''}
                onChange={onChange}
                ref={ref}
                error={!!errors.oldPassword}
                placeholder="Введите пароль"
                label="Старый пароль"
                {...fieldProps}
              />
            )}
          />
          {errors.oldPassword && errors.oldPassword.message && <Hint error text={errors.oldPassword.message} />}

          <Controller
            name="newPassword"
            control={control}
            render={({
              field: {
                ref, value, onChange, ...fieldProps
              },
            }) => (
              <InputPassword
                value={value || ''}
                onChange={onChange}
                ref={ref}
                error={!!errors.newPassword}
                placeholder="Введите пароль"
                label="Новый пароль"
                {...fieldProps}
              />
            )}
          />
          {errors.newPassword && errors.newPassword.message && <Hint error text={errors.newPassword.message} />}

          <Controller
            name="reenterPassword"
            control={control}
            render={({
              field: {
                ref, value, onChange, ...fieldProps
              },
            }) => (
              <InputPassword
                value={value || ''}
                onChange={onChange}
                ref={ref}
                error={!!errors.reenterPassword}
                placeholder="Введите пароль"
                label="Повторите новый пароль"
                {...fieldProps}
              />
            )}
          />
          {errors.reenterPassword && errors.reenterPassword.message && <Hint error text={errors.reenterPassword.message} />}

          <Button type="submit" disabled={statusChangePassword === EStatus.loading} loading={statusChangePassword === EStatus.loading} background="green">Изменить</Button>
        </form>
      </Modal>
    </div>
  );
};

export default SecuritySlide;
