import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { AxiosError, AxiosResponse } from 'axios';
import { Verification, EVerificationType } from './types';

export type VerificationId = {
  verification_id: number
}

export type VerificationsSendEmailCode = {
  email: string
} & VerificationId

export type VerificationsSendWhatsAppCode = {
  phoneNumber: string
} & VerificationId

export type ConfirmGA = {
  ga_verification_code: string,
  secret_key: string
}

export type VerificationConfirmGA = {
  confirm: ConfirmGA
} & VerificationId

export type Confirm = {
  sms_verification_code?: number
  whatsapp_verification_code?: number
  email_verification_code?: number
  ga_verification_code?: string
}

export type VerificationConfirm = {
  confirm: Confirm
} & VerificationId

export type VerificationsSendPhoneCode = {
  phoneNumber: string
} & VerificationId

export const fetchVerifications = createAsyncThunk(
  'verifications/fetchVerifications',
  async (actionName: EVerificationType, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Verification> = await $api.post(ROUTE_PATH.verifications.verification, { action_name: actionName });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchVerificationsConfirm = createAsyncThunk(
  'verifications/fetchVerificationsConfirm',
  async (params: VerificationConfirm, { rejectWithValue }) => {
    try {
      const response = await $api.post<boolean>(ROUTE_PATH.verifications.confirm, params.confirm, {
        headers: {
          'verification-id': params.verification_id,
        },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchVerificationsSendPhoneCode = createAsyncThunk(
  'verifications/fetchVerificationsSendPhoneCode',
  async (params: VerificationsSendPhoneCode, { rejectWithValue }) => {
    try {
      const response = await $api.post<boolean>(ROUTE_PATH.verifications.sendPhoneCode, { phone_number: params.phoneNumber }, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchVerificationsSendEmailCode = createAsyncThunk(
  'verifications/fetchVerificationsSendEmailCode',
  async (params: VerificationsSendEmailCode, { rejectWithValue }) => {
    try {
      const response = await $api.post<boolean>(ROUTE_PATH.verifications.sendEmailCode, { email: params.email }, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchVerificationsSendWhatsAppCode = createAsyncThunk(
  'verifications/fetchVerificationsSendWhatsAppCode',
  async (params: VerificationsSendWhatsAppCode, { rejectWithValue }) => {
    try {
      const response = await $api.post<boolean>(ROUTE_PATH.verifications.sendWhatsAppCode, { phone_number: params.phoneNumber }, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchVerificationsConfirmGA = createAsyncThunk(
  'verifications/fetchVerificationsConfirmGA',
  async (params: VerificationConfirmGA, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<boolean> = await $api.post(ROUTE_PATH.verifications.confirgGA, params.confirm, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);
