const USER_AGREEMENT = 'https://info.league.broker/dokumentaciya/usloviya-ispolzovaniya';
const PRIVACY_POLICY = 'https://info.league.broker/dokumentaciya/politika-konfidencialnosti';
const LEGAL_INFORMATION = 'https://info.league.broker';
const RISK_WARNINGS = 'https://info.league.broker/dokumentaciya/preduprezhdeniya-o-riskakh';
const COOKIE_INFO = 'https://info.league.broker/dokumentaciya/politika-v-otnoshenii-failov-cookie';

const VERIFICATION_FAQ = 'https://info.league.broker/verifikaciya/zachem-nuzhna-verifikaciya';
const VERIFICATION_QUESTIONS_FAQ = 'https://info.league.broker/verifikaciya/chasto-zadavaemye-voprosy-o-verifikacii';

const GA_LINK_APP_STORE = 'https://apps.apple.com/us/app/google-authenticator/id388497605';
const GA_LINK_GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US&pli=1';

const SCREENER_VIDEO_YOUTUBE = 'https://www.youtube.com/watch?v=NSo24Ty4s5c&ab_channel=LeagueofTraders';

const TELEGRAM_BOT_SUPPORT = 'https://t.me/LeagueBroker_Support_bot';
const DISCORD_CHANNEL = 'https://discord.gg/TUP9BzTvfX';

export {
  USER_AGREEMENT,
  PRIVACY_POLICY,
  GA_LINK_APP_STORE,
  GA_LINK_GOOGLE_PLAY,
  LEGAL_INFORMATION,
  SCREENER_VIDEO_YOUTUBE,
  RISK_WARNINGS,
  COOKIE_INFO,
  VERIFICATION_FAQ,
  VERIFICATION_QUESTIONS_FAQ,
  TELEGRAM_BOT_SUPPORT,
  DISCORD_CHANNEL,
};
