import { useLayoutEffect } from 'react';
import { batch, useSelector } from 'react-redux';
import { selectTheme } from 'src/app/store/slices/user/selectors';
import { setThemeMode } from 'src/app/store/slices/user/slice';
import { ChangeTheme, fetchChangeTheme } from 'src/app/store/slices/user/thunks';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { useAppDispatch } from 'src/app/store/store';

export const useTheme = () => {
  const dispatch = useAppDispatch();
  const defaultTheme = localStorage.getItem('appTheme') || EThemeMode.DARK;
  const theme = useSelector(selectTheme) || defaultTheme;

  const setTheme = (mode: EThemeMode) => {
    const params: ChangeTheme = {
      theme: mode,
    };

    batch(() => {
      dispatch(setThemeMode(mode));
      dispatch(fetchChangeTheme(params));
    });
  };

  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);

    localStorage.setItem('appTheme', theme);
  }, [theme]);

  return {
    theme, setTheme,
  };
};
