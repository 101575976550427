import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PasswordStepsState } from './types';

const initialState: PasswordStepsState = {
  passwordLength: false,
  lowercase: false,
  uppercase: false,
  digits: false,
  specialChars: false,
  reenterPassword: false,
};

export const passwordStepsSlice = createSlice({
  name: 'password-steps',
  initialState,
  reducers: {
    updatePasswordSteps: (state, action: PayloadAction<PasswordStepsState>) => ({ ...state, ...action.payload }),
    resetPasswordSteps: (state) => {
      state.passwordLength = false;
      state.lowercase = false;
      state.uppercase = false;
      state.digits = false;
      state.specialChars = false;
      state.reenterPassword = false;
    },
  },
});

export const { updatePasswordSteps, resetPasswordSteps } = passwordStepsSlice.actions;

export const passwordStepsReducer = passwordStepsSlice.reducer;
