import { Link } from 'react-router-dom';
import { VERIFICATION_FAQ, VERIFICATION_QUESTIONS_FAQ } from 'src/shared/constants/app-links';

import DropdownMenu from './dropdown-menu/dropdown-menu';

import './verification-slide.scss';

const VerificationSlide = () => (
  <div className="slide verification-slide">
    <p className="description">Проверка персональных данных</p>

    <DropdownMenu />

    <div className="faq-questions">
      <Link target="_blank" to={VERIFICATION_FAQ}>Зачем нужна верификация?</Link>
      <Link target="_blank" to={VERIFICATION_QUESTIONS_FAQ}>Часто задаваемые вопросы по верификации</Link>
    </div>
  </div>
);

export default VerificationSlide;
