import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { Transfers } from './types';

export const fetchTransfers = createAsyncThunk(
  'transfers/fetchTransfers',
  async (transfers: Transfers, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.transfers, transfers);
      const { data } = response;

      if (data === undefined) throw response;
      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);
