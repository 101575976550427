import { memo } from 'react';
import { Asset, SearchAssetType } from 'src/app/store/slices/assets/types';
import '../../transfers.scss';

type ISelectCurrency = {
    asset: Asset | SearchAssetType | null
    handleSetAsset: (asset: Asset | SearchAssetType) => void
    className: string
}

const SelectCurrency = memo(({ asset, handleSetAsset: handle, className } : ISelectCurrency) => (asset ? (
  <div
    className={className}
    role="button"
    tabIndex={0}
    onKeyDown={() => handle(asset)}
    onClick={() => handle(asset)}
  >
    <img src={asset.image} alt={asset.symbol} />
    <span className="short-name">{asset.symbol}</span>
    <span className="full-name">{asset.name}</span>
  </div>
) : null));

export default SelectCurrency;
