import { useId } from 'react';

import './input-radio.scss';

interface IInputRadioProps {
  labelContent: string;
  groupName: string;
  isActive?: boolean;
  comment?: string;
  onClick?: () => void;
  checked?: boolean
}

const InputRadio = ({
  isActive,
  comment,
  labelContent,
  groupName,
  onClick,
  checked,
}: IInputRadioProps) => {
  const uniqueId = `input-radio-${useId()}`;

  return (
    <div
      className="input-radio"
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <input
        type="radio"
        id={uniqueId}
        name={groupName}
        checked={checked}
        disabled
      />
      <label htmlFor={uniqueId}><p>{labelContent}</p></label>
      { comment && <span className="comment">{comment}</span> }
    </div>
  );
};

export default InputRadio;
