import moment from 'moment';
import { useSelector } from 'react-redux';
import { Drawer } from '@mui/material';
import { useDisclosure } from '@mantine/hooks';
import { LoadingOverlay } from '@mantine/core';
import { useEffect, useLayoutEffect, useState } from 'react';

import { Exchange } from 'src/app/store/slices/exchanges/types';
import { selectRebate } from 'src/app/store/slices/bonus/selectors';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { useAppDispatch } from 'src/app/store/store';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { clearRebateHistory } from 'src/app/store/slices/bonus/slice';
import { getDate, scrollToTop, sortSubAccountsByFavorite } from 'src/shared/libs/helpers/helper.lib';
import { fetchRebateHistory, RebateHistoryParams } from 'src/app/store/slices/bonus/thunks';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import { ReactComponent as FilterIconSvg } from 'src/shared/assets/images/filter.svg';
import Empty from 'src/entities/empty/empty';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import ButtonClose from 'src/shared/ui/button/button-close/button-close';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import RebateItem from './components/rebate-item';
import { allExchanges, allSubAccounts } from '../constants';

import styles from './rebate-history-table.module.scss';

const RebateHistoryTableAdaptive = () => {
  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const [openedTableFilter, { open: openTableFilter, close: closeTableFilter }] = useDisclosure(false);
  const { availableExchanges: exchanges } = useExchange();

  const { rebateHistory, statusRebateHistory } = useSelector(selectRebate);
  const subAccounts = useSelector(selectSubAccounts);

  const [fetchPage, setFetchPage] = useState(1);
  const [currentExchange, setCurrentExchange] = useState<Nullable<Exchange>>(null);
  const [currentSubAccount, setCurrentSubAccount] = useState<Nullable<SubAccountType>>(null);

  const { currentDateAsString, sevenDaysAgoAsString } = getDate();
  const [dateRange, setDateRange] = useState<any>([sevenDaysAgoAsString, currentDateAsString]);
  const [startDate, endDate] = dateRange;

  useIntersectionObserver(fetchPage, rebateHistory?.meta.total_pages || 1, rebateHistory?.items, 'rebate-history-item', setFetchPage, 9);

  const handleResetFilter = () => {
    const currentDate = moment();
    const startDateCurrent = currentDate.clone().subtract(7, 'days');

    const start = startDateCurrent.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end = currentDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const checkStartDate = moment(startDate).format('YYYY-MM-DD');
    const checkEndDate = moment(endDate).format('YYYY-MM-DD');

    if (checkStartDate === moment(start).format('YYYY-MM-DD') && checkEndDate === moment(end).format('YYYY-MM-DD') && currentExchange?.name === 'Все' && currentSubAccount?.user_name === 'Все') return;

    setCurrentExchange(allExchanges);
    setCurrentSubAccount(allSubAccounts);
    setFetchPage(1);
    setDateRange([start, end]);
  };

  const handleClearBonusHistory = () => {
    dispatch(clearRebateHistory());
    setFetchPage(1);
  };

  const handleSetCurrentSubAccount = (subAccount: SubAccountType) => {
    if (subAccount.id === currentSubAccount?.id) return;

    handleClearBonusHistory();
    setCurrentSubAccount(subAccount);
  };

  const handleSetCurrentExchange = (exchange: Exchange) => {
    handleClearBonusHistory();
    setCurrentExchange(exchange);
  };

  const handleSetFetchParams = () => {
    handleClearBonusHistory();
    closeTableFilter();
    scrollToTop(document.body.scrollHeight);
  };

  useEffect(() => {
    if (!currentExchange || !currentSubAccount || openedTableFilter) return;

    const fetchParams: RebateHistoryParams = {
      from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      page: fetchPage,
      limit: 10,
    };

    if (currentSubAccount.user_name !== 'Все') {
      fetchParams.sub_account_id = currentSubAccount.id;
    }

    if (currentExchange.name !== 'Все') {
      fetchParams.exchange_id = currentExchange.id;
    }

    if (startDate && endDate) {
      dispatch(fetchRebateHistory(fetchParams));
    }
  }, [startDate, endDate, fetchPage, currentSubAccount, currentExchange, openedTableFilter]);

  useEffect(() => () => {
    handleClearBonusHistory();
  }, []);

  useLayoutEffect(() => {
    if (exchanges && subAccounts) {
      const [firstExchange] = exchanges;
      const [firstSubAccount] = subAccounts;

      if (firstExchange) {
        setCurrentExchange(allExchanges);
      }
      if (firstSubAccount) {
        setCurrentSubAccount(allSubAccounts);
      }
    }
  }, [exchanges, subAccounts]);

  return (
    <>
      <section className={styles.rebateHistoryTableAdaptive}>
        <header>
          <h3>История <br /> начисления бонусов</h3>
          <FilterIconSvg onClick={openTableFilter} className={styles.filterBtn} />
        </header>

        <div className={styles.historyTableWrapper}>
          {rebateHistory && (
            <ul className={styles.table}>
              { rebateHistory.items.map((rebateHistory) => (
                <li key={rebateHistory.id} className="rebate-history-item">
                  <RebateItem rebateHistory={rebateHistory} />
                </li>
              ))}
            </ul>
          )}
          {statusRebateHistory === EStatus.success && rebateHistory?.items?.length === 0 && (
            <Empty>
              Нет истории <br /> за выбранный период
            </Empty>
          )}
          {statusRebateHistory === EStatus.rejected && (
            <Empty>
              Произошла внутренняя ошибка <br />при получении данных
            </Empty>
          )}
          {statusRebateHistory === EStatus.loading && !rebateHistory && (
            <SelectSkeleton height={164} />
          )}
          {statusRebateHistory === EStatus.loading && rebateHistory && rebateHistory.items.length > 0 && (
            <div className={styles.loadingItemWrapper}>
              <LoadingOverlay
                className={`loader ${styles.loader}`}
                visible
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: '#00C37C', type: 'dots' }}
              />
            </div>
          )}
        </div>
      </section>

      <Drawer className={styles.drawer} onClose={() => closeTableFilter()} anchor="bottom" open={openedTableFilter}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <h3>Фильтры</h3>
            <ButtonClose className={styles.closeBtn} onClick={() => closeTableFilter()} />
          </div>

          <div className={styles.content}>
            <DataPickerRange monthsShown={1} whiteMode={theme === 'light'} removeData={handleClearBonusHistory} setPage={setFetchPage} setDateRange={setDateRange} dateRange={dateRange} />

            <InputForm title="Биржа">
              {exchanges && currentExchange ? (
                <Select
                  isActiveSearch={false}
                  isSelectValue={(
                    <div className={styles.selectItem}>
                      {currentExchange.image && <img src={currentExchange.image} alt={currentExchange.image} />}
                      <span className="short-name">{currentExchange.name}</span>
                    </div>
                  )}
                >
                  {
                    [allExchanges, ...exchanges].map((exchange) => (
                      <div
                        className={styles.selectItem}
                        key={exchange.name}
                        role="button"
                        tabIndex={0}
                        onClick={() => handleSetCurrentExchange(exchange)}
                        onKeyDown={() => handleSetCurrentExchange(exchange)}
                      >
                        {exchange.image && <img src={exchange.image} alt={exchange.image} />}
                        <span className="short-name">{exchange.name}</span>
                      </div>
                    ))
                  }
                </Select>
              ) : <SelectSkeleton /> }
            </InputForm>

            <InputForm title="Счет">
              {subAccounts && currentSubAccount ? (
                <Select
                  isActiveSearch={false}
                  isSelectValue={(
                    <div className={styles.selectItem}>
                      <span className="short-name">{currentSubAccount.user_name}</span>
                    </div>
                  )}
                >
                  { [allSubAccounts, ...subAccounts].sort(sortSubAccountsByFavorite).map((account) => (
                    <div
                      key={account.id}
                      className={styles.selectItem}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSetCurrentSubAccount(account)}
                      onKeyDown={() => handleSetCurrentSubAccount(account)}
                    >
                      <span className="short-name">{account.user_name}</span>
                    </div>
                  ))}
                </Select>
              ) : <SelectSkeleton /> }
            </InputForm>

            <Button onClick={handleSetFetchParams} background="green">Применить</Button>
            <Button onClick={handleResetFilter} background={theme === 'light' ? 'black' : 'white'}>Сбросить фильтры</Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default RebateHistoryTableAdaptive;
