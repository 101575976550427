export const ACCESS_TYPE = [
  {
    id: '1',
    label: 'Неограниченный',
    comment: 'Не рекомендуется',
  },
  {
    id: '2',
    label: 'Только к проверенным IP-адресам',
    comment: '',
  },

] as const;

export const PERMISSIONS = [
  {
    type: 0,
    name: 'Чтение',
    comment: 'Обязательно',
    isActive: false,
  },
  {
    type: 1,
    name: 'Спотовая торговля',
    comment: '',
    isActive: true,
  },
  {
    type: 2,
    name: 'Фьючерсная торговля',
    comment: '',
    isActive: true,
  },
] as const;
