import { ReactComponent as FilterIconSvg } from 'src/shared/assets/images/filter.svg';
import { PositionExchange } from 'src/pages/trading/model/types';
import { Nullable, SubAccountsModify } from 'src/shared/types/global-types';
import { Drawer } from '@mui/material';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import Button from 'src/shared/ui/button/button/button';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';
import { useDisclosure } from '@mantine/hooks';
import ButtonClose from 'src/shared/ui/button/button-close/button-close';
import Select from 'src/shared/ui/select/select';
import { BalanceType } from 'src/entities/balance-type';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import styles from './bonus-params-adaptive.module.scss';

interface IBonusParamsAdaptiveProps {
    openedDrawer: boolean;
    openDrawer: () => void;
    closeDrawer: () => void;
    removeData: () => void;
    setDateRange: (data: string[]) => void;
    dateRange: string[];
    currentExchange: Nullable<PositionExchange>
    exchanges: Nullable<PositionExchange[]>
    subAccounts: SubAccountsModify[]
    currentSubAccount: Nullable<SubAccountsModify>
    setCurrentSubAccount: (value: SubAccountsModify) => void
    setCurrentExchange: (value: PositionExchange) => void
    resetFilters: () => void;
    setRebateParams: () => void
  }

const BonusParamsAdaptive = ({
  removeData, setDateRange, dateRange,
  openedDrawer, openDrawer, closeDrawer,
  currentExchange, exchanges, subAccounts, currentSubAccount,
  setCurrentSubAccount, setCurrentExchange, resetFilters: resetAction, setRebateParams,
}: IBonusParamsAdaptiveProps) => {
  const { theme } = useTheme();

  const handleRemoveTransferHistory = () => {};

  return (
    <>
      <div className={styles.bonusParams}>
        <h1>Статистика</h1>
        <FilterIconSvg className={styles.filterBtn} onClick={openDrawer} />
      </div>

      <Drawer className={styles.drawer} onClose={closeDrawer} anchor="bottom" open={openedDrawer}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <h3>Фильтры</h3>
            <ButtonClose className={styles.closeBtn} onClick={() => closeDrawer()} />
          </div>

          <div className={styles.content}>
            <DataPickerRange monthsShown={1} whiteMode={theme === 'light'} removeData={handleRemoveTransferHistory} setPage={() => {}} setDateRange={setDateRange} dateRange={dateRange} />

            <InputForm titleColor="white" title="Биржа">
              { exchanges && currentExchange ? (
                <Select
                  isActiveSearch={false}
                  isSelectValue={(
                    <div className={styles.selectCurrentItem}>
                      <img src={currentExchange.image} alt="exchange" />
                      <span className="short-name">{currentExchange.name}</span>
                    </div>
                  )}
                >
                  {
                    exchanges.map((exchange) => (
                      <div
                        key={exchange.id}
                        className={styles.selectItem}
                        role="button"
                        tabIndex={0}
                        onClick={() => setCurrentExchange(exchange)}
                        onKeyDown={() => setCurrentExchange(exchange)}
                      >
                        <img src={exchange.image} alt="exchange" />
                        <span className="short-name">{exchange.name}</span>
                      </div>
                    ))
                  }
                </Select>
              ) : <SelectSkeleton />}
            </InputForm>

            <InputForm title="Cчета">
              {subAccounts && currentSubAccount ? (
                <Select
                  isActiveSearch={false}
                  isSelectValue={(
                    <div className={styles.selectCurrentItem}>
                      <BalanceType type={currentSubAccount.type} />
                      {currentSubAccount.name}
                    </div>
                  )}
                >
                  {subAccounts.filter((subAccount) => subAccount.exchange_id === currentExchange?.id).map((subAccount) => (
                    <div
                      key={subAccount.id}
                      className={styles.selectItem}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setCurrentSubAccount(subAccount)}
                      onClick={() => setCurrentSubAccount(subAccount)}
                    >
                      <BalanceType type={subAccount.type} />
                      {subAccount.name}
                    </div>
                  ))}
                </Select>
              ) : <SelectSkeleton />}
            </InputForm>

            <Button onClick={setRebateParams} background="green">Применить</Button>
            <Button onClick={resetAction} background={theme === 'light' ? 'black' : 'white'}>Сбросить фильтры</Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default BonusParamsAdaptive;
