import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import ReCaptcha, { ReCAPTCHA } from 'react-google-recaptcha';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputPhone from 'src/pages/auth/components/inputs/input-phone';
import InputActionSelect from 'src/pages/auth/components/inputs-auth/input-select';
import { SMS_TYPES } from 'src/shared/constants/constants';
import { selectRegistration } from 'src/pages/auth/registration/model/selectors';
import ForgotPasswordService, { ForgotDataStepOne } from 'src/services/forgot-password.service';
import { AxiosError } from 'axios';
import InputText from '../../../components/inputs-auth/input-auth';
import {
  setForgotEmail, setForgotPhoneNumber, setForgotSmsType, setStep, setMethodSms,
} from '../../model/slice';

import styles from './forgot-step-one.module.scss';

const schema = yup.object().shape({
  email: yup.string().required('Электронная почта обязательна').email('Введите корректный адрес электронной почты'),
  phone_number: yup.string().required('Телефон обязателен'),
  recaptcha_token: yup.string().required('Заполните капчу'),
});

type FormStepOne = {
  email: string,
  phone_number: string,
  recaptcha_token: string,
}

const ForgotStepOne = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { regionCode } = useSelector(selectRegistration);
  const { setAlertMessage } = useAlert();

  const captchaRef = useRef<ReCaptcha>(null);
  const captchaContainerRef = useRef<HTMLInputElement>(null);

  const [captcha, setCaptcha] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectSendSmsType, setSelectSmsType] = useState('whatsapp');

  const form = useForm<FormStepOne>({
    defaultValues: {
      email: '',
      phone_number: '',
      recaptcha_token: '',
    },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, formState } = form;
  const { errors } = formState;

  const handleResetCaptcha = () => {
    if (captchaRef.current) {
      captchaRef.current.reset();
    }
  };

  const sendVerificationCodes = async (email: string, phoneNumber: string): Promise<boolean> => {
    let responseStatus = false;

    try {
      const [responseEmail, responsePhone] = await Promise.all([
        ForgotPasswordService.sendCodeEmail(email),
        selectSendSmsType === 'phone'
          ? ForgotPasswordService.sendCodePhone({ email, phone_number: phoneNumber })
          : ForgotPasswordService.sendCodeWhatsApp({ email, phone_number: phoneNumber }),
      ]);

      if (responseEmail.data && responsePhone.data) {
        responseStatus = true;
      }
    } catch (error) {
      console.error('An error occurred while sending verification codes:', error);
    }

    return responseStatus;
  };

  const forgotPasswordStepOne = async (data: FormStepOne) => {
    setLoading(true);
    const phoneNumber = `+${regionCode}${data.phone_number}`;

    const forgotData: ForgotDataStepOne = {
      email: data.email,
      phone_number: phoneNumber,
      recaptcha_token: data.recaptcha_token,
      method_sms: selectSendSmsType,
    };

    const result = await ForgotPasswordService.stepOne(forgotData);

    if (result.data) {
      const sendCodeStatus = await sendVerificationCodes(data.email, phoneNumber);

      if (sendCodeStatus) {
        batch(() => {
          dispatch(setStep(1));
          dispatch(setMethodSms(result.data.methods));
          dispatch(setForgotEmail(data.email));
          dispatch(setForgotPhoneNumber(phoneNumber));
          dispatch(setForgotSmsType(selectSendSmsType));
        });
      }
    } else if (result instanceof AxiosError && result.response) {
      setLoading(false);
      handleResetCaptcha();

      const { detail } = result.response.data;

      if (detail === 'INVALID_CAPTCHA') {
        setAlertMessage('Капча устарела', 'error');
      } else if (detail === 'USER_NOT_FOUND') {
        setAlertMessage('Нет такой учетной записи', 'error');
      }
    } else {
      setLoading(false);
      handleResetCaptcha();
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const onSubmit = async (userData: FormStepOne) => {
    forgotPasswordStepOne(userData);
  };

  return (
    <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <InputText
            error={!!errors.email}
            placeholder="Электронная почта"
            label="E-mail"
            {...field}
          />
        )}
      />
      {errors.email && errors.email.message && <Hint error text={errors.email.message} />}

      <Controller
        name="phone_number"
        control={control}
        render={({
          field: {
            ref, value, onChange, ...fieldProps
          },
        }) => (
          <InputPhone
            ref={ref}
            error={!!errors.phone_number}
            placeholder="Ваш номер"
            label="Номер телефона"
            value={value || ''}
            onChange={onChange}
            {...fieldProps}
          />
        )}
      />
      {errors.phone_number && errors.phone_number.message && <Hint error text={errors.phone_number.message} />}

      <InputActionSelect
        sendSmsType={SMS_TYPES}
        setSelectSmsType={setSelectSmsType}
      />

      <Controller
        name="recaptcha_token"
        control={control}
        render={({ field }) => (
          <div ref={captchaContainerRef} className="recaptcha">
            <ReCaptcha
              ref={captchaRef}
              sitekey={window.appConfig.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(value) => {
                setCaptcha(value);
                field.onChange(value);
              }}
              asyncScriptOnLoad={() => {
          captchaContainerRef.current!.className = `${captchaContainerRef.current!.className} recaptcha_loaded`;
              }}
            />
          </div>
        )}
      />
      {errors.recaptcha_token && errors.recaptcha_token.message && (
        <Hint error text={errors.recaptcha_token.message} />
      )}

      <div className={styles.actions}>
        <Button maxWidth="130px" onClick={() => navigate('/auth/login')} background="whiteGray">Назад</Button>
        <Button maxWidth="100%" disabled={loading} type="submit" loading={loading} background="green">Продолжить</Button>
      </div>
    </form>
  );
};

export default ForgotStepOne;
