import { ReactComponent as LogOutSvg } from 'src/shared/assets/images/header/log-out.svg';
import Button from 'src/shared/ui/button/button/button';
import Hint from 'src/shared/ui/help/hint';
import styles from './exit-modal-content.module.scss';

interface IExitModalContentProps {
    logOut: () => void
    onClose: () => void
}

const ExitModalContent = ({ logOut, onClose }: IExitModalContentProps) => (
  <div className={styles.exitWrapper}>

    <Hint mediumText text="Если вы не будете активны в течение 24 часов, система автоматически выйдет из вашей учетной записи для обеспечения безопасности." />
    <div className={styles.exitActions}>
      <Button onClick={logOut} background="red">Выйти <LogOutSvg /></Button>
      <Button onClick={onClose} background="black">Отменить</Button>
    </div>
  </div>
);

export default ExitModalContent;
