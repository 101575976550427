import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { store } from 'src/app/store/store';
import { devConsoleLog } from 'src/shared/libs/helpers/helper.lib';
import { socket } from 'src/shared/api/websocket.instance';
import { EOutputEventType, ISubscribeEvent, IUnsubscribeEvent } from 'src/shared/types/interfaces';
import { addSubsciptionQueue, removeSubsciptionQueue } from 'src/app/store/slices/subscriptions/slice';
import { NotificationsResponse } from './types';
import { EInputEventType } from '../../../shared/types/interfaces';

export type NotificationsParams = {
  limit: number
  page: number
}

export type NotificationsReadStatusParams = {
  notification_ids?: number[]
  read_all?: boolean
}

export type NotificationMark = {
  notification_ids: number[]
}

export const subscribeNotifications = () => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на Notifications');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.NOTIFICATIONS,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribeNotifications = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от Notifications');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.NOTIFICATIONS,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (params: NotificationsParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<NotificationsResponse>(ROUTE_PATH.notifications, {
        params,
      });

      const { data } = response;
      if (data === undefined) throw response;

      subscribeNotifications();

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchUpdateNotificationsReadStatus = createAsyncThunk(
  'notifications/fetchUpdateNotificationsReadStatus',
  async (params: NotificationsReadStatusParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<boolean> = await $api.put(ROUTE_PATH.notifications, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchNotificationsMarkAsRead = createAsyncThunk(
  'notifications/fetchNotificationsMarkAsRead',
  async (params: NotificationMark, { rejectWithValue }) => {
    try {
      const response = await $api.put<boolean>(ROUTE_PATH.notifications, params);

      const { data } = response;
      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchNotificationsDelete = createAsyncThunk(
  'notifications/fetchNotificationsDelete',
  async (notificationId: number[], { rejectWithValue }) => {
    try {
      const response = await $api.delete<boolean>(`${ROUTE_PATH.notifications}?notification_ids=[${notificationId}]`);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);
