import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';

import { TradingType } from 'src/shared/types/global-types';
import { CreatePositionCategory, TradeList } from './types';

export type TradeListParams = {
  sub_account_id?: number;
  exchange_id?: number;
  trading_type?: TradingType;
  from_date: string;
  to_date: string;
  symbol?: string;
  side?: 'LONG' | 'SHORT'
  pnl?: [number | null, number | null];
  leverage?: [number | null, number | null];
  profit?: [number | null, number | null];
  active_quantity?: [number | null, number | null];
  active_quantity_usdt?: [number | null, number | null];
  duration?: [number | null, number | null];
  category?: string
}

export type FetchParamsTradeList = {
  query: {
    limit: number;
    page: number;
  },
  body: TradeListParams
}

export type CreatePositionCategoryResponse = {
  categories: string[],
  id: string
}

export const fetchCreatePositionCategory = createAsyncThunk(
  'tradeList/fetchCreatePositionCategory',
  async (params: CreatePositionCategory, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<CreatePositionCategoryResponse[]> = await $api.post(ROUTE_PATH.positions.summaryCategory, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchTradeList = createAsyncThunk(
  'tradeList/fetchTradingList',
  async (params: FetchParamsTradeList, { rejectWithValue }) => {
    try {
      const response = await $api.post<TradeList>(ROUTE_PATH.positions.summary, params.body, {
        params: {
          limit: params.query.limit.toString(),
          page: params.query.page.toString(),
        },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);
