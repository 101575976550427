import { useState } from 'react';

import { ReactComponent as EyeOpenedSvg } from 'src/shared/assets/images/auth/eye-opened.svg';
import { ReactComponent as EyeClosedSvg } from 'src/shared/assets/images/auth/eye-closed.svg';

import './eye-icon.scss';

const EyeIcon = () => {
  const [isOpenedEye, setIsOpenedEye] = useState(false);

  return (
    <div className="icon eye-icon">
      <button onClick={() => setIsOpenedEye(!isOpenedEye)}>
        { isOpenedEye
          ? <EyeClosedSvg className="eye-closed" />
          : <EyeOpenedSvg className="eye-opened" />}
      </button>
    </div>
  );
};

export default EyeIcon;
