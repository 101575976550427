import React from 'react';

interface IBybitHintContentProps {
  typeLabel: string
  maker: string,
  taker: string,
  rebatePercent: string
}
const BybitHintContent = ({
  taker, maker, typeLabel, rebatePercent,
}: IBybitHintContentProps) => (
  <div>
    {typeLabel}:
    <br />
    Maker — {maker}%
    <br />
    Taker — {taker}%

    <br /><br />
    Кроме того, вы получаете дополнительное вознаграждение в размере {rebatePercent}% от торговых комиссий
  </div>
);

export default BybitHintContent;
