import className from 'classnames';
import {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { Exchange } from 'src/app/store/slices/exchanges/types';
import { useAppDispatch } from 'src/app/store/store';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { setFavoriteSubAccount } from 'src/app/store/slices/sub-accounts/slice';
import { EditSubAccount, fetchEditSubAccount } from 'src/app/store/slices/sub-accounts/thunks';
import { selectEditSubAccountStatus, selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';

import { ModalEditSubAccount } from 'src/shared/ui/modal/modal-edit-subaccount';
import { ReactComponent as ArrowLeftCircleIconSvg } from 'src/shared/assets/images/arrow-left-circle.svg';
import { ReactComponent as FavoriteIconSvg } from 'src/shared/assets/images/favorite.svg';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import useExchange from 'src/shared/libs/hooks/use-exchange';

import { Nullable } from 'src/shared/types/global-types';
import { saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { DEFAULT_ERROR_STATE } from 'src/pages/accounts/constants';
import { BalanceType } from 'src/entities/balance-type';
import { ActiveLink } from '../../../../model/types';
import styles from './subaccount-container.module.scss';

const SubaccountContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { setAlertMessage } = useAlert();
  const { getExchangeImageById, getExchangeNameById } = useExchange();

  const subAccounts = useSelector(selectSubAccounts);
  const editStatus = useSelector(selectEditSubAccountStatus);
  const { exchanges } = useSelector(selectExchanges);

  // State of the modal for creating an sub-account
  const [opened, { open, close }] = useDisclosure(false);
  const [errorMessage, setNameErrorMessage] = useState(DEFAULT_ERROR_STATE);

  const [subAccountName, setSubAccountName] = useState('');
  const [subAccount, setSubAccount] = useState<Nullable<SubAccountType>>(null);
  const [exchange, setExchange] = useState<Nullable<Exchange>>(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [activeLink, setActiveLink] = useState<ActiveLink>('sub_account');

  const [isBybit, setIsBybit] = useState(false);

  const [page, subAccountId, selectTab] = pathname.substring(1).split('/');

  const handleCloseModal = () => {
    setNameErrorMessage(DEFAULT_ERROR_STATE);
    close();
  };

  const handleOpenModal = () => {
    if (exchanges && subAccount) {
      setSubAccountName(subAccount.user_name);
    }
    open();
  };

  const checkSubAccountNameExist = useCallback((name: string) => {
    if (exchange && subAccounts && subAccount) {
      const subAccountsName = subAccounts.filter((item) => item.exchange_id === exchange.id && item.user_name !== subAccount.user_name).map((subAccount) => subAccount.user_name);

      return subAccountsName.includes(name.trim());
    }
    return false;
  }, [exchange, subAccounts, subAccount]);

  const editSubAccount = async () => {
    if (!subAccount || subAccountName === '' || !exchange) return;

    if (checkSubAccountNameExist(subAccountName)) {
      setNameErrorMessage({ state: true, message: 'Это имя уже занято' });
      return;
    }
    if (!checkSubAccountNameExist(subAccountName)) {
      setNameErrorMessage(DEFAULT_ERROR_STATE);
    }
    if (subAccountName.length > 16) {
      setNameErrorMessage({ state: true, message: 'Максимальная длина 16 символов' });
      return;
    }

    const editSubAccount: EditSubAccount = {
      exchange_id: exchange.id,
      user_name: subAccountName,
      is_favorite: isFavorite,
    };

    if (isFavorite) {
      dispatch(setFavoriteSubAccount(editSubAccount.user_name));

      const selectedSubAccountId = subAccounts?.find((item) => item.id === subAccount.id);
      if (selectedSubAccountId) saveToLocalStorage('favoriteSubAccountId', selectedSubAccountId.id);
    }

    const response = await dispatch(fetchEditSubAccount({ editSubAccount, subAccountId: subAccount.id }));

    if (response.payload) {
      close();
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  useLayoutEffect(() => {
    if (subAccounts && exchanges) {
      const currentSubAccount = subAccounts?.find((item) => item.id === Number(subAccountId));

      if (currentSubAccount) {
        setSubAccount(currentSubAccount);
        setIsFavorite(currentSubAccount.is_favorite);
        const exchange = exchanges.find((item) => item.id === currentSubAccount.exchange_id);

        if (exchange) {
          setExchange(exchange);
          const checkIsByBit = getExchangeNameById(exchange.id) === 'Bybit';
          setIsBybit(checkIsByBit);
        }
      }
    }
  }, [subAccounts, exchanges, subAccountId]);

  useEffect(() => {
    if (selectTab) {
      const queryLink = selectTab as ActiveLink;
      setActiveLink(queryLink);
    } else {
      setActiveLink('sub_account');
    }
  }, [pathname, selectTab]);

  return (
    <section className={styles.subContainer}>
      <header>
        <Link to="/sub-accounts">
          <div className={styles.navigation}>
            <ArrowLeftCircleIconSvg />
          </div>
        </Link>

        <div className={styles.subAccountName}>
          {subAccount && <img src={getExchangeImageById(subAccount.exchange_id)} alt="exchange" />}
          { subAccount?.exchange_id === 2 && <BalanceType type={3} />}
          <button className={className(styles.navigationButton, { [styles.active]: activeLink === 'sub_account' })} onClick={() => navigate(`/${page}/${subAccountId}`)}>{subAccount?.user_name}</button>
          {subAccount?.is_favorite && <FavoriteIconSvg />}

          <div className={styles.navigations}>
            <button onClick={() => navigate(`/${page}/${subAccountId}/analytics`)} className={className(styles.navigationButton, { [styles.active]: activeLink === 'analytics' })}>Аналитика</button>
            {!isBybit && <button onClick={() => navigate(`/${page}/${subAccountId}/dust-transforms`)} className={className(styles.navigationButton, { [styles.active]: activeLink === 'dust-transforms' })}>Пыль</button>}
          </div>
        </div>

        <Button onClick={handleOpenModal} maxWidth="190px" background="black">Редактировать счет</Button>
      </header>

      <ModalEditSubAccount
        opened={opened}
        closeModal={handleCloseModal}
        exchange={exchange}
        editStatus={editStatus}
        editSubAccount={editSubAccount}
        inputValue={subAccountName}
        inputOnChange={setSubAccountName}
        errorMessage={errorMessage}
        isFavorite={isFavorite}
        setIsFavorite={setIsFavorite}
      />
    </section>
  );
};

export default SubaccountContainer;
