import Button from 'src/shared/ui/button/button/button';
import Hint from 'src/shared/ui/help/hint';
import styles from './kyc-policy-modal-content.module.scss';

interface IKycPolicyModalContentProps {
    verify: () => void
    onClose: () => void
    btnStatus: boolean
}

const KycPolicyModalContent = ({ verify, onClose, btnStatus }: IKycPolicyModalContentProps) => (
  <div className={styles.policyWrapper}>

    <Hint mediumText text="Не предоставляются услуги лицам, которые проживают на следующих территориях: Демократическая республика Конго (ДРК), Крым, Донецкая область, Запорожская область, Луганская область, Херсонская область, Иран, Ирак, Корейская Народно-Демократическая Республика (КНДР), Канада, Куба,  Малайзия, Марокко, Мьянма, Нидерланды, Никарагуа, Пакистан, Сирия, Сингапур, Судан, США,  Южный Судан, Зимбабве." />

    <div className={styles.actions}>
      <Button loading={btnStatus} disabled={btnStatus} onClick={verify} background="green">Подтверждаю</Button>
      <Button disabled={btnStatus} onClick={onClose} background="black">Отказаться</Button>
    </div>
  </div>
);

export default KycPolicyModalContent;
