import { Loader } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import { selectKyc } from 'src/app/store/slices/kyc/selectors';
import { fetchVerifyKycUrl } from 'src/app/store/slices/kyc/thunks';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { EKycStatus, EThemeMode } from 'src/app/store/slices/user/types';
import { EStatus } from 'src/shared/types/global-types';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import { Divider } from '@mui/material';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import styles from './kyc-modal-view.module.scss';

interface IKycModalViewProps {
  closeModal: () => void
}

const KycModaLView = ({ closeModal }: IKycModalViewProps) => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();
  const { theme } = useTheme();
  const { status } = useSelector(selectKyc);
  const { kycStatus } = useSelector(selectUser);

  const verificationKyc = async () => {
    try {
      const { payload } = await dispatch(fetchVerifyKycUrl());

      if (typeof payload === 'string' && payload.startsWith('http')) {
        window.open(payload, '_blank');
        closeModal();
      } else if (typeof payload === 'string' && !payload.startsWith('http')) {
        setAlertMessage(payload, 'error');
      }
    } catch (error) {
      console.error('Ошибка при проверке KYC', error);
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Чтобы использовать данную функцию, пройдите базовую верификацию аккаунта</p>
      <Button loading={status === EStatus.loading} disabled={kycStatus !== EKycStatus.CREATED} onClick={verificationKyc} background="green">
        { status === EStatus.loading ? <Loader size={16} color="white" /> : 'Пройти верификацию'}
      </Button>
      <p className={styles.info}>
        Верификация всех пользователей является обязательным условием регламента Межправительственной комиссии по финансовому мониторингу (FATF), регулирующей деятельность компании.
        <br />
        <br />
        Проверка клиентов сервиса позволяет уменьшить риски мошенничества, отмывания денег и других незаконных действий, а также обеспечивает безопасность для пользователей и компании.
      </p>
      <Divider color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />
      <p className={styles.info}>
        Не предоставляются услуги лицам, которые проживают на следующих территориях: Демократическая республика Конго (ДРК), Крым, Донецкая область, Запорожская область, Луганская область, Херсонская область, Иран, Ирак, Корейская Народно-Демократическая Республика (КНДР), Канада, Куба,  Малайзия, Марокко, Мьянма, Нидерланды, Никарагуа, Пакистан, Сирия, Сингапур, Судан, США,  Южный Судан, Зимбабве.
      </p>

    </div>
  );
};

export default KycModaLView;
