import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useCheckStep = (email: string | null, path: string) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate(path);
    }
  }, [email, path]);
};

export default useCheckStep;
