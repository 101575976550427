import { EStatus, Meta, Nullable } from 'src/shared/types/global-types';
import { TransferHistorySortByColumns } from './thunks';

export type TransferHistoryType = {
  asset: { id: number, symbol: string }
  exchange_id: number
  asset_id: number
  id: number
  transfer_type: string
  quantity: string
  to_sub_account_id: number
  from_sub_account_id: number
  from_address: any
  to_address: any
  to_transfer_trading_type: string
  from_transfer_trading_type: string
  withdrawal_request_id: Nullable<number>
  created_at: string
}

export type WithdrawalStatus = 'PROCESSED' | 'TRANSFERRED' | 'CREATED' | 'REJECTED';

export type WithdrawalDetail = {
  sub_account_id: number
  exchange_id: number
  quantity: string
  address: string
  asset_symbol: string
  network_symbol: string;
  status: WithdrawalStatus
  created_at: Date // UTC
  transaction_id?: string // TXID
  error_description?: string
}

export type TransfersHistoryResponse = {
  meta: Meta,
  items: TransferHistoryType[],
}

export interface ITransferHistorySlice {
  transfersHistory: Nullable<TransfersHistoryResponse>,
  withdrawalDetail: Nullable<WithdrawalDetail>,
  status: EStatus
  withdrawalDetailStatus: EStatus
}

export enum ETransferType {
  REBATES_INPUT = 'REBATES_INPUT',
  EXTERNAL_INPUT = 'EXTERNAL_INPUT',
  EXTERNAL_OUTPUT = 'EXTERNAL_OUTPUT',
  INTERNAL_INPUT = 'INTERNAL_INPUT',
  INTERNAL_OUTPUT = 'INTERNAL_OUTPUT',
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
}

export enum ETransferTypeNumber {
  EXTERNAL_INPUT = 1,
  EXTERNAL_OUTPUT = 2,
  INTERNAL_INPUT = 3,
  INTERNAL_OUTPUT = 4,
  INTERNAL_TRANSFER = 5,
}

export type SortFieldTransferHistory = keyof TransferHistoryType

export type TransferHistorySortColumn = {
  column: TransferHistorySortByColumns,
  title: string,
  orderBy?: 'asc' | 'desc'
}
