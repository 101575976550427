import {
  useRef, useState, useEffect,
} from 'react';
import { ReactComponent as InfoSvg } from 'src/shared/assets/images/auth/info.svg';
import classNames from 'classnames';
import { FloatingPosition, Tooltip } from '@mantine/core';
import { devConsoleLog } from 'src/shared/libs/helpers/helper.lib';
import styles from './hint.module.scss';

interface IHintProps {
  text: string
  error?: boolean
  warning?: boolean
  hintOpen?: boolean
  hintText?: React.ReactNode | JSX.Element | string
  position?: FloatingPosition
  mediumText?: boolean
  mawText?: number
  offset?: number
  className?: string
}

const Hint = ({
  text, error, hintOpen, hintText, position, mawText, warning, mediumText, offset, className,
}: IHintProps) => {
  const [showText, setShowText] = useState(false);

  const buttonRef = useRef<any>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const hintTextRef = useRef<HTMLDivElement>(null);

  const logClickTarget = (event: MouseEvent) => {
    try {
      const target = event.target as HTMLElement;
      const button = buttonRef.current;
      const hintText = hintTextRef.current;
      const isTooltip = target?.parentElement?.className.includes('tooltipWrapper');

      if (button && button.contains(target)) {
        setShowText((prev) => !prev);
      } else if (hintText && hintText.contains(target)) {
        setShowText((prev) => !prev);
      } else if (button && !button.contains(target) && !isTooltip) {
        setShowText(false);
      }
    } catch (error) {
      devConsoleLog('Error handling click event:', error);
    }
  };

  useEffect(() => {
    document.addEventListener('click', logClickTarget);

    return () => {
      document.removeEventListener('click', logClickTarget);
    };
  }, []);

  return (
    <div className={classNames(styles.hintContainer, className)}>
      <div ref={tooltipRef} className={classNames(styles.hint, { [styles.error]: error, [styles.warning]: warning, [styles.mediumText]: mediumText })}>
        <Tooltip
          className={styles.tooltipWrapper}
          maw={mawText || 350}
          mah={250}
          opened={hintOpen && showText}
          multiline
          disabled={!hintOpen}
          withArrow
          label={hintText}
          position={position || 'bottom'}
          offset={offset || 5}
          zIndex={10000}
        >
          <div className={styles.hintBtn} ref={buttonRef}><InfoSvg /></div>
        </Tooltip>
        {text && <span ref={hintTextRef}>{text}</span>}
      </div>

    </div>
  );
};

export default Hint;
