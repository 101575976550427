import ExternalInputIconSvg from 'src/shared/assets/images/account/EXTERNAL_INPUT.svg';
import ExternalOutputIconSvg from 'src/shared/assets/images/account/EXTERNAL_OUTPUT.svg';
import InternalInputIconSvg from 'src/shared/assets/images/account/INTERNAL_INPUT.svg';
import InternalOutputIconSvg from 'src/shared/assets/images/account/INTERNAL_OUTPUT.svg';
import InternalTransferIconSvg from 'src/shared/assets/images/account/INTERNAL_TRANSFER.svg';

import { ETransferType } from '../../../../widgets/transfer-history/model/types';

export const transferTypeTranslations: Record<string, string> = {
  ALL_TRANSFERS: 'Все',
  [ETransferType.REBATES_INPUT]: 'Бонусы',
  [ETransferType.EXTERNAL_INPUT]: 'Внешний ввод',
  [ETransferType.EXTERNAL_OUTPUT]: 'Внешний вывод',
  [ETransferType.INTERNAL_INPUT]: 'Внутренний ввод',
  [ETransferType.INTERNAL_OUTPUT]: 'Внутренний вывод',
  [ETransferType.INTERNAL_TRANSFER]: 'Внутренний перевод',
};

export const transferTypeIconList: Record<string, string> = {
  [ETransferType.REBATES_INPUT]: InternalInputIconSvg,
  [ETransferType.EXTERNAL_INPUT]: ExternalInputIconSvg,
  [ETransferType.EXTERNAL_OUTPUT]: ExternalOutputIconSvg,
  [ETransferType.INTERNAL_INPUT]: InternalInputIconSvg,
  [ETransferType.INTERNAL_OUTPUT]: InternalOutputIconSvg,
  [ETransferType.INTERNAL_TRANSFER]: InternalTransferIconSvg,
};

export const translateTypeOfTranslation = (type: string): string | undefined => transferTypeTranslations[type];

export const transferTypeIcon = (type: string) => transferTypeIconList[type];
