import { AuthLayout } from 'src/layouts';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import AbstractIconsSvg from 'src/shared/assets/images/auth/abstract.svg';
import LanguageSelection from 'src/entities/language-selection/language-selection';

import { useAppDispatch } from 'src/app/store/store';
import { useSelector } from 'react-redux';
import { useLayoutEffect } from 'react';
import AuthForm from './components/auth-form/auth-form';
import AuthBody from '../components/auth-body/auth-body';
import AuthTitle from '../components/auth-title/auth-title';
import AuthFooter from '../components/auth-footer/auth-footer';
import AuthHelper from '../components/auth-helper/auth-helper';

import { informSlides, infromTitleSilde } from '../lib/helpers/constants';
import styles from './login.module.scss';
import { selectSlidesPage } from './model/selectors';
import { setSlidesPage } from './model/slice';

const pageTitle = 'Вход в систему | League of Traders';

const LoginPage = () => {
  useChangePageTitle(pageTitle);

  const dispatch = useAppDispatch();
  const slidePage = useSelector(selectSlidesPage);

  useLayoutEffect(() => {
    dispatch(setSlidesPage(0));
  }, []);

  return (
    <AuthLayout page="">
      <div className={styles.loginContainer}>
        <section className={styles.bodyWrapper}>

          <AuthBody
            activeSlide="first"
            titleUp="Добро пожаловать"
            titleDown="в личный кабинет"
            description=" Торгуйте на Binance, Bybit, OKX
         и получайте кешбек от наторгованной
         комиссии"
            informList={informSlides[slidePage]}
            informTitle={infromTitleSilde[slidePage]}
          />
        </section>

        <section className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <LanguageSelection bgColor="#131313" />
            <AuthTitle visible={false} step="none">Войти <br /> в личный кабинет</AuthTitle>
            <AuthForm />
            <AuthHelper title="Нет аккаунта?" path="/auth/registration" linkTitle="Регистрация" />
          </div>

          <AuthFooter />
        </section>

        <img className={styles.abstract} src={AbstractIconsSvg} alt="abstract" />
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
