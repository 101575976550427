import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import AuthFooter from '../components/auth-footer/auth-footer';
import AuthTitle from '../components/auth-title/auth-title';

import './acess-recovery.scss';

const pageTitle = 'Восстановление доступа';

const AccessRecoveryPage = () => {
  useChangePageTitle(pageTitle);

  return (
    <div className="page auth-page access-recovery-page">
      <div className="auth-form">
        <div className="container">
          <div className="main-block">
            <AuthTitle step="none">Восстановление доступа</AuthTitle>
          </div>
        </div>
      </div>

      <AuthFooter />
    </div>
  );
};

export default AccessRecoveryPage;
