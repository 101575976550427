import { useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Balance } from 'src/app/store/slices/balance/types';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import { BalanceType } from 'src/entities/balance-type';
import { ETradingType, Nullable } from 'src/shared/types/global-types';
import { plusValues, toFixed } from 'src/shared/libs/helpers/helper.lib';

import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import styles from './account-balances.module.scss';

interface IAccountBalancesProps {
    subAccounts: Nullable<SubAccountType[]>
}

const AccountBalances = ({ subAccounts } : IAccountBalancesProps) => {
  const { balances } = useSelector(selectBalances);
  const [balance, setBalance] = useState<Nullable<Balance>>(null);

  const { getSubAccountExchangeId } = useSubAccount();
  const { getExchangeNameById } = useExchange();

  const [currentExchangeName, setCurrentExchangeName] = useState<Nullable<string>>(null);

  const balanceVisible = useSelector(selectHiddenBalance);

  const { pathname } = window.location;
  const [_, id] = pathname.split('/sub-accounts/');

  useEffect(() => {
    if (balances) {
      const currentBalance = balances.sub_accounts.find((item) => item.sub_account_id === Number(id));
      if (currentBalance) setBalance(currentBalance);
    }
  }, [balances]);

  useLayoutEffect(() => {
    if (balance) {
      const subAccountExhcangeId = getSubAccountExchangeId(balance.sub_account_id);

      if (subAccountExhcangeId) {
        const exchangeName = getExchangeNameById(subAccountExhcangeId);
        if (exchangeName) setCurrentExchangeName(exchangeName);
      }
    }
  }, [balance]);

  return (
    <div className={styles.balancesWrapper}>
      {subAccounts
                && (
                  <div className={styles.subAccount}>
                    <div className={styles.info}>
                      <div className={styles.totalQuantity}>
                        <p>Общий баланс:</p>
                        <span>{balance ? !balanceVisible ? parseFloat(toFixed(plusValues(String(balance.spot), String(balance.futures)), USDT_PRECISION)) : HIDDEN_BALANCES : 0} USDT
                          {currentExchangeName === 'Bybit' && <BalanceType type={ETradingType.unified} />}
                        </span>
                      </div>

                      <div className={styles.accounts}>
                        <div className={styles.quantity}>
                          {currentExchangeName === 'Bybit' ? <p>Маржинальные активы:</p> : <p>Фьючерсный счет:</p>}
                          <div className={styles.balance}>
                            <span>{balance ? !balanceVisible ? parseFloat(toFixed(String(balance.futures), USDT_PRECISION)) : HIDDEN_BALANCES : '0.0'} USDT</span>
                            <BalanceType type={ETradingType.futures} />
                          </div>
                        </div>

                        <div className={styles.quantity}>
                          {currentExchangeName === 'Bybit' ? <p>Свободные активы:</p> : <p>Спотовый счет:</p>}

                          <div className={styles.balance}>
                            <span>{balance ? !balanceVisible ? parseFloat(toFixed(String(balance.spot), USDT_PRECISION)) : HIDDEN_BALANCES : '0.0'} USDT</span>
                            <BalanceType type={ETradingType.spot} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                )}
    </div>
  );
};

export default AccountBalances;
