import { Nullable } from 'src/shared/types/global-types';
import PositionsSummuryTable from './components/transaction-history-table/positions-summury-table';

interface IAnalyticsContainer {
  subAccountId: Nullable<number>
}

const AnalyticsContainer = ({ subAccountId } : IAnalyticsContainer) => (
  <PositionsSummuryTable subAccountId={subAccountId} />
);
export default AnalyticsContainer;
