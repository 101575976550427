import { useSelector, useDispatch } from 'react-redux';
import { selectPasswordSteps } from 'src/app/store/slices/password-steps/selectors';
import { resetPasswordSteps, updatePasswordSteps } from 'src/app/store/slices/password-steps/slice';

const usePasswordExecutionSteps = () => {
  const dispatch = useDispatch();
  const result = useSelector(selectPasswordSteps);

  const passwordExecutionSteps = (newValue: string) => {
    dispatch(
      updatePasswordSteps({
        passwordLength: newValue.length >= 8,
        lowercase: /[a-z]/.test(newValue),
        uppercase: /[A-Z]/.test(newValue),
        digits: /\d/.test(newValue),
        specialChars: /[!@#$%^&*]/.test(newValue),
        reenterPassword: false,
      }),
    );
  };

  const resetValidationSteps = () => {
    dispatch(resetPasswordSteps());
  };

  return { result, passwordExecutionSteps, resetValidationSteps };
};

export default usePasswordExecutionSteps;
