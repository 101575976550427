import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import Toogler from 'src/shared/ui/toogler/toogler';
import UsdtIcon from 'src/shared/assets/images/usdt.svg';
import styles from './bonus-my-bonuses.module.scss';

const BonusMyBonuses = () => {
  const [autoConvert, setAutoConvert] = useState(true);
  const balanceVisible = useSelector(selectHiddenBalance);

  return (
    <section className={styles.container}>
      <div className={styles.title}>Мои бонусы</div>
      <div className={styles.balance}>Текущий баланс бонусов:
        <span>{!balanceVisible ? `${parseFloat('0').toLocaleString().replace(',', '.')} USDT` : HIDDEN_BALANCES}</span>
      </div>
      <div className={styles.currencies}>
        <div className={styles.currenciesTitle}>Монеты, из которых <br /> состоит баланс:</div>
        <div className={styles.currenciesAsset}>
          <img src={UsdtIcon} alt="asset" />
          USDT
          <span>{!balanceVisible ? parseFloat('0').toLocaleString().replace(',', '.') : HIDDEN_BALANCES}</span>
        </div>
      </div>
      <Toogler isBlocked isActive={!autoConvert} onChange={setAutoConvert} labelContent="Конвертировать автоматически" />
      <p className={styles.info}>Конвертация производится в течение суток после зарплаты торгового дня</p>
    </section>
  );
};
export default BonusMyBonuses;
