import { useLayoutEffect } from 'react';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { join } from 'src/shared/libs/helpers/helper.lib';
import { useLoadScript } from 'src/shared/libs/hooks/use-load-script';

import Alerts from 'src/shared/ui/alert/alerts/alerts';
import useAlert from 'src/shared/libs/hooks/use-alert';
import useMobile from 'src/shared/libs/hooks/useMobile';

interface ILayoutProps {
  page: string;
  children: React.ReactNode;
}

const AuthLayout = ({ children, page }: ILayoutProps) => {
  useLoadScript('https://www.google.com/recaptcha/api.js');

  const { isOpen } = useAlert();
  const isMobile = useMobile();

  useLayoutEffect(() => {
    if (isMobile) {
      document.documentElement.setAttribute('data-theme', EThemeMode.DARK);
    } else {
      document.documentElement.setAttribute('data-theme', EThemeMode.LIGHT);
    }
  }, [isMobile]);

  return (
    <div className={join('page', 'auth-page', page)}>
      {isOpen && <Alerts />}
      {children}
    </div>
  );
};

export default AuthLayout;
