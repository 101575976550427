import { memo } from 'react';
import { Balance } from 'src/app/store/slices/balance/types';
import { useAppDispatch } from 'src/app/store/store';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { ETradingType, Nullable, TradingType } from 'src/shared/types/global-types';
import { ResetPnl, fetchResetPnl } from 'src/app/store/slices/sub-accounts/thunks';
import { scrollToTop, toFixedDecimalPrecision } from 'src/shared/libs/helpers/helper.lib';

import useAlert from 'src/shared/libs/hooks/use-alert';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import Account from '../account/account';
import styles from './account-block.module.scss';

interface IAccountBlockProps {
    selectedSubAccount: Nullable<SubAccountType>;
    balance: Nullable<Balance>;
    closeMenu?: () => void
}

const AccountBlock = memo(({
  selectedSubAccount, balance, closeMenu,
} : IAccountBlockProps) => {
  const { setAlertMessage } = useAlert();
  const { getExchangeNameById } = useExchange();

  const dispatch = useAppDispatch();
  const isBybit = getExchangeNameById(selectedSubAccount?.exchange_id) === 'Bybit';

  const spotBalance = balance?.spot ? toFixedDecimalPrecision(balance.spot, 1).replace(/^0$/, '0.0') : '0.0';
  const futuresBalance = balance?.futures ? toFixedDecimalPrecision(balance?.futures, 1).replace(/^0$/, '0.0') : '0.0';

  const spotPnlUsdt = balance?.spot_pnl_usdt === '-0.0' ? '0.0' : balance?.spot_pnl_usdt || '0.0';
  const futuresPnlUsdt = balance?.futures_pnl_usdt === '-0.0' ? '0.0' : balance?.futures_pnl_usdt || '0.0';

  const spotPnlPercent = balance?.spot_pnl_percent === '-0.00' ? '0.00' : balance?.spot_pnl_percent ?? '0.00';
  const futuresPnlPercent = balance?.futures_pnl_percent === '-0.00' ? '0.00' : balance?.futures_pnl_percent ?? '0.00';

  const handleResetPnl = async (subAccountId: Nullable<number>, tradingType: TradingType) => {
    if (!subAccountId) return;

    const params: ResetPnl = {
      sub_account_id: subAccountId,
      trading_type: tradingType,
    };

    const { payload } = await dispatch(fetchResetPnl(params));

    if (payload === true) {
      setAlertMessage('PNL обнулен', 'success');
    } else if (payload === 'TOO_MANY_REQUESTS') {
      setAlertMessage('Слишком много попыток. Повторите через 10 секунд', 'warning');
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const handleClose = () => {
    if (closeMenu) {
      closeMenu();
      scrollToTop();
    }
  };

  const renderAccountBlock = () => (isBybit ? (
    <>
      <Account
        selectedSubAccount={selectedSubAccount}
        balance={balance}
        closeMenu={handleClose}
        resetPnl={handleResetPnl}
        accountTitle={isBybit ? 'Маржинальные активы' : 'Фьючерсы'}
        accountBalance={futuresBalance}
        accountPnlUsdt={futuresPnlUsdt}
        accountPnlPercent={futuresPnlPercent}
        balanceAllowed={balance?.futures_allowed || '0'}
        balanceType={ETradingType.futures}
      />

      <Account
        selectedSubAccount={selectedSubAccount}
        balance={balance}
        closeMenu={handleClose}
        resetPnl={handleResetPnl}
        accountTitle={isBybit ? 'Свободные активы' : 'Спотовый'}
        accountBalance={spotBalance}
        accountPnlUsdt={spotPnlUsdt}
        accountPnlPercent={spotPnlPercent}
        balanceAllowed={balance?.spot_allowed || '0'}
        balanceType={ETradingType.spot}
      />
    </>
  ) : (
    <>
      <Account
        selectedSubAccount={selectedSubAccount}
        balance={balance}
        closeMenu={handleClose}
        resetPnl={handleResetPnl}
        accountTitle={isBybit ? 'Свободные активы' : 'Спотовый'}
        accountBalance={spotBalance}
        accountPnlUsdt={spotPnlUsdt}
        accountPnlPercent={spotPnlPercent}
        balanceAllowed={balance?.spot_allowed || '0'}
        balanceType={ETradingType.spot}
      />

      <Account
        selectedSubAccount={selectedSubAccount}
        balance={balance}
        closeMenu={handleClose}
        resetPnl={handleResetPnl}
        accountTitle={isBybit ? 'Маржинальные активы' : 'Фьючерсы'}
        accountBalance={futuresBalance}
        accountPnlUsdt={futuresPnlUsdt}
        accountPnlPercent={futuresPnlPercent}
        balanceAllowed={balance?.futures_allowed || '0'}
        balanceType={ETradingType.futures}
      />
    </>
  ));

  return (
    <div className={styles.subAccountsWrapper}>
      {renderAccountBlock()}
    </div>
  );
});

export default AccountBlock;
