import InputForm from 'src/shared/ui/input/input-form/input-form';
import './auto-leverages-calculator.scss';
import Select from 'src/shared/ui/select/select';
import { EStatus, Nullable, DetailError } from 'src/shared/types/global-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import InputText from 'src/shared/ui/input/input-text/input-text';
import Button from 'src/shared/ui/button/button/button';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import useAlert from 'src/shared/libs/hooks/use-alert';
import { useAppDispatch } from 'src/app/store/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { CALCULATION_LEVERAGE, CALCULATION_LEVERAGE_ERROR_MSG } from '../../constants';
import { selectLeverages } from '../../model/selectors';
import { CalclulationLeverage, CalclulatorLeverageQueue } from '../../model/types';
import { addCalculationToQueue } from '../../model/slice';
import { fetchCalculate, SetCalculateParams } from '../../model/thunks';

interface IAutoLeveragesCalculatorProps {
    subAccountId: Nullable<number>
    openAutoCalculator: boolean
}

const AutoLeveragesCalculator = ({ subAccountId, openAutoCalculator }: IAutoLeveragesCalculatorProps) => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();

  const [calculatorValue, setCalculatorValue] = useState('');
  const [calculationLeverage, setCalculationLeverage] = useState<Nullable<CalclulationLeverage>>(null);

  const balanceVisible = useSelector(selectHiddenBalance);
  const { balances } = useSelector(selectBalances);
  const { leveragesCalculationQueue, statusCalculate } = useSelector(selectLeverages);

  const checkSubAccountCalculationStatus = () => leveragesCalculationQueue.some((task) => task.sub_account_id === subAccountId);

  const handleSetCalculationLeverage = (item: CalclulationLeverage) => {
    setCalculationLeverage(item);

    if (item.type === 'FROM_DEPOSIT') {
      if (balances && subAccountId) {
        const findBalance = balances.sub_accounts.find((balance) => balance.sub_account_id === subAccountId);
        const value = findBalance ? findBalance.futures : '0';

        setCalculatorValue(value);
      }
    } else if (item.type === 'FROM_WORKING_VOLUME') {
      setCalculatorValue('0');
    } else {
      throw new Error('This type is not supported');
    }
  };

  const handleOnCalculate = async () => {
    if (!subAccountId || !calculationLeverage) return;

    if (calculationLeverage.type === 'FROM_WORKING_VOLUME' && calculatorValue.trim() === '') {
      setAlertMessage('Введите размер депозита', 'warning');
      return;
    }
    if (Number(calculatorValue) < 1) {
      setAlertMessage('Мин. значение: 1 USDT', 'warning');
      return;
    }
    if (Number(calculatorValue) > 1_000_000_000) {
      setAlertMessage('Макс. значение: 1 миллиард USDT', 'warning');
      return;
    }

    const params: SetCalculateParams = {
      params: {
        deposit: Number(calculatorValue),
        type: calculationLeverage.type,
      },
      subAccountId,
    };

    const response = await dispatch(fetchCalculate(params));
    const payload = unwrapResult(response);

    const calculationTask: CalclulatorLeverageQueue = {
      sub_account_id: subAccountId,
      status: 'STARTED',
    };

    if (payload === true) {
      dispatch(addCalculationToQueue(calculationTask));
      setAlertMessage(CALCULATION_LEVERAGE_ERROR_MSG.true.msg, 'success');
    }

    if (typeof payload === 'object' && 'detail' in payload) {
      const responseMessage = CALCULATION_LEVERAGE_ERROR_MSG[payload.detail] || CALCULATION_LEVERAGE_ERROR_MSG.default;
      setAlertMessage(responseMessage.msg, responseMessage.type);
      dispatch(addCalculationToQueue(calculationTask));
    }
  };

  useEffect(() => {
    if (subAccountId) {
      setCalculationLeverage(CALCULATION_LEVERAGE[0]);
    }
  }, [subAccountId]);

  useEffect(() => {
    if (balances) {
      const findBalance = balances.sub_accounts.find((balance) => balance.sub_account_id === subAccountId);

      if (findBalance) setCalculatorValue(findBalance.futures);
    }
  }, [subAccountId, balances]);

  return openAutoCalculator ? (
    <div className="auto-leverages-calculator">
      <InputForm title="Расчет плечей от">
        <Select
          isActiveSearch={false}
          isSelectValue={(
            <span
              className="marge-item"
            >{calculationLeverage?.name || CALCULATION_LEVERAGE[0].name}
            </span>
          )}
        >
          { CALCULATION_LEVERAGE.map((item) => (
            <span
              className="marge-item"
              key={item.type}
              role="button"
              tabIndex={0}
              onClick={() => handleSetCalculationLeverage(item)}
              onKeyDown={() => handleSetCalculationLeverage(item)}
            >{item.name}
            </span>
          ))}
        </Select>
      </InputForm>

      <InputForm title="Сумма">
        <InputText
          inputType={!balanceVisible ? 'number' : 'password'}
          value={calculatorValue}
          placeholder="Введите значение"
          onChange={setCalculatorValue}
        />
      </InputForm>

      <Button
        disabled={statusCalculate === EStatus.loading || checkSubAccountCalculationStatus()}
        loading={statusCalculate === EStatus.loading}
        onClick={handleOnCalculate}
        background={checkSubAccountCalculationStatus() ? 'orange' : 'green'}
      >
        { checkSubAccountCalculationStatus() ? 'В процессе...' : 'Рассчитать плечи'}
      </Button>
    </div>
  ) : null;
};

export default AutoLeveragesCalculator;
