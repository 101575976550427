import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/app/store/store';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { START_PAGE } from 'src/shared/constants/constants';
import { saveTokensLocalStorage } from 'src/shared/libs/helpers/save-tokens';
import { Login } from 'src/pages/auth/login/model/types';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import AuthService, { SendCodeLogin } from 'src/services/auth.service';

import { Nullable } from 'src/shared/types/global-types';
import InputText from '../../../components/inputs-auth/input-auth';
import InputPassword from '../../../components/inputs-auth/input-password';
import RestorePassword from '../respotore-password/restore-password';
import LoginVerificationForm from '../login-verification-form/login-verification-form';
import { setAuth, setSessionId } from '../../model/slice';

import styles from './auth-form.module.scss';

const schema = yup.object().shape({
  email: yup.string().required('Электронная почта обязательна').email('Введите корректный адрес электронной почты'),
  password: yup.string().required('Пароль обязателен'),
});

export type LoginForm = {
  email: string,
  password: string
}

const AuthForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [verifyFormVisible, setVerifyFormVisible] = useState(false);
  const [userData, setUserData] = useState<Nullable<LoginForm>>(null);
  const [verificationId, setVerificationId] = useState(0);
  const [methods, setMethods] = useState<string[]>([]);

  const { setAlertMessage } = useAlert();
  const [loading, setLoading] = useState(false);

  const form = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, formState } = form;
  const { errors } = formState;

  const handleFetchVerification = async (methods: string[], verificationId: number, userData: LoginForm) => {
    let result = false;

    const sendCodeData: SendCodeLogin = {
      ...userData,
      verification_id: verificationId,
    };

    if (methods.includes('phone')) {
      const { data } = await AuthService.sendCodePhoneLogin(sendCodeData);

      if (data) {
        result = true;
      } else {
        result = false;
      }
    }
    if (methods.includes('whatsapp')) {
      const { data } = await AuthService.sendCodeWhatsAppLogin(sendCodeData);

      if (data) {
        result = true;
      } else {
        result = false;
      }
    }
    if (methods.includes('ga')) {
      result = true;
    }

    return result;
  };

  const onSubmit = async (userData: LoginForm) => {
    setLoading(true);

    const loginVerify: Login = {
      email: userData.email,
      password: userData.password,
    };

    try {
      const responseAuth = await AuthService.login(loginVerify);

      /* If active 2FA authorization */
      if (responseAuth.methods && responseAuth.verification_id) {
        const result = await handleFetchVerification(responseAuth.methods, responseAuth.verification_id, userData);

        if (result) {
          setUserData(userData);
          setMethods(responseAuth.methods);
          setVerificationId(responseAuth.verification_id);
          setVerifyFormVisible(true);
        } else {
          setAlertMessage('Слишком много попыток. Повторите через 10 секунд', 'warning');
        }
      }

      /* If not active 2FA authorization */
      if (responseAuth.access_token && responseAuth.refresh_token) {
        saveTokensLocalStorage(responseAuth);
        dispatch(setAuth(true));
        navigate(START_PAGE);

        if (responseAuth.session_id) dispatch(setSessionId(responseAuth.session_id));
      }
    } catch (error: unknown) {
      if (typeof error === 'string') {
        if (error === 'INVALID_EMAIL_OR_PASSWORD') {
          form.setError('email', {
            type: 'manual',
            message: '',
          });
          form.setError('password', {
            type: 'manual',
            message: 'Неправильный логин или пароль',
          });
        } else if (error === 'TOO_MANY_REQUESTS') {
          setAlertMessage('Слишком много попыток. Повторите через 10 секунд', 'warning');
        } else {
          setAlertMessage(error, 'error');
        }
      } else {
        setAlertMessage('Произошла внутренняя ошибка', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!verifyFormVisible && (
        <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputText
                error={!!errors.email}
                placeholder="Электронная почта"
                label="E-mail"
                {...field}
              />
            )}
          />
          {errors.email && errors.email.message && <Hint error text={errors.email.message} />}

          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputPassword
                error={!!errors.password}
                placeholder="Введите пароль"
                label="Пароль"
                {...field}
              />
            )}
          />

          {errors.password && errors.password.message && <Hint error text={errors.password.message} />}

          <RestorePassword />
          <Button type="submit" loading={loading} background="green">Войти</Button>
        </form>
      )}

      <LoginVerificationForm verificationId={verificationId} userData={userData} isVisible={verifyFormVisible} methods={methods} />
    </>
  );
};

export default AuthForm;
