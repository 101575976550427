import { ReactComponent as TipIconSvg } from 'src/shared/assets/images/tip-icon.svg';

import './advice-paragraph.scss';
import { join } from 'src/shared/libs/helpers/helper.lib';

interface IAdviceParagraphProps {
  children: React.ReactNode;
  big?: boolean
  warning?: boolean
  isHidden?: boolean
  maxWidth?: string
}

const AdviceParagraph = ({
  children, big, warning, isHidden, maxWidth,
}: IAdviceParagraphProps) => {
  const styles = maxWidth ? { maxWidth } : {};

  return (!isHidden ? (
    <div style={styles} className={join('advice-paragraph', big && 'big-black', warning && 'warning')}>
      <TipIconSvg />
      <p>{children}</p>
    </div>
  ) : null);
};

export default AdviceParagraph;
