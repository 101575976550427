import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import Empty from 'src/entities/empty/empty';

import './news.scss';

const pageTitle = 'Новости';

const NewsPage = () => {
  useChangePageTitle(pageTitle);

  return (
    <div className="news-page">
      <Empty>Эта страница в процессе разработки ...</Empty>
    </div>
  );
};

export default NewsPage;
