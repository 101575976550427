import { LoadingOverlay } from '@mantine/core';
import styles from './loading-page-state.module.scss';

const LoadingPageState = () => (
  <div className={styles.loadingWrapper}>
    <LoadingOverlay
      className="loader"
      visible
      zIndex={1000}
      overlayProps={{ radius: 'sm', blur: 2 }}
      loaderProps={{ color: '#00C37C', type: 'dots' }}
    />
  </div>
);

export default LoadingPageState;
