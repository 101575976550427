import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { fetchNotifications, fetchNotificationsMarkAsRead, fetchUpdateNotificationsReadStatus } from './thunks';
import { INotificationsSlice, Notification, NotificationReadEvent } from './types';

const initialState: INotificationsSlice = {
  notifications: null,
  status: EStatus.success,
  statusAllRead: EStatus.success,
  statusMarkAsRead: EStatus.success,
  statusMarkAsDelete: EStatus.success,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotificationsSlice: (state) => {
      state.notifications = null;
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      if (state.notifications) {
        state.notifications.items.unshift(action.payload);
      } else {
        state.notifications = {
          items: [action.payload],
          meta: {
            total_items: 1,
            total_pages: 1,
          },
        };
      }
    },
    deleteNotification: (state, action: PayloadAction<number[]>) => {
      if (state.notifications) {
        state.notifications.items = state.notifications.items.filter(
          (item) => !action.payload.includes(item.id),
        );
      }
    },
    markAllRead: (state) => {
      if (state.notifications) {
        state.notifications.items = state.notifications.items.map((notification) => ({
          ...notification,
          read: true,
        }));
      }
    },
    markReadByIds: (state, action: PayloadAction<NotificationReadEvent[]>) => {
      const updatedIds = action.payload;

      if (state.notifications && state.notifications.items) {
        // Create a new array with updated notifications
        state.notifications.items = state.notifications.items.map((notification) => {
          // Check if the current notification exists in the updatedIds array
          const updatedNotification = updatedIds.find((updateNotification) => updateNotification.id === notification.id);
          if (updatedNotification) {
            // Return a new notification object with the updated read state
            return {
              ...notification,
              read: updatedNotification.read,
            };
          }
          // If the notification is not found in the updatedIds array, return it unchanged
          return notification;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = EStatus.loading;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = EStatus.success;

        if (!state.notifications) {
          state.notifications = action.payload;
        } else {
          state.notifications.items = [...state.notifications.items, ...action.payload.items];
        }
      })
      .addCase(fetchNotifications.rejected, (state) => {
        state.status = EStatus.rejected;
        state.notifications = null;
      })

      .addCase(fetchUpdateNotificationsReadStatus.pending, (state) => {
        state.statusAllRead = EStatus.loading;
      })
      .addCase(fetchUpdateNotificationsReadStatus.fulfilled, (state, action) => {
        state.statusAllRead = EStatus.success;
      })
      .addCase(fetchUpdateNotificationsReadStatus.rejected, (state) => {
        state.statusAllRead = EStatus.rejected;
      })

      .addCase(fetchNotificationsMarkAsRead.pending, (state) => {
        state.statusMarkAsRead = EStatus.loading;
      })
      .addCase(fetchNotificationsMarkAsRead.fulfilled, (state) => {
        state.statusMarkAsRead = EStatus.success;
      })
      .addCase(fetchNotificationsMarkAsRead.rejected, (state) => {
        state.statusMarkAsRead = EStatus.rejected;
      });
  },
});

export const {
  clearNotificationsSlice, addNotification, markAllRead, markReadByIds, deleteNotification,
} = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
