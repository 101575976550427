import { RootState } from 'src/app/store/store';

export const selectTrading = (state: RootState) => state.trading;
export const selectPositions = (state: RootState) => state.trading.positions;
export const selectStatusPositions = (state: RootState) => state.trading.status;
export const selectStatusOrders = (state: RootState) => state.trading.statusOrders;
export const selectStatusHidden = (state: RootState) => state.trading.statusHidden;
export const selectPositionsPnl = (state: RootState) => state.trading.pnl;
export const selectOrders = (state: RootState) => state.trading.orders;
export const selectStatusClosePositions = (state: RootState) => state.trading.statusAll;
export const selectStatusClosePosition = (state: RootState) => state.trading.statusClose;
export const selectStatusOrderCancel = (state: RootState) => state.trading.statusOrderCancel;
export const selectStatusCloseOrders = (state: RootState) => state.trading.statusOrderCancelAll;
export const selectStatusReduce = (state: RootState) => state.trading.statusReduce;
