import {
  useRef,
  useState,
  useEffect,
  FormEventHandler,
} from 'react';
import { Modal, LoadingOverlay, Tooltip } from '@mantine/core';
import { AxiosError } from 'axios';
import { useDisclosure } from '@mantine/hooks';

import { IpAddress } from 'src/app/store/slices/user/types';
import { useSelector, batch } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import { selectKycVerify, selectUser } from 'src/app/store/slices/user/selectors';
import { removeVerifications } from 'src/app/store/slices/verifications/slice';
import { EVerificationType, Verification } from 'src/app/store/slices/verifications/types';
import { selectVerifications } from 'src/app/store/slices/verifications/selectors';
import { clearApiKeys, removeApiKey } from 'src/app/store/slices/sub-accounts/slice';
import { cleareIpAddress, removeIpAddress } from 'src/app/store/slices/user/slice';

import {
  CreateIpAddressParams, DeleteIpAddress, fetchUserAddIpAddresses, fetchUserDeleteIpAddresses, fetchUserIpAddresses,
} from 'src/app/store/slices/user/thunks';
import { ApiKey, SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import {
  CreateApiKeyParams, EditApiKeyParams, RemoveApiKeyParams, fetchApiKey, fetchCreateApiKey, fetchEditApiKey, fetchRemoveApiKey,
} from 'src/app/store/slices/sub-accounts/thunks';
import {
  selectApiKeys, selectApiKeysCreateStatus, selectApiKeysEditStatus, selectApiKeysGetStatus, selectSubAccounts,
} from 'src/app/store/slices/sub-accounts/selectors';
import {
  Confirm, VerificationConfirm, VerificationsSendEmailCode, VerificationsSendPhoneCode, VerificationsSendWhatsAppCode, fetchVerifications, fetchVerificationsConfirm, fetchVerificationsSendEmailCode, fetchVerificationsSendPhoneCode, fetchVerificationsSendWhatsAppCode,
} from 'src/app/store/slices/verifications/thunks';

import { manipulateString } from 'src/pages/profile/libs/helpers/manipulate-string';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import {
  handleSupportTelegram, hideEmail, join, sortSubAccountsByFavorite,
} from 'src/shared/libs/helpers/helper.lib';

import Hint from 'src/shared/ui/help/hint';
import Empty from 'src/entities/empty/empty';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import Toogler from 'src/shared/ui/toogler/toogler';
import Passport from 'src/entities/passport/password';
import useAlert from 'src/shared/libs/hooks/use-alert';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';
import useMobile from 'src/shared/libs/hooks/useMobile';
import InputText from 'src/shared/ui/input/input-text/input-text';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import Delimiter from 'src/shared/ui/delimiter/delimiter/delimiter';
import InputCode from 'src/shared/ui/input/input-code/input-code';
import InputRadio from 'src/shared/ui/input/input-radio/input-radio';
import InputAction from 'src/pages/auth/components/inputs-auth/input-action';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import LockIconSvg from 'src/shared/assets/images/lock.svg';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DefaultIconSvg from 'src/shared/assets/images/lot.png';
import AdviceParagraph from 'src/shared/ui/paragraph/advice-paragraph/advice-paragraph';
import validateFormNewApi from 'src/pages/profile/libs/helpers/validate-form-new-api';

import { ReactComponent as WrenchIconSvg } from 'src/shared/assets/images/wrench.svg';
import { ReactComponent as BasketIconSvg } from 'src/shared/assets/images/basket.svg';
import { ReactComponent as ShieldIconSvg } from 'src/shared/assets/images/profile/shield.svg';
import ApiKeysTableAdaptive from './adaptive/api-keys-table-adaptive';
import { ACCESS_TYPE, PERMISSIONS } from '../../constants';
import './api-keys-slide.scss';

const findRenderPermissions = (permissionsTypes: typeof PERMISSIONS, permisions: number[]) => {
  let returnResult: string[] = ['Чтение'];

  const result = permissionsTypes
    .filter((item) => permisions.includes(item.type))
    .map((item) => item.name);

  return returnResult.concat(result);
};

const ApiKeysSlide = () => {
  const [openedSecret, { open: openSecret, close: closeSecret }] = useDisclosure(false);
  const [openedVerify, { open: openVerify, close: closeVerify }] = useDisclosure(false);
  const { getSubAccountNameById } = useSubAccount();
  const { getExchangeImageById } = useExchange();
  const { setAlertMessage } = useAlert();
  const isMobile = useMobile();

  const dispatch = useAppDispatch();
  const apiKeys = useSelector(selectApiKeys);
  const subAccounts = useSelector(selectSubAccounts);
  const verifyKYC = useSelector(selectKycVerify);
  const statusGetApiKeys = useSelector(selectApiKeysGetStatus);
  const statusCreateApiKey = useSelector(selectApiKeysCreateStatus);
  const statusEditApiKey = useSelector(selectApiKeysEditStatus);
  const { statusAddIpAdress, IpAddresses } = useSelector(selectUser);
  const { verification, statusConfirm } = useSelector(selectVerifications);
  const { user, settings } = useSelector(selectUser);

  const verifyFormRef = useRef<HTMLFormElement>(null);

  // verify modal from states
  const [codeEmail, setCodeEmail] = useState('');
  const [codePhone, setCodePhone] = useState('');
  const [codeGA, setCodeGA] = useState('');

  const [editInput, setEditInput] = useState<'createApi' | 'editApi' | 'deleteApi'| 'createIp' | 'deleteIp' | ''>('');
  const [statusCreateApikey, setStatusCreateApiKey] = useState(false);
  const [verifyInputError, setVerifyInputError] = useState({
    emailError: false,
    phoneError: false,
    gaError: false,
  });

  const [apiKeyName, setApiKeyName] = useState('');
  const [tooglerEditApiKey, setTooglerEditApiKey] = useState(false);
  const [secret, setSecret] = useState({ apiKey: '', apiSecret: '' });
  const [selectedIpAddress, setSelectedIpAddress] = useState<IpAddress[]>([]);
  const [subAccount, setSubAccount] = useState<Nullable<SubAccountType>>(null);
  const [apiKeyValidation, setApiKeyValidation] = useState(false);
  const [ipAddressId, setIpAddressIp] = useState(0);

  const [selectPermissions, setSelectPermissions] = useState<number[]>([]);
  const [selectedApiKey, setSelectedApiKey] = useState<Nullable<ApiKey>>(null);
  const [accessIpAddress, setAccessIpAddress] = useState('Неограниченный');

  const [ipLabel, setIpLabel] = useState('');
  const [ipAddresses, setIpAddresses] = useState('');
  const [addIpOpen, setAddIpOpen] = useState(false);
  const [ipAddressValidationError, setIpAddressValidationError] = useState({ title: '', state: false });
  const [ipLabelValidationError, setIpLabelValidationError] = useState({ title: '', state: false });

  const handleFetchVerification = async (verificationType: EVerificationType) => {
    let result: string | boolean = false;

    const { payload } = await dispatch(fetchVerifications(verificationType));
    const responseVerification = payload as Verification;

    if (payload === 'TOO_MANY_REQUESTS') {
      result = payload;
      return result;
    }

    if (responseVerification && user) {
      const sendPhoneCodeParams: VerificationsSendPhoneCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: responseVerification.verification_id,
      };

      if (responseVerification.methods.includes('phone')) {
        const { payload } = await dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));

        if (payload) {
          result = true;
        }
      }

      if (responseVerification.methods.includes('whatsapp')) {
        const { payload } = await dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));

        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('email')) {
        dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('ga')) {
        if (payload) {
          result = true;
        }
      }
    } else {
      setAlertMessage('Произошла внутренняя ошибка!', 'error');
    }

    return result;
  };

  const closeModalSecret = () => {
    closeSecret();
    setSecret({ apiKey: '', apiSecret: '' });
  };

  const handlePermissions = (type: number) => {
    if (type === 0) return;

    if (selectPermissions.includes(type)) {
      setSelectPermissions((prev) => prev.filter((item) => item !== type));
    } else {
      setSelectPermissions((prev) => [...prev, type]);
    }
  };

  const handleDeleteIpAddress = async (ipAddressId: number) => {
    setEditInput('deleteIp');
    setIpAddressIp(ipAddressId);

    const result = await handleFetchVerification(EVerificationType.DELETE_IP_ADDRESS);

    if (result === true) {
      openVerify();
    } else {
      setStatusCreateApiKey(false);
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const deleteIpAddress = async () => {
    if (!verification) return;

    const params: DeleteIpAddress = {
      ip_address_id: ipAddressId,
      verification_id: verification.verification_id,
    };

    const { payload } = await dispatch(fetchUserDeleteIpAddresses(params));

    if (payload) {
      dispatch(removeIpAddress(ipAddressId));
      setAlertMessage('IP-Адрес удален', 'success');
    } else {
      setAlertMessage('Ошибка при удалении IP-адреса ', 'error');
    }
  };

  const handleCreateIpAddress = async () => {
    if (!verifyKYC) {
      setAlertMessage('Сначала пройдите верификацию!', 'warning');
      return;
    }

    setEditInput('createIp');

    const result = await handleFetchVerification(EVerificationType.CREATE_IP_ADDRESS);

    if (result === true) {
      openVerify();
    } else if (result === 'TOO_MANY_REQUESTS') {
      setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
    } else {
      setStatusCreateApiKey(false);
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const createIpAddress = async () => {
    if (!verification) return;

    const ipLabelValidationResult = validateFormNewApi(ipLabel, ipAddresses);

    if (ipLabelValidationResult.state) {
      setIpLabelValidationError(ipLabelValidationResult);
      return;
    }

    setIpAddressValidationError({ title: '', state: false });

    const createIpAddress: CreateIpAddressParams = {
      newIpAddress: {
        ip: ipAddresses,
        user_name: ipLabel,
      },
      verification_id: verification.verification_id,
    };

    const { payload } = await dispatch(fetchUserAddIpAddresses(createIpAddress));

    if (typeof payload === 'object') {
      setAlertMessage('IP-Адрес успешно добавлен', 'success');
      setAddIpOpen(false);
      setIpLabel('');
      setIpAddresses('');
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('Произошла ошибка при создании IP-Адреса', 'error');
    }
  };

  const handleEditApiKey = async (apiKey: ApiKey) => {
    setEditInput('editApi');
    setSelectedApiKey(apiKey);
    setTooglerEditApiKey(true);

    const findSubAccountById = subAccounts?.find((subAccount) => subAccount.id === apiKey.sub_account_id);

    if (findSubAccountById) {
      setSubAccount(findSubAccountById);
      setApiKeyName(apiKey.user_name);
      setSelectPermissions(apiKey.permissions);

      if (apiKey.ip_addresses.length > 0) {
        setAccessIpAddress('Только к проверенным IP-адресам');
      } else {
        setAccessIpAddress('Неограниченный');
      }

      setSelectedIpAddress(apiKey.ip_addresses);
    }
  };

  const handleVerifyEditApiKey = async () => {
    const result = await handleFetchVerification(EVerificationType.CHANGE_API_KEY);

    if (result === true) {
      openVerify();
    } else if (result === 'TOO_MANY_REQUESTS') {
      setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const handleCancleEditApiKey = () => {
    setSelectedApiKey(null);
    setApiKeyName('');
    setSelectPermissions([]);
    setSelectedIpAddress([]);
    setAccessIpAddress('Неограниченный');
    setEditInput('createApi');
    setTooglerEditApiKey(false);
  };

  const editApiKey = async () => {
    if (!selectedApiKey || !verification) return;

    const params: EditApiKeyParams = {
      editApiKey: {
        secret_id: selectedApiKey.id,
        user_name: apiKeyName.trim(),
        permissions: selectPermissions.filter((permission) => permission !== 0),
        ip_addresses: selectedIpAddress.map((item) => item.ip),
      },
      verification_id: verification.verification_id,
    };

    const { payload } = await dispatch(fetchEditApiKey(params));

    if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else if (typeof payload === 'object') {
      setAlertMessage('Api-ключ изменен', 'success');
      setEditInput('createApi');
      setTooglerEditApiKey(false);
      setAccessIpAddress('Неограниченный');
    } else {
      console.log(payload);
      setAlertMessage(JSON.stringify(payload), 'warning');
    }
  };

  const handleCreateApiKey = async () => {
    if (!verifyKYC) {
      setAlertMessage('Сначала пройдите верификацию!', 'warning');
      return;
    }

    setEditInput('createApi');
    setStatusCreateApiKey(true);

    const result = await handleFetchVerification(EVerificationType.CREATE_API_KEY);

    if (result === true) {
      setStatusCreateApiKey(false);
      openVerify();
    } else if (result === 'TOO_MANY_REQUESTS') {
      setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
      setStatusCreateApiKey(false);
    } else {
      setStatusCreateApiKey(false);
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const createApiKey = async () => {
    if (!verifyKYC) {
      setAlertMessage('Сначала пройдите верификацию!', 'warning');
      return;
    }

    if (!subAccount || !verification) return;

    const params: CreateApiKeyParams = {
      newApiKey: {
        exchange_id: subAccount.exchange_id,
        sub_account_id: subAccount.id,
        user_name: apiKeyName.trim(),
        permissions: selectPermissions.filter((permission) => permission !== 0),
        ip_addresses: selectedIpAddress.map((item) => item.ip),
      },
      verification_id: verification.verification_id,
    };

    const { payload } = await dispatch(fetchCreateApiKey(params));

    if (typeof payload === 'object') {
      const apiKey = payload as ApiKey;
      setSecret({ apiKey: apiKey.api_key, apiSecret: apiKey.api_secret });
      setApiKeyName('');
      openSecret();
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      console.log(payload);
      setAlertMessage(JSON.stringify(payload), 'warning');
    }
  };

  const handleDeleteApiKey = async (apiKey: ApiKey) => {
    setEditInput('deleteApi');
    setSelectedApiKey(apiKey);

    const result = await handleFetchVerification(EVerificationType.DELETE_API_KEY);

    if (result === true) {
      openVerify();
    } else if (result === 'TOO_MANY_REQUESTS') {
      setAlertMessage('Слишком много попыток. Повторите через 2 минуты', 'warning');
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const handleSetSubAccount = (subAccount: SubAccountType) => {
    setSubAccount(subAccount);
  };

  const deleteApiKey = async () => {
    if (!selectedApiKey || !verification) return;

    const params: RemoveApiKeyParams = {
      secret_id: selectedApiKey.id,
      verification_id: verification.verification_id,
    };

    const { payload } = await dispatch(fetchRemoveApiKey(params));

    if (payload === true) {
      setAlertMessage('Api-ключ удален', 'success');
      dispatch(removeApiKey(selectedApiKey.id));
    } else if (payload instanceof AxiosError && payload.response) {
      const { detail } = payload.response.data;
      const message = typeof detail === 'string' ? detail : JSON.stringify(detail);

      setAlertMessage(message, 'error');
    } else {
      setAlertMessage('Не удалось удалить API-ключ', 'error');
    }
  };

  const handleSelectedIpAddress = (ipAddress: IpAddress) => {
    const findIpAddress = selectedIpAddress.find((item) => item.id === ipAddress.id);

    if (findIpAddress) {
      setSelectedIpAddress((prev) => prev.filter((item) => item.id !== ipAddress.id));
    } else {
      setSelectedIpAddress((prev) => [...prev, ipAddress]);
    }
  };

  const clearVerifyInputs = () => {
    setCodeEmail('');
    setCodePhone('');
    setCodeGA('');
  };

  const handleCloseVerify = () => {
    if (statusConfirm === EStatus.loading) return;

    dispatch(removeVerifications());
    clearVerifyInputs();
    setVerifyInputError({
      emailError: false,
      phoneError: false,
      gaError: false,
    });
    closeVerify();
  };

  const handleSetAccessIpAddress = (label: string) => {
    setAccessIpAddress(label);

    if (label === 'Неограниченный') setSelectedIpAddress([]);
  };

  const handleSubmitVerification: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (responseConfirm.payload === true) {
          closeVerify();
          clearVerifyInputs();

          if (editInput === 'editApi') editApiKey();
          if (editInput === 'createApi') createApiKey();
          if (editInput === 'deleteApi') deleteApiKey();
          if (editInput === 'createIp') createIpAddress();
          if (editInput === 'deleteIp') deleteIpAddress();
        } else if (responseConfirm.payload === 'VERIFICATION_NOT_FOUND') {
          setAlertMessage('Время действия запроса истекло. Пожалуйста, повторите попытку.', 'error');
          closeVerify();
          clearVerifyInputs();
        } else if (verification) {
          if (verification.methods.includes('email')) {
            setVerifyInputError((prev) => (
              { ...prev, emailError: true }
            ));
          }
          if (verification.methods.includes('phone')) {
            setVerifyInputError((prev) => (
              { ...prev, phoneError: true }
            ));
          }
          if (verification.methods.includes('whatsapp')) {
            setVerifyInputError((prev) => (
              { ...prev, phoneError: true }
            ));
          }
          if (verification.methods.includes('ga')) {
            setVerifyInputError((prev) => (
              { ...prev, gaError: true }
            ));
          }
        }
      }
    }
  };

  const handleAutoSubmit = async () => {
    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (!responseConfirm.payload === true) {
          if (verification) {
            if (verification.methods.includes('email')) {
              setVerifyInputError((prev) => (
                { ...prev, emailError: true }
              ));
            }
            if (verification.methods.includes('phone')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('whatsapp')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('ga')) {
              setVerifyInputError((prev) => (
                { ...prev, gaError: true }
              ));
            }
          }
        } else if (responseConfirm.payload === 'VERIFICATION_NOT_FOUND') {
          setAlertMessage('Время действия запроса истекло. Пожалуйста, повторите попытку.', 'error');
          closeVerify();
          clearVerifyInputs();
        } else {
          closeVerify();
          clearVerifyInputs();

          if (editInput === 'editApi') editApiKey();
          if (editInput === 'createApi') createApiKey();
          if (editInput === 'deleteApi') deleteApiKey();
          if (editInput === 'createIp') createIpAddress();
          if (editInput === 'deleteIp') deleteIpAddress();
        }
      }
    }
  };

  const handleFetchCodeSms = () => {
    if (user && verification && settings) {
      if (settings.type_sms_code === 'phone') {
        const sendPhoneCodeParams: VerificationsSendPhoneCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };
        dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));
      } else {
        const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };
        dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));
      }
    }
  };

  const handleFetchCodeEmail = () => {
    if (user && verification) {
      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: verification.verification_id,
      };

      dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
    }
  };

  const renderVerificationForm = (methods: string[]) => {
    const formElements: React.ReactNode[] = [];

    if (methods.includes('email')) {
      formElements.push(
        <div key="email-input" className="verification-input">
          <InputAction
            label="Код из Письма"
            placeholder="Код"
            type="number"
            actionTitle="Отправить код"
            onClick={handleFetchCodeEmail}
            actionActive
            error={verifyInputError.emailError}
            {...{
              value: codeEmail,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodeEmail(e.target.value),
            }}
          />
          <span className="verification-input-text">Код подтверждения будет отправлен на {hideEmail(user?.email)}</span>
        </div>,
      );
    }

    if (methods.includes('phone')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label="Код из СМС"
            placeholder="Код"
            actionTitle="Отправить код"
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('whatsapp')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label="Код из WhatsApp"
            placeholder="Код"
            actionTitle="Отправить код"
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('ga')) {
      formElements.push(
        <div key="ga-input" className="verification-input">
          <InputForm title="Код Google Authenticator">
            <InputCode onChange={setCodeGA} error={verifyInputError.gaError} />
          </InputForm>
        </div>,
      );
    }

    formElements.push(
      <div key="modal-hint" className="verification-input">
        <Hint
          hintOpen
          position="bottom-start"
          text="Возникли проблемы с верификацией?"
          hintText={(
            <div>
              Код с почты не подходит:
              <br />
              - Возможно, срок действия кода истек, запросите новый код по кнопке - Отправить код
              <br />
              - Убедитесь в корректности почты
              <br />
              <br />
              Код Google Autenticator не подходит:
              <br />
              - Синхронизируйте время между устройствами. Если вы используете GA на Андроид, воспользуйтесь функцией - Синхронизация времени
              <br />
              - Убедитесь что вы вводите код для аккаунта league.broker
              <br />
              <br />
              Если какой то из кодов по прежнему не подходит, обратитесь в тех. поддержку <button onClick={handleSupportTelegram}>Телеграмм</button>
            </div>
          )}
        />
      </div>,
    );

    formElements.push(
      <div key="verify-modal-action" className="verify-modal-action">
        <Button
          disabled={statusConfirm === EStatus.loading}
          loading={statusConfirm === EStatus.loading}
          type="submit"
          onClick={() => {}}
          background="green"
        >Подтвердить
        </Button>
        <Button disabled={statusConfirm === EStatus.loading} onClick={handleCloseVerify} background="gray">Отмена</Button>
      </div>,
    );

    return <form onSubmit={handleSubmitVerification} ref={verifyFormRef} className="verification-form-wrapper">{formElements}</form>;
  };

  useEffect(() => {
    if (!verification) return;

    const checkOnlyGA = verification.methods.every((method) => method === 'ga');

    if (openedVerify && !checkOnlyGA) {
      setTimeout(() => {
        if (verifyFormRef.current) {
          const inputElement = verifyFormRef.current[0] as HTMLInputElement;
          inputElement.focus();
        }
      }, 100);
    }
  }, [verifyFormRef.current, openedVerify, verification]);

  useEffect(() => {
    batch(() => {
      dispatch(fetchApiKey());
      dispatch(fetchUserIpAddresses());
    });

    return () => {
      dispatch(clearApiKeys());
      dispatch(cleareIpAddress());
    };
  }, []);

  useEffect(() => {
    if (subAccounts) {
      const [favoriteSubAccount] = subAccounts.slice().sort(sortSubAccountsByFavorite);
      if (favoriteSubAccount) setSubAccount(favoriteSubAccount);
    }
  }, [subAccounts]);

  useEffect(() => {
    if (verification) {
      const onlyGa = verification.methods.every((method) => method === 'ga') && verification.methods.includes('ga');
      const onlyEmail = verification.methods.every((method) => method === 'email') && verification.methods.includes('email');
      const hasGaAndEmail = verification.methods.includes('ga') && verification.methods.includes('email');
      const hasGaAndPhone = verification.methods.includes('ga') && verification.methods.includes('phone');
      const hasGaAndWhatsapp = verification.methods.includes('ga') && verification.methods.includes('whatsapp');

      if (onlyGa) {
        if (codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (onlyEmail) {
        if (codeEmail.length === 5) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndEmail) {
        if (codeEmail.length === 5 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndPhone) {
        if (codePhone.length === 5 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndWhatsapp) {
        if (codePhone.length === 4 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }
    }
  }, [codeGA, codeEmail, verification]);

  return (
    <div className="slide api-keys-slide">
      <div className="apikey-list-wrapper">

        <div className={join('apikey-list-container', !verifyKYC && 'not_active')}>
          <h4 className="title">API-ключи</h4>
          { !isMobile && verifyKYC && (
            <div className="apikey-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Название</th>
                    <th>Счет</th>
                    <th>API-ключ</th>
                    <th>Разрешения</th>
                    <th>Действие</th>
                  </tr>
                </thead>

                {statusGetApiKeys === EStatus.success && (
                  <tbody>
                    {apiKeys?.map((apiKey) => (
                      <tr key={apiKey.id}>
                        <td className="name"><div className="name-tr">{apiKey.user_name}</div></td>
                        <td>
                          <div className="account-tr">
                            <img src={getExchangeImageById(apiKey.exchange_id) || DefaultIconSvg} alt="api-key-exchange" />
                            {getSubAccountNameById(apiKey.sub_account_id)}
                          </div>
                        </td>
                        <td><div className="apikey-tr">{manipulateString(apiKey.api_key)}</div></td>
                        <td className="permissions">
                          <ul>
                            {findRenderPermissions(PERMISSIONS, apiKey.permissions).map((permission) => (
                              <li key={permission}>{permission}</li>
                            ))}
                          </ul>
                        </td>
                        <td className="actions">

                          <Tooltip withArrow label="Редактировать" position="bottom" offset={9}>
                            <button onClick={() => handleEditApiKey(apiKey)}><WrenchIconSvg /></button>
                          </Tooltip>

                          <Tooltip withArrow label="Удалить" position="bottom" offset={9}>
                            <button onClick={() => handleDeleteApiKey(apiKey)}><BasketIconSvg /></button>
                          </Tooltip>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>

              {statusGetApiKeys === EStatus.success && apiKeys && apiKeys.length === 0 && (
                <div className="api-keys-state-view">
                  <Empty>
                    Здесь будут отображаться <br /> ваши API-ключи
                  </Empty>
                </div>
              ) }
              {statusGetApiKeys === EStatus.loading && (
                <div className="api-keys-state-view">
                  <LoadingOverlay
                    className="loader"
                    visible
                    style={{ height: '100%' }}
                    zIndex={1000}
                    overlayProps={{ radius: 'sm', blur: 2 }}
                    loaderProps={{ color: '#00C37C', type: 'dots' }}
                  />
                </div>
              ) }
              {statusGetApiKeys === EStatus.rejected && (<div className="api-keys-state-view">Произошла внутренняя ошибка <br /> при получении API-ключей</div>)}
            </div>
          )}

          {!isMobile && (
            <div className="apikey-slide-passport">
              <Passport visible={!!verifyKYC} />
            </div>
          )}

          {isMobile && <ApiKeysTableAdaptive selectedApiKey={selectedApiKey} editApiKey={handleEditApiKey} />}
        </div>
      </div>

      <div className="create-apikey-container modal">
        <h4 className="title">
          {!tooglerEditApiKey && <span>Создание API-ключа</span>}
          {tooglerEditApiKey && <span>Редактирование <br /> API-ключа</span>}
        </h4>

        <div className={join('select-wallet', editInput === 'editApi' && 'disabled-select')}>
          <InputForm title="Выбор счета">
            { subAccounts && subAccount ? (
              <Select
                disabledSelect={editInput === 'editApi'}
                isActiveSearch={false}
                isSelectValue={(
                  <div className="instrument">
                    <img src={getExchangeImageById(subAccount.exchange_id)} alt="exchange" />
                    <span className="short-name">{subAccount.user_name}</span>
                    {editInput === 'editApi' && <img className="disabled-select-img" src={LockIconSvg} alt="lock" />}
                  </div>
                )}
              >
                {
                  subAccounts.slice().sort(sortSubAccountsByFavorite).map((subAccount) => (
                    <div
                      key={subAccount.id}
                      className="instrument"
                      role="button"
                      tabIndex={0}
                      onClick={() => setSubAccount(subAccount)}
                      onKeyDown={() => handleSetSubAccount(subAccount)}
                    >
                      <img src={getExchangeImageById(subAccount.exchange_id) || DefaultIconSvg} alt="exchange" />
                      <span className="short-name">{subAccount.user_name}</span>
                    </div>
                  ))
                }
              </Select>
            )
              : <SelectSkeleton />}
          </InputForm>
        </div>

        <div className="apikey-name">
          <h5>Название</h5>
          <InputText
            value={apiKeyName}
            placeholder="BinanceSpot"
            onChange={setApiKeyName}
          />
          {apiKeyValidation && <span className="validation-error"><Hint error text="Поле не может быть пустым" /></span>}
        </div>

        <div className="apikey-permissions">
          <h5>Разрешения</h5>
          {
            PERMISSIONS.map((permission) => (
              <Toogler
                key={permission.type}
                isActive={!permission.isActive || selectPermissions.includes(permission.type)}
                isActiveDisabled={permission.type === 0}
                onChange={() => handlePermissions(permission.type)}
                labelContent={permission.name}
                comment={permission.comment}
              />
            ))
          }
        </div>

        <div className="access-type">
          <h5>Доступ по IP-адресу</h5>
          {
            ACCESS_TYPE.map((type) => (
              <InputRadio
                checked={accessIpAddress === type.label}
                key={type.id}
                groupName="access-types"
                labelContent={type.label}
                comment={type.comment}
                onClick={() => handleSetAccessIpAddress(type.label)}
              />
            ))
          }
        </div>

        {accessIpAddress === 'Только к проверенным IP-адресам'
           && (
             <div className="ip-type">
               <h5>Список IP-адресов</h5>

               <ul className="ip-address-list">
                 { IpAddresses?.map((ipAddress) => (
                   <li key={ipAddress.id}>
                     <input type="checkbox" checked={selectedIpAddress.some((item) => item.id === ipAddress.id)} onChange={() => handleSelectedIpAddress(ipAddress)} />
                     <label htmlFor={`ip-address-${ipAddress.id}`}>{ipAddress.user_name}</label>
                     <span>{ipAddress.ip}</span>
                     <button onClick={() => handleDeleteIpAddress(ipAddress.id)}><BasketIconSvg /></button>
                   </li>
                 ))}
                 {IpAddresses && IpAddresses.length === 0 && <span className="ip-address-list-empty">Список пуст</span>}
               </ul>
               <AdviceParagraph>Выберите IP-адреса которые должны быть включены к текущему API-ключу</AdviceParagraph>
               <Button onClick={() => setAddIpOpen((prev) => !prev)} background="black">{!addIpOpen ? '+ Добавить новый адрес' : 'Отменить'}</Button>
               <Delimiter />

               {addIpOpen && (
                 <div className="new-ip">
                   <div className="new-ip-inputs">
                     <div>
                       <h5>Название</h5>
                       <InputText
                         value={ipLabel}
                         placeholder="IP-view12"
                         onChange={setIpLabel}
                       />
                       {ipLabelValidationError.state && <span className="validation-error"><Hint error text={ipLabelValidationError.title} /></span>}
                     </div>

                     <div>
                       <h5>IP-Адрес</h5>
                       <InputText
                         value={ipAddresses}
                         placeholder="215.215.215.215"
                         onChange={setIpAddresses}
                       />
                       {ipAddressValidationError.state && <span className="validation-error"><Hint error text={ipAddressValidationError.title} /></span>}
                     </div>
                   </div>
                   <Button loading={statusAddIpAdress === EStatus.loading} disabled={statusAddIpAdress === EStatus.loading} onClick={handleCreateIpAddress} background="green">Добавить</Button>
                   <Delimiter />
                 </div>
               )}

             </div>
           )}

        <div className="actions">
          <Button
            disabled={statusCreateApiKey === EStatus.loading || apiKeyName.length <= 1 || statusCreateApikey}
            loading={statusCreateApiKey === EStatus.loading || statusEditApiKey === EStatus.loading}
            onClick={tooglerEditApiKey ? handleVerifyEditApiKey : handleCreateApiKey}
            background={apiKeyName.length <= 1 ? 'black' : 'green'}
          >{tooglerEditApiKey ? 'Сохранить' : 'Создать'}
          </Button>
          {tooglerEditApiKey && <Button onClick={handleCancleEditApiKey} background="black">Отменить</Button>}
        </div>

        <div className="remark">
          <ShieldIconSvg />
          <p>Требуется подтверждение</p>
        </div>
      </div>

      <Modal
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="sm"
        opened={openedSecret}
        onClose={closeModalSecret}
        title={<span>API-ключ <br /> успешно создан</span>}
        closeOnClickOutside={false}
        closeOnEscape={false}
        className="modal-custom-overflow"
      >
        <div className="secret-key-modal">

          <InputForm title="API-ключ">
            <div className="secret-key-input">
              <p>{manipulateString(secret.apiKey)}</p>
              <CopyIcon value={secret.apiKey} />
            </div>
          </InputForm>

          <InputForm title="Secret-key">
            <div className="secret-key-input">
              <p>{manipulateString(secret.apiSecret)}</p>
              <CopyIcon value={secret.apiSecret} />
            </div>
          </InputForm>
          <Button onClick={closeModalSecret} background="green">Готово</Button>

          <Hint warning text="Обязательно сохраните Secret-key — он отображается только один раз! Этот ключ будет нужен, чтобы подключить личный кабинет к внешним сервисам." />

        </div>
      </Modal>

      <Modal
        title="Подтвердите действие"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        style={{ overflow: 'unset' }}
        radius={16}
        size="sm"
        opened={openedVerify}
        onClose={handleCloseVerify}
        closeOnClickOutside={false}
        closeOnEscape={false}
        className="modal-custom-overflow"
      >
        <div>
          {verification && renderVerificationForm(verification.methods)}
        </div>
      </Modal>
    </div>
  );
};

export default ApiKeysSlide;
