import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import Button from 'src/shared/ui/button/button/button';
import styles from './bonus-conversion.module.scss';

const BonusConversion = () => {
  const [autoConvert, setAutoConvert] = useState(true);

  const balanceVisible = useSelector(selectHiddenBalance);

  return (
    <section className={styles.container}>
      <div className={styles.title}>Ручная <br /> конвертация <br /> бонусов</div>

      <p className={styles.info}>Выберите монету, в которую хотите конвертировать свои бонусы, а затем укажите её количество</p>

      <ul className={styles.assets}>
        <li><button className={styles.asset}>USDT</button></li>
      </ul>

      <div className={styles.quantity}>
        <span>Количество USDT</span>
        <button>Все</button>
      </div>

      <p className={styles.available}>Доступно <span>{!balanceVisible ? parseFloat('0').toLocaleString().replace(',', '.') : HIDDEN_BALANCES}</span> USDT</p>
      <p className={styles.bonuses}>Спишется бонусов: <span>{!balanceVisible ? parseFloat('0').toLocaleString().replace(',', '.') : HIDDEN_BALANCES}</span></p>

      <Button disabled={false} loading={false} background="green">Конвертировать</Button>
    </section>
  );
};
export default BonusConversion;
