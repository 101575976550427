import { Balance, TotalBalance } from 'src/app/store/slices/balance/types';
import { SubAccountAssetUpdate, SubAccountAsset } from 'src/app/store/slices/sub-accounts/types';
import { ResponseUserKycStatus } from 'src/app/store/slices/user/types';
import {
  InstrumentUpdate, LeveragesCalculator, LeverageСhoosen, MarginModeEventData, UpdateMarginModeInstrument,
} from 'src/pages/leverages/model/types';
import { PositionSummaryRemove, PositionsSummary, PositionsSummaryUpdate } from 'src/pages/account/model/types';
import { TransferHistoryType } from 'src/widgets/transfer-history/model/types';
import { Notification, NotificationReadEvent } from 'src/widgets/notifications/model/types';
import {
  Order, Position, RemoveOrderById, UpdateOrder, UpdatePosition,
} from '../../pages/trading/model/types';
import { UpdateInstrument } from './global-types';

export enum EInputEventType {
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  PING = 'PING',
  ECHO = 'ECHO',
}

export enum EOutputEventType {
  BALANCES = 'BALANCES',
  TOTAL_BALANCE = 'TOTAL_BALANCE',
  KYC = 'KYC',
  PONG = 'PONG',
  ORDERS = 'ORDERS',
  POSITIONS = 'POSITIONS',
  TRANSFERS = 'TRANSFERS',
  MARGIN_MODE = 'MARGIN_MODE',
  LEVERAGES = 'LEVERAGES',
  COMMON_PNL = 'COMMON_PNL',
  INSTRUMENTS = 'INSTRUMENTS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  POSITIONS_SUMMARY = 'POSITIONS_SUMMARY',
  SUB_ACCOUNT_ASSETS = 'SUB_ACCOUNT_ASSETS',
  LEVERAGES_CALCULATOR = 'LEVERAGES_CALCULATOR',
  SYSTEM = 'SYSTEM',
  ACCOUNT = 'ACCOUNT',
}
// From frontend to backend
export interface ISubscribeEvent {
  event: EInputEventType.SUBSCRIBE;
  metadata: {
    event_type: EOutputEventType;
    data?: number[]
  };
}

export interface IUnsubscribeEvent {
  event: EInputEventType.UNSUBSCRIBE;
  metadata: {
    event_type: EOutputEventType;
    data?: number[]
  };
}

export interface IPingEvent {
  event: EInputEventType.PING;
}

export interface PongEvent {
  event: EOutputEventType.PONG,
  metadata: {
    event_type: 'PONG',
  }
}

export interface IEchoEvent {
  event: EInputEventType.ECHO;
  metadata: any;
}

export interface ISystemUpdatedEvent {
  event: EOutputEventType.SYSTEM;
  metadata: {
    event_type: 'MAIN_FRONTEND_UPDATED'
  }
}

export interface IAccountEvent {
  event: EOutputEventType.ACCOUNT;
  metadata: {
    event_type: 'LOGOUT',
    data: { session_id: string }
  }
}

// Position events
export interface IPositionCreateEvent {
  event: EOutputEventType.POSITIONS;
  metadata: {
    event_type: 'CREATE';
    data: Position;
  };
}

export interface IPositionUpdatedEvent {
  event: EOutputEventType.POSITIONS;
  metadata: {
    event_type: 'UPDATE';
    data: UpdatePosition;
  };
}

export interface IPositionPnlUpdatedEvent {
  event: EOutputEventType.COMMON_PNL;
  metadata: {
    event_type: 'UPDATE';
    data: string;
  };
}

export interface IPositionRemovedEvent {
  event: EOutputEventType.POSITIONS;
  metadata: {
    event_type: 'REMOVE';
    data: Position;
  };
}
// Order events
export interface IOrderCreateEvent {
  event: EOutputEventType.ORDERS;
  metadata: {
    event_type: 'CREATE';
    data: Order;
  };
}

export interface IOrderUpdatedEvent {
  event: EOutputEventType.ORDERS;
  metadata: {
    event_type: 'UPDATE';
    data: UpdateOrder;
  };
}

export interface IOrderRemovedEvent {
  event: EOutputEventType.ORDERS;
  metadata: {
    event_type: 'REMOVE';
    data: RemoveOrderById;
  };
}

// Balance events
export interface IBalancesUpdatedEvent {
  event: EOutputEventType.BALANCES;
  metadata: {
    event_type: 'UPDATE';
    data: Balance;
  };
}

export interface ITotalBalanceUpdatedEvent {
  event: EOutputEventType.TOTAL_BALANCE;
  metadata: {
    event_type: 'UPDATE';
    data: TotalBalance;
  };
}

// Leverage events
export interface ILeverageUpdatedEvent {
  event: EOutputEventType.LEVERAGES;
  metadata: {
    event_type: 'UPDATE';
    data: InstrumentUpdate;
  };
}

export interface ILeverageCalculatorEvent {
  event: EOutputEventType.LEVERAGES;
  metadata: {
    event_type: 'CALCULATOR:FINISHED';
    data: LeveragesCalculator
  };
}

export interface ILeverageСhoosenEvent {
  event: EOutputEventType.LEVERAGES;
  metadata: {
    event_type: 'FOR_SELECTED_INSTRUMENTS:FINISHED';
    data: LeverageСhoosen
  };
}

export interface ILeverageMarginModeEvent {
  event: EOutputEventType.MARGIN_MODE;
  metadata: {
    event_type: 'UPDATE';
    data: UpdateMarginModeInstrument
  };
}

export interface ILeverageMarginModeAllEvent {
  event: EOutputEventType.MARGIN_MODE;
  metadata: {
    event_type: 'FINISHED';
    data: MarginModeEventData
  };
}

// SubAccountAssets events
export interface ISubAccountAssetUpdatedEvent {
  event: EOutputEventType.SUB_ACCOUNT_ASSETS;
  metadata: {
    event_type: 'UPDATE';
    data: SubAccountAssetUpdate;
  };
}

export interface ISubAccountAssetCreatedEvent {
  event: EOutputEventType.SUB_ACCOUNT_ASSETS;
  metadata: {
    event_type: 'CREATE';
    data: SubAccountAsset;
  };
}

export interface ISubAccountAssetRemovedEvent {
  event: EOutputEventType.SUB_ACCOUNT_ASSETS;
  metadata: {
    event_type: 'REMOVE';
    data: SubAccountAsset;
  };
}

export interface IInstrumentUpdatedEvent {
  event: EOutputEventType.INSTRUMENTS,
  metadata: {
    event_type: 'UPDATE',
    data: UpdateInstrument
  }
}

export interface IKycStatusUpdatedEvent {
  event: EOutputEventType.KYC,
  metadata: {
    event_type: 'UPDATE',
    data: ResponseUserKycStatus
  }
}

export interface IPositionsSummuryCreateEvent {
  event: EOutputEventType.POSITIONS_SUMMARY,
  metadata: {
    event_type: 'CREATE',
    data: PositionsSummary
  }
}

export interface IPositionsSummuryUpdatedEvent {
  event: EOutputEventType.POSITIONS_SUMMARY,
  metadata: {
    event_type: 'UPDATE',
    data: PositionsSummaryUpdate
  }
}

export interface IPositionsSummuryRemovedEvent {
  event: EOutputEventType.POSITIONS_SUMMARY,
  metadata: {
    event_type: 'REMOVE',
    data: PositionSummaryRemove
  }
}

export interface ITransfersHistoryCreateEvent {
  event: EOutputEventType.TRANSFERS,
  metadata: {
    event_type: 'CREATE',
    data: TransferHistoryType
  }
}

export interface INotificationDeleteEvent {
  event: EOutputEventType.NOTIFICATIONS;
  metadata: {
    event_type: 'DELETE';
    data: number[];
  };
}

export interface INotificationCreatedEvent {
  event: EOutputEventType.NOTIFICATIONS;
  metadata: {
    event_type: 'CREATE';
    data: Notification;
  };
}

export interface INotificationReadEvent {
  event: EOutputEventType.NOTIFICATIONS;
  metadata: {
    event_type: 'READ';
    data: NotificationReadEvent[];
  };
}

export type PositionEventTypes = IPositionCreateEvent | IPositionUpdatedEvent | IPositionRemovedEvent | IPositionPnlUpdatedEvent
export type OrdersEventTypes = IOrderCreateEvent | IOrderUpdatedEvent | IOrderRemovedEvent
export type BalancesEventTypes = IBalancesUpdatedEvent
export type TotalBalanceEventTypes = ITotalBalanceUpdatedEvent
export type LeveragesEventTypes = ILeverageUpdatedEvent | ILeverageCalculatorEvent | ILeverageMarginModeEvent | ILeverageMarginModeAllEvent | ILeverageСhoosenEvent
export type SubAccountAssetEventTypes = ISubAccountAssetUpdatedEvent | ISubAccountAssetCreatedEvent | ISubAccountAssetRemovedEvent
export type InstrumentEventTypes = IInstrumentUpdatedEvent
export type KycEventTypes = IKycStatusUpdatedEvent
export type PositionsSummuryTypes = IPositionsSummuryCreateEvent | IPositionsSummuryUpdatedEvent | IPositionsSummuryRemovedEvent
export type TransfersTypes = ITransfersHistoryCreateEvent
export type NotificationsTypes = INotificationDeleteEvent | INotificationCreatedEvent | INotificationReadEvent
export type SystemTypes = ISystemUpdatedEvent
export type AccountTypes = IAccountEvent

export type EventTypesAll = PositionEventTypes
  | OrdersEventTypes
  | BalancesEventTypes
  | TotalBalanceEventTypes
  | LeveragesEventTypes
  | SubAccountAssetEventTypes
  | InstrumentEventTypes
  | PongEvent
  | KycEventTypes
  | TransfersTypes
  | PositionsSummuryTypes
  | NotificationsTypes
  | NotificationsTypes
  | SystemTypes
  | AccountTypes
