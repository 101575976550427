import { useState, useLayoutEffect } from 'react';

const width = window.innerWidth <= 976;

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(width);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 976);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useMobile;
