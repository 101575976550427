import { useEffect, useRef } from 'react';
import { join } from 'src/shared/libs/helpers/helper.lib';
import './input-text.scss';

export interface IInputTextProps {
  value: number | string;
  placeholder?: string;
  isDisabled?: boolean;
  onChange: (value: any) => void;
  children?: React.ReactNode;
  className?: string;
  error?: boolean;
  focus?: boolean;
  inputType?: string;
}

const InputText = ({
  value,
  placeholder,
  isDisabled,
  onChange,
  children,
  className,
  error,
  focus,
  inputType,
}: IInputTextProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focus && !isDisabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDisabled, focus]);

  return (
    <div className={join('input-text', className && className, error && 'input-error')}>
      <input
        ref={inputRef}
        type={inputType || 'text'}
        value={value}
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={(e) => onChange(e.target.value)}
      />

      {children}
    </div>
  );
};

export default InputText;
